import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useNavigate } from 'react-router-dom'


export default function ShortlistReports(props) {

    const navigate = useNavigate();

    function handleGoToReports() {
        navigate('/reports', {state: {client_profile_id: props.clientProfile.id}})
    }

    function handleGoToReportsCreate() {
        navigate('/reports', {state: {client_profile_id: props.clientProfile.id, showCreateReportModal: true}})
    }

    return (
        <div className='clickable-card' style={{backgroundColor:'white', marginBottom: '30px'}}>
            <div style={{width: '100%'}}>
                <Container fluid>
                    <Row style={{backgroundColor: 'rgb(240, 240, 240)', padding: '15px'}}>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                            <div onClick={() => {handleGoToReports()}}>
                                <h2 className='styled-header'>Shortlist Reports</h2>
                            </div>
                        </Col>
                        <Col>
                            <div style={{width: '100%'}}>
                                <i style={{float: 'right'}} onClick={() => {handleGoToReportsCreate()}} class="fa fa-2x fa-plus clickable-icon" aria-hidden="true"></i>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{padding: '15px'}}>
                        <Col md={12} lg={12}>
                            <div>
                                {
                                    props.reportsCount ?
                                    <h4>{props.reportsCount} { props.reportsCount === 1 ?<span>Report</span> : <span>Reports</span> }</h4>
                                    :
                                        <h4>No reports</h4>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>  
        </div>
    )
}
