import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { useState } from 'react'
import { createAppraisedBoatFeature, deleteAppraisedBoatFeature } from '../../../../requests/appraisal_report/appraisedBoatFeature'

export default function Features(props) {

    const [showModal, setShowModal] = useState(false);

    const [features, setFeatures] = useState(props.appraisedBoat.features)


    const [addFeature, setAddFeature] = useState('')

    const [featureError, setFeatureError] = useState('')


    function validateFeature() {
        setFeatureError('')

        if (addFeature === '') {
            setFeatureError('Please enter a feature')
            return false
        }
        return true
    }


    function handleAddFeature() {
        if (!validateFeature()) {
            return
        }

        var fd = {
            appraised_boat_id: props.appraisedBoat.id,
            feature: addFeature
        }

        createAppraisedBoatFeature(fd, 
            (data) => {
                setFeatures([...features, {item: addFeature, id: data.appraised_boat_feature.id}])
                setAddFeature('')
                props.reloadReportCallback()
            },
            (data) => {
                console.log(data)
            }
        )
    }



    function handleDeleteFeature(id) {
        var fd = {
            appraised_boat_feature_id: id
        }
        deleteAppraisedBoatFeature(fd, 
            (data) => {
                setFeatures(features.filter((feature) => feature.id !== id))
                props.reloadReportCallback()
            },
            (data) => {
                console.log(data)
            }
        )
    }

    return (
        <>
            <div className='mild-neumorphic-card editable-section report-p-2 report-br-1' onClick={() => {setShowModal(true)}} style={{background: `linear-gradient(to left bottom, ${props.theme}, ${props.theme})`}}>
                <h3 style={{color: props.themePrimaryText}} className='report-title'>Features</h3>
                <Container>
                    <Row>
                        <Col className='g-0'>
                            {
                                props.appraisedBoat.features.map((feature, index) => {
                                    return (
                                        <div key={feature.id} className='requirements-text-container'>
                                            <p className='report-text' style={{color: props.themeSecondaryText}}>{feature.item}</p>
                                        </div>
                                    )
                                })
                            }
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Features</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        {
                            features.map((feature, index) => {
                                return (
                                    <Row key={feature.id} style={{marginTop: '20px'}}>
                                        <Col xs={8} sm={8} md={8} lg={8} xl={8} className='g-0'>
                                            <h4>{feature.item}</h4>
                                        </Col>
                                        <Col xs={4} sm={4} md={4} lg={4} xl={4} >
                                            <div>
                                                <Button className="danger-bg" onClick={() => {handleDeleteFeature(feature.id)}}>Delete</Button>
                                            </div>
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </Container>
                    <Container>
                        <Row style={{marginTop: '40px'}}>
                            <Col>
                                <p>Add Feature</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={8} lg={8}>
                                <Form.Control value={addFeature} onChange={(e) => {setAddFeature(e.target.value)}} type='text' placeholder='Add Feature' />
                                <p style={{color: 'red'}}>{featureError}</p>
                            </Col>
                            <Col md={4} lg={4}>
                            <Button className="primary-bg" onClick={() => {handleAddFeature()}}>
                                Add
                            </Button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='secondary-bg' onClick={() => {setShowModal(false)}}>Done</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
