import React from 'react';

const AnimatedLogo = () => {
  return (
    <div className="animated-logo-container">
      <img style={{width: '50%'}} src='./new_logo_trans.png' className="animated-logo" alt="logo" />
    </div>
  );
};

export default AnimatedLogo;