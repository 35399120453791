import React from 'react'
import IndSectionCard from './IndSectionCard'

export default function BuildReportSection() {
  return (
    <div>
        <IndSectionCard image="/help-images/build-report-page1.png" title="Build Report Page" textSections={[
            "The build Report Page is where you can build a report of potential boats that match the clients requirements",
            "You can change the theme of the report to match your brand using the 'Change Theme' button",
            "You can add boats that you have saved from searches by clicking the 'Add Boats' button",
            "You can choose to include listing URLs by toggling the 'Include Listing URLs' switch",
            "You can also choose to include market insights by toggling the 'Include Market Insights' switch",
            "once you are happy with the report you can click the 'Create PDF' button to create a PDF of the report"
        ]} />
        <IndSectionCard image="/help-images/build-report-page5.png" title="Change Theme" textSections={[
            "You can select from a set of pre-defined themes or you can create your own custom theme by selecting the 'Custom Theme' option"
        ]} />
        <IndSectionCard image="/help-images/build-report-page6.png" title="Custom Theme" textSections={[
            "The trim color is the color of the colored background panels, this can be changed to match your logo or brand",
            "the primary color is the color of the titles in the report",
            "the secondary color is the color of some of the text in the report",
            "As you change the options you can see how it will look in the demo above"
        ]} />
        <IndSectionCard image="/help-images/build-report-page4.png" title="Title Page" textSections={[
            "The title page is the first page of the report and contains the clients details as well as your details as a broker",
            "You can edit your details by clicking on your details"
        ]} />
        <IndSectionCard image="/help-images/build-report-page7.png" title="Add Boats" textSections={[
            "You can add boats that you have saved from searches by clicking the 'Add Boats' button",
            "You can then select the boats you want to add to the report"
        ]} />
        <IndSectionCard image="/help-images/build-report-page2.png" title="Contents Page" textSections={[
            "The contents page is the second page of the report and contains a table of contents for the report",
            "It will update as you add and remove items from the report"
        ]} />
        <IndSectionCard image="/help-images/build-report-page3.png" title="Market Insights" textSections={[
            "The market insights section is the third page of the report and contains market insights for the report",
            "the market insights are based on the boats that you have added to the report and will update as you add and remove boats",
            "if you do not want to include market insights in the report you can toggle the 'Include Market Insights' switch"
        ]} />
        <IndSectionCard image="/help-images/build-report-page8.png" title="Boat Cards" textSections={[
            "The boat cards are the main part of the report and contain the details of the boats that you have added to the report",
            "Any section in the Boat Card can be clicked and edited",
            "To remove a boat from the report, click the 'Remove' button at the bottom of the boat card",
            "To view the listing for the boat, click the 'View Listing' button at the bottom of the boat card",
            "To add an item to the card click the 'Add Item' button at the bottom of the boat card"
        ]} />
        <IndSectionCard image="/help-images/build-report-page9.png" title="Change Image" textSections={[
            "You can change the image for the boat by clicking boats image",
            "You can then select the image you want to use for the boat from the images that are available from the listing"
        ]} />
        <IndSectionCard image="/help-images/build-report-page10.png" title="Edit Title" textSections={[
            "You can edit the title of the boat by clicking the title",
            "You can then enter the title you want to use for the boat"
        ]} />   
        <IndSectionCard image="/help-images/build-report-page11.png" title="Edit Specifications" textSections={[
            "You can edit the specifications of the boat by clicking the specifications",
            "You can then enter the specifications you want to use for the boat"
        ]} />
        <IndSectionCard image="/help-images/build-report-page12.png" title="Requirements" textSections={[
            "You can edit the requirements of the boat by clicking the requirements section",
            "You can then check or uncheck whether the boat matches the clients requirements"
        ]} />
        <IndSectionCard image="/help-images/build-report-page13.png" title="Add Field" textSections={[
            "You can add a field to the boat card by clicking the 'Add Item' button",
            "You can then enter the title of the field and the body of the field"
        ]} />
        <IndSectionCard image="/help-images/build-report-page14.png" title="Edit Field" textSections={[
            "You can edit a field by clicking the field",
            "You can then edit the title and body of the field",
            "You can also delete the field"
        ]} />
        <IndSectionCard image="/help-images/build-report-page15.png" title="Summary Page" textSections={[
            "The summary page is the last page of the report and contains a summary of the boats that you have added to the report",
            "You can add items to the summary page by clicking the 'Add Item' button",
            "You can edit the items by clicking the item",
            "You can also delete the item"
        ]} />
        
    </div>
  )
}
