import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Header from '../../universal_components/Header'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import CreateAccount from './components/CreateAccount'
import ListAccounts from './components/ListAccounts'
import { authCheckIsAdmin } from '../../requests/auth'

export default function Admin() {
    
    const navigate = useNavigate();

    const [isAdmin, setIsAdmin] = useState(false)

    useEffect(() => {
        authCheckIsAdmin(
            (data) => {
            
                if (data.success === false) {
                    navigate('/home')
                }
            
                if (!data.is_admin) {
                    navigate('/home')
                }

                if (data.is_admin) {
                    setIsAdmin(true)
                }
            },
            (data) => {
                console.log(data)
            }
        )
    }, [navigate])




  
    return (
        <>
            {
                isAdmin ? 
                <>
                    <Header signedIn={true} />
                    <Container fluid>
                        <Row>
                            <Col>
                                <div style={{padding: '100px'}}>
                                    <h1>Admin</h1>

                                    <Container fluid>
                                        <Row>
                                            <Col xs={12} sm={12} md={6} lg={4} xl={4} className='g-0'>
                                                <CreateAccount />
                                                <ListAccounts />
                                            </Col>
                                            <Col xs={12} sm={12} md={6} lg={4} xl={4} className='g-0'>
                                                
                                            </Col>
                                        </Row>
                                    </Container>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </>
                :
                <></>
            }
        </>
    )
}
