import React, { useEffect, useRef, useMemo, useCallback } from "react";
import { Typography, Box } from "@mui/material";
import * as echarts from "echarts";

const ParentGraph = ({
  data = {},
  make,
  model,
  currency = "USD",
}) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const currencySymbols = useMemo(
    () => ({
      AUD: "$",
      USD: "$",
      EUR: "€",
      GBP: "£",
      NZD: "$",
      CAD: "$",
    }),
    []
  );

  const getCurrencySymbol = useCallback(
    (abbr) => {
      return currencySymbols[abbr] || abbr;
    },
    [currencySymbols]
  );

  const formatNumber = useCallback((num) => {
    const width = window.innerWidth;

   
    if (width < 960) { 
      if (num >= 1e9) return (num / 1e9).toFixed(1) + "B";
      if (num >= 1e6) return (num / 1e6).toFixed(1) + "M";
      if (num >= 1e3) return (num / 1e3).toFixed(1) + "K";
    }

  
    return Math.round(num)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }, []);

  const setChartOptions = useCallback(() => {
    if (!chartInstance.current || !data.price_curve) return;

    const years = Object.keys(data.price_curve);
    const priceValues = Object.values(data.price_curve);
    const upperBoundValues = Object.values(data.error_curve_upper_bound || {});
    const lowerBoundValues = Object.values(data.error_curve_lower_bound || {});

    const priceColor = "#219ebc";
    const upperBoundColor = "#023047";
    const lowerBoundColor = "#77d1fc";

    chartInstance.current.setOption({
      grid: {
        left: "30%",
        right: "15%",
        top: "5%",
        bottom: "20%",
      },
      tooltip: {
        trigger: "axis",
        confine: true,
        formatter: (params) => {
          const tooltipItems = params.map((param) => {
            return `<span style="color: ${
              param.color
            }; font-weight: bold; text-transform: uppercase;">${
              param.seriesName
            }: ${getCurrencySymbol(currency)}${formatNumber(
              param.value
            )}</span>`;
          });
          return `<div style="color: #000; font-weight: bold; text-transform: uppercase;">Boat Age: ${
            params[0].name
          }</div><div>${tooltipItems.join("<br/>")}</div>`;
        },
        backgroundColor: "#ffffff",
        borderColor: "#034d70",
        textStyle: {
          color: "#034d70",
        },
      },
      xAxis: {
        type: "category",
        data: years,
        name: "Boat Age (Years)",
        nameLocation: "middle",
        nameTextStyle: {
          padding: 20,
          color: "#034d70",
        },
        axisLabel: {
          color: "#034d70",
        },
      },
      yAxis: {
        type: "value",
        name: `Value (${currency})`,
        nameLocation: "middle",
        nameTextStyle: {
          padding: 60,
          color: "#034d70",
        },
        axisLabel: {
          formatter: (value) =>
            `${getCurrencySymbol(currency)}${formatNumber(value)}`,
          color: "#034d70",
        },
      },
      series: [
        {
          name: "Price",
          data: priceValues,
          type: "line",
          smooth: true,
          lineStyle: {
            color: priceColor,
          },
          itemStyle: {
            color: priceColor,
          },
        },
        {
          name: "Upper Bound",
          data: upperBoundValues,
          type: "line",
          smooth: true,
          lineStyle: { opacity: 0 },
          areaStyle: {
            color: "rgba(0, 147, 210, 0.2)",
          },
          itemStyle: {
            color: upperBoundColor,
          },
        },
        {
          name: "Lower Bound",
          data: lowerBoundValues,
          type: "line",
          smooth: true,
          lineStyle: { opacity: 0 },
          areaStyle: {
            color: "rgba(0, 147, 210, 0.2)",
            origin: "end",
          },
          itemStyle: {
            color: lowerBoundColor,
          },
        },
      ],
    });
  }, [data, currency, formatNumber, getCurrencySymbol]);

  useEffect(() => {
    if (!chartInstance.current) {
      chartInstance.current = echarts.init(chartRef.current);
    }
    setChartOptions();
  }, [setChartOptions, currency]);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: 2,
        mb: 2,
        position: "relative",
        width: "100%",
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          textTransform: "uppercase",
          textAlign: "center",
          p: 1,
          fontSize: {
            xs: "0.7em", 
            sm: "0.8em",   
            md: "1.1em", 
          },
          color: "#034d70",
        }}
      >
        {make} {model} Price Curve
      </Typography>
      <Box
        ref={chartRef}
        sx={{
          width: "100%",
          height: "35vh",
          borderRadius: 2,
        }}
      />
    </Box>
  );
};

export default ParentGraph;