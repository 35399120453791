import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useState } from 'react'
import { getMyBoats } from '../../../requests/myBoats'
import { createAppraisalReportBoat } from '../../../requests/appraisal_report/appraisalReportBoat'
import AnimatedLogo from '../../../universal_components/AnimatedLogo'



export default function AddBoatsButton(props) {

    const [addingBoat, setAddingBoat] = useState(false);

    const [myBoats, setMyBoats] = useState([]);

    const [showAddBoatsModal, setShowAddBoatsModal] = useState(false);

    const [canCloseModal, setCanCloseModal] = useState(true);

     
    function removeReportBoatsFromMyBoats(report_boats, my_boats) {
        var newMyBoats = [];
        for (var i = 0; i < my_boats.length; i++) {
            var found = false;
            for (var j = 0; j < report_boats.length; j++) {
                if (my_boats[i].url === report_boats[j].url) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                newMyBoats.push(my_boats[i]);
            }
        }
        return newMyBoats;
    }
                

    function handleOpenAddMyBoatsModal() {
        getMyBoats(
            props.report.client_profile.id,
            (data) => {
                console.log(data.my_boats)
                setMyBoats(removeReportBoatsFromMyBoats(props.report.appraisal_report_boats, data.my_boats));
            },
            (data) => {
                console.log(data)
            }
        )
        setShowAddBoatsModal(true);
    }



    function getCurrencySymbol(currency) {
        switch(currency) {
            case 'USD':
                return '$';
            case 'NZD':
                return '$';
            case 'AUD':
                return '$';
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            default:
                return '$';
        }
    }

    function formatNumber(number) {
        if (number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return '';
        }
    }


    function handleAddBoat(myBoatId) {
        setAddingBoat(true);
        setCanCloseModal(false);
        var fd = {
            appraisal_report_id: props.report.id,
            my_boat_id: myBoatId
        }

        console.log(fd)

        createAppraisalReportBoat(fd, (data) => {
            setMyBoats(myBoats.filter((boat) => boat.id !== myBoatId));
            setAddingBoat(false);
            setCanCloseModal(true);
            props.addAppraisalReportBoatCallback()
        },
        (data) => {
            console.log(data)
        }
        )
    } 


    return (
        <>
            <Button style={{margin: '15px'}} className="primary-bg" onClick={() => {handleOpenAddMyBoatsModal()}}>Add Boats</Button>

            <Modal size='lg' show={showAddBoatsModal} onHide={() => setShowAddBoatsModal(false)}>
                <Modal.Header closeButton={canCloseModal}>
                    <Modal.Title className="styled-header">Add Boats</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        addingBoat ?
                        <div style={{textAlign: 'center'}}>
                            <AnimatedLogo />
                            <h2 className="styled-header">Adding Boat...</h2>
                        </div>
                        :
                        <div>

                            {
                                myBoats.map((boat) => {
                                    return (
                                        <Row key={boat.id} style={{marginTop: '20px'}}>
                                            <Col md={6} lg={6}>
                                                <div style={{
                                                    backgroundImage: `url(${boat.image_url})`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundPosition: 'center',
                                                    backgroundSize: 'cover',
                                                    height: '200px',
                                                    width: '100%'
                                                }} ></div> 
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <h4 className='styled-header'>{boat.title}</h4>
                                                <h5>{boat.broker}</h5>
                                                {
                                                    boat.price &&
                                                        <h5>{getCurrencySymbol(boat.currency)}{formatNumber(boat.price)} {boat.currency}</h5>
                                                }
                                                <div>
                                                    <Button onClick={() => {handleAddBoat(boat.id)}}>Add Boat</Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    )
                                })
                            }
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        canCloseModal &&
                        <Button className="primary-bg" onClick={() => setShowAddBoatsModal(false)}>
                            Done
                        </Button>
                    }   
                </Modal.Footer>
            </Modal>
        </>
    )
}
