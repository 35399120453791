import { baseApiUrl } from "../../settings";
import { 
    tokenRequiredRequestWithBody
} from "../baseRequest";


export const createAppraisalReportSummaryField = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/summary-fields`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}



export const updateAppraisalReportSummaryField = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/summary-fields`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}



export const deleteAppraisalReportSummaryField = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/summary-fields`, 
        'DELETE', 
        fd, 
        successCallback, 
        failedCallback)
}