import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import { useState } from 'react'
import { updateAppraisalReportBoatFeature } from '../../../../requests/appraisal_report/appraisalReportBoat'

export default function Features(props) {

    const [showModal, setShowModal] = useState(false);

    const [checklistItems, setChecklistItems] = useState(props.boat.features)
    

    function handleCheckChanged(featureId, checked) {
        var newChecklistItems = []
        for (let i=0; i<checklistItems.length; i++) {
            if (checklistItems[i].id === featureId) {
                var changed = checklistItems[i]
                changed.checked = checked;
                newChecklistItems.push(changed)
            } else {
                newChecklistItems.push(checklistItems[i])
            }
        }
        setChecklistItems(newChecklistItems)

        var fd = {
            appraisal_report_boat_feature_id: featureId,
            checked: checked
        }

        updateAppraisalReportBoatFeature(fd, 
            (data) => {
                props.reloadReportCallback()
            },
            (data) => {
                console.log(data)
            }
        )


    }

    return (
        <>
            <div className='mild-neumorphic-card editable-section report-p-2 report-br-1 report-mr-1' onClick={() => {setShowModal(true)}} style={{background: `linear-gradient(to left bottom, ${props.theme}, ${props.theme})`}}>
                <h3 style={{color: props.themePrimaryText}} className='report-title'>Features</h3>
                <Container>
                    <Row>
                        <Col xs={9} sm={9} md={9} lg={9} xl={9} className='g-0'>
                            {
                                props.boat.features.map((item, index) => {
                                    return (
                                        <div key={item.id} className='requirements-text-container'>
                                            <p className='report-text' style={{color: props.themeSecondaryText}}>{item.item}</p>
                                        </div>
                                    )
                                })
                            }
                        </Col>
                        <Col xs={3} sm={3} md={3} lg={3} xl={3} className='g-0'>
                            {
                                props.boat.features.map((item, index) => {
                                    return (
                                        <div key={item.id} className='requirements-image-container'>
                                            {
                                                item.checked ?
                                                    <Image src='./green_tick.png' className='requirements-image' />
                                                :
                                                    <Image src='./red_cross.png' className='requirements-image' />
                                            }
                                        </div>
                                    )
                                })
                            }
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Features</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        {
                            checklistItems.map((item, index) => {
                                return (
                                    <Row key={item.id} style={{marginTop: '1vw'}}>
                                        <Col sm={9} md={9} lg={9} xl={9} className='g-0'>
                                            <h4>{item.item}</h4>
                                        </Col>
                                        <Col sm={3} md={3} lg={3} xl={3} className='g-0'>
                                            <Form.Check type='checkbox' checked={item.checked} onChange={() => {handleCheckChanged(item.id, !item.checked)}} />
                                        </Col>
                                    </Row>
                                )
                            })
                        }
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="primary-bg" onClick={() => setShowModal(false)}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
