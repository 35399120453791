import { baseApiUrl } from "../../settings";
import { 
    tokenRequiredRequestWithBody
} from "../baseRequest";




export const createAppraisedBoatField = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/appraised-boats/fields`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}



export const updateAppraisedBoatField = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/appraised-boats/fields`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}



export const deleteAppraisedBoatField = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/appraised-boats/fields`, 
        'DELETE', 
        fd, 
        successCallback, 
        failedCallback)
}