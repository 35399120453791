import { baseApiUrl } from "../../settings";
import { 
    tokenRequiredRequestWithBody
} from "../baseRequest";



export const createReportBoat = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/report-boats`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}



export const updateReportBoatImage = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/report-boats/image`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}


export const updateReportBoatImageCropped = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/report-boats/image-cropped`,
        'PUT',
        fd,
        successCallback,
        failedCallback)
}




export const updateReportBoatTitle = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/report-boats/title`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}



export const updateReportBoatSpecifications = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/report-boats/specifications`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}



export const deleteReportBoat = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/report-boats`, 
        'DELETE', 
        fd, 
        successCallback, 
        failedCallback)
}



export const updateReportBoatChecklistItem = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/report-boats/checklist-items`,
        'PUT',
        fd,
        successCallback,
        failedCallback)
}




export const createReportBoatExtraImage = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/report-boats/extra-images`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}



export const deleteReportBoatExtraImage = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/report-boats/extra-images`, 
        'DELETE', 
        fd, 
        successCallback, 
        failedCallback)
}