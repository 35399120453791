import React from 'react'
import IndSectionCard from './IndSectionCard'

export default function SearchSection() {
  return (
    <div>
        <IndSectionCard image="/help-images/search-page1.png" title="Search Page" textSections={[
            "The Search page is where you can search over 70 websites that list boats for sale",
            "You can search with either images or keywords as well as finding similar boats to any listing that is in the search results"
        ]} />

        <IndSectionCard image="/help-images/search-page2.png" title="Image Search" textSections={[
            "To search with images, click the 'Upload Image' button or drag and drop an image into the box",
            "you can upload multiple images for a single search query, more images can provide better results",
            "You can use the filters to restrict the search to your requirements"
        ]} />
        <IndSectionCard image="/help-images/search-page4.png" title="Keyword Search" textSections={[
            "To search with keywords, enter your keywords into the search bar",
            "You can use the filters to restrict the search to your requirements",
            "You can also use the order by dropdown to change the order of the results"
        ]} />
        <IndSectionCard image="/help-images/search-page3.png" title="Search Results Card" textSections={[
            "The search results card shows you several different options for each listing",
            "Click on the listing to view where it is originally listed",
            "Click on the 'Find Similar' button to find similar boats to the one you are viewing",
            "Click on the 'Add to My Boats' button to add the boat to your My Boats page so it can be used in your reports",
            "click on the 'Remove From My Boats' button to remove the boat from your My Boats page"
        ]} />
    </div>
  )
}
