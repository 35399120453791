import Landing from "./pages/landing/Landing";
import Home from "./pages/home/Home";
import Search from "./pages/search/Search";
import MyClients from "./pages/my_clients/MyClients";
import ClientPage from "./pages/client_page/ClientPage";
import SavedBoats from "./pages/saved_boats/SavedBoats";
import Reports from "./pages/reports/Reports";
import AppraisalReports from "./pages/appraisal_reports/AppraisalReports";
import BuildReport from "./pages/build_report/BuildReport";
import BuildAppraisalReport from "./pages/build_appraisal_report/BuildAppraisalReport";
import ResetPassword from "./pages/reset_password/ResetPassword";
import Admin from "./pages/admin/Admin";
import MergingGraphPage from "./pages/merging_graph_page/MergingGraphPage";
import MergingGraphPage2 from "./pages/merging_graph_page/MergingGraphPage2";
import Help from "./pages/help/Help";
import Footer from "./universal_components/Footer";
import HomePageUpload from "./pages/upload_image/HomePageUpload"

import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.css";
import "./report.css";
import RequireToken from "./RequireToken";
import RequireNoToken from "./RequireNoToken";
// import UploadImage from "./pages/upload_image/UploadImage";
// import { Box } from "@mui/material";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route
            index
            element={
              <RequireNoToken>
                <Landing />
                <Footer />
              </RequireNoToken>
            }
          />

          <Route
            path="admin-panel"
            element={
              <RequireToken>
                <Admin />
                <Footer />
              </RequireToken>
            }
          />

          <Route path="reset-password" element={<ResetPassword />} />
          <Route
            path="/merging-graphs"
            element={
              <RequireToken>
                <Home />
                <Footer />
              </RequireToken>
            }
          />
          <Route
            path="search"
            element={
              <RequireToken>
                <Search />
                <Footer />
              </RequireToken>
            }
          />
          <Route
            path="my-clients"
            element={
              <RequireToken>
                <MyClients />
                <Footer />
              </RequireToken>
            }
          />
          <Route
            path="client-page"
            element={
              <RequireToken>
                <ClientPage />
                <Footer />
              </RequireToken>
            }
          />
          <Route
            path="saved-boats"
            element={
              <RequireToken>
                <SavedBoats />
                <Footer />
              </RequireToken>
            }
          />
          <Route
            path="reports"
            element={
              <RequireToken>
                <Reports />
                <Footer />
              </RequireToken>
            }
          />
          <Route
            path="appraisal-reports"
            element={
              <RequireToken>
                <AppraisalReports />
                <Footer />
              </RequireToken>
            }
          />
          <Route
            path="build-report"
            element={
              <RequireToken>
                <BuildReport />
                <Footer />
              </RequireToken>
            }
          />
          {/* <Route
            path="upload"
            element={
              <RequireToken>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    minHeight: "100vh",
                  }}
                >
                  <Box sx={{ flexGrow: 1 }}>
                    <UploadImage />
                  </Box>
                  <Footer sx={{ mt: "auto" }} />
                </Box>
              </RequireToken>
            }
          /> */}

          <Route
            path="merging-graphs2"
            element={
              <RequireToken>
                <MergingGraphPage />
                <Footer />
              </RequireToken>
            }
          />
          <Route
            path="home"
            element={
              <RequireToken>
                <HomePageUpload />
                <Footer />
              </RequireToken>
            }
          />
                  <Route
            path="pricing"
            element={
              <RequireToken>
                <MergingGraphPage2 />
                <Footer />
              </RequireToken>
            }
          />
          <Route
            path="build-appraisal-report"
            element={
              <RequireToken>
                <BuildAppraisalReport />
                <Footer />
              </RequireToken>
            }
          />
          <Route
            path="help"
            element={
              <RequireToken>
                <Help />
              </RequireToken>
            }
          />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
