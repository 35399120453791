import React from 'react'
import { useState } from 'react'
import SpecificationsModal from './SpecificationsModal'


export default function BoatPrice(props) {

    const [showEditPriceModal, setShowEditPriceModal] = useState(false);

    function getCurrencySymbol(currency) {
        switch(currency) {
            case 'USD':
                return '$';
            case 'NZD':
                return '$';
            case 'AUD':
                return '$';
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            default:
                return '$';
        }
    }

    function formatNumber(number) {
        if (number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return '';
        }
    }
    
    return (
        <div>
            <h3 onClick={() => {setShowEditPriceModal(true)}} style={{cursor: 'pointer'}} className='report-subtitle-sm-landscape'><b>{getCurrencySymbol(props.boat.currency)}{formatNumber(parseInt(props.boat.price))} {props.boat.currency}</b></h3>
            {
                props.boat.converted_currency && props.report.report_currency &&
                <h4 className='report-text-sm'>{getCurrencySymbol(props.report.report_currency)}{formatNumber(parseInt(props.boat.converted_currency))} {props.report.report_currency}</h4>
            }
            <SpecificationsModal
                showModal={showEditPriceModal}
                setShowModal={setShowEditPriceModal}
                price={props.boat.price}
                currency={props.boat.currency}
                length={props.boat.length}
                country={props.boat.country}
                year={props.boat.year}
                boatId={props.boat.id}
                reloadReportCallback={props.reloadReportCallback}
            />

        </div>
    )
}
