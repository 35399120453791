import React from 'react'
import { useState } from 'react'
import SpecificationsModal from './SpecificationsModal'

export default function BoatSpecs(props) {
    
    const [showEditSpecsModal, setShowEditSpecsModal] = useState(false)
    
    return (
        <>
            <div className='report-pl-1 report-pr-1' style={{cursor: 'pointer'}} onClick={() => {setShowEditSpecsModal(true)}}> 
                <div style={{display: 'flex', backgroundColor: 'rgb(230, 230, 230)'}}>        
                    <p className='report-text-landscape' style={{margin: '3px', paddingRight: '5px', paddingLeft: '5px', width:'100%'}}>Length</p>
                    <p className='report-text-landscape' style={{margin: '3px', width:'100%'}}>{props.boat.length}m</p>
                </div>
                <div style={{display: 'flex', backgroundColor: 'rgb(250, 250, 250)'}}>
                    <p className='report-text-landscape' style={{margin: '3px', paddingRight: '5px', paddingLeft: '5px', width:'100%'}}>Year</p>        
                    <p className='report-text-landscape' style={{margin: '3px', width:'100%'}}>{props.boat.year}</p>
                </div>
                <div style={{display: 'flex', backgroundColor: 'rgb(230, 230, 230)'}}>
                    <p className='report-text-landscape' style={{margin: '3px', paddingRight: '5px', paddingLeft: '5px', width:'100%'}}>Location</p>
                    <p className='report-text-landscape' style={{margin: '3px', width:'100%'}}>{props.boat.country}</p>
                </div>
            </div>
            <SpecificationsModal
                showModal={showEditSpecsModal}
                setShowModal={setShowEditSpecsModal}
                price={props.boat.price}
                currency={props.boat.currency}
                length={props.boat.length}
                country={props.boat.country}
                year={props.boat.year}
                boatId={props.boat.id}
                reloadReportCallback={props.reloadReportCallback}
            />
        </>
    )
}
