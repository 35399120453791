import React from 'react'
import Page from '../../../generic_report_components/Page'
import ColorCut from '../../../generic_report_components/ColorCut'
import InsightsCard from './insights_page_components/InsightsCard'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { getBrokerDetails } from '../../../requests/brokerDetails'
import { useState, useEffect } from 'react'


export default function MarketInsightsPage(props) {

    function formatNumber(number) {
        if (number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return '';
        }
    }


    const [brokerName, setBrokerName] = useState(null);
    const [brokerEmail, setBrokerEmail] = useState(null);
    const [brokerPhone, setBrokerPhone] = useState(null);


    useEffect(() => {
        getBrokerDetails(
            (data) => {
                if (data.broker_details) {
                    setBrokerName(data.broker_details.name)
                    setBrokerEmail(data.broker_details.email)
                    setBrokerPhone(data.broker_details.phone)
                }
            },
            (data) => {
                console.log(data)
            }
        )
    }, [])



    return (
        <div style={{marginBottom: '40px'}}>
            <Page
                includeFooter={true} 
                brokerDetails={{name: brokerName, email: brokerEmail, phone: brokerPhone}}
                theme={{
                    theme:props.theme,
                    themePrimaryText:props.themePrimaryText,
                }}
            >
                <ColorCut theme={props.theme} height='15%' rightCut='70%'>
                    <div className='report-p-2'>
                        <h1 style={{color:props.themePrimaryText}} className='report-title'>Market Insights</h1>
                        <h2 className='report-subtitle' style={{color: props.themeSecondaryText}}>{props.report.market_insights.similar_listings} similar boats out of {formatNumber(props.report.market_insights.total_listings)} for sale</h2>
                    </div>
                </ColorCut>

                <Row>
                    <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                        <div className="report-p-2 report-mt-2 report-insights-section">
                            <InsightsCard 
                                title="Similar Boats Location Distribution" 
                                themePrimaryText={props.themePrimaryText}
                                src={props.report.market_insights.heatmap_path} 
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <div className='report-insights-section-left report-p-2 report-mt-2'>
                            <InsightsCard 
                                title="Similar Boats Length Distribution" 
                                themePrimaryText={props.themePrimaryText}
                                src={props.report.market_insights.length_plot_path} 
                            />
                        </div>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                        <div className='report-insights-section-right report-p-2 report-mt-2'>
                            <InsightsCard 
                                title="Similar Boats Price Distribution" 
                                themePrimaryText={props.themePrimaryText}
                                src={props.report.market_insights.price_plot_path} 
                            />
                        </div>
                    </Col>
                </Row>
            </Page>
        </div>
    )
}
