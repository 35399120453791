import { baseApiUrl } from "../settings";
import { 
    tokenRequiredRequest,
    tokenRequiredRequestWithBody
} from "./baseRequest";




export const createClientProfile = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/client-profiles`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}


export const updateClientProfile = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/client-profiles`,
        'PUT',
        fd,
        successCallback,
        failedCallback)
}


export const getClientProfiles = (successCallback, failedCallback) => {
    return tokenRequiredRequest(
        `${baseApiUrl}/client-profiles`, 
        'GET', 
        successCallback, 
        failedCallback)
}


export const getClientProfilesArchived = (successCallback, failedCallback) => {
    return tokenRequiredRequest(
        `${baseApiUrl}/client-profiles?archived=True`,
        'GET',
        successCallback,
        failedCallback)
}



export const getClientProfile = (id, successCallback, failedCallback) => {
    return tokenRequiredRequest(
        `${baseApiUrl}/client-profiles/${id}`, 
        'GET', 
        successCallback, 
        failedCallback)
}


export const archiveClientProfile = (id, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/client-profiles/archive`, 
        'PUT', 
        {
            client_profile_id: id,
            archive: true
        },
        successCallback, 
        failedCallback)
}


export const unarchiveClientProfile = (id, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/client-profiles/archive`, 
        'PUT', 
        {
            client_profile_id: id,
            archive: false
        },
        successCallback, 
        failedCallback)
}