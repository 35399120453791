import React from 'react'
import Image from 'react-bootstrap/Image'

export default function IndSectionCard(props) {
  return (
    <>
        <div style={{padding: '40px', width: '100%', marginBottom: '50px'}} className='neumorphic-card'>
            <div style={{width: '100%', textAlign: 'center'}}>
                <Image src={props.image} style={{width: '80%'}} />
            </div>
            <h3 style={{marginTop: '40px'}} className='styled-header'>{props.title}</h3>
            {
                props.textSections.map((section, index) => {
                    return (
                        <div key={index} style={{marginTop: '20px'}}>
                            <p>{section}</p>
                        </div>
                    )
                })
            }
        </div>
    </>
  )
}
