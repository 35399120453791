
export const setToken = (token)=>{
    localStorage.setItem('wfybtoken', token)
}

export const fetchToken = ()=>{
    return localStorage.getItem('wfybtoken')
}


export const removeToken = ()=>{
    localStorage.removeItem('wfybtoken')
}





