import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "../../universal_components/Header";
import DirectoryCard from "../home/components/DirectoryCard";
import { useNavigate } from "react-router-dom";
import MergingForm from "./components/MergingForm";
import ParentGraph from "./components/ParentGraph";
import MergedGraph from "./components/MergedGraph";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { getPricingData } from "../../api/boat";

const graphColors = ["blue", "orange", "green"];

export default function MergingGraphPage() {
  const navigate = useNavigate();
  const [valueData, setValueData] = useState(null);
  const [loadingValueData, setLoadingValueData] = useState(false);
  const [noPricingGuidance, setNoPricingGuidance] = useState(false);
  const [currencyData, setCurrencyData] = useState({
    currency: "AUD",
    abbr: "AUD",
  });
  const [pinnedGraphs, setPinnedGraphs] = useState([]);
  const [selectedGraphs, setSelectedGraphs] = useState([]);
  const [mergedGraphs, setMergedGraphs] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [highlightCheckboxes, setHighlightCheckboxes] = useState(false);
  const [showMergeTooltip, setShowMergeTooltip] = useState(false);
  const [currencyTooltipShown, setCurrencyTooltipShown] = useState(false);

  const handleSearchClick = () => {
    navigate("/search", { state: { client_profile_id: null } });
  };

  const handleMyClientsClick = () => {
    navigate("/my-clients");
  };

  const handleValueDataChange = (data, year, make, model, noData) => {
    setLoadingValueData(true);
    setNoPricingGuidance(noData);
    try {
      if (data) {
        setValueData({
          data,
          baseYear: year,
          title: `${make} ${model} Price Curve`,
          make,
          model,
        });
      } else {
        setValueData(null);
      }
    } catch (error) {
      console.error("Error updating value data:", error);
    } finally {
      setLoadingValueData(false);
    }
  };

  const handleCurrencyChange = async (currency) => {
    setCurrencyData({ currency, abbr: currency });

    if (valueData) {
      setLoadingValueData(true);

      try {
        const pricingData = await getPricingData(
          valueData.make,
          valueData.model,
          valueData.baseYear,
          currency
        );
        if (pricingData.message) {
          setNoPricingGuidance(true);
          setValueData(null);
        } else {
          setNoPricingGuidance(false);
          setValueData({
            ...valueData,
            data: pricingData,
          });
        }
      } catch (error) {
        console.error("Error fetching pricing data:", error.message);
        setNoPricingGuidance(true);
        setValueData(null);
      } finally {
        setLoadingValueData(false);
      }
    }
  };

  const handlePinToggle = (graph) => {
    if (pinnedGraphs.includes(graph)) {
      setPinnedGraphs(pinnedGraphs.filter((g) => g !== graph));
      setSelectedGraphs(selectedGraphs.filter((g) => g !== graph));
    } else {
      setPinnedGraphs([...pinnedGraphs, graph]);
    }
  };

  const handleDeleteGraph = (graph) => {
    if (graph === valueData) {
      setValueData(null);
      setNoPricingGuidance(false);
    }
    setPinnedGraphs(pinnedGraphs.filter((g) => g !== graph));
    setSelectedGraphs(selectedGraphs.filter((g) => g !== graph));

    if (mergedGraphs) {
      const newMergedGraphs = mergedGraphs.dataSets.filter(
        (dataSet) => dataSet !== graph.data
      );
      if (newMergedGraphs.length < 2) {
        setMergedGraphs(null);
      } else {
        const makeModelList = newMergedGraphs.map(
          (g, i) => `${pinnedGraphs[i].make} ${pinnedGraphs[i].model}`
        );
        setMergedGraphs({ dataSets: newMergedGraphs, makeModelList });
      }
    }
  };

  const isGraphPinned = (graph) => {
    return pinnedGraphs.some(
      (pinnedGraph) =>
        pinnedGraph.make === graph.make &&
        pinnedGraph.model === graph.model &&
        pinnedGraph.baseYear === graph.baseYear
    );
  };

  const handleGraphSelect = (graph) => {
    if (selectedGraphs.includes(graph)) {
      setSelectedGraphs(selectedGraphs.filter((g) => g !== graph));
    } else {
      setSelectedGraphs([...selectedGraphs, graph]);
    }
  };

  const runMergeGraphs = () => {
    if (selectedGraphs.length < 2) {
      setSnackbarMessage("You must select at least two graphs for merging");
      setSnackbarOpen(true);
      setHighlightCheckboxes(true);
      setTimeout(() => setHighlightCheckboxes(false), 1000);
      return;
    }

    const mergedDataSets = selectedGraphs.map((graph) => graph.data);
    const makeModelList = selectedGraphs.map(
      (graph) => `${graph.make} ${graph.model}`
    );

    const allXValues = mergedDataSets.flatMap((dataSet) =>
      Object.keys(dataSet.price_curve).map(Number)
    );
    const minX = Math.min(...allXValues);
    const maxX = Math.max(...allXValues);

    setMergedGraphs({
      dataSets: mergedDataSets,
      makeModelList,
      minX,
      maxX,
    });

    setShowMergeTooltip(false);
  };

  const getGraphColor = (index) => {
    return graphColors[index % graphColors.length];
  };

  const handleRemoveMergedGraph = () => {
    setMergedGraphs(null);
  };

  const handleUnselectGraph = (graph) => {
    handleGraphSelect(graph);
    const newMergedGraphs = selectedGraphs.filter((g) => g !== graph);
    if (newMergedGraphs.length < 2) {
      setMergedGraphs(null);
    } else {
      const mergedDataSets = newMergedGraphs.map((g) => g.data);
      const makeModelList = newMergedGraphs.map((g) => `${g.make} ${g.model}`);
      setMergedGraphs({ dataSets: mergedDataSets, makeModelList });
    }
  };

  return (
    <>
      <Header signedIn={true} />
      <Container maxWidth={false} disableGutters>
        <Grid container style={{ minHeight: "100vh" }}>
          <Grid
            item
            xs={12}
            sm={12}
            lg={3}
            sx={{
              backgroundColor: "rgb(210, 210, 210)",
              pb: { xs: 5, md: 3, lg: 0 },
            }}
          >
            <Box
              sx={{
                backgroundImage: "url(./landing-img.jpeg)",
                width: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
                py: "10vh",
              }}
            >
              <Typography variant="h3" className="styled-header">
                Broker Portal
              </Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                md={6}
                lg={12}
                sx={{ mx: { xs: 2, sm: 2, md: 0 } }}
              >
                <DirectoryCard
                  title="My Clients"
                  description="View and manage your clients and their reports."
                  callToAction="Go to My Clients"
                  navigate={handleMyClientsClick}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={12}
                sx={{ mx: { xs: 2, sm: 2, md: 0 } }}
              >
                <DirectoryCard
                  title="Search"
                  description="Use our Proprietary Photolumination technology to find your clients their dream boat."
                  callToAction="Go to Search"
                  navigate={handleSearchClick}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            lg={9}
            sx={{
              backgroundImage: "url(./classic_yacht.jpg)",
              minHeight: "100vh",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                p: { xs: 1, md: 2 },
                position: "relative",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={5}>
                  <Box
                    sx={{
                      p: 2,
                      backgroundColor: "white",
                      borderRadius: 2,
                    }}
                  >
                    <MergingForm
                      onValueDataChange={handleValueDataChange}
                      onCurrencyChange={handleCurrencyChange}
                      pinnedGraphs={pinnedGraphs}
                      handleGraphSelect={handleGraphSelect}
                      mergeGraphs={runMergeGraphs}
                      selectedGraphs={selectedGraphs}
                      showMergeTooltip={showMergeTooltip}
                      setShowMergeTooltip={setShowMergeTooltip}
                      currencyTooltipShown={currencyTooltipShown}
                      setCurrencyTooltipShown={setCurrencyTooltipShown}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} lg={7}>
                  <Box
                    sx={{
                      borderRadius: 2,
                      position: "relative",
                      mb: { xs: 2, lg: 0 },
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    {!valueData && loadingValueData ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <CircularProgress sx={{ height: "10vh", mt: 2 }} />
                      </Box>
                    ) : noPricingGuidance ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "40vh",
                          backgroundColor: "white",
                          borderRadius: 2,
                        }}
                      >
                        <Typography variant="h6" color="textSecondary">
                          No pricing guidance available for the given make and
                          model
                        </Typography>
                      </Box>
                    ) : (
                      valueData &&
                      !isGraphPinned(valueData) && (
                        <Box
                          sx={{
                            width: "100%",
                            mb: 1,
                            zIndex: 1,
                          }}
                        >
                          <ParentGraph
                            dataSets={[valueData.data]}
                            title={valueData.title}
                            currency={currencyData.currency}
                            conversionRate={1}
                            abbr={currencyData.abbr}
                            onPinToggle={() => handlePinToggle(valueData)}
                            onDelete={() => {
                              handleDeleteGraph(valueData);
                              setValueData(null);
                              setNoPricingGuidance(false);
                            }}
                            isPinned={false}
                            color={getGraphColor(0)}
                            makeModelList={[
                              `${valueData.make} ${valueData.model}`,
                            ]}
                          />
                        </Box>
                      )
                    )}
                    {pinnedGraphs.map((graph, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: "100%",
                          height: "auto",
                          zIndex: 1,
                          order: index - 2,
                          position: "relative",
                        }}
                      >
                        <ParentGraph
                          dataSets={[graph.data]}
                          title={graph.title}
                          currency={currencyData.currency}
                          conversionRate={1}
                          abbr={currencyData.abbr}
                          onPinToggle={() => handlePinToggle(graph)}
                          onDelete={() => handleDeleteGraph(graph)}
                          isPinned={true}
                          color={getGraphColor(index)}
                          selected={selectedGraphs.includes(graph)}
                          onSelect={() => handleUnselectGraph(graph)}
                          makeModelList={[`${graph.make} ${graph.model}`]}
                          showCheckbox={true}
                          highlightCheckboxes={highlightCheckboxes}
                          setShowMergeTooltip={setShowMergeTooltip}
                        />
                      </Box>
                    ))}
                    {mergedGraphs && (
                      <Box
                        sx={{
                          width: "100%",
                          mb: 1,
                          zIndex: 1,
                        }}
                      >
                        <MergedGraph
                          dataSets={mergedGraphs.dataSets}
                          title="Merged Graph"
                          currency={currencyData.currency}
                          conversionRate={1}
                          abbr={currencyData.abbr}
                          onDelete={handleRemoveMergedGraph}
                          color="multi"
                          makeModelList={mergedGraphs.makeModelList}
                          minX={mergedGraphs.minX}
                          maxX={mergedGraphs.maxX}
                        />
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="warning"
          sx={{ backgroundColor: "#034d70", color: "#fff" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
