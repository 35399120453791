import React from 'react'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { updateReportBoatField, deleteReportBoatField } from '../../../../requests/report/reportBoatField'

export default function AnalysisField(props) {
    
    const [showEditFieldModal, setShowEditFieldModal] = useState(false);
    const [title, setTitle] = useState(props.field.title)
    const [body, setBody] = useState(props.field.body)
    const [titleError, setTitleError] = useState('')
    const [bodyError, setBodyError] = useState('')



    function validateFormData() {
        var valid = true;

        setTitleError('');
        setBodyError('');

        if (title.length === 0) {
            setTitleError('Title is required')
            valid = false;
        }

        if (body.length === 0) {
            setBodyError('Body is required')
            valid = false;
        }

        return valid;
    }



    function handleEditField() {

        if (!validateFormData()) {
            return;
        }

        var fd = {
            id: props.field.id,
            title: title,
            body: body
        }

        updateReportBoatField(fd, 
            (data) => {
                setShowEditFieldModal(false);
            },
            (data) => {
                console.log(data)
            }
        )
    }


    function handleDeleteReportBoatField() {
        var fd = {
            id: props.field.id
        }

        deleteReportBoatField(fd, 
            (data) => {
                console.log(data)
                props.deleteFieldCallback(props.field);
                setShowEditFieldModal(false);
            },
            (data) => {
                console.log(data)
            }
        )
    }
    
    return (
        <div className='report-pl-2'>
            <div className='editable-section' onClick={() => {setShowEditFieldModal(true)}}>
                <h3 style={{color: props.themePrimaryText}} className='report-subtitle-sm'>{title}</h3>
                <p className='report-text'>{body}</p>
            </div>
            <Modal show={showEditFieldModal} onHide={() => handleEditField()}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Edit Field</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p style={{marginTop: '20px'}}>Title</p>
                        <Form.Control type='text' value={title} onChange={(e) => {setTitle(e.target.value)}} />
                        <p style={{color: 'red'}}>{titleError}</p>

                        <p style={{marginTop: '20px'}}>Body</p>
                        <Form.Control as='textarea' rows={3} value={body} onChange={(e) => {setBody(e.target.value)}} />
                        <p style={{color: 'red'}}>{bodyError}</p>
                    
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="danger-bg" onClick={() => handleDeleteReportBoatField()}>Delete</Button>
                    <Button className="primary-bg" style={{marginLeft: '20px'}} onClick={() => handleEditField()}>Save</Button>
                </Modal.Footer>
            </Modal>

        </div>  
    )
}
