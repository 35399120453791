import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useState, useEffect } from 'react'
import { createClientRequirement, getClientRequirements, updateClientRequirement, deleteClientRequirement } from '../../../requests/clientRequirement'


export default function ClientRequirements(props) {

    const [showAddRequirementModal, setShowAddRequirementModal] = useState(false);

    const [showManageRequirementModal, setShowManageRequirementModal] = useState(false);


    const [requirementToAdd, setRequirementToAdd] = useState('');

    const [requirementToManage, setRequirementToManage] = useState('');

    const [editedRequirement, setEditedRequirement] = useState('');

    const [clientRequirements, setClientRequirements] = useState([]);

    useEffect(() => {
        getClientRequirements(
            props.clientProfileId,
            (data) => {
                console.log(data)
                setClientRequirements(data.client_requirements)
            },
            (data) => {
                console.log(data)
            }
        )
    }, [props.clientProfileId])



    function reloadClientRequirements() {
        getClientRequirements(
            props.clientProfileId,
            (data) => {
                console.log(data)
                setClientRequirements(data.client_requirements)
            },
            (data) => {
                console.log(data)
            }
        )
    }



    function handleAddClientRequirement() {

        var fd = {
            client_profile_id: props.clientProfileId,
            requirement: requirementToAdd
        }

        createClientRequirement(
            fd,
            (data) => {
                console.log(data)
                setRequirementToAdd('');
                reloadClientRequirements();
            },
            (data) => {
                console.log(data)
            }
        )
    }


    function handleOpenManageRequirementModal(requirement) {
        setRequirementToManage(requirement);
        setEditedRequirement(requirement.requirement);
        setShowManageRequirementModal(true);
    }


    function handleEditRequirement() {
        var fd = {
            client_requirement_id: requirementToManage.id,
            requirement: editedRequirement
        }

        updateClientRequirement(
            fd,
            (data) => {
                console.log(data)
                reloadClientRequirements();
                setShowManageRequirementModal(false);
            },
            (data) => {
                console.log(data)
            }
        )
    }


    function handleDeleteRequirement() {

        var fd = {
            client_requirement_id: requirementToManage.id
        }

        deleteClientRequirement(
            fd,
            (data) => {
                console.log(data)
                reloadClientRequirements();
                setShowManageRequirementModal(false);
            },
            (data) => {
                console.log(data)
            }
        )
    }


    return (
        <>
            <div style={{backgroundColor:'white', marginBottom: '30px'}}>
                <div style={{width: '100%', padding: '15px', backgroundColor: 'rgb(240, 240, 240)'}}>
                    <Row>
                        <Col>
                            <h2 className='styled-header'>Requirements <i style={{marginLeft: '20px', float: 'right'}} onClick={() => {setShowAddRequirementModal(true)}} class="fa fa-plus clickable-icon" aria-hidden="true"></i></h2>
                        </Col>
                    </Row>
                </div>
                    {
                        clientRequirements.map((requirement, index) => {
                            return (
                                <div style={{padding: '15px'}}>
                                    <div key={index}>
                                        <h4 onClick={() => {handleOpenManageRequirementModal(requirement)}} className='clickable-card'>{requirement.requirement}</h4>
                                    </div>
                                </div>
                            )
                        })
                    }
                
            </div>
            <Modal show={showAddRequirementModal} onHide={() => {setShowAddRequirementModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Add Client Requirement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control value={requirementToAdd} onChange={(e) => {setRequirementToAdd(e.target.value)}} type="text" placeholder="Enter Requirement" />
                </Modal.Body>
                <Modal.Footer>
                    <Button className='secondary-bg' onClick={() => {setShowAddRequirementModal(false)}}>
                        Close
                    </Button>
                    <Button className="primary-bg" onClick={() => {handleAddClientRequirement()}}>
                        Add Requirement
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showManageRequirementModal} onHide={() => {setShowManageRequirementModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Manage Client Requirement</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control value={editedRequirement} onChange={(e) => {setEditedRequirement(e.target.value)}} type="text" placeholder="Edit" />
                </Modal.Body>
                <Modal.Footer>
                    <Button className="secondary-bg" onClick={() => {setShowManageRequirementModal(false)}}>
                        Close
                    </Button>
                    <Button className="primary-bg" onClick={() => {handleEditRequirement()}}>
                        Save Changes
                    </Button>
                    <Button className="danger-bg" onClick={() => {handleDeleteRequirement()}}>
                        Delete Requirement
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
