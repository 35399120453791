import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Page from '../../../generic_report_components/Page'
import ColorCut from '../../../generic_report_components/ColorCut'
import AppraisedBoatTitle from './appraised_boat_components/AppraisedBoatTitle'
import AppraisedBoatHeroImage from './appraised_boat_components/AppraisedBoatHeroImage'
import Specifications from './appraised_boat_components/Specifications'
import Features from './appraised_boat_components/Features'
import FieldsSection from './appraised_boat_components/FieldsSection'
import { useState } from 'react'
import ExtraImages from './appraised_boat_components/ExtraImages'
import { useEffect } from 'react'
import { getBrokerDetails } from '../../../requests/brokerDetails'


export default function AppraisedBoat(props) {

    const [showAddItemModal, setShowAddItemModal] = useState(false);

    const [brokerName, setBrokerName] = useState(null);
    const [brokerEmail, setBrokerEmail] = useState(null);
    const [brokerPhone, setBrokerPhone] = useState(null);


    useEffect(() => {
        getBrokerDetails(
            (data) => {
                if (data.broker_details) {
                    setBrokerName(data.broker_details.name)
                    setBrokerEmail(data.broker_details.email)
                    setBrokerPhone(data.broker_details.phone)
                }
            },
            (data) => {
                console.log(data)
            }
        )
    }, [])

    return (
        <div style={{marginBottom: '40px'}}>
            <Page
                includeFooter={true} 
                brokerDetails={{name: brokerName, email: brokerEmail, phone: brokerPhone}}
                theme={{
                    theme:props.theme,
                    themePrimaryText:props.themePrimaryText,
                }}
            >
                <AppraisedBoatHeroImage 
                    appraisedBoat={props.appraisedBoat}
                />
                <ColorCut theme={props.theme} height='12%' rightCut='70%'>
                    <AppraisedBoatTitle 
                        themePrimaryText={props.themePrimaryText}
                        themeSecondaryText={props.themeSecondaryText}
                        appraisedBoat={props.appraisedBoat}
                    />
                </ColorCut>
                <Specifications
                    themePrimaryText={props.themePrimaryText}
                    themeSecondaryText={props.themeSecondaryText}
                    appraisedBoat={props.appraisedBoat}
                />
                <Container>
                    <Row>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                            <FieldsSection
                                themePrimaryText={props.themePrimaryText}
                                themeSecondaryText={props.themeSecondaryText}
                                showAddItemModal={showAddItemModal}
                                setShowAddItemModal={setShowAddItemModal}
                                appraisedBoat={props.appraisedBoat}
                                reloadReportCallback={props.reloadReportCallback}
                            />

                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                            <Features
                                theme={props.theme}
                                themePrimaryText={props.themePrimaryText}
                                themeSecondaryText={props.themeSecondaryText}
                                appraisedBoat={props.appraisedBoat}
                                reloadReportCallback={props.reloadReportCallback}
                            />
                        </Col>
                    </Row>
                </Container>
            </Page>
            <Container className='mild-neumorphic-card report-button-bar'>
                <Row>
                    <Col className='g-0'>
                        <Button className='button-bar-button primary-bg' style={{width: '100%', borderRadius: '0px'}} onClick={() => {setShowAddItemModal(true)}} >Add Item</Button>
                    </Col>
                </Row>
            </Container>

            <ExtraImages
                theme={props.theme}
                themePrimaryText={props.themePrimaryText}
                themeSecondaryText={props.themeSecondaryText}
                appraisedBoat={props.appraisedBoat}
            />

        </div>
    )
}
