import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Header from '../../universal_components/Header'
import SideCard from './components/SideCard'
import SearchSection from './components/SearchSection'
import MyBoatsSection from './components/MyBoatsSection'
import MyReportsSection from './components/MyReportsSection'
import BuildReportSection from './components/BuildReportSection'
import BuildAppraisalReportSection from './components/BuildAppraisalReportSection'
import { useState } from 'react'

export default function Help() {

    const [showPage, setShowPage] = useState('search')


    function handlePageChange(page) {
        setShowPage(page)
    }

    return (
        <>
            <Header signedIn={true} />
            <Container fluid>
                <Row>
                    <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                        <SideCard handlePageChange={handlePageChange} />
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={6} xl={6}>
                        <div style={{marginTop: '100px'}}>
                            {
                                showPage === 'search' ?
                                    <SearchSection />
                                :
                                    null
                            }
                            {
                                showPage === 'my boats' ?
                                    <MyBoatsSection />
                                :
                                    null
                            }
                            {
                                showPage === 'my reports' ?
                                    <MyReportsSection />
                                :
                                    null
                            }
                            {
                                showPage === 'build report' ?
                                    <BuildReportSection />
                                :
                                    null

                            }
                            {
                                showPage === 'build appraisal report' ?
                                    <BuildAppraisalReportSection />
                                :
                                    null
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
