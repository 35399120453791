import React from 'react'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useState, useCallback } from 'react';
import { updateReportBoatImageCropped } from '../../../../requests/report/reportBoat';
import Cropper from 'react-easy-crop';


export default function HeroImage(props) {

	const [currentImageUrl, setCurrentImageUrl] = useState(props.boat.selected_image_url);

	const allImages = [props.boat.image_url, ...JSON.parse(props.boat.image_urls)];

	const [showSelectImageModal, setShowSelectImageModal] = useState(false);

	const [showCropImageModal, setShowCropImageModal] = useState(false);

	const [selectedImage, setSelectedImage] = useState(null);

	const [croppedArea, setCroppedArea] = useState(null)

	function handleSelectImage(image) {
		setSelectedImage(image);
		setShowSelectImageModal(false);
		setShowCropImageModal(true);
	}


	const [crop, setCrop] = useState({ x: 0, y: 0 });


	const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
		setCroppedArea(croppedArea)
		console.log(croppedArea)
	}, [])



	const handleSaveCroppedImage = () => {

		setShowCropImageModal(false);

		var fd = {
			report_boat_id: props.boat.id,
			image_url: selectedImage,
			y_start: croppedArea.y,
		}

		updateReportBoatImageCropped(
			fd, 
			(data) => {
				console.log(data);
				setCurrentImageUrl(data.report_boat.selected_image_url);
			},
			(data) => {
				console.log(data);
			}
		)
	}





	return (
		<>
		<div className='hero-image' 
			style={{
			backgroundImage: `url(${currentImageUrl})`,
			backgroundRepeat: 'no-repeat',
			backgroundPosition: 'center',
			backgroundSize: 'cover',
			}}
			onClick={() => setShowSelectImageModal(true)} 
		></div>
		
		<Modal  show={showSelectImageModal} onHide={() => setShowSelectImageModal(false)}>
			<Modal.Header closeButton>
			<Modal.Title className="styled-header">Select Image</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			<Row>
				{
				allImages.map((imageUrl, index) => {
					return (
					<Col key={imageUrl} md={4} lg={4}>
						<Image onClick={() => {handleSelectImage(imageUrl)}} className='mild-neumorphic-card hero-image' src={imageUrl} style={{width: '100%', height: 'auto', marginTop: '20px'}}/>
					</Col>
					)
				})
				}
			</Row>
			</Modal.Body>
			<Modal.Footer>
			<Button className="primary-bg" onClick={() => setShowSelectImageModal(false)}>
				Done
			</Button>
			</Modal.Footer>
		</Modal>


		<Modal size='lg' show={showCropImageModal} onHide={() => setShowCropImageModal(false)}>
			<Modal.Header closeButton>
				<Modal.Title className="styled-header">Crop Image to fit</Modal.Title>
			</Modal.Header>
			<Modal.Body>
			
			<div style={{width: '100%', height: '600px'}}>
				<Cropper
				image={selectedImage}
				crop={crop}
				onCropChange={setCrop}
				aspect={5 / 2}
				onCropComplete={onCropComplete}
				/>
			</div>

			</Modal.Body>
			<Modal.Footer>
			<Button className="primary-bg" onClick={() => {handleSaveCroppedImage()}}>
				Save
			</Button>
			</Modal.Footer>
		</Modal>

		
		</>

	)
}
