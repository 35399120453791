import React, { useState, useEffect, useCallback } from "react";
import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  CircularProgress,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { getAllBoats, getPricingData } from "../../../api/boat";
import useFetch from "../../../hooks/useFetch";

const PricingTool = ({ onValueDataChange, onCurrencyChange }) => {
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("");
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("AUD");
  const [models, setModels] = useState([]);
  const [priceRange, setPriceRange] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const countries = ["USA", "Canada", "Australia", "UK"];

  const { data: makesData, isLoading, error } = useFetch(getAllBoats);
  const makes = makesData ? Object.keys(makesData) : [];

  const fetchModels = useCallback(async () => {
    if (make) {
      try {
        const data = await getAllBoats();
        if (data && data[make]) {
          setModels(data[make]);
        } else {
          setModels([]);
        }
      } catch (error) {
        console.error("Error fetching models:", error);
        setModels([]);
      }
    }
  }, [make]);

  useEffect(() => {
    fetchModels();
  }, [fetchModels]);

  const handleMakeChange = (event, newValue) => {
    setMake(newValue || "");
    setModel("");
    setYear("");
    setCountry("");
    setPriceRange(null);
    onValueDataChange(null, null, "", "", false);
  };

  const handleModelChange = (event) => {
    setModel(event.target.value || "");
    setYear("");
    setCountry("");
    setPriceRange(null);
    onValueDataChange(null, null, "", "", false);
  };

  const handleYearChange = (event) => {
    setYear(event.target.value);
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleCurrencyChange = async (event) => {
    const newCurrency = event.target.value;
    setCurrency(newCurrency);
    onCurrencyChange(newCurrency);

    if (make && model && year) {
      setLoading(true);

      try {
        const pricingData = await getPricingData(
          make,
          model,
          year,
          newCurrency
        );
        if (pricingData.message) {
          onValueDataChange(null, null, "", "", true);
          setPriceRange(null);
        } else {
          onValueDataChange(pricingData, year, make, model, false);
          setPriceRange(pricingData.global_price_range);
        }
      } catch (error) {
        console.error("Error fetching pricing data:", error.message);
        onValueDataChange(null, null, "", "", true);
        setPriceRange(null);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRunRequest = async () => {
    if (!make || !model || !year) {
      setSnackbarMessage("Make, Model, and Year are required");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    try {
      const pricingData = await getPricingData(
        make,
        model,
        year,
        currency,
        country
      );
      if (pricingData.message) {
        onValueDataChange(null, null, "", "", true);
        setPriceRange(null);
      } else {
        onValueDataChange(pricingData, year, make, model, false);
        setPriceRange(pricingData.global_price_range);
      }
    } catch (error) {
      console.error("Error fetching pricing data:", error.message);
      onValueDataChange(null, null, "", "", true);
      setPriceRange(null);
    } finally {
      setLoading(false);
    }
  };

  const currencySymbols = {
    AUD: "$",
    USD: "$",
    EUR: "€",
    GBP: "£",
    NZD: "$",
    CAD: "$",
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getCurrencySymbol = (abbr) => {
    return currencySymbols[abbr] || abbr;
  };

  const formatPriceRange = (range) => {
    if (!range) return "";
    const symbol = getCurrencySymbol(currency);
    return `Min: ${symbol}${formatNumber(
      range.min
    )}, Max: ${symbol}${formatNumber(range.max)}`;
  };

  useEffect(() => {
    if (error) {
      console.error("Error fetching makes data:", error.message);
      setSnackbarMessage("Error fetching boat makes data.");
      setSnackbarOpen(true);
    }
  }, [error]);

  return (
    <Box>
      <Box sx={{ p: 3, mb: 3 }}>
        <Typography
          variant="h4"
          gutterBottom
          sx={{ textAlign: "center", color: "#034d70" }}
        >
          Pricing Tool
        </Typography>
        {isLoading || loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress sx={{ height: "10vh", mt: 2 }} />
          </Box>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Autocomplete
                options={makes}
                value={make}
                onChange={handleMakeChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="Make"
                    margin="normal"
                    InputProps={{
                      ...params.InputProps,
                    }}
                    sx={{
                      input: {
                        color: "#000",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#034d70",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#0a93d9",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#034d70",
                        },
                        "&:hover fieldset": {
                          borderColor: "#0a93d9",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#0a93d9",
                        },
                        "& input:-webkit-autofill": {
                          WebkitBoxShadow: `0 0 0 100px #deebf0 inset !important`,
                          backgroundColor: "transparent !important",
                          color: "#000",
                        },
                      },
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Tooltip
                title={!make ? "Please select a make" : ""}
                placement="top"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#034d70",
                      color: "#fff",
                      fontSize: "1em",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "#034d70",
                    },
                  },
                }}
              >
                <span>
                  <TextField
                    fullWidth
                    label="Model"
                    value={model}
                    onChange={handleModelChange}
                    margin="normal"
                    disabled={!make}
                    select
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            backgroundColor: "#034d70",
                            color: "#fff",
                          },
                        },
                      },
                    }}
                    sx={{
                      input: {
                        color: "#000",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#034d70",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#0a93d9",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#034d70",
                        },
                        "&:hover fieldset": {
                          borderColor: "#0a93d9",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#0a93d9",
                        },
                        "& input:-webkit-autofill": {
                          WebkitBoxShadow: `0 0 0 100px #deebf0 inset !important`,
                          backgroundColor: "transparent !important",
                          color: "#000",
                        },
                        "& .MuiSelect-select": {
                          "&::-webkit-scrollbar": {
                            width: "8px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#2c7599",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: "#6FA2BB",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "#a1c6d4",
                          },
                          overflowX: "hidden",
                        },
                      },
                    }}
                  >
                    {models.map((model, index) => (
                      <MenuItem key={`${model}-${index}`} value={model}>
                        {model}
                      </MenuItem>
                    ))}
                  </TextField>
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Tooltip
                title={!model ? "Please select a model" : ""}
                placement="top"
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#034d70",
                      color: "#fff",
                      fontSize: "1em",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "#034d70",
                    },
                  },
                }}
              >
                <span>
                  <TextField
                    fullWidth
                    label="Year"
                    value={year}
                    onChange={handleYearChange}
                    margin="normal"
                    disabled={!model}
                    sx={{
                      input: {
                        color: "#000",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#034d70",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#0a93d9",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#034d70",
                        },
                        "&:hover fieldset": {
                          borderColor: "#0a93d9",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#0a93d9",
                        },
                        "& input:-webkit-autofill": {
                          WebkitBoxShadow: `0 0 0 100px #fff inset !important`,
                          backgroundColor: "transparent !important",
                          color: "#000",
                        },
                      },
                    }}
                  />
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Country"
                value={country}
                onChange={handleCountryChange}
                margin="normal"
                select
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        backgroundColor: "#034d70",
                        color: "#fff",
                      },
                    },
                  },
                }}
                sx={{
                  input: {
                    color: "#000",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#034d70",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#0a93d9",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#034d70",
                    },
                    "&:hover fieldset": {
                      borderColor: "#0a93d9",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0a93d9",
                    },
                    "& input:-webkit-autofill": {
                      WebkitBoxShadow: `0 0 0 100px #deebf0 inset !important`,
                      backgroundColor: "transparent !important",
                      color: "#000",
                    },
                    "& .MuiSelect-select": {
                      "&::-webkit-scrollbar": {
                        width: "8px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#2c7599",
                        borderRadius: "4px",
                      },
                      "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#6FA2BB",
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "#a1c6d4",
                      },
                      overflowX: "hidden",
                    },
                  },
                }}
              >
                {countries.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Currency"
                value={currency}
                onChange={handleCurrencyChange}
                margin="normal"
                select
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        backgroundColor: "#034d70",
                        color: "#fff",
                      },
                    },
                  },
                }}
                sx={{
                  input: {
                    color: "#000",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#034d70",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#0a93d9",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#034d70",
                    },
                    "&:hover fieldset": {
                      borderColor: "#0a93d9",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0a93d9",
                    },
                    "& input:-webkit-autofill": {
                      WebkitBoxShadow: `0 0 0 100px #deebf0 inset !important`,
                      backgroundColor: `transparent !important`,
                      color: "#000",
                    },
                    "& .MuiSelect-select": {
                      "&::-webkit-scrollbar": {
                        width: "8px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#2c7599",
                        borderRadius: "4px",
                      },
                      "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#6FA2BB",
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "#a1c6d4",
                      },
                      overflowX: "hidden",
                    },
                  },
                }}
              >
                <MenuItem value="USD">USD</MenuItem>
                <MenuItem value="GBP">GBP</MenuItem>
                <MenuItem value="EUR">EUR</MenuItem>
                <MenuItem value="CAD">CAD</MenuItem>
                <MenuItem value="AUD">AUD</MenuItem>
                <MenuItem value="NZD">NZD</MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Global Price Range"
                value={formatPriceRange(priceRange)}
                margin="normal"
                disabled
                sx={{
                  input: {
                    color: "#000",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#034d70",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#0a93d9",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#034d70",
                    },
                    "&:hover fieldset": {
                      borderColor: "#0a93d9",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0a93d9",
                    },
                    "& input:-webkit-autofill": {
                      WebkitBoxShadow: `0 0 0 100px #deebf0 inset !important`,
                      backgroundColor: "transparent !important",
                      color: "#000",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleRunRequest}
                sx={{
                  backgroundColor: "#034d70",
                  "&:hover": {
                    backgroundColor: "#0a93d9",
                  },
                }}
              >
                <Typography variant="button">Run request</Typography>
              </Button>
            </Grid>
          </Grid>
        )}
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="warning"
          sx={{ backgroundColor: "#034d70", color: "#fff" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default PricingTool;
