import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function Footer(props) {

    console.log(props.theme)

    return (
        <div>
            <Container fluid>
                {
                    props.withLine &&
                        <Row>
                            <Col>
                                <div style={{height: '1px', width: '100%', backgroundColor:props.theme.theme}}></div>
                            </Col>
                        </Row>
                }
                <Row className='report-pt-1 report-pb-1' style={{color: props.theme.themePrimaryText}}>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                    <Col>
                        <p className='report-text'>{props.brokerDetails.name}</p>
                    </Col>
                    <Col style={{textAlign: 'center'}}>
                        <p className='report-text'>{props.brokerDetails.email}</p>
                    </Col>
                    <Col>
                        <p className='report-text' style={{float: 'right'}}>{props.brokerDetails.phone}</p>
                    </Col>
                    <Col xs={1} sm={1} md={1} lg={1} xl={1}></Col>
                </Row>
            </Container>
        </div>
    )
}
