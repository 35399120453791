import React from 'react'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { updateReportBoatTitle } from '../../../../requests/report/reportBoat'
import Button from 'react-bootstrap/Button'


export default function Title(props) {

    const [showEditTitleModal, setShowEditTitleModal] = useState(false)

    const [title, setTitle] = useState(props.boat.title)
    const [titleError, setTitleError] = useState('')


    function validateFormData() {
        var isValid = true;
        setTitleError('')
        if (title.length < 1) {
            isValid = false;
            setTitleError('Title is required')
        }

        return isValid;
    }


    function handleSaveTitle() {
        if (!validateFormData()) {
            return;
        }
        var fd = {
            report_boat_id: props.boat.id,
            title: title
        }

        updateReportBoatTitle(fd, 
            (data) => {
                setShowEditTitleModal(false)
            },
            (data) => {
                console.log(data)
            }
        )
    }

    return (
        <>
            <div className='editable-section report-p-2' onClick={() => {setShowEditTitleModal(true)}}>
                <h1 style={{color: props.themePrimaryText}} className='report-title'>{title}</h1>
                {
                    props.report.include_listing_url &&
                    <p className='report-text' style={{color: props.themeSecondaryText}}>{props.boat.url}</p>
                }
            </div>

            <Modal show={showEditTitleModal} onHide={() => handleSaveTitle()}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Edit Title</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>Title</p>
                        <input type='text' className='form-control' value={title} onChange={(e) => {setTitle(e.target.value)}} />
                        <p style={{color: 'red'}}>{titleError}</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='primary-bg' onClick={() => handleSaveTitle()}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
