import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useState } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { updateTheme } from '../../../requests/theme'


export default function ChangeThemeButton(props) {

    const [showThemeModal, setShowThemeModal] = useState(false)

    const [showCustomThemeModal, setShowCustomThemeModal] = useState(false)

    const [customColor, setCustomColor] = useState('#024680')
    const [customPrimaryText, setCustomPrimaryText] = useState('#0197dd')
    const [customSecondaryText, setCustomSecondaryText] = useState('#ffffff')

    const themes = {
        'teal':{
            'theme': 'rgb(2, 70, 128)',
            'primaryText': 'rgb(1, 151, 221)',
            'secondaryText': 'rgb(255, 255, 255)'
        },
        'yellow':{
            'theme': 'rgb(252,228,19)',
            'primaryText': 'rgb(0, 0, 0)',
            'secondaryText': 'rgb(255, 255, 255)'
        },
        'green':{
            'theme': 'rgb(15, 212, 35)',
            'primaryText': 'rgb(0, 0, 0)',
            'secondaryText': 'rgb(255, 255, 255)'
        },
        'red':{
            'theme': 'rgb(199, 48, 48)',
            'primaryText': 'rgb(0, 0, 0)',
            'secondaryText': 'rgb(255, 255, 255)'
        },
        'gray':{
            'theme': 'rgb(128, 128, 128)',
            'primaryText': 'rgb(0, 0, 0)',
            'secondaryText': 'rgb(255, 255, 255)'
        },
        'black':{
            'theme': 'rgb(0, 0, 0)',
            'primaryText': 'rgb(150, 150, 150)',
            'secondaryText': 'rgb(255, 255, 255)'
        },
    }

    function handleUpdateTheme(theme) {
        var fd = {
            theme_color: JSON.stringify({
                theme:theme.theme,
                primaryText:theme.primaryText,
                secondaryText:theme.secondaryText
            })
        }

        updateTheme(
            fd, 
            (data) => {
                props.setThemeCallback(theme)
                setShowThemeModal(false)
            }, 
            (data) => {
                console.log(data)
            }
        )
    }


    function handleCustomColorSelect(value) {
        handleUpdateTheme(value)
    }

    return (
        <>
            <Button style={{margin: '15px'}} className='primary-bg' onClick={() => {setShowThemeModal(true)}}>Change Theme</Button>
          
            <Modal size='lg' show={showThemeModal} onHide={() => setShowThemeModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Change Theme</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <h5>Choose a theme color</h5>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4} sm={4} md={4} lg={2} xl={2}>
                            <div className='theme-button' onClick={() => {handleUpdateTheme(themes.teal)}} style={{backgroundColor: 'rgb(2, 70, 128)', height: '50px', width: '50px', borderRadius: '10px', marginLeft: 'auto', marginRight: 'auto', marginTop:'40px'}}></div>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={2} xl={2}>
                            <div className='theme-button' onClick={() => {handleUpdateTheme(themes.yellow)}} style={{backgroundColor: 'rgb(212, 212, 15)', height: '50px', width: '50px', borderRadius: '10px', marginLeft: 'auto', marginRight: 'auto', marginTop:'40px'}}></div>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={2} xl={2}>
                            <div className='theme-button' onClick={() => {handleUpdateTheme(themes.green)}} style={{backgroundColor: 'rgb(15, 212, 35)', height: '50px', width: '50px', borderRadius: '10px', marginLeft: 'auto', marginRight: 'auto', marginTop:'40px'}}></div>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={2} xl={2}>
                            <div className='theme-button' onClick={() => {handleUpdateTheme(themes.red)}} style={{backgroundColor: 'rgb(199, 48, 48)', height: '50px', width: '50px', borderRadius: '10px', marginLeft: 'auto', marginRight: 'auto', marginTop:'40px'}}></div>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={2} xl={2}>
                            <div className='theme-button' onClick={() => {handleUpdateTheme(themes.gray)}} style={{backgroundColor: 'rgb(180, 180, 180)', height: '50px', width: '50px', borderRadius: '10px', marginLeft: 'auto', marginRight: 'auto', marginTop:'40px'}}></div>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={2} xl={2}>
                            <div className='theme-button' onClick={() => {handleUpdateTheme(themes.black)}} style={{backgroundColor: 'rgb(0, 0, 0)', height: '50px', width: '50px', borderRadius: '10px', marginLeft: 'auto', marginRight: 'auto', marginTop:'40px'}}></div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{width: '100%', textAlign: 'center', marginTop: '40px'}}>
                                <Button className='secondary-bg' onClick={() => {setShowThemeModal(false); setShowCustomThemeModal(true)}}>Or select a custom theme</Button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal size='lg' show={showCustomThemeModal} onHide={() => setShowCustomThemeModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Custom Theme</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <div style={{width: '100%', textAlign: 'left', padding: '20px', marginBottom: '10px', backgroundColor:customColor}}>
                                <h3 style={{color: customPrimaryText}}>Primary Text</h3>
                                <h5 style={{color: customSecondaryText}}>Secondary Text</h5>
                            </div>
                            <div style={{width: '100%', textAlign: 'left', marginBottom: '20px', backgroundColor: 'white', padding: '20px'}}>
                                <h3 style={{color: customPrimaryText}}>Primary Text</h3>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{width: '100%', textAlign: 'center', marginTop: '40px'}}>
                                <h5>Trim Color</h5>
                                <Form.Control
                                    style={{marginTop: '30px', marginLeft: 'auto', marginRight: 'auto'}}
                                    type="color"
                                    defaultValue="#563d7c"
                                    title="Choose your color"
                                    value={customColor}
                                    onChange={(e) => {setCustomColor(e.target.value)}}
                                />    
                            </div>
                        </Col>
                        <Col>
                            <div style={{width: '100%', textAlign: 'center', marginTop: '40px'}}>
                                <h5>Primary Text Color</h5>
                                <Form.Control
                                    style={{marginTop: '30px', marginLeft: 'auto', marginRight: 'auto'}}
                                    type="color"
                                    defaultValue="#563d7c"
                                    title="Choose your color"
                                    value={customPrimaryText}
                                    onChange={(e) => {setCustomPrimaryText(e.target.value)}}
                                />    
                            </div>
                        </Col>
                        <Col>
                            <div style={{width: '100%', textAlign: 'center', marginTop: '40px'}}>
                                <h5>Secondary Text Color</h5>
                                <Form.Control
                                    style={{marginTop: '30px', marginLeft: 'auto', marginRight: 'auto'}}
                                    type="color"
                                    defaultValue="#563d7c"
                                    title="Choose your color"
                                    value={customSecondaryText}
                                    onChange={(e) => {setCustomSecondaryText(e.target.value)}}
                                />    
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div style={{marginTop: '40px', marginLeft: '20px'}}>
                                <Button className='secondary-bg' onClick={() => {setShowCustomThemeModal(false); handleCustomColorSelect({theme: customColor, primaryText: customPrimaryText, secondaryText: customSecondaryText})}}>Save</Button>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

        </>
    )
}
