import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'

export default function EditBrokerDetailsModal(props) {
    return (
        <Modal show={props.showEditBrokerModal} onHide={() => props.setShowEditBrokerModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title className="styled-header">Edit Broker Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <Form.Control style={{marginTop: '20px'}} type='text' placeholder='Broker Name' value={props.editBrokerName} onChange={(e) => props.setEditBrokerName(e.target.value)} />
                    <p style={{color: 'red'}}>{props.editBrokerNameError}</p>
                    
                    <Form.Control style={{marginTop: '20px'}} type='text' placeholder='Broker Email' value={props.editBrokerEmail} onChange={(e) => props.setEditBrokerEmail(e.target.value)} />
                    <p style={{color: 'red'}}>{props.editBrokerEmailError}</p>
                    
                    <Form.Control style={{marginTop: '20px'}} type='text' placeholder='Broker Phone' value={props.editBrokerPhone} onChange={(e) => props.setEditBrokerPhone(e.target.value)} />
                    <p style={{color: 'red'}}>{props.editBrokerPhoneError}</p>
                
                </div>
                <div>
                    <Button style={{marginTop: '20px'}} className="primary-bg" onClick={() => {props.handleEditBrokerDetails()}}>Save</Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}
