import { baseApiUrl } from "../../settings";
import { 
    tokenRequiredRequest,
    tokenRequiredRequestWithBody
} from "../baseRequest";


export const createClientAlert = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/client-alerts`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}



export const getClientAlert = (client_profile_id, successCallback, failedCallback) => {
    return tokenRequiredRequest(
        `${baseApiUrl}/client-alerts/${client_profile_id}`, 
        'GET', 
        successCallback, 
        failedCallback)
}



export const archiveClientAlerts = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/client-alerts/archive`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}


