import { baseApiUrl } from "../settings";
import { 
    tokenRequiredRequest,
    tokenRequiredRequestWithBody
} from "./baseRequest";




export const createBrokerDetails = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/broker-details`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}



export const getBrokerDetails = (successCallback, failedCallback) => {
    return tokenRequiredRequest(
        `${baseApiUrl}/broker-details`, 
        'GET', 
        successCallback, 
        failedCallback)
}




export const updateBrokerDetails = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/broker-details`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}




export const deleteBrokerDetails = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/broker-details`, 
        'DELETE', 
        fd, 
        successCallback, 
        failedCallback)
}