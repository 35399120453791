import React from 'react'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Form from 'react-bootstrap/Form'
import Field from './Field'
import {useDropzone} from 'react-dropzone';
import { createAppraisedBoatField } from '../../../../requests/appraisal_report/appraisedBoatField'


export default function FieldsSection(props) {

    const [showAddSubtitleTextModal, setShowAddSubtitleTextModal] = useState(false)
    const [showAddSubtitleTextImageModal, setShowAddSubtitleTextImageModal] = useState(false)

    const [fieldTitle, setFieldTitle] = useState('')
    const [fieldBody, setFieldBody] = useState('')
    const [fieldImage, setFieldImage] = useState('./placeholder.jpg')

    const [fieldTitleError, setFieldTitleError] = useState('')
    const [fieldBodyError, setFieldBodyError] = useState('')
    const [fieldImageError, setFieldImageError] = useState('')


    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            var file = acceptedFiles[0];
            if(file){
                var reader = new FileReader();
    
                reader.onload = function(e){
                    setFieldImage(e.target.result)
                }
    
                reader.readAsDataURL(file);
            }
        }
    });


    function validateFormData() {
        var valid = true;

        setFieldTitleError('')
        setFieldBodyError('')
        setFieldImageError('')

        if(fieldTitle === ''){
            setFieldTitleError('Title is required')
            valid = false;
        }

        if(fieldBody === ''){
            setFieldBodyError('Body is required')
            valid = false;
        }

        if (showAddSubtitleTextImageModal === true) {
            if(fieldImage === './placeholder.jpg'){
                setFieldImageError('Image is required')
                valid = false;
            }
        }

        return valid;
    }



    function handleAddField() {
        if(!validateFormData()){
            return;
        }

        var fd = {
            appraised_boat_id: props.appraisedBoat.id,
            title: fieldTitle,
            body: fieldBody,
            image: fieldImage === './placeholder.jpg' ? null : fieldImage
        }

        createAppraisedBoatField(fd, 
            (data) => {
                props.reloadReportCallback();
                handleClearInputs();
            },
            (data) => {
                console.log(data)
            }
        )
    }

    function handleClearInputs() {
        setFieldTitle('');
        setFieldBody('');
        setFieldImage('./placeholder.jpg');
        props.setShowAddItemModal(false);
        setShowAddSubtitleTextModal(false);
        setShowAddSubtitleTextImageModal(false);
    }

    function deleteFieldCallback(fieldId) {
        props.reloadReportCallback();
    }


    return (
        <>
            <div style={{width: '100%'}}>
                {
                    props.appraisedBoat.fields.map((field, index) => {
                        return (
                            <Field 
                                themePrimaryText={props.themePrimaryText}
                                themeSecondaryText={props.themeSecondaryText}
                                key={index}
                                field={field}
                                deleteFieldCallback={deleteFieldCallback}
                            />
                        )
                    })

                }
            </div>

            <Modal show={props.showAddItemModal} onHide={() => props.setShowAddItemModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Add Item</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    <p style={{marginTop: '40px'}}>Add Field (Subtitle + text)</p>
                    <div onClick={() => {props.setShowAddItemModal(false); setShowAddSubtitleTextModal(true);}} className='select-field-type' style={{padding: '20px'}}>
                        <div style={{width: '60%', height: '30px', borderRadius: '15px', backgroundColor: 'rgb(150,150,150)'}}></div>
                        
                        <div style={{marginTop: '20px', width: '80%', height: '20px', borderRadius: '10px', backgroundColor: 'rgb(180,180,180)'}}></div>
                        <div style={{marginTop: '10px', width: '80%', height: '20px', borderRadius: '10px', backgroundColor: 'rgb(180,180,180)'}}></div>
                        <div style={{marginTop: '10px',width: '80%', height: '20px', borderRadius: '10px', backgroundColor: 'rgb(180,180,180)'}}></div>
                    </div>

                    <p style={{marginTop: '40px'}}>Add Field (Subtitle + text + image)</p>
                    <div onClick={() => {props.setShowAddItemModal(false); setShowAddSubtitleTextImageModal(true);}} className='select-field-type' style={{padding: '20px'}}>
                        <div style={{width: '60%', height: '30px', borderRadius: '15px', backgroundColor: 'rgb(150,150,150)'}}></div>
                        <Container>
                            <Row>
                                <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                                    <Image src='/gen_image.jpg' style={{width: '100%', height: '100%', marginTop: '20px'}} />
                                </Col>
                                <Col>
                                    <div style={{marginTop: '20px', width: '100%', height: '20px', borderRadius: '10px', backgroundColor: 'rgb(180,180,180)'}}></div>
                                    <div style={{marginTop: '10px', width: '100%', height: '20px', borderRadius: '10px', backgroundColor: 'rgb(180,180,180)'}}></div>
                                    <div style={{marginTop: '10px',width: '100%', height: '20px', borderRadius: '10px', backgroundColor: 'rgb(180,180,180)'}}></div>
                                    <div style={{marginTop: '10px',width: '100%', height: '20px', borderRadius: '10px', backgroundColor: 'rgb(180,180,180)'}}></div>

                                </Col>
                            </Row>
                        </Container>
                    </div>
                    

                </Modal.Body>
            </Modal>


            <Modal show={showAddSubtitleTextModal} onHide={() => {handleClearInputs()}}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Add Field</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Title</p>
                    <Form.Control value={fieldTitle} onChange={(e) => {setFieldTitle(e.target.value)}} type='text' placeholder='title' />
                    <p style={{color: 'red'}}>{fieldTitleError}</p>

                    <p style={{marginTop: '20px'}}>Body</p>
                    <Form.Control value={fieldBody} onChange={(e) => {setFieldBody(e.target.value)}} as='textarea' rows={5} placeholder='body' />
                    <p style={{color: 'red'}}>{fieldBodyError}</p>
                
                </Modal.Body>
                <Modal.Footer>
                    <Button className="primary-bg" onClick={() => {handleAddField()}}>Save</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAddSubtitleTextImageModal} onHide={() => {handleClearInputs()}}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Add Field</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Title</p>
                    <Form.Control value={fieldTitle} onChange={(e) => {setFieldTitle(e.target.value)}} type='text' placeholder='title' />
                    <p style={{color: 'red'}}>{fieldTitleError}</p>

                    <p style={{marginTop: '20px'}}>Body</p>
                    <Form.Control value={fieldBody} onChange={(e) => {setFieldBody(e.target.value)}} as='textarea' rows={5} placeholder='body' />
                    <p style={{color: 'red'}}>{fieldBodyError}</p>

                    <p style={{marginTop: '20px'}}>Image</p>
                    <div className="image-upload" style={{textAlign: 'center', marginTop:'40px'}} {...getRootProps()}  >
                        <Image className='upload-image mild-neumorphic-card' id="previewImg" src={fieldImage} style={{width: '50%'}} />
                        <input {...getInputProps()} />
                    </div>
                    <p style={{color: 'red'}}>{fieldImageError}</p>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="primary-bg" onClick={() => {handleAddField()}}>Save</Button>
                </Modal.Footer>
            </Modal>

                    
        </>
    )
}
