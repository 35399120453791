import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useState } from 'react';
import { updateReportBoatSpecifications } from '../../../../requests/report/reportBoat';





export default function SpecificationsModal(props) {

    const [editPrice, setEditPrice] = useState(props.price);
    const [editCurrency, setEditCurrency] = useState(props.currency);
    const [editPriceError, setEditPriceError] = useState('');

    const [editLength, setEditLength] = useState(props.length);
    const [editLengthError, setEditLengthError] = useState('');

    const [editCountry, setEditCountry] = useState(props.country);
    const [editCountryError, setEditCountryError] = useState('');

    const [editYear, setEditYear] = useState(props.year);
    const [editYearError, setEditYearError] = useState('');

    function validateFormData() {
        var valid = true;
        setEditPriceError('');
        setEditLengthError('');
        setEditCountryError('');
        setEditYearError('');

        if (editPrice === '') {
            setEditPriceError('Price is required');
            valid = false;
        }

        if (editLength === '') {
            setEditLengthError('Length is required');
            valid = false;
        }

        if (editCountry === '') {
            setEditCountryError('Location is required');
            valid = false;
        }

        if (editYear === '') {
            setEditYearError('Year is required');
            valid = false;
        }

        return valid;
    }



    function handleEditSpecifications() {
        if (!validateFormData()) {
            return;
        }

        var fd = {
            report_boat_id: props.boatId,
            price: editPrice,
            currency: editCurrency,
            length: editLength,
            location: editCountry,
            year: editYear
        };
        updateReportBoatSpecifications(fd, 
            (data) => {
                props.setShowModal(false);
                props.reloadReportCallback();
            },
            (data) => {
                console.log(data)
            }
        )
    }



    return (
        <Modal show={props.showModal} onHide={() => {props.setShowModal(false)}}>
            <Modal.Header closeButton>
                <Modal.Title className="styled-header">Edit Specifications</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p style={{marginTop: '20px'}}>Price</p>

                <Row>
                    <Col xs={8} sm={8} md={8} lg={8} xl={8}>
                        <Form.Control type="number" placeholder="Price" value={editPrice} onChange={(e) => setEditPrice(e.target.value)} />
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                        <Form.Control as="select" value={editCurrency} onChange={(e) => setEditCurrency(e.target.value)}>
                            <option value='USD'>USD</option>
                            <option value='NZD'>NZD</option>
                            <option value='AUD'>AUD</option>
                            <option value='EUR'>EUR</option>
                            <option value='GBP'>GBP</option>
                        </Form.Control>
                    </Col>
                </Row>
                <p style={{color: 'red'}}>{editPriceError}</p>

                <p style={{marginTop: '40px'}}>Length (m)</p>
                <Form.Control type="number" placeholder="Length" value={editLength} onChange={(e) => setEditLength(e.target.value)} />
                <p style={{color: 'red'}}>{editLengthError}</p>

                <p style={{marginTop: '40px'}}>Location</p>
                <Form.Control type="text" placeholder="Country" value={editCountry} onChange={(e) => setEditCountry(e.target.value)} />
                <p style={{color: 'red'}}>{editCountryError}</p>
                
                <p style={{marginTop: '40px'}}>Year</p>
                <Form.Control type="number" placeholder="Year" value={editYear} onChange={(e) => setEditYear(e.target.value)} />
                <p style={{color: 'red'}}>{editYearError}</p>
            
            </Modal.Body>
            <Modal.Footer>
                <Button className="primary-bg" onClick={() => {handleEditSpecifications()}}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
