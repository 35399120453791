import React from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useState } from 'react'
import {useDropzone} from 'react-dropzone';
import { adminCreateAccount } from '../../../requests/admin'


export default function CreateAccount() {

    const [showCreateAccountModal, setShowCreateAccountModal] = useState(false)
    const [showAccountCreatedModal, setShowAccountCreatedModal] = useState(false)

    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [logoImage, setLogoImage] = useState('./placeholder.jpg')

    const [firstNameError, setFirstNameError] = useState('')
    const [lastNameError, setLastNameError] = useState('')
    const [emailError, setEmailError] = useState('')
    const [phoneError, setPhoneError] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [logoImageError, setLogoImageError] = useState('')

    const [selectedTheme, setSelectedTheme] = useState({
        'theme': 'rgb(2, 70, 128)',
        'primaryText': 'rgb(1, 151, 221)',
        'secondaryText': 'rgb(255, 255, 255)'
    })


    const [customColor, setCustomColor] = useState('#024680')
    const [customPrimaryText, setCustomPrimaryText] = useState('#0197dd')
    const [customSecondaryText, setCustomSecondaryText] = useState('#ffffff')

    const themes = {
        'teal':{
            'theme': 'rgb(2, 70, 128)',
            'primaryText': 'rgb(1, 151, 221)',
            'secondaryText': 'rgb(255, 255, 255)'
        },
        'yellow':{
            'theme': 'rgb(252,228,19)',
            'primaryText': 'rgb(0, 0, 0)',
            'secondaryText': 'rgb(255, 255, 255)'
        },
        'green':{
            'theme': 'rgb(15, 212, 35)',
            'primaryText': 'rgb(0, 0, 0)',
            'secondaryText': 'rgb(255, 255, 255)'
        },
        'red':{
            'theme': 'rgb(199, 48, 48)',
            'primaryText': 'rgb(0, 0, 0)',
            'secondaryText': 'rgb(255, 255, 255)'
        },
        'gray':{
            'theme': 'rgb(128, 128, 128)',
            'primaryText': 'rgb(0, 0, 0)',
            'secondaryText': 'rgb(255, 255, 255)'
        },
        'black':{
            'theme': 'rgb(0, 0, 0)',
            'primaryText': 'rgb(150, 150, 150)',
            'secondaryText': 'rgb(255, 255, 255)'
        },
    }


    function handleSelectDefaultTheme(theme) {
        setSelectedTheme(themes[theme])
    }


    function handleSelectCustomTheme(theme, primaryText, secondaryText) {
        setSelectedTheme({
            'theme': theme,
            'primaryText': primaryText,
            'secondaryText': secondaryText
        })

        setCustomColor(theme)
        setCustomPrimaryText(primaryText)
        setCustomSecondaryText(secondaryText)
    }



    function validateFormData() {
        var valid = true;
        
        setFirstNameError('')
        setLastNameError('')
        setEmailError('')
        setPhoneError('')
        setPasswordError('')
        setLogoImageError('')

        if(firstName === ''){
            setFirstNameError('First Name is required')
            valid = false;
        }

        if(lastName === ''){
            setLastNameError('Last Name is required')
            valid = false;
        }

        if(email === ''){
            setEmailError('Email is required')
            valid = false;
        }

        if(phone === ''){
            setPhoneError('Phone is required')
            valid = false;
        }

        if(password === ''){
            setPasswordError('Password is required')
            valid = false;
        }

        if(logoImage === './placeholder.jpg'){
            setLogoImageError('Logo Image is required')
            valid = false;
        }

        return valid;
    }


    function makerandPassword() {
        const length = 8;
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    }


    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            var file = acceptedFiles[0];
            if(file){
                var reader = new FileReader();
    
                reader.onload = function(e){
                    setLogoImage(e.target.result)
                }
    
                reader.readAsDataURL(file);
            }
        }
    });


    function createAccountAdminSuccess() {
        setShowCreateAccountModal(false)
        setShowAccountCreatedModal(true)
    }

    function createAccountAdminFailed() {
        console.log('failed')
    }

    function handleCreateAccount() {
        if(!validateFormData()){
            return;
        }

        var fd = {
            'first_name': firstName,
            'last_name': lastName,
            'email': email,
            'phone': phone,
            'password': password,
            'logo_image': logoImage,
            'theme': JSON.stringify(selectedTheme)
        }

        adminCreateAccount(fd, createAccountAdminSuccess, createAccountAdminFailed)
    }


    function handleCloseCreated() {
        setShowAccountCreatedModal(false)
        setFirstName('')
        setLastName('')
        setEmail('')
        setPhone('')
        setPassword('')
        setLogoImage('./placeholder.jpg')
        setSelectedTheme({
            'theme': 'rgb(2, 70, 128)',
            'primaryText': 'rgb(1, 151, 221)',
            'secondaryText': 'rgb(255, 255, 255)'
        })

    }


    return (
        <>
            <div style={{marginTop: '50px', padding: '40px'}} className='neumorphic-card'>
                <h3>Create Account</h3>
                <Button className="primary-bg" onClick={() => {setShowCreateAccountModal(true)}}>Create Account</Button>
            </div>
            <Modal size='lg' show={showCreateAccountModal} onHide={() => setShowCreateAccountModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Create Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    
                    <p>First Name</p>
                    <Form.Control value={firstName} onChange={(e) => {setFirstName(e.target.value)}} type="text" placeholder="First Name" />
                    <p style={{color: 'red'}}>{firstNameError}</p>

                    <p style={{marginTop: '40px'}}>Last Name</p>
                    <Form.Control value={lastName} onChange={(e) => {setLastName(e.target.value)}} type="text" placeholder="Last Name" />
                    <p style={{color: 'red'}}>{lastNameError}</p>

                    <p style={{marginTop: '40px'}}>Email</p>
                    <Form.Control value={email} onChange={(e) => {setEmail(e.target.value)}} type="email" placeholder="Email" />
                    <p style={{color: 'red'}}>{emailError}</p>

                    <p style={{marginTop: '40px'}}>Phone</p>
                    <Form.Control value={phone} onChange={(e) => {setPhone(e.target.value)}} type="text" placeholder="Phone" />
                    <p style={{color: 'red'}}>{phoneError}</p>

                    <p style={{marginTop: '40px'}}>Password</p>
                    <Form.Control value={password} onChange={(e) => {setPassword(e.target.value)}} type="text" placeholder="Password" />
                    <p style={{color: 'red'}}>{passwordError}</p>

                    <Button style={{marginTop: '20px'}} onClick={() => {setPassword(makerandPassword())}} className="primary-bg">Generate Password</Button>

                    <p style={{marginTop: '40px'}}>Logo</p>

                    <div className="image-upload" style={{textAlign: 'center', marginTop:'40px'}} {...getRootProps()}  >
                        <Image className='upload-image mild-neumorphic-card' id="previewImg" src={logoImage} style={{width: '60%'}} />
                        <input {...getInputProps()} />
                    </div>
                    <p style={{color: 'red'}}>{logoImageError}</p>

                    <Row style={{marginTop: '100px'}}>
                        <Col>
                            <div style={{width: '100%', textAlign: 'center'}}>
                                <h5>Choose a theme color</h5>
                            </div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '40px'}}>
                        <Col>
                            <div style={{width: '100%', textAlign: 'left', padding: '20px', marginBottom: '10px', backgroundColor:selectedTheme.theme}}>
                                <h3 style={{color: selectedTheme.primaryText}}>Primary Text</h3>
                                <h5 style={{color: selectedTheme.secondaryText}}>Secondary Text</h5>
                            </div>
                            <div style={{width: '100%', textAlign: 'left', marginBottom: '20px', backgroundColor: 'white', padding: '20px'}}>
                                <h3 style={{color: selectedTheme.primaryText}}>Primary Text</h3>
                            </div>

                        </Col>
                    </Row>
                    <Row>
                        <Col xs={4} sm={4} md={4} lg={2} xl={2}>
                            <div className='theme-button' onClick={() => {handleSelectDefaultTheme('teal')}} style={{backgroundColor: 'rgb(2, 70, 128)', height: '50px', width: '50px', borderRadius: '10px', marginLeft: 'auto', marginRight: 'auto', marginTop:'40px'}}></div>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={2} xl={2}>
                            <div className='theme-button' onClick={() => {handleSelectDefaultTheme('yellow')}} style={{backgroundColor: 'rgb(212, 212, 15)', height: '50px', width: '50px', borderRadius: '10px', marginLeft: 'auto', marginRight: 'auto', marginTop:'40px'}}></div>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={2} xl={2}>
                            <div className='theme-button' onClick={() => {handleSelectDefaultTheme('green')}} style={{backgroundColor: 'rgb(15, 212, 35)', height: '50px', width: '50px', borderRadius: '10px', marginLeft: 'auto', marginRight: 'auto', marginTop:'40px'}}></div>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={2} xl={2}>
                            <div className='theme-button' onClick={() => {handleSelectDefaultTheme('red')}} style={{backgroundColor: 'rgb(199, 48, 48)', height: '50px', width: '50px', borderRadius: '10px', marginLeft: 'auto', marginRight: 'auto', marginTop:'40px'}}></div>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={2} xl={2}>
                            <div className='theme-button' onClick={() => {handleSelectDefaultTheme('gray')}} style={{backgroundColor: 'rgb(180, 180, 180)', height: '50px', width: '50px', borderRadius: '10px', marginLeft: 'auto', marginRight: 'auto', marginTop:'40px'}}></div>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={2} xl={2}>
                            <div className='theme-button' onClick={() => {handleSelectDefaultTheme('black')}} style={{backgroundColor: 'rgb(0, 0, 0)', height: '50px', width: '50px', borderRadius: '10px', marginLeft: 'auto', marginRight: 'auto', marginTop:'40px'}}></div>
                        </Col>
                    </Row>
                    <Row style={{marginTop: '50px'}}>
                        <Col>
                            <div style={{width: '100%', textAlign: 'center', marginTop: '40px'}}>
                                <h5>Trim Color</h5>
                                <Form.Control
                                    style={{marginTop: '30px', marginLeft: 'auto', marginRight: 'auto'}}
                                    type="color"
                                    defaultValue="#563d7c"
                                    title="Choose your color"
                                    value={customColor}
                                    onChange={(e) => {handleSelectCustomTheme(e.target.value, customPrimaryText, customSecondaryText)}}
                                />    
                            </div>
                        </Col>
                        <Col>
                            <div style={{width: '100%', textAlign: 'center', marginTop: '40px'}}>
                                <h5>Primary Text Color</h5>
                                <Form.Control
                                    style={{marginTop: '30px', marginLeft: 'auto', marginRight: 'auto'}}
                                    type="color"
                                    defaultValue="#563d7c"
                                    title="Choose your color"
                                    value={customPrimaryText}
                                    onChange={(e) => {handleSelectCustomTheme(customColor, e.target.value, customSecondaryText)}}
                                />    
                            </div>
                        </Col>
                        <Col>
                            <div style={{width: '100%', textAlign: 'center', marginTop: '40px'}}>
                                <h5>Secondary Text Color</h5>
                                <Form.Control
                                    style={{marginTop: '30px', marginLeft: 'auto', marginRight: 'auto'}}
                                    type="color"
                                    defaultValue="#563d7c"
                                    title="Choose your color"
                                    value={customSecondaryText}
                                    onChange={(e) => {handleSelectCustomTheme(customColor, customPrimaryText, e.target.value)}}
                                />    
                            </div>
                        </Col>
                    </Row>

                </Modal.Body>
                <Modal.Footer>
                    <Button className="primary-bg" onClick={() => {handleCreateAccount()}}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showAccountCreatedModal}>
                <Modal.Header>
                    <Modal.Title className="styled-header">Account Created</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{firstName} {lastName}</p>
                    <p>{email}</p>
                    <p>{password}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="danger-bg" onClick={() => {handleCloseCreated()}}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
