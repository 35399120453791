import { baseApiUrl } from "../../settings";
import { 
    tokenRequiredRequestWithBody
} from "../baseRequest";



export const createAppraisalReportBoat = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/report-boats`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}



export const deleteAppraisalReportBoat = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/report-boats`, 
        'DELETE', 
        fd, 
        successCallback, 
        failedCallback)
}



export const createAppraisalReportBoatExtraImage = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/report-boats/extra-images`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}



export const deleteAppraisalReportBoatExtraImage = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/report-boats/extra-images`, 
        'DELETE', 
        fd, 
        successCallback, 
        failedCallback)
}




export const updateAppraisalReportBoatTitle = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/report-boats/title`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}



export const updateAppraisalReportBoatImage = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/report-boats/image`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}




export const updateAppraisalReportBoatImageCropped = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/report-boats/image-cropped`,
        'PUT',
        fd,
        successCallback,
        failedCallback)
}




export const updateAppraisalReportBoatSpecifications = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/report-boats/specifications`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}



export const updateAppraisalReportBoatFeature = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/report-boats/features`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}



