import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useState } from 'react';
import { updateAppraisalReportBoatSpecifications } from '../../../../requests/appraisal_report/appraisalReportBoat';


export default function Specifications(props) {

    const [showEditSpecificationsModal, setShowEditSpecificationsModal] = useState(false);

    const [currency, setCurrency] = useState(props.boat.currency);
    const [price, setPrice] = useState(props.boat.price);
    const [length, setLength] = useState(props.boat.length);
    const [country, setCountry] = useState(props.boat.country);
    const [year, setYear] = useState(props.boat.year);

    const [priceError, setPriceError] = useState('');
    const [lengthError, setLengthError] = useState('');
    const [countryError, setCountryError] = useState('');
    const [yearError, setYearError] = useState('');


    function getCurrencySymbol(currency) {
        switch(currency) {
            case 'USD':
                return '$';
            case 'NZD':
                return '$';
            case 'AUD':
                return '$';
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            default:
                return '$';
        }
    }

    function formatNumber(number) {
        if (number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return '';
        }
    }

    function validateFormData() {
        var valid = true;

        setPriceError('');
        setLengthError('');
        setCountryError('');
        setYearError('');

        if (price === '') {
            setPriceError('Price is required');
            valid = false;
        }

        if (length === '') {
            setLengthError('Length is required');
            valid = false;
        }

        if (country === '') {
            setCountryError('Country is required');
            valid = false;
        }

        if (year === '') {
            setYearError('Year is required');
            valid = false;
        }

        return valid;
    }

    function handleEditSpecifications() {

        if (!validateFormData()) {
            return;
        }

        var fd = {
            appraisal_report_boat_id: props.boat.id,
            price: price,
            currency: currency,
            length: length,
            country: country,
            year: year
        };
        updateAppraisalReportBoatSpecifications(fd, 
            (data) => {
                setShowEditSpecificationsModal(false);
            },
            (data) => {
                console.log(data);
            }
        )
    }


    return (
        <div className="report-p-2">
            <Row className='editable-section' onClick={() => {setShowEditSpecificationsModal(true)}}>
                <Col>
                    <h3 style={{color: props.themePrimaryText}} className='report-subtitle-sm'>Price</h3>
                    <h3 className='report-subtitle-sm'>{getCurrencySymbol(currency)}{formatNumber(price)} {currency}</h3>
                </Col>
                <Col>
                    <h3 style={{color: props.themePrimaryText}} className='report-subtitle-sm'>Length</h3>
                    <h3 className='report-subtitle-sm'>{Math.round(length)}m ({Math.round(length * 3.281)}ft)</h3>
                </Col>
                <Col>
                    <h3 style={{color: props.themePrimaryText}} className='report-subtitle-sm'>Location</h3>
                    <h3 className='report-subtitle-sm'>{country}</h3>
                </Col>
                <Col>
                    <h3 style={{color: props.themePrimaryText}} className='report-subtitle-sm'>Year</h3>
                    <h3 className='report-subtitle-sm'>{year}</h3>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div style={{height:'0.05vw', backgroundColor: 'grey', marginTop:'1vw'}}></div>
                </Col>
            </Row>

            <Modal show={showEditSpecificationsModal} onHide={() => {handleEditSpecifications()}}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Edit Specifications</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p style={{marginTop: '20px'}}>Price</p>
                    
                    <Row>
                        <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                            <Form.Control type="number" placeholder="Price" value={price} onChange={(e) => setPrice(e.target.value)} />
                        </Col>
                        <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <Form.Control as="select" value={currency} onChange={(e) => setCurrency(e.target.value)}>
                                <option value='USD'>USD</option>
                                <option value='NZD'>NZD</option>
                                <option value='AUD'>AUD</option>
                                <option value='EUR'>EUR</option>
                                <option value='GBP'>GBP</option>
                            </Form.Control>
                        </Col>
                    </Row>
                    <p style={{color: 'red'}}>{priceError}</p>
                    
                    <p style={{marginTop: '40px'}}>Length (m)</p>
                    <Form.Control type="number" placeholder="Length" value={length} onChange={(e) => setLength(e.target.value)} />
                    <p style={{color: 'red'}}>{lengthError}</p>
                    
                    <p style={{marginTop: '40px'}}>Location</p>
                    <Form.Control type="text" placeholder="Country" value={country} onChange={(e) => setCountry(e.target.value)} />
                    <p style={{color: 'red'}}>{countryError}</p>
                    
                    <p style={{marginTop: '40px'}}>Year</p>
                    <Form.Control type="number" placeholder="Year" value={year} onChange={(e) => setYear(e.target.value)} />
                    <p style={{color: 'red'}}>{yearError}</p>
                
                </Modal.Body>
                <Modal.Footer>
                    <Button className="primary-bg" onClick={() => {handleEditSpecifications()}}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>
                    
        </div>   
    )
}
