import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { removeToken } from '../Auth'
import Dropdown from 'react-bootstrap/Dropdown';


export default function Header(props) {
  
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false)
  
  function toggleDrawer() {
    setIsOpen(!isOpen)
  }


  function handleSignOut() {
    removeToken()
    navigate('/')
  }


  return (
    <Container fluid className='mild-neumorphic-card' style={{backgroundColor: 'white', height: '80px'}}>
    <Row>
      <Col>
        <div>
        <Row>
          <Col className='g-0'>
              <div>
                  <Image onClick={() => {navigate('/home')}} src="./new_logo_trans.png" style={{height: '80px', width: 'auto'}} />
              </div>
          </Col>
          <Col className='top-nav-collapsed'>
            <Row>
              <Col xs={6} sm={6} md={6}  style={{paddingTop: '15px', textAlign: 'right'}}>
                <Dropdown>
                  <Dropdown.Toggle style={{backgroundColor: 'rgba(0,0,0,0)', border: 'none', color: 'black'}} >
                    <div style={{display: 'flex'}}>
                      <i class="fa fa-lg fa-bell" style={{cursor: 'pointer', marginTop: '10px'}} aria-hidden="true"></i>
                      <div style={{backgroundColor: 'red', height: '20px', width: '20px', borderRadius: '10px', color: 'white'}} hidden>
                        2
                      </div>
                    </div>
                  </Dropdown.Toggle>

                  <Dropdown.Menu style={{width: '400px'}}>
                    <Dropdown.Item>
                    <div>
                      <p>No New Notifications</p>
                    </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
              <Col>
                {
                  props.signedIn ?
                  <div style={{paddingTop: '25px', paddingRight: '30px'}}>
                    <i onClick={() => {toggleDrawer()}} style={{float: 'right', color: 'black'}} className="fa fa-2x fa-bars"></i>
                  </div>
                  :
                  null
                }
              </Col>
            </Row>
          </Col>
          <Col className='top-nav-expanded'>
            {
              props.signedIn ?
                <Row style={{paddingTop: '20px'}}>
                  <Col>
                    <h5 onClick={() => {navigate('/home')}} className='nav-link'>Home</h5>
                  </Col>
                  <Col>
                    <h5 onClick={() => {navigate('/search', {state: {client_profile_id: null}})}} className='nav-link'>Search</h5>
                  </Col>
                  <Col>
                    <h5 onClick={() => {navigate('/my-clients')}} className='nav-link'>My Clients</h5>
                  </Col>
                  <Col>
                    <h5 onClick={() => {handleSignOut()}} className='nav-link' style={{color: 'red'}}>Sign Out</h5>
                  </Col>
                  <Col>
                    <Dropdown>
                      <Dropdown.Toggle style={{backgroundColor: 'rgba(0,0,0,0)', border: 'none', color: 'black'}} >
                        <div style={{display: 'flex'}}>
                          <i class="fa fa-lg fa-bell" style={{cursor: 'pointer', marginTop: '10px'}} aria-hidden="true"></i>
                          <div style={{backgroundColor: 'red', height: '20px', width: '20px', borderRadius: '10px', color: 'white'}} hidden>
                            2
                          </div>
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu style={{width: '400px'}}>
                        <Dropdown.Item>
                          <div>
                            <p>No New Notifications</p>
                          </div>
                        </Dropdown.Item>
                        
                      </Dropdown.Menu>
                    </Dropdown>
                  </Col>
                </Row>
              :
                null
            } 
          </Col>
        </Row>
        </div>
      </Col>
    </Row>
    <Drawer
      open={isOpen}
      onClose={toggleDrawer}
      direction='right'
      style={{backgroundColor: 'rgba(255, 255, 255, 0.9)'}}
    >
      <div style={{
          backgroundImage: 'url(./landing-img.jpeg)', 
          width: '100%', 
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          textAlign: 'center',
          paddingTop: '5vh',
          paddingBottom: '5vh',
      }}>
        <Image src="./new_logo_trans.png" style={{height: 'auto', width: '80%'}} />
      </div>
      <div style={{padding: '30px'}}>
        <div style={{textAlign: 'left'}}>
          <h5 onClick={() => {navigate('/home')}} className='nav-drawer-link'>Home</h5>
        </div>
        <div style={{paddingTop: '20px', textAlign: 'left'}}>
          <h5 onClick={() => {navigate('/search', {state: {client_profile_id: null}})}} className='nav-drawer-link'>Search</h5>
        </div>
        <div style={{paddingTop: '20px', textAlign: 'left'}}>
          <h5 onClick={() => {navigate('/my-clients')}} className='nav-drawer-link'>My Clients</h5>
        </div>
        <div style={{paddingTop: '20px', textAlign: 'left'}}>
          <h5 onClick={() => {handleSignOut()}} className='nav-drawer-link' style={{color: 'red'}}>Sign Out</h5>
        </div>
      </div>
    </Drawer>
    </Container>
  )
}
