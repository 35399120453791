import React from 'react'
import Page from '../../../generic_report_components/Page'
import ColorCut from '../../../generic_report_components/ColorCut'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useState } from 'react'
import { updateAppraisalReportListingPrice } from '../../../requests/appraisal_report/appraisalReport'
import { createAppraisalReportSummaryField } from '../../../requests/appraisal_report/appraisalReportSummaryField'
import SummaryFields from './summary_page_components/SummaryFields'
import { useEffect } from 'react'
import { getBrokerDetails } from '../../../requests/brokerDetails'


export default function SummaryPage(props) {

    const [showAddListingPriceModal, setShowAddListingPriceModal] = useState(false)
    const [showAddFieldModal, setShowAddFieldModal] = useState(false)
    const [listingPriceInput, setListingPriceInput] = useState(props.report.listing_price ? props.report.listing_price : 0)
    const [listingPriceInputError, setListingPriceInputError] = useState('')
    const [addFieldTitle, setAddFieldTitle] = useState('')
    const [addFieldBody, setAddFieldBody] = useState('')
    const [titleError, setTitleError] = useState('')
    const [bodyError, setBodyError] = useState('')


    function validateListingPrice() {
        var valid = true;

        setListingPriceInputError('');

        if (listingPriceInput === 0) {
            setListingPriceInputError('Listing price is required')
            valid = false;
        }

        return valid;
    }


    function validateFormData() {
        var valid = true;
        
        setTitleError('');
        setBodyError('');

        if (addFieldTitle.length === 0) {
            setTitleError('Title is required')
            valid = false;
        }

        if (addFieldBody.length === 0) {
            setBodyError('Body is required')
            valid = false;
        }

        return valid;
    }



    function handleSaveListingPrice() {
        if (!validateListingPrice()) {
            return;
        }

        var fd = {
            appraisal_report_id: props.report.id,
            listing_price: listingPriceInput
        }

        updateAppraisalReportListingPrice(fd, 
            (data) => {
                setShowAddListingPriceModal(false)
                props.reloadReportCallback()
            },
            (data) => {
                console.log(data)
            }
        )

    }


    function handleSaveSummaryField() {
        if (!validateFormData()) {
            return;
        }

        
        var fd = {
            appraisal_report_id: props.report.id,
            title: addFieldTitle,
            body: addFieldBody
        }

        createAppraisalReportSummaryField(fd, 
            (data) => {
                setShowAddFieldModal(false)
                setAddFieldTitle('')
                setAddFieldBody('')
                props.reloadReportCallback()
            },
            (data) => {
                console.log(data)
            }
        )

    }



    function getCurrencySymbol(currency) {
        switch(currency) {
            case 'USD':
                return '$';
            case 'NZD':
                return '$';
            case 'AUD':
                return '$';
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            default:
                return '$';
        }
    }

    function formatNumber(number) {
        if (number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return '';
        }
    }


    const [brokerName, setBrokerName] = useState(null);
    const [brokerEmail, setBrokerEmail] = useState(null);
    const [brokerPhone, setBrokerPhone] = useState(null);


    useEffect(() => {
        getBrokerDetails(
            (data) => {
                if (data.broker_details) {
                    setBrokerName(data.broker_details.name)
                    setBrokerEmail(data.broker_details.email)
                    setBrokerPhone(data.broker_details.phone)
                }
            },
            (data) => {
                console.log(data)
            }
        )
    }, [])



    return (
        <>
            <Page
                includeFooter={true} 
                brokerDetails={{name: brokerName, email: brokerEmail, phone: brokerPhone}}
                theme={{
                    theme:props.theme,
                    themePrimaryText:props.themePrimaryText,
                }}
            >
                <ColorCut theme={props.theme} height="20%" rightCut="70%">
                    <div className="report-p-2">
                        <h1 style={{color: props.themePrimaryText}} className='report-title'>Summary</h1>
                        <div>
                            {
                                props.report.listing_price ?
                                    <div className='report-pt-1 editable-section' onClick={() => {setShowAddListingPriceModal(true)}}>
                                        <h1 style={{color: props.themeSecondaryText}} className='report-title'>Recommended Listing Price:</h1>
                                        <h1 style={{color: props.themeSecondaryText}} className='report-title'>{getCurrencySymbol(props.report.report_currency)}{formatNumber(props.report.listing_price)} {props.report.report_currency}</h1>
                                    </div>
                                :
                                    <h1 style={{color: props.themeSecondaryText}} className='report-title editable-section report-pt-1' onClick={() => {setShowAddListingPriceModal(true)}}>Add Recommended Listing Price</h1>
                                
                            }
                        </div>
                    </div>
                </ColorCut>
                
                <SummaryFields 
                    theme={props.theme} 
                    themePrimaryText={props.themePrimaryText}
                    themeSecondaryText={props.themeSecondaryText}
                    report={props.report}
                    reloadReportCallback={props.reloadReportCallback}
                />

            </Page>
            <Container className='mild-neumorphic-card report-button-bar'>
                <Row>
                    <Col className='g-0'>
                        <Button className='button-bar-button primary-bg' style={{width: '100%', borderRadius: '0px'}}  onClick={() => {setShowAddFieldModal(true)}} >Add Field</Button>
                    </Col>
                </Row>
            </Container>
            
            <Modal show={showAddListingPriceModal} onHide={() => {setShowAddListingPriceModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Recommended Listing Price</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <p style={{marginTop: '20px'}}>Price</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={7} sm={7} md={7} lg={7} xl={7}>
                            <Form.Control style={{marginTop: '10px'}} value={listingPriceInput} type='number' onChange={(e) => {setListingPriceInput(e.target.value)}} />
                            <p style={{color: 'red'}}>{listingPriceInputError}</p>
                        </Col>
                        <Col xs={5} sm={5} md={5} lg={5} xl={5}>
                            <h5 style={{marginTop: '20px'}}>{props.report.report_currency}</h5>
                        </Col>
                    </Row>
                
                </Modal.Body>
                <Modal.Footer>
                    <Button className="primary-bg" onClick={() => {handleSaveListingPrice()}}>Save</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showAddFieldModal} onHide={() => {setShowAddFieldModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Add Summary Field</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <p style={{marginTop: '20px'}}>Title</p>
                    <Form.Control value={addFieldTitle} type='text' onChange={(e) => {setAddFieldTitle(e.target.value)}} />
                    <p style={{color: 'red'}}>{titleError}</p>

                    <p style={{marginTop: '20px'}}>Body</p>
                    <Form.Control value={addFieldBody} type='text' as='textarea' rows={5} onChange={(e) => {setAddFieldBody(e.target.value)}} />
                    <p style={{color: 'red'}}>{bodyError}</p>


                </Modal.Body>
                <Modal.Footer>
                    <Button className="primary-bg" onClick={() => {handleSaveSummaryField()}}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
