import React from 'react'
import Page from '../../../generic_report_components/Page'
import ColorCut from '../../../generic_report_components/ColorCut'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { getBrokerDetails } from '../../../requests/brokerDetails'
import { useState, useEffect } from 'react'


export default function ContentsPage(props) {


    function buildBoatSections() {
        
        var sections = []
        
        for (let i=0; i<props.report.appraisal_report_boats.length; i++) {
            var section = {
                comparisonNum: i+1,
                title:props.report.appraisal_report_boats[i].title
            }
            if (props.report.include_market_insights) {
                section.pageNum = i+3
            } else {
                section.pageNum = i+2
            }
            sections.push(section)
        }
        return sections
    }

    const boatSections = buildBoatSections()


    const [brokerName, setBrokerName] = useState(null);
    const [brokerEmail, setBrokerEmail] = useState(null);
    const [brokerPhone, setBrokerPhone] = useState(null);


    useEffect(() => {
        getBrokerDetails(
            (data) => {
                if (data.broker_details) {
                    setBrokerName(data.broker_details.name)
                    setBrokerEmail(data.broker_details.email)
                    setBrokerPhone(data.broker_details.phone)
                }
            },
            (data) => {
                console.log(data)
            }
        )
    }, [])

    return (
        <div style={{marginBottom: '40px'}}>
            <Page
                includeFooter={true} 
                brokerDetails={{name: brokerName, email: brokerEmail, phone: brokerPhone}}
                theme={{
                    theme:props.theme,
                    themePrimaryText:props.themePrimaryText,
                }}
            >
                <ColorCut theme={props.theme} height="15%" rightCut="70%">
                    <div className="report-p-2">
                        <h1 style={{color: props.themePrimaryText}} className='report-title'>Contents</h1>
                    </div>
                </ColorCut>
                <Container className='report-p-2 report-mt-4'>
                    <Row>
                        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                            <div >
                                <h3 className='report-subtitle' style={{color: props.themePrimaryText}}>Appraised Boat</h3>
                            </div>
                        </Col>
                        <Col>
                            <div>
                                <h3 className='report-subtitle'>1</h3>
                            </div>
                        </Col>
                    </Row>
                    <div style={{width: '100%', height: '1px', backgroundColor: 'black'}}></div>
                    {
                        props.report.include_market_insights &&
                        <>
                            <Row className='report-mt-3'>
                                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                    <div >
                                        <h3 className='report-subtitle' style={{color: props.themePrimaryText}}>Market Insights</h3>
                                    </div>
                                </Col>
                                <Col>
                                    <div>
                                        <h3 className='report-subtitle'>2</h3>
                                    </div>
                                </Col>
                            </Row>
                            <div style={{width: '100%', height: '1px', backgroundColor: 'black'}}></div>
                        </>
                    }
                    <div>
                        {
                            boatSections.length > 0 ?
                            <>
                            <h3 className='report-subtitle report-mt-3' style={{color: props.themePrimaryText}}>Comparisons</h3>
                            {
                                boatSections.map((section, index) => {
                                    return (
                                        <>
                                            <Row className='report-mt-1'>
                                                <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                                    <div >
                                                        <h3 className='report-subtitle-sm'>{section.title}</h3>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div>
                                                        <h3 className='report-subtitle'>{section.pageNum}</h3>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div style={{width: '100%', height: '1px', backgroundColor: 'black'}}></div>
                                        </>
                                    )
                                })
                            }
                            </>
                            :
                            null
                        }   
                    </div>
                    <div>
                        <Row className='report-mt-3'>
                            <Col xs={10} sm={10} md={10} lg={10} xl={10}>
                                <div >
                                    <h3 className='report-subtitle' style={{color: props.themePrimaryText}}>Summary</h3>
                                </div>
                            </Col>
                            <Col>
                                <div>
                                    <h3 className='report-subtitle'>
                                        {
                                            props.report.include_market_insights ?
                                                <span>{boatSections.length + 3}</span>
                                            :
                                                <span>{boatSections.length + 2}</span>
                                        }
                                    </h3>
                                </div>
                            </Col>
                        </Row>
                        <div style={{width: '100%', height: '1px', backgroundColor: 'black'}}></div>
                    </div>
                
                </Container>
            </Page>
        </div>
    )
}
