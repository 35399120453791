import React from 'react'

export default function ColorCut(props) {
    return (
        <div 
            style={{
                height: props.height, 
                background: `linear-gradient(to left bottom, ${props.theme}, ${props.theme})`, 
                clipPath: `polygon(0 0, 0 100%, 100% ${props.rightCut}, 100% 0)`
            }}
        >
            {
                props.children ? props.children : null
            }
        </div>
    )
}
