import React from 'react'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { updateReportSummaryField, deleteReportSummaryField } from '../../../../requests/report/reportSummaryField'

export default function SummaryField(props) {

    const [showModal, setShowModal] = useState(false);
    const [title, setTitle] = useState(props.field.title);
    const [body, setBody] = useState(props.field.body);
    const [titleError, setTitleError] = useState('');
    const [bodyError, setBodyError] = useState('');


    function validateFormData() {
        var valid = true;

        setTitleError('');
        setBodyError('');

        if (title.length === 0) {
            setTitleError('Title is required');
            valid = false;
        }

        if (body.length === 0) {
            setBodyError('Body is required');
            valid = false;
        }

        return valid;
    }   


    function handleEditSummaryField() {

        if (!validateFormData()) {
            return;
        }

        let fd = {
            id: props.field.id,
            title: title,
            body: body
        }
        updateReportSummaryField(fd, 
            (data) => {
                setShowModal(false);
            },
            (data) => {
                console.log(data)
            }
        )
    }

    function handleDeleteSummaryField() {
        var fd = {
            id: props.field.id
        }

        deleteReportSummaryField(fd, 
            (data) => {
                props.deleteFieldCallback(props.field.id);
                setShowModal(false);
            },
            (data) => {
                console.log(data)
            }
        )
    }


    return (
        <>
            <div style={{marginBottom: '2vw'}} className="editable-section" onClick={() => {setShowModal(true)}}>
                <h3 style={{color: props.themePrimaryText}} className='report-title'>{title}</h3>
                <p className='report-text'>{body}</p>
            </div>

            <Modal show={showModal} onHide={() => handleEditSummaryField()}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Edit Field</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p style={{marginTop: '20px'}}>Title</p>
                        <Form.Control type='text' value={title} onChange={(e) => {setTitle(e.target.value)}} />
                        <p style={{color: 'red'}}>{titleError}</p>

                        <p style={{marginTop: '20px'}}>Body</p>
                        <Form.Control as='textarea' rows={3} value={body} onChange={(e) => {setBody(e.target.value)}} />
                        <p style={{color: 'red'}}>{bodyError}</p>
                    
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-danger' onClick={() => {handleDeleteSummaryField()}}>Delete</button>
                    <button className='btn btn-primary' onClick={() => {handleEditSummaryField()}}>Save</button>
                </Modal.Footer>
            </Modal>
        </>
        
    )
}
