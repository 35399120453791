import { baseApiUrl } from "../settings";
import { 
    tokenRequiredRequest,
    tokenRequiredRequestWithBody
} from "./baseRequest";




export const createMyBoat = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/my-boats`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}




export const getMyBoats = (clientProfileId, successCallback, failedCallback) => {
    console.log(clientProfileId)
    return tokenRequiredRequest(
        `${baseApiUrl}/my-boats?client_profile_id=${clientProfileId}`,
        'GET', 
        successCallback, 
        failedCallback)
}




export const getMyBoatsArchived = (successCallback, failedCallback) => {
    return tokenRequiredRequest(
        `${baseApiUrl}/my-boats/archived`, 
        'GET', 
        successCallback, 
        failedCallback)
}




export const archiveMyBoat = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/my-boats/archive`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}




export const deleteMyBoat = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/my-boats`, 
        'DELETE', 
        fd, 
        successCallback, 
        failedCallback)
}



