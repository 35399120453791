import { baseApiUrl } from "../settings";
import { 
    tokenRequiredRequest,
    tokenNotRequiredRequestWithBody
} from "./baseRequest";




export const authLogin = (fd, successCallback, failedCallback) => {
    return tokenNotRequiredRequestWithBody(
        `${baseApiUrl}/auth/login`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}


export const authCheckToken = (successCallback, failedCallback) => {
    return tokenRequiredRequest(
        `${baseApiUrl}/auth/check-token`, 
        'GET',
        successCallback, 
        failedCallback)
}



export const authCheckIsAdmin = (successCallback, failedCallback) => {
    return tokenRequiredRequest(
        `${baseApiUrl}/auth/check-is-admin`, 
        'GET',
        successCallback, 
        failedCallback)
}



export const authInitiateResetPassword = (fd, successCallback, failedCallback) => {
    return tokenNotRequiredRequestWithBody(
        `${baseApiUrl}/auth/initiate-reset-password`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}



export const authCheckResetTokenValid = (fd, successCallback, failedCallback) => {
    return tokenNotRequiredRequestWithBody(
        `${baseApiUrl}/auth/check-reset-token`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}



export const authResetPassword = (fd, successCallback, failedCallback) => {
    return tokenNotRequiredRequestWithBody(
        `${baseApiUrl}/auth/reset-password`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}


