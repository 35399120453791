import React from 'react'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'

export default function PrivacyPolicyModal() {
    
    const [showPrivacyModal, setShowPrivacyModal] = useState(false)
    
    return (
        <>
            <p className='footer-text' onClick={() => {setShowPrivacyModal(true)}}>Privacy Policy</p>
            <Modal show={showPrivacyModal} onHide={() => {setShowPrivacyModal(false)}} size='lg'>
                <Modal.Header closeButton>
                    <Modal.Title className='styled-header'>Privacy Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4 className='styled-header'>1. Introduction</h4>
                    <p>We Find Your Boat Limited ("we", "us", or "our") respects your privacy and is committed to protecting it through our compliance with this policy. This policy describes the types of information we may collect from you or that you may provide when you visit our website (the "Site") and our practices for collecting, using, maintaining, protecting, and disclosing that information.</p>                
                    
                    <h4 style={{marginTop: '30px'}} className='styled-header'>2. Information We Collect About You</h4>
                    <p>We collect several types of information from and about users of our Site, including information:</p>
                    <p style={{marginLeft: '30px'}}>Directly from you when you provide it to us.</p>
                    <p style={{marginLeft: '30px'}}>Automatically as you navigate through the site.</p>

                    <h4 style={{marginTop: '30px'}} className='styled-header'>3. How We Use Your Information</h4>
                    <p>We use your information, including any personal data:</p>
                    <p style={{marginLeft: '30px'}}>To provide and improve our Site and its contents to you.</p>
                    <p style={{marginLeft: '30px'}}>To provide you with information, products, or services that you request from us.</p>
                    <p style={{marginLeft: '30px'}}>To fulfill the purpose for which you provide it.</p>
                    <p style={{marginLeft: '30px'}}>To notify you about changes to our Site or any products or services we offer or provide through it.</p>
                    <p style={{marginLeft: '30px'}}>For any other purpose with your consent.</p>

                    <h4 style={{marginTop: '30px'}} className='styled-header'>4. Disclosure of Your Information</h4>
                    <p>We may disclose aggregated information about our users, and information that does not identify any individual, without restriction. We may disclose personal information that we collect or you provide:</p>
                    <p style={{marginLeft: '30px'}}>To fulfill the purpose for which you provide it.</p>
                    <p style={{marginLeft: '30px'}}>For any other purpose disclosed by us when you provide the information.</p>

                    <h4 style={{marginTop: '30px'}} className='styled-header'>5. Data Security</h4>
                    <p>We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure.</p>

                    <h4 style={{marginTop: '30px'}} className='styled-header'>6. Changes to Our Privacy Policy</h4>
                    <p>We may update our privacy policy from time to time. If we make material changes to how we treat our users' personal information, we will post the new privacy policy on this page.</p>

                    <h4 style={{marginTop: '30px'}} className='styled-header'>7. Contact Information</h4>
                    <p>To ask questions or comment about this privacy policy and our privacy practices, contact us at: Tim@wefindyourboat.com</p>

                    <p style={{marginTop: '30px'}}>By using our Site, you agree to this Privacy Policy. Please read it carefully.</p>

                    
                </Modal.Body>
            </Modal>
        </>
    )
}
