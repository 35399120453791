import React, { useEffect, useRef, useCallback, useState } from "react";
import * as echarts from "echarts";
import {
  Typography,
  Box,
  Slider,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const MergedGraph = ({
  dataSets,
  title,
  currency,
  conversionRate,
  abbr,
  makeModelList,
  minX,
  maxX,
}) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const [zoom, setZoom] = useState(0);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isLargeOrXLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  // Rounding function
  const formatNumber = useCallback((num) => {
    return Math.round(num)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }, []);

  const setChartOptions = useCallback(() => {
    if (!chartInstance.current || dataSets.length === 0) return;

    const colorSets = {
      blue: {
        price: "#219ebc",
        upperBound: "#023047",
        lowerBound: "#77d1fc",
        area: "rgba(0, 147, 210, 0.2)",
      },
      orange: {
        price: "#ff5821",
        upperBound: "#dc4c23",
        lowerBound: "#ff855d",
        area: "rgba(255, 87, 51, 0.2)",
      },
      green: {
        price: "#2a9d8f",
        upperBound: "#1f6f61",
        lowerBound: "#84ccab",
        area: "rgba(42, 157, 143, 0.2)",
      },
      purple: {
        price: "#a259ff",
        upperBound: "#7a3db6",
        lowerBound: "#c07cff",
        area: "rgba(162, 89, 255, 0.2)",
      },
      darkblue: {
        price: "#00439c",
        upperBound: "#006eff",
        lowerBound: "#4f9bff",
        area: "rgba(137, 188, 255, 0.2)",
      },
      brown: {
        price: "#8b4513",
        upperBound: "#69340f",
        lowerBound: "#a0522d",
        area: "rgba(139, 69, 19, 0.2)",
      },
      gray: {
        price: "#808080",
        upperBound: "#666666",
        lowerBound: "#a9a9a9",
        area: "rgba(128, 128, 128, 0.2)",
      },
      turquoise: {
        price: "#40e0d0",
        upperBound: "#2eb3a5",
        lowerBound: "#67ebdb",
        area: "rgba(64, 224, 208, 0.2)",
      },
      darkgreen: {
        price: "#073c08",
        upperBound: "#0f7910",
        lowerBound: "#17b718",
        area: "rgba(130, 224, 137, 0.2)",
      },
      yellow: {
        price: "#ffcc00",
        upperBound: "#cc9900",
        lowerBound: "#eeed0e",
        area: "rgba(255, 204, 0, 0.1)",
      },
      red: {
        price: "#ce2029",
        upperBound: "#87161c",
        lowerBound: "#ea5a5f",
        area: "rgba(206, 32, 41, 0.2)",
      },
    };

    const colorKeys = Object.keys(colorSets);

    const getColorSet = (index) => {
      return colorSets[colorKeys[index % colorKeys.length]];
    };

    const series = dataSets.flatMap((dataSet, index) => {
      const boatAges = Object.keys(dataSet.price_curve || {}).map(Number);
      const priceValues = Array(maxX - minX + 1).fill(null);
      const upperBoundValues = Array(maxX - minX + 1).fill(null);
      const lowerBoundValues = Array(maxX - minX + 1).fill(null);

      boatAges.forEach((age) => {
        const xIndex = age - minX;
        priceValues[xIndex] = dataSet.price_curve[age] * conversionRate;
        upperBoundValues[xIndex] =
          dataSet.error_curve_upper_bound?.[age] * conversionRate || null;
        lowerBoundValues[xIndex] =
          dataSet.error_curve_lower_bound?.[age] * conversionRate || null;
      });

      const colors = getColorSet(index);

      return [
        {
          name: `Price ${makeModelList[index]}`,
          data: priceValues,
          type: "line",
          smooth: true,
          lineStyle: {
            color: colors.price,
          },
          itemStyle: {
            color: colors.price,
          },
        },
        {
          name: `Upper Bound ${makeModelList[index]}`,
          data: upperBoundValues,
          type: "line",
          smooth: true,
          lineStyle: {
            opacity: 0,
          },
          areaStyle: {
            color: colors.area,
            origin: "start",
          },
          itemStyle: {
            color: colors.upperBound,
          },
        },
        {
          name: `Lower Bound ${makeModelList[index]}`,
          data: lowerBoundValues,
          type: "line",
          smooth: true,
          lineStyle: {
            opacity: 0,
          },
          areaStyle: {
            color: colors.area,
            origin: "end",
          },
          itemStyle: {
            color: colors.lowerBound,
          },
        },
      ];
    });

    const gridSettings = isSmallScreen
      ? {
          left: "22%",
          right: "6%",
          top: "5%",
          bottom: "20%",
        }
      : {
          left: "30%",
          right: "15%",
          top: "5%",
          bottom: "20%",
        };

    chartInstance.current.setOption({
      grid: gridSettings,
      tooltip: {
        trigger: "axis",
        confine: true,
        formatter: (params) => {
          const xValue = params[0].name;
          let tooltipItems = "";

          params.forEach((param, index) => {
            const datasetIndex = Math.floor(index / 3);
            const colorSet = getColorSet(datasetIndex);
            const value =
              param.value !== null && param.value !== undefined
                ? `${formatNumber(param.value)} ${currency}`
                : null;

            if (value !== null) {
              const color = param.seriesName.includes("Price")
                ? colorSet.price
                : param.seriesName.includes("Upper Bound")
                ? colorSet.upperBound
                : colorSet.lowerBound;
              tooltipItems += `<div style="color:${color}; font-weight:bold; text-transform:uppercase;">
                                ${param.seriesName}: ${value}
                              </div>`;
            }
          });

          if (!tooltipItems) {
            tooltipItems = `<div style="font-weight:bold; color:red;">No data available</div>`;
          }

          return `<div style="font-weight:bold;">Years old: ${xValue}</div>${tooltipItems}`;
        },
      },
      xAxis: {
        type: "category",
        data: Array.from({ length: maxX - minX + 1 }, (_, i) => i + minX),
        name: "Boat Age (Years)",
        nameLocation: "middle",
        nameGap: isSmallScreen ? 30 : 40,
        nameTextStyle: {
          fontSize: isSmallScreen ? "1em" : "1.3em",
          fontWeight: isSmallScreen ? "normal" : "bold",
          color: "#034d70",
        },
        axisLabel: {
          fontSize: "0.8em",
          formatter: (value, index) => {
            if (isSmallScreen) {
              return index % 5 === 0 ? value : "";
            }
            return value;
          },
        },
      },
      yAxis: {
        type: "value",
        name: `Value (${abbr})`,
        nameLocation: "middle",
        nameGap: isSmallScreen ? 62 : 83,
        nameTextStyle: {
          fontSize: isSmallScreen ? "1em" : "1.3em",
          fontWeight: isSmallScreen ? "normal" : "bold",
          color: "#034d70",
        },
        axisLabel: {
          formatter: (value) => {
            if (isLargeOrXLargeScreen) {
              return `${formatNumber(value)}`;
            }
            if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`;
            if (value >= 1000) return `${(value / 1000).toFixed(1)}K`;
            return formatNumber(value);
          },
          fontSize: "0.8em",
        },
      },
      series,
      dataZoom: [
        {
          type: "inside",
          zoomOnMouseWheel: false,
          moveOnMouseMove: true,
          moveOnTouch: true,
          start: 0,
          end: 100 / (1 + zoom * 1.5),
        },
        {
          type: "slider",
          show: false,
        },
      ],
    });
  }, [
    dataSets,
    conversionRate,
    abbr,
    minX,
    maxX,
    zoom,
    currency,
    makeModelList,
    isSmallScreen,
    isLargeOrXLargeScreen,
    formatNumber,
  ]);

  const initializeChart = useCallback(() => {
    if (chartInstance.current) {
      chartInstance.current.dispose();
    }
    chartInstance.current = echarts.init(chartRef.current);
    setChartOptions();
  }, [setChartOptions]);

  useEffect(() => {
    initializeChart();
  }, [initializeChart]);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: 2,
        mb: 2,
        position: "relative",
        width: "100%",
        p: { xs: 1, sm: 2 },
      }}
    >
      <Typography
        variant="h6"
        gutterBottom
        sx={{
          textTransform: "uppercase",
          textAlign: "center",
          p: 1,
          color: "#034d70",
          fontSize: { xs: "1.2em", sm: "1.5em" },
          fontWeight: "bold",
        }}
      >
        {title}
      </Typography>
      <Box
        ref={chartRef}
        sx={{
          width: "100%",
          height: { xs: "50vh", sm: "45vh", lg: "35vh" },
          borderRadius: 2,
        }}
      />
      <Box
        sx={{
          width: "50%",
          mt: 2,
          mx: "auto",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography variant="body2" color="textSecondary" align="center">
          Zoom Level: {zoom}x
        </Typography>
        <Slider
          value={zoom}
          min={0}
          max={2.5}
          step={0.1}
          onChange={(event, newValue) => setZoom(newValue)}
          valueLabelDisplay="auto"
          marks
          sx={{
            width: "50%",
            ml: 2,
            color: "#034d70",
          }}
        />
      </Box>
    </Box>
  );
};

export default MergedGraph;
