import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useNavigate } from 'react-router-dom'
import TandCModal from './TandCModal'
import PrivacyPolicyModal from './PrivacyPolicyModal'


export default function Footer() {
    
    const navigate = useNavigate();

    
    return (
        <Container fluid style={{backgroundColor: 'rgb(2, 100, 151)', color: 'white', minHeight: '200px'}}>
            <Row className='footer-container'>
                <Col xs={12} sm={12} md={12} lg={3} xl={3} className='footer-section'>
                    <TandCModal />
                    <PrivacyPolicyModal />
                    <p>© 2023 We Find Your Boat Limited</p>
                </Col>
                <Col xs={12} sm={12} md={12} lg={3} xl={3} className='footer-section'>
                    <p>Get in touch</p>
                    <p><i class="fa fa-phone" style={{marginRight: '10px', color: 'rgb(1, 151, 221)'}} aria-hidden="true"></i>+64 21 213 6437</p>
                    <p><i class="fa fa-envelope" style={{marginRight: '10px', color: 'rgb(1, 151, 221)'}} aria-hidden="true"></i>Tim@wefindyourboat.com</p>
                </Col>
                <Col xs={12} sm={12} md={12} lg={2} xl={2} className='footer-section'>
                    <p className='footer-text' onClick={() => {navigate('/home')}}>Home</p>
                    <p className='footer-text' onClick={() => {navigate('/search', {state: {client_profile_id: null}})}}>Search</p>
                    <p className='footer-text' onClick={() => {navigate('/my-clients')}}>My Clients</p>
                </Col>
                <Col className='g-0'>
                    <div style={{
                        backgroundImage: 'url(./landing-img.jpeg)', 
                        width: '100%', 
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        textAlign: 'center',
                        paddingTop: '10vh',
                        paddingBottom: '10vh',
                        height: '100%',
                        clipPath: 'polygon(100% 100%, 30% 100%, 0 0, 100% 0)'
                    }}>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
