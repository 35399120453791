import React from 'react'
import { useState } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useDropzone } from 'react-dropzone'
import { updateAppraisedBoatField, deleteAppraisedBoatField } from '../../../../requests/appraisal_report/appraisedBoatField'


export default function Field(props) {

    const [showEditFieldModal, setShowEditFieldModal] = useState(false)

    const [fieldTitle, setFieldTitle] = useState(props.field.title)
    const [fieldBody, setFieldBody] = useState(props.field.body)
    const [fieldImage, setFieldImage] = useState(props.field.image_url)

    const [editFieldTitle, setEditFieldTitle] = useState(props.field.title)
    const [editFieldBody, setEditFieldBody] = useState(props.field.body)
    const [editFieldImage, setEditFieldImage] = useState(props.field.image_url)

    const [titleError, setTitleError] = useState('')
    const [bodyError, setBodyError] = useState('')


    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            var file = acceptedFiles[0];
            if(file){
                var reader = new FileReader();
    
                reader.onload = function(e){
                    setEditFieldImage(e.target.result)
                }
    
                reader.readAsDataURL(file);
            }
        }
    });


    function validateFormData() {
        var valid = true;

        setTitleError('')
        setBodyError('')

        if(editFieldTitle === ''){
            setTitleError('Title is required')
            valid = false;
        }

        if(editFieldBody === ''){
            setBodyError('Body is required')
            valid = false;
        }

        return valid;
    }



    function handleSaveEditField() {

        if(!validateFormData()){
            return;
        }

        var fd = {
            appraised_boat_field_id: props.field.id,
            title: editFieldTitle,
            body: editFieldBody,
            image: editFieldImage 
        }

        if(editFieldImage === props.field.image_url){
            fd.image = null
        }


        updateAppraisedBoatField(fd, 
            (data) => {
                setFieldTitle(editFieldTitle)
                setFieldBody(editFieldBody)
                setFieldImage(editFieldImage)
                setShowEditFieldModal(false)
            },
            (data) => {
                console.log(data);
            }
        )
    }


    function handleDeleteField() {
        var fd ={
            appraised_boat_field_id: props.field.id
        }

        deleteAppraisedBoatField(fd, 
            (data) => {
                setShowEditFieldModal(false)
                props.deleteFieldCallback(props.field.id)
            },
            (data) => {
                console.log(data);
            }
        )
    }
    

    return (
        <>
            <Container style={{cursor: 'pointer'}} className='report-mb-2' onClick={() => {setShowEditFieldModal(true)}}>
                <Row>
                    {
                        fieldImage ?
                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                            <Image className='mild-neumorphic-card' src={fieldImage} fluid />
                        </Col>
                        :
                        null
                    }
                    <Col 
                        xs={fieldImage ? 8 : 12}
                        sm={fieldImage ? 8 : 12}
                        md={fieldImage ? 8 : 12}
                        lg={fieldImage ? 8 : 12}
                        xl={fieldImage ? 8 : 12}
                    >
                        <div>
                            <h3 style={{color: props.themePrimaryText}} className='report-subtitle-sm'>{fieldTitle}</h3>
                            <p className='report-text'>{fieldBody}</p>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Modal show={showEditFieldModal} onHide={() => setShowEditFieldModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Edit Field</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Title</p>
                    <Form.Control value={editFieldTitle} onChange={(e) => {setEditFieldTitle(e.target.value)}} type='text' placeholder='title' />
                    <p style={{color: 'red'}}>{titleError}</p>

                    <p style={{marginTop: '20px'}}>Body</p>
                    <Form.Control value={editFieldBody} onChange={(e) => {setEditFieldBody(e.target.value)}} as='textarea' rows={5} placeholder='body' />
                    <p style={{color: 'red'}}>{bodyError}</p>
                    {
                        props.field.image_url ?
                        <>
                            <p style={{marginTop: '20px'}}>Image</p>
                            <div className="image-upload" style={{textAlign: 'center', marginTop:'40px'}} {...getRootProps()}  >
                                <Image className='upload-image mild-neumorphic-card' id="previewImg" src={editFieldImage} style={{width: '50%'}} />
                                <input {...getInputProps()} />
                            </div>
                        </>
                        :
                        null
                    }

                </Modal.Body>
                <Modal.Footer>
                    <Button className="danger-bg" onClick={() => {handleDeleteField()}}>
                        Delete
                    </Button>
                    <Button className="primary-bg" onClick={() => handleSaveEditField()}>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

        </>
    )
}
