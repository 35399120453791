import { baseApiUrl } from "../settings";
import { 
    tokenRequiredRequest,
    tokenRequiredRequestWithBody
} from "./baseRequest";



export const createClientImage = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/client-images`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}


export const getClientImages = (clientProfileId, successCallback, failedCallback) => {
    return tokenRequiredRequest(
        `${baseApiUrl}/client-images?client_profile_id=${clientProfileId}`, 
        'GET', 
        successCallback, 
        failedCallback)
}



export const archiveClientImage = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/client-images/archive`, 
        'PUT', 
        fd,
        successCallback, 
        failedCallback)
}

