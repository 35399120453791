import React from 'react'
import Page from '../../../generic_report_components/Page'
import ColorCut from '../../../generic_report_components/ColorCut'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useState, useEffect } from 'react'
import { getBrokerDetails, updateBrokerDetails } from '../../../requests/brokerDetails'


export default function TitlePage(props) {

    const [logoUrl, setLogoUrl] = useState(null);

    const [brokerName, setBrokerName] = useState(null);
    const [brokerEmail, setBrokerEmail] = useState(null);
    const [brokerPhone, setBrokerPhone] = useState(null);

    const [editBrokerName, setEditBrokerName] = useState('');
    const [editBrokerEmail, setEditBrokerEmail] = useState('');
    const [editBrokerPhone, setEditBrokerPhone] = useState('');

    const [editBrokerNameError, setEditBrokerNameError] = useState('');
    const [editBrokerEmailError, setEditBrokerEmailError] = useState('');
    const [editBrokerPhoneError, setEditBrokerPhoneError] = useState('');


    const [showEditBrokerModal, setShowEditBrokerModal] = useState(false);


    useEffect(() => {
        getBrokerDetails(
            (data) => {
                if (data.broker_details) {
                    setLogoUrl(data.broker_details.logo)
                    setBrokerName(data.broker_details.name)
                    setBrokerEmail(data.broker_details.email)
                    setBrokerPhone(data.broker_details.phone)
                    setEditBrokerName(data.broker_details.name)
                    setEditBrokerEmail(data.broker_details.email)
                    setEditBrokerPhone(data.broker_details.phone)
                }
            },
            (data) => {
                console.log(data)
            }
        )
    }, [])


    function validateFormData() {
        var valid = true;
        setEditBrokerNameError('');
        setEditBrokerEmailError('');
        setEditBrokerPhoneError('');
        if (editBrokerName.length < 1) {
            setEditBrokerNameError('Please enter a name');
            valid = false;
        } else {
            setEditBrokerNameError('');
        }
        if (editBrokerEmail.length < 1) {
            setEditBrokerEmailError('Please enter an email');
            valid = false;
        } else {
            setEditBrokerEmailError('');
        }
        if (editBrokerPhone.length < 1) {
            setEditBrokerPhoneError('Please enter a phone number');
            valid = false;
        } else {
            setEditBrokerPhoneError('');
        }
        return valid;
    }



    function handleEditBrokerDetails() {
        if (!validateFormData()) {
            return;
        }
        var fd = {
            name: editBrokerName,
            email: editBrokerEmail,
            phone: editBrokerPhone
        };
        updateBrokerDetails(fd, 
            (data) => {
                setBrokerName(editBrokerName);
                setBrokerEmail(editBrokerEmail);
                setBrokerPhone(editBrokerPhone);
                setShowEditBrokerModal(false);
            },
            (data) => {
                console.log(data);
            }
        )
    }


    return (
        <div style={{marginBottom: '40px'}}>
            <Page
                includeFooter={true} 
                brokerDetails={{name: brokerName, email: brokerEmail, phone: brokerPhone}}
                theme={{
                    theme:props.theme,
                    themePrimaryText:props.themePrimaryText,
                }}
            >
                <ColorCut theme={props.theme} height='30%' rightCut='60%'/>
                <div style={{width: '100%', textAlign: 'center'}}>
                    <Image className='report-mt-5' src={logoUrl} style={{width: '60%'}} />
                </div>
                <div className='report-mt-2' style={{width: '100%', textAlign: 'center'}}>
                    <h1 className='report-title' style={{color:props.themePrimaryText}}>Appraisal Report created for {props.report.client_profile.name}</h1>
                </div>
                <div onClick={() => {setShowEditBrokerModal(true)}} className='editable-section report-mt-2' style={{width: '100%', textAlign: 'center'}}>
                    <h4 className='report-subtitle'>{brokerName}</h4>
                    <h4 className='report-subtitle'>{brokerEmail}</h4>
                    <h4 className='report-subtitle'>{brokerPhone}</h4>
                </div>
            </Page>
            <Modal show={showEditBrokerModal} onHide={() => setShowEditBrokerModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Edit Broker Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Form.Control style={{marginTop: '20px'}} type='text' placeholder='Broker Name' value={editBrokerName} onChange={(e) => setEditBrokerName(e.target.value)} />
                        <p style={{color: 'red'}}>{editBrokerNameError}</p>
                        
                        <Form.Control style={{marginTop: '20px'}} type='text' placeholder='Broker Email' value={editBrokerEmail} onChange={(e) => setEditBrokerEmail(e.target.value)} />
                        <p style={{color: 'red'}}>{editBrokerEmailError}</p>
                        
                        <Form.Control style={{marginTop: '20px'}} type='text' placeholder='Broker Phone' value={editBrokerPhone} onChange={(e) => setEditBrokerPhone(e.target.value)} />
                        <p style={{color: 'red'}}>{editBrokerPhoneError}</p>
                    
                    </div>
                    <div>
                        <Button style={{marginTop: '20px'}} className="primary-bg" onClick={() => {handleEditBrokerDetails()}}>Save</Button>
                    </div>
                </Modal.Body>
            </Modal>
        </div>   
    )
}
