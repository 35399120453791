import { baseApiUrl } from "../../settings";
import { 
    tokenRequiredRequestWithBody
} from "../baseRequest";


export const createAppraisalReportBoatField = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/report-boats/fields`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}



export const updateAppraisalReportBoatField = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/report-boats/fields`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}




export const deleteAppraisalReportBoatField = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/report-boats/fields`, 
        'DELETE', 
        fd, 
        successCallback, 
        failedCallback)
}