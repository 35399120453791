import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { getClientProfile } from '../../requests/clientProfile'
import { getMyBoats } from '../../requests/myBoats'
import { getReports } from '../../requests/report/report'
import { getAppraisalReports } from '../../requests/appraisal_report/appraisalReport'
import { updateClientProfile } from '../../requests/clientProfile'
import ClientImages from './components/ClientImages'
import ClientRequirements from './components/ClientRequirements'
import SavedBoats from './components/SavedBoats'
import ShortlistReports from './components/ShortlistReports'
/*import AppraisalReports from './components/AppraisalReports'*/
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../../universal_components/Header'
import CreateAlert from './components/CreateAlert'



export default function ClientPage() {

    const {state} = useLocation();

    const [clientProfile, setClientProfile] = useState(null);

    const [myBoatsCount, setMyBoatsCount] = useState(null);

    const [reportsCount, setReportsCount] = useState(null);

    /*const [appraisalReportsCount, setAppraisalReportsCount] = useState(null);*/

    const [showEditClientProfileModal, setShowEditClientProfileModal] = useState(false);


    const [editClientProfileName, setEditClientProfileName] = useState(null);
    const [editClientProfileEmail, setEditClientProfileEmail] = useState(null);
    const [editClientProfilePhone, setEditClientProfilePhone] = useState(null);
    const [editClientProfileRequiredLength, setEditClientProfileRequiredLength] = useState(null);
    const [editClientProfileBudget, setEditClientProfileBudget] = useState(null);
    const [editClientProfileCurrency, setEditClientProfileCurrency] = useState(null);
    
    const navigate = useNavigate();


    useEffect(() => {
        getClientProfile(
            state.client_profile_id, 
            (data) => {
                console.log(data)
                setClientProfile(data.client_profile)
                setEditClientProfileName(data.client_profile.name)
                setEditClientProfileEmail(data.client_profile.email)
                setEditClientProfilePhone(data.client_profile.phone)
                setEditClientProfileRequiredLength(data.client_profile.required_length)
                setEditClientProfileBudget(data.client_profile.budget)
                setEditClientProfileCurrency(data.client_profile.currency)
            },
            (data) => {
                console.log(data)
            }
        )
    }, [state.client_profile_id])


    useEffect(() => {
        getMyBoats(
            state.client_profile_id,
            (data) => {
                console.log(data)
                setMyBoatsCount(data.my_boats.length)
            },
            (data) => {
                console.log(data)
            }
        )
    }, [state.client_profile_id])


    useEffect(() => {
        getReports(
            state.client_profile_id,
            (data) => {
                console.log(data)
                setReportsCount(data.reports.length)
            },
            (data) => {
                console.log(data)
            }
        )
    }, [state.client_profile_id])


    useEffect(() => {
        getAppraisalReports(
            state.client_profile_id,
            (data) => {
                console.log(data)
                /*setAppraisalReportsCount(data.reports.length)*/
            },
            (data) => {
                console.log(data)
            }
        )
    }, [state.client_profile_id])


    function getCurrencySymbol(currency) {
        switch(currency) {
            case 'USD':
                return '$';
            case 'NZD':
                return '$';
            case 'AUD':
                return '$';
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            default:
                return '$';
        }
    }

    function formatNumber(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    function handleEditClientProfile() {
        var fd = {
            client_profile_id: state.client_profile_id,
            name: editClientProfileName,
            email: editClientProfileEmail,
            phone: editClientProfilePhone,
            required_length: editClientProfileRequiredLength,
            budget: editClientProfileBudget,
            currency: editClientProfileCurrency
        }

        updateClientProfile(
            fd,
            (data) => {
                console.log(data)
                setClientProfile(data.client_profile)
                setShowEditClientProfileModal(false)
            },
            (data) => {
                console.log(data)
            }
        )
    }



    return (
        <>
            <Header signedIn={true} />
            <Container fluid style={{minHeight: '100vh', backgroundColor: 'rgb(220, 220, 220)'}}>
                {
                    clientProfile && 
                    
                    <Row>
                        <Col md={12} lg={3} className='g-0'>
                            <div className='sidecard'>

                                <div style={{
                                    backgroundImage: 'url(./landing-img.jpeg)', 
                                    width: '100%', 
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    textAlign: 'center',
                                    paddingTop: '5vh',
                                    paddingBottom: '5vh',
                                }}>
                                    <Image className='mild-neumorphic-card' src='/profile-placeholder.png' style={{width: '20%', borderRadius: '50%'}} />
                                    
                                    <div className='clickable-card' onClick={() => {setShowEditClientProfileModal(true)}}> 
                                        <h1 style={{paddingTop:'10px'}} className='styled-header'>{clientProfile.name}</h1>
                                    </div>
                                    
                                    <div style={{paddingLeft: '20px', paddingRight:'20px'}}>
                                        <Button onClick={() => {navigate('/search', {state: {client_profile_id: clientProfile.id}})}} className='primary-bg' style={{width: '45%', marginTop: '20px'}}>Search <i style={{marginLeft: '10px'}} class="fa fa-search" aria-hidden="true"></i></Button>
                                        <CreateAlert clientProfileId={state.client_profile_id} />
                                    </div>
                                </div>
                                <div style={{height: '1px', width: '100%', backgroundColor: 'gray'}}></div>
                                <div className='clickable-card' style={{padding: '30px'}} onClick={() => {setShowEditClientProfileModal(true)}}>
                                    <h4><i class="fa fa-envelope" style={{marginRight: '20px'}} aria-hidden="true"></i> {clientProfile.email}</h4>
                                    <h4 style={{paddingTop:'20px'}}><i class="fa fa-phone" style={{marginRight: '20px'}} aria-hidden="true"></i> {clientProfile.phone}</h4>
                                    <h5 style={{paddingTop:'20px'}}>Required Length: {clientProfile.required_length}m</h5>
                                    <h5 style={{paddingTop:'20px'}}>Budget: {getCurrencySymbol(clientProfile.currency)}{formatNumber(clientProfile.budget)} {clientProfile.currency}</h5>
                                </div>
                        
                            </div>
                        </Col>
                        <Col lg={8}>
                            <Row className='content-section'>
                                <Col md={12} lg={6} className='g-0 responsive-col-padding'>
                                    <ClientRequirements clientProfileId={clientProfile.id} />
                                    <SavedBoats clientProfile={clientProfile} myBoatsCount={myBoatsCount} />
                                    <ClientImages clientProfileId={clientProfile.id} />
                                </Col>
                                <Col md={12} lg={6} className='g-0 responsive-col-padding'>
                                    <ShortlistReports clientProfile={clientProfile} reportsCount={reportsCount} />
                                    {/*<AppraisalReports clientProfile={clientProfile} appraisalReportsCount={appraisalReportsCount} />*/}
                                </Col>
                            </Row>     
                               
                        </Col>
                    </Row>
                }
                
            </Container>
            <Modal show={showEditClientProfileModal} onHide={() => {setShowEditClientProfileModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Edit Client Profile</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control value={editClientProfileName} onChange={(e) => {setEditClientProfileName(e.target.value)}} style={{marginTop: '20px'}} type="text" placeholder="Client Name" />
                    <Form.Control value={editClientProfileEmail} onChange={(e) => {setEditClientProfileEmail(e.target.value)}} style={{marginTop: '20px'}} type="text" placeholder="Client Email" />
                    <Form.Control value={editClientProfilePhone} onChange={(e) => {setEditClientProfilePhone(e.target.value)}} style={{marginTop: '20px'}} type="text" placeholder="Client Phone" />
                    
                    <p style={{marginTop: '20px'}}>Length of boat (metres)</p>
                    <Form.Control value={editClientProfileRequiredLength} onChange={(e) => {setEditClientProfileRequiredLength(e.target.value)}} type="number" placeholder='Required Length' />

                    <p style={{marginTop: '20px'}}>Budget</p>
                    <Row>
                        <Col>
                            <Form.Control value={editClientProfileBudget} onChange={(e) => {setEditClientProfileBudget(e.target.value)}} type="number" placeholder="Budget" />
                        </Col>
                        <Col>
                            <Form.Control value={editClientProfileCurrency} onChange={(e) => {setEditClientProfileCurrency(e.target.value)}} as="select">
                                <option>USD</option>
                                <option>EUR</option>
                                <option>GBP</option>
                                <option>NZD</option>
                                <option>AUD</option>
                            </Form.Control>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="secondary-bg" onClick={() => {setShowEditClientProfileModal(false)}}>
                        Close
                    </Button>
                    <Button className="primary-bg" onClick={() => {handleEditClientProfile()}}>
                        Save Changes
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
