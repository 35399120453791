import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import {Image as BootstrapImage} from  'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { useState, useEffect } from 'react'
import {useDropzone} from 'react-dropzone';
import SharedFilters from './SharedFilters'
import { imageSearch } from '../../../requests/search'
import { getClientImages } from '../../../requests/clientImage'


export default function ImageSearchCard(props) {

    const [searchImages, setSearchImages] = useState([])
    const [sharedFilters, setSharedFilters] = useState({})
    const [showImageModal, setShowImageModal] = useState(false);
    const [clientImages, setClientImages] = useState([])



    useEffect(() => {
        if(props.clientProfile){
            getClientImages(
                props.clientProfile.id,
                (data) => {
                    console.log(data)
                    setClientImages(data.client_images)
                },
                (data) => {
                    console.log(data)
                }
            )
        }
    }, [props.clientProfile])


    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            var file = acceptedFiles[0];
            if(file){
                var reader = new FileReader();
    
                reader.onload = function(e){
                    setSearchImages(() => [...searchImages, {url:e.target.result}])
                }
    
                reader.readAsDataURL(file);
            }
        }
    });
    


    function runSearch() {

        if (searchImages.length === 0) {
            return;
        }

        var fd = sharedFilters
        console.log(fd)

        fd['images'] = JSON.stringify(searchImages.map(image => image.url));
        fd['page'] = 1;

        props.handleSearchStart('image', fd)

        imageSearch(fd, 
            (data) => {
                props.handleSearchResults(data.results, 1)
            },
            (data) => {
                console.log(data)
            }
        )

    }


    function handleChangeSearchFilters(newFilters) {
        setSharedFilters(newFilters)
    }


    function handleRemoveImage(image_url) {
        setSearchImages(searchImages.filter(image => image.url !== image_url))
    }


    function fetchImage(url) {

        //remove .cdn from url
        url = url.replace('.cdn', '')

        fetch(url, {
          method: 'GET'
        })
        .then((response) => response.blob())
        .then((blob) => {
            var reader = new FileReader();
            reader.readAsDataURL(blob); 
            reader.onloadend = function() {
              var base64data = reader.result;                
              setSearchImages(() => [...searchImages, {url:base64data}])
            }
        });
      }

    

    return (
        <>
            <Container fluid style={{paddingBottom: '50px'}}>
                <Row>
                    <Col className='g-0'>
                        <div style={{padding: '20px', backgroundColor: 'rgb(240, 240, 240'}}>

                            {
                                props.clientProfile ?
                                <div style={{width: '100%', textAlign: 'center', marginBottom: '40px'}}>
                                    <Button onClick={() => {setShowImageModal(true)}} className='primary-bg' >Select From Client Images</Button>
                                </div>
                                :
                                null
                            }

                            <Row>
                                {
                                    searchImages.map((image, index) => {
                                        return (
                                            <Col xs={6} sm={4} md={4} lg={4} xl={4} key={index}>
                                                <BootstrapImage onClick={() => {handleRemoveImage(image.url)}} className='search-image' src={image.url} style={{width: '100%', height: 'auto'}} />
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        </div>
                        
                        <div className="image-upload" style={{textAlign: 'center', paddingTop:'40px', backgroundColor: 'rgb(240, 240, 240)'}} {...getRootProps()}  >
                            <BootstrapImage className='upload-image mild-neumorphic-card' id="previewImg" src="./placeholder.jpg" style={{width: '200px'}} />
                            <input {...getInputProps()} />
                        </div>

                        <div style={{paddingLeft: '60px', paddingTop: '30px', paddingBottom: '30px', backgroundColor: 'rgb(240, 240, 240)'}}>
                            <Button onClick={() => {runSearch()}} className='primary-bg' style={{width: '80%'}}>Search</Button>
                        </div>
                        <div style={{height: '1px', width: '100%', backgroundColor: 'gray'}}></div>
                        
                        <div style={{textAlign: 'center', paddingTop: '30px'}}>
                            <h3 className='styled-header'>Filters</h3>
                        </div>
                        <SharedFilters handleChangeSearchFilters={handleChangeSearchFilters} />

                    </Col>
                </Row>
            </Container>
            <Modal show={showImageModal} onHide={() => {setShowImageModal(false)}} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Select Images</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row>
                            {
                                clientImages.map((image, index) => {
                                    return (
                                        <Col xs={6} sm={4} md={4} lg={4} xl={4} key={index}>
                                            <BootstrapImage className='search-client-image mild-neumorphic-card' onClick={() => {fetchImage(image.url)}} src={image.url} style={{width: '100%', height: 'auto'}} />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    )
}
