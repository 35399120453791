import React from 'react'
import IndSectionCard from './IndSectionCard'

export default function MyReportsSection() {
  return (
    <div>
        <IndSectionCard image="/help-images/my-reports-page1.png" title="My Reports Page" textSections={[
            "The My Reports page is where you can view and manage all the reports you have created",
            "From here you can create either a Shortlist Report (A shortlist of potential boats anlong with market insights for a buyer)",
            "Or an Appraisal Report (A report that provides a detailed analysis of a specific boat along with comparisons and market insights)"
        ]} />
        <IndSectionCard image="/help-images/my-reports-page2.png" title="Report Card" textSections={[
            "Click on the 'Edit' button to edit or update the report",
            "Click on the 'Archive' button to archive the report, this will not delete the report but it will no longer be visible on this page",
            "Once a PDF has been created you can click on the 'View PDF' button to view the PDF"
        ]} />
        <IndSectionCard image="/help-images/my-reports-page3.png" title="Create Shortlist Report" textSections={[
            "To create a new shortlist report, click the 'Create Report' button on the shortlist reports card",
            "You can then add the clients details as well as the basic requirements of the client",
            "If you do not wish to have the listing URL's in the report, you can uncheck the 'Include Listing URLs' checkbox"
        ]} />
        <IndSectionCard image="/help-images/my-reports-page4.png" title="Create Appraisal Report" textSections={[
            "To create a new appraisal report, click the 'Create Report' button on the appraisal reports card",
            "You can then add the clients details",
            "You also need to add the basic details that is being appraised as well as a main image",
            "The report currency is the currency the recommended listing price will be displayed in"
        ]} />
    </div>
  )
}
