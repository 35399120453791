import React from 'react'
import LandscapePage from '../../../generic_report_components/LandscapePage'
import { useState, useEffect } from 'react';
import { getBrokerDetails} from '../../../requests/brokerDetails';
import LandInsightsCard from './insights_page_components/LandInsightsCard';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function LandMarketInsightsPage(props) {

    const [brokerName, setBrokerName] = useState(null);
    const [brokerEmail, setBrokerEmail] = useState(null);
    const [brokerPhone, setBrokerPhone] = useState(null);


    useEffect(() => {
        getBrokerDetails(
            (data) => {
                if (data.broker_details) {
                    setBrokerName(data.broker_details.name)
                    setBrokerEmail(data.broker_details.email)
                    setBrokerPhone(data.broker_details.phone)
                }
            },
            (data) => {
                console.log(data)
            }
        )
    }, [])

    return (
        <div style={{marginBottom: '40px'}}>
            <LandscapePage
                includeFooter={true}
                brokerDetails={{name: brokerName, email: brokerEmail, phone: brokerPhone}}
                theme={{
                    theme:props.theme,
                    themePrimaryText:props.themePrimaryText,
                }}
            >
                <div className="report-pt-2 report-pl-2 report-pr-2">
                    <h2 className='report-title-landscape' style={{color: props.themePrimaryText}}>Market Insights</h2>
                    <hr style={{marginBottom: '0px', marginTop: '0px'}}></hr>
                </div>

                <Container fluid>
                    <Row>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                            <div style={{width: '40%'}} className="report-p-1 report-insights-section mx-auto">
                                <LandInsightsCard 
                                    title="Similar Boats Location Distribution" 
                                    themePrimaryText={props.themePrimaryText}
                                    src={props.report.market_insights.heatmap_path} 
                                />
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div className='report-insights-section-left report-p-1'>
                                <LandInsightsCard 
                                    title="Similar Boats Length Distribution" 
                                    themePrimaryText={props.themePrimaryText}
                                    src={props.report.market_insights.length_plot_path} 
                                />
                            </div>
                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4}>
                            <div className='report-insights-section-right report-p-1'>
                                <LandInsightsCard 
                                    title="Similar Boats Price Distribution" 
                                    themePrimaryText={props.themePrimaryText}
                                    src={props.report.market_insights.price_plot_path} 
                                />
                            </div>
                        </Col>
                        <Col>

                        </Col>
                    </Row>
                </Container>

            </LandscapePage>
        </div>
    )
}
