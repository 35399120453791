import React from 'react'
import Image from 'react-bootstrap/Image'

export default function InsightsCard(props) {
  return (
    <div className='mild-neumorphic-card' style={{textAlign: 'center', padding: '2vw', borderRadius: '1vw'}}>
        <h4 style={{color: props.themePrimaryText}} className='report-subtitle'>{props.title}</h4>
        <Image src={props.src} fluid/>
    </div>
  )
}
