import React from 'react'
import Col from 'react-bootstrap/Col'


export default function Cell(props) {
    return (
        <Col xs={2} md={2} lg={2} className='g-0'>
            {
                props.index % 2 === 0 ?
                    <p 
                        className='report-text-sm-landscape report-p-tiny' 
                        style={{ 
                            margin: '0px', 
                            backgroundColor: 'rgb(245, 245, 245)', 
                            height: '100%',
                            textAlign: 'center'
                        }}>
                            {props.text}
                        </p>    
                :
                    <p 
                        className='report-text-sm-landscape report-p-tiny' 
                        style={{
                            margin: '0px', 
                            backgroundColor: 'rgb(230, 230, 230)', 
                            height: '100%',
                            textAlign: 'center'
                        }}>
                            {props.text}
                        </p>
            }  
        </Col>
    )
}
