import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { getClientProfile } from '../../requests/clientProfile'
import { getReports, createReport } from '../../requests/report/report'
import { getClientRequirements } from '../../requests/clientRequirement'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../../universal_components/Header'


export default function Reports() {
    const navigate = useNavigate()

    const {state} = useLocation();

    const [clientProfile, setClientProfile] = useState(null);

    const [reports, setReports] = useState(null);

    const [showChooseReportTypeModal, setShowChooseReportTypeModal] = useState(state.showCreateReportModal || false);
    const [showCreateReportModal, setShowCreateReportModal] = useState(false);

    const [reportType, setReportType] = useState(null);

    const [reportCurrency, setReportCurrency] = useState('NZD');
    const [includeListingUrls, setIncludeListingUrls] = useState(true);
    const [checklistItemInput, setChecklistItemInput] = useState('');
    const [requirementsChecklistItems, setRequirementsChecklistItems] = useState([]);



    useEffect(() => {
        getClientProfile(
            state.client_profile_id, 
            (data) => {
                console.log(data)
                setClientProfile(data.client_profile)
            },
            (data) => {
                console.log(data)
            }
        )
    }, [state.client_profile_id])


    useEffect(() => {
        getReports(
            state.client_profile_id,
            (data) => {
                console.log(data)
                setReports(data.reports)
            },
            (data) => {
                console.log(data)
            }
        )
    }, [state.client_profile_id])



    useEffect(() => {
        getClientRequirements(
            state.client_profile_id,
            (data) => {
                console.log(data)
                var initRequirements = [];
                data.client_requirements.forEach((cr) => {
                    initRequirements.push(cr.requirement)
                })
                setRequirementsChecklistItems(initRequirements)
            },
            (data) => {
                console.log(data)
            }
        )
    }, [state.client_profile_id])


    function handleSelectReportType(type) {
        setReportType(type)
        setShowChooseReportTypeModal(false)
        setShowCreateReportModal(true)
    }


    function removeChecklistItem(item) {
        var newChecklistItems = requirementsChecklistItems.filter((i) => {
            return i !== item
        })
        setRequirementsChecklistItems(newChecklistItems)
    }

    function handleKeyPressChecklistItemInput(event){
        if(event.key === 'Enter'){
            setRequirementsChecklistItems([...requirementsChecklistItems, checklistItemInput]); 
            setChecklistItemInput('');
        }
    }


    function handleCreateReport() {
        var fd = {
            client_profile_id: state.client_profile_id,
            include_listing_url: includeListingUrls,
            report_currency: reportCurrency,
            checklist_items: requirementsChecklistItems,
            report_type: reportType
        }

        createReport(
            fd,
            (data) => {
                console.log(data)
                setShowCreateReportModal(false)
                navigate('/build-report', {state: {report_id: data.report.id}});
            },
            (data) => {
                console.log(data)
            }
        )
    }


    function getCurrencySymbol(currency) {
        switch(currency) {
            case 'USD':
                return '$';
            case 'NZD':
                return '$';
            case 'AUD':
                return '$';
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            default:
                return '$';
        }
    }

    function formatNumber(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }



    return (
        <>
            <Header signedIn={true} />
            <Container fluid style={{minHeight: '100vh', backgroundColor: 'rgb(220, 220, 220)'}}>
                {
                    clientProfile && 
                    
                    <Row>
                        <Col md={12} lg={3} className='g-0'>
                            <div className='sidecard'>

                                <div className='clickable-card' style={{
                                    backgroundImage: 'url(./landing-img.jpeg)', 
                                    width: '100%', 
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    textAlign: 'center',
                                    paddingTop: '5vh',
                                    paddingBottom: '5vh',
                                }}>
                                    <Image onClick={() => {navigate('/client-page', {state: {client_profile_id: clientProfile.id}});}} className='mild-neumorphic-card' src='/profile-placeholder.png' style={{width: '20%', borderRadius: '50%'}} />
                                    <h1 onClick={() => {navigate('/client-page', {state: {client_profile_id: clientProfile.id}});}} style={{paddingTop:'10px'}} className='styled-header'>{clientProfile.name}</h1>
                                    <h2 className='styled-header' style={{marginTop: '30px'}}>Shortlist Reports</h2>
                                    <Button onClick={() => {setShowChooseReportTypeModal(true)}} className='primary-bg' style={{marginTop: '30px'}}>Create Report</Button>
                                </div>

                                <div style={{height: '1px', width: '100%', backgroundColor: 'gray'}}></div>
                                
                                <div style={{padding: '30px'}}>
                                    <h5 style={{paddingTop:'20px'}}><i class="fa fa-envelope" style={{marginRight: '20px'}} aria-hidden="true"></i> {clientProfile.email}</h5>
                                    <h5 style={{paddingTop:'20px'}}><i class="fa fa-phone" style={{marginRight: '20px'}} aria-hidden="true"></i> {clientProfile.phone}</h5>
                                    <h6 style={{paddingTop:'20px'}}>Required Length: {clientProfile.required_length}m</h6>
                                    <h6 style={{paddingTop:'20px'}}>Budget: {getCurrencySymbol(clientProfile.currency)}{formatNumber(clientProfile.budget)} {clientProfile.currency}</h6>
                                </div>
                        
                            </div>
                        </Col>
                        <Col lg={8} className='g-0'>
                            <Container fluid>
                            <Row className='content-section'>
                                
                                {
                                    reports && reports.map((report) => {
                                        return (
                                            <Col md={12} lg={6} className='g-0 responsive-col-padding'>
                                                <div className='clickable-card' style={{backgroundColor:'white', marginBottom: '30px'}}>
                                                    
                                                    <div style={{padding: '30px', backgroundColor: 'rgb(240, 240, 240)'}}>
                                                        <h2 className='styled-header'>
                                                            <span onClick={() => {navigate('/build-report', {state: {report_id: report.id}})}}>
                                                                {report.created_at}
                                                            </span>
                                                            {
                                                                report.pdf ?
                                                                    <a target='_blank' rel="noreferrer" href={report.pdf.pdf_path} style={{float: 'right'}} className='clickable-icon' >
                                                                        <i class="fa fa-lg fa-file-pdf-o" aria-hidden="true"></i>
                                                                    </a>
                                                                :
                                                                    null
                                                            }
                                                        </h2>
                                                    </div>
                                                    <div style={{paddingLeft: '30px', paddingRight: '30px', paddingTop: '15px', paddingBottom: '15px'}}>
                                                        <h4 style={{width: '100%'}}>{report.report_boats.length} Boats</h4>
                                                    </div>
                                                        
                                                </div>
                                            </Col>
                                        )
                                    })
                                }
                                        
                            </Row>   
                            </Container>                 
                        </Col>
                    </Row>
                }
                
            </Container>

            <Modal show={showChooseReportTypeModal} onHide={() => setShowChooseReportTypeModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Choose Report Template</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row>
                            <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                                <div style={{textAlign: 'center'}}>
                                    <h4>Landscape</h4>
                                </div>
                                <div style={{width: '100%', height: '130px', backgroundColor: 'white', cursor: 'pointer'}} className='selectable-shadow' onClick={() => {handleSelectReportType('landscape')}}>
                                    <Container fluid>
                                        <Row>
                                            <Col className='g-0' xs={8} sm={8} md={8} lg={8} xl={8}>
                                                <Image src='./cropped_placeholder.jpg' style={{width: '100%', height: '70px'}} />
                                                <Container fluid>
                                                    <Row>
                                                        {
                                                            [1,2,3,4,5,6].map((item) => {
                                                                return (
                                                                    <Col className='g-0' xs={4} sm={4} md={4} lg={4} xl={4} style={{paddingTop: '3px', paddingLeft: '3px'}}>
                                                                        <Image src='./cropped_placeholder.jpg' style={{width: '100%', height: '20px'}} />
                                                                    </Col>
                                                                )
                                                            })
                                                        }
                                                    </Row>
                                                </Container>
                                            </Col>
                                            <Col className='g-0'>
                                                <div style={{width: '100%', padding: '10px'}}>
                                                    <Image src='./cropped_placeholder.jpg' style={{width: '100%'}} />
                                                </div>
                                                <div style={{padding: '5px'}}>
                                                    <div style={{backgroundColor: 'rgb(230,230,230)', height: '10px', borderRadius: '5px'}}></div>
                                                    <div style={{backgroundColor: 'rgb(230,230,230)', height: '6px', borderRadius: '3px', marginTop: '5px', width: '70%'}}></div>
                                                </div>
                                                <div style={{padding: '5px'}}>
                                                    <div style={{backgroundColor: 'rgb(230,230,230)', height: '6px', borderRadius: '3px', width: '90%', marginTop: '3px'}}></div>
                                                    <div style={{backgroundColor: 'rgb(230,230,230)', height: '6px', borderRadius: '3px', width: '90%', marginTop: '3px'}}></div>
                                                    <div style={{backgroundColor: 'rgb(230,230,230)', height: '6px', borderRadius: '3px', width: '90%', marginTop: '3px'}}></div>
                                                </div>
                                                
                                            </Col>
                                        </Row>
                                    </Container>
                                </div>
                            </Col>
                            {/*}
                            <Col>
                                <div style={{textAlign: 'center'}}>
                                    <h4>Portrait</h4>
                                </div>
                                <div style={{width: '100%', height: '250px', backgroundColor: 'white', cursor: 'pointer'}} className='selectable-shadow' onClick={() => {handleSelectReportType('portrait')}}>
                                    <Image src='./cropped_placeholder.jpg' style={{width: '100%', height: '35%'}} />
                                    <div style={{padding: '10px'}}>
                                        <div style={{backgroundColor: 'rgb(230,230,230)', height: '10px', borderRadius: '5px', width: '50%'}}></div>
                                        <div style={{backgroundColor: 'rgb(230,230,230)', height: '6px', borderRadius: '3px', marginTop: '5px', width: '70%'}}></div>
                                        <Container fluid>
                                            <Row style={{paddingTop: '5px'}}>
                                                {
                                                    [1,2,3,4].map((item) => {
                                                        return (
                                                            <Col className='g-0' xs={3} sm={3} md={3} lg={3} xl={3} style={{paddingTop: '3px', paddingLeft: '3px'}}>
                                                                <div style={{backgroundColor: 'rgb(230,230,230)', height: '10px', borderRadius: '5px', width: '100%', marginTop: '3px'}}></div>
                                                            </Col>
                                                        )
                                                    })
                                                }
                                            </Row>
                                            <Row>
                                                <Col xs={7} sm={7} md={7} lg={7} xl={7} className='g-0'>
                                                    <div style={{padding: '5px'}}>
                                                        <div style={{backgroundColor: 'rgb(230,230,230)', height: '8px', borderRadius: '4px', marginTop: '10px', width: '50%'}}></div>
                                                        <div style={{backgroundColor: 'rgb(230,230,230)', height: '6px', borderRadius: '3px', marginTop: '3px', width: '100%'}}></div>
                                                        <div style={{backgroundColor: 'rgb(230,230,230)', height: '6px', borderRadius: '3px', marginTop: '3px', width: '100%'}}></div>

                                                        <div style={{backgroundColor: 'rgb(230,230,230)', height: '8px', borderRadius: '4px', marginTop: '10px', width: '50%'}}></div>
                                                        <div style={{backgroundColor: 'rgb(230,230,230)', height: '6px', borderRadius: '3px', marginTop: '3px', width: '100%'}}></div>
                                                        <div style={{backgroundColor: 'rgb(230,230,230)', height: '6px', borderRadius: '3px', marginTop: '3px', width: '100%'}}></div>
                                                    </div>
                                                </Col>
                                                <Col xs={5} sm={5} md={5} lg={5} xl={5} className='g-0'>
                                                    <div style={{padding: '5px'}}>
                                                        <div style={{backgroundColor: 'rgb(230,230,230)', height: '70px', borderRadius: '10px', marginTop: '3px'}}></div>
                                                    </div>
                                                </Col>  
                                            </Row>
                                        </Container>
                                    </div>
                                </div>
                            </Col>*/}
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>


            <Modal show={showCreateReportModal} onHide={() => setShowCreateReportModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Create Report</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{marginTop:'20px'}}>
                        <h6 style={{marginTop:'20px'}}>Report Currency</h6>
                        <Form.Control value={reportCurrency} onChange={(e) => {setReportCurrency(e.target.value)}} as='select' placeholder='Report Currency' >
                            <option>NZD</option>
                            <option>AUD</option>
                            <option>GBP</option>
                            <option>USD</option>
                            <option>EUR</option>
                        </Form.Control>
                        <Form.Check checked={includeListingUrls} onClick={() => {setIncludeListingUrls(!includeListingUrls)}} size='lg' style={{marginTop: '40px'}} type='switch' label='Include Listing URLs' />
                    </div>
                    {
                        reportType === 'portrait' ?
                            <div style={{marginTop: '80px', marginBottom: '40px'}}>
                                <h4 className='styled-header'>Requirements Checklist Items</h4>
                                <div>
                                    {
                                        requirementsChecklistItems.map((item, index) => {
                                            return (
                                                <div key={index}>
                                                    <h5>{item} <Button className='danger-bg' size="sm" style={{marginLeft: '20px', paddingLeft: '15px', paddingRight: '15px'}} onClick={() => {removeChecklistItem(item)}}>X</Button></h5>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <h6 style={{marginTop: '60px'}}>Add any requirements to compare each boat too.</h6>
                                <InputGroup>
                                    <Form.Control 
                                        value={checklistItemInput} 
                                        onChange={(e) => {setChecklistItemInput(e.target.value)}} 
                                        onKeyUp={handleKeyPressChecklistItemInput}
                                        style={{marginTop: '20px'}} 
                                        type='text' 
                                        placeholder='Item Name' 
                                    />
                                    <Button className='primary-bg' onClick={() => {setRequirementsChecklistItems([...requirementsChecklistItems, checklistItemInput]); setChecklistItemInput('')}} style={{marginTop: '20px'}}>Add</Button>
                                </InputGroup>
                            </div>
                        :
                            null
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button className="secondary-bg" onClick={() => setShowCreateReportModal(false)}>
                        Close
                    </Button>
                    <Button className="primary-bg" onClick={() => handleCreateReport()}>
                        Create
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

