import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Header from "../../universal_components/Header";
import DirectoryCard from "../home/components/DirectoryCard";
import { useNavigate } from "react-router-dom";
import UploadImage from "./UploadImage";
import PricingTool from "../merging_graph_page/PricingTool";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export default function HomePageUpload() {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(0);
  const [currency, setCurrency] = useState("AUD");  

  const handleSearchClick = () => {
    navigate("/search", { state: { client_profile_id: null } });
  };

  const handleMyClientsClick = () => {
    navigate("/my-clients");
  };

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <>
      <Header signedIn={true} />
      <Container maxWidth={false} disableGutters>
        <Grid container style={{ minHeight: "100vh" }}>
          <Grid
            item
            xs={12}
            sm={12}
            lg={3}
            sx={{
              backgroundColor: "rgb(210, 210, 210)",
              pb: { xs: 5, md: 3, lg: 0 },
            }}
          >
            <Box
              sx={{
                backgroundImage: "url(./landing-img.jpeg)",
                width: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
                py: "10vh",
              }}
            >
              <Typography variant="h3" className="styled-header">
                Broker Portal
              </Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                md={6}
                lg={12}
                sx={{ mx: { xs: 2, sm: 2, md: 0 } }}
              >
                <DirectoryCard
                  title="My Clients"
                  description="View and manage your clients and their reports."
                  callToAction="Go to My Clients"
                  navigate={handleMyClientsClick}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={12}
                sx={{ mx: { xs: 2, sm: 2, md: 0 } }}
              >
                <DirectoryCard
                  title="Search"
                  description="Use our Proprietary Photolumination technology to find your clients their dream boat."
                  callToAction="Go to Search"
                  navigate={handleSearchClick}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            lg={9}
            sx={{
              backgroundImage: "url(./classic_yacht.jpg)",
              minHeight: "100vh",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "auto",
                p: { xs: 0, md: 2 }
              }}
            >
              <Tabs
                value={selectedTab}
                onChange={handleTabChange}
                aria-label="Tool tabs"
                sx={{ mb: { xs: 0, md: 2 }, backgroundColor: "white", borderRadius: { xs: 0, md: 2 }, p: 2 }}
              >
                <Tab label="Pricing Tool" sx={{ fontWeight: "bold" }} />
                <Tab label="Find With Image" sx={{ fontWeight: "bold" }} />
              </Tabs>
              <Grid container spacing={2} sx={{ flex: 1 }}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      p: 0,
                      backgroundColor: "white",
                      borderRadius: { xs: 0, md: 2 },
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    {selectedTab === 0 && <PricingTool currency={currency} setCurrency={setCurrency} />}
                    {selectedTab === 1 && <UploadImage currency={currency} setCurrency={setCurrency} />}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
