import React from 'react'
import Footer from './Footer'


export default function LandscapePage(props) {
  return (
    <div>
        <div className="mild-neumorphic-card landscape-page" style={{backgroundColor: 'white', marginLeft: 'auto', marginRight: 'auto'}}>
            <div style={props.includeFooter ? {height: '93%'} : {}}>
                {props.children}
            </div>
            {
                props.includeFooter && props.brokerDetails && props.theme &&
                <Footer brokerDetails={props.brokerDetails} theme={props.theme}/>       
            }
        </div>
    </div>
  )
}
