import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Field from './Field'


export default function Fields(props) {
    return (
        <Container>
            <Row>
                <Col>
                    {
                        props.boat.fields.map((field, index) => {
                            return (
                                <Field
                                    key={index}
                                    theme={props.theme}
                                    themePrimaryText={props.themePrimaryText}
                                    themeSecondaryText={props.themeSecondaryText}
                                    field={field}
                                    reloadReportCallback={props.reloadReportCallback}
                                />
                            )
                        })
                    }
                </Col>
            </Row>
        </Container>
    )
}
