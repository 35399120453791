import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Header from '../../universal_components/Header'
import SearchCard from './components/SearchCard'
import { useState, useEffect, useRef } from 'react'
import SearchResultCard from './components/SearchResultCard'
import LoadingCard from './components/LoadingCard'
import { imageSearch, keywordSearch, similarSearch} from '../../requests/search'
import { getMyBoats } from '../../requests/myBoats'
import { useLocation } from 'react-router-dom'
import { getClientProfile } from '../../requests/clientProfile'



export default function Search() {

    const {state} = useLocation();

    const [searchClient, setSearchClient] = useState();

    useEffect(() => {
        if (state.client_profile_id) {
            getClientProfile(
                state.client_profile_id,
                (data) => {
                    console.log(data)
                    setSearchClient(data.client_profile)
                },
                (data) => {
                    console.log(data)
                }
            )
        }
    }, [state.client_profile_id])



    const [myBoats, setMyBoats] = useState([]);

    const [searchResults, setSearchResults] = useState([]);
    const [searchLoading, setSearchLoading] = useState(false);
    const [searchResultsLoaded, setSearchResultsLoaded] = useState(false);

    const [currentSearchFd, setCurrentSearchFd] = useState(null);

    const [currentSearchType, setCurrentSearchType] = useState('image');

    const [searchCurrency, setSearchCurrency] = useState(null);

    const ref = useRef(null);

    const handleScroll = () => {
        ref.current?.scrollIntoView({behavior: 'smooth'});
    };


    function handleGetMyBoats() {
        if (!searchClient) {
            return;
        }
        getMyBoats(
            searchClient.id,
            (data) => {
                console.log(data.my_boats)
                setMyBoats(data.my_boats);
            },
            (data) => {
                console.log(data)
            }
        )
    }

    function cleanSearchResults(results, pageNum) {
        var cleanedResults = [];
        results.forEach((result) => {
            var cleanedResult = {
                title: result.title,
                broker: result.broker,
                url: result.url,
                price: result.price,
                currency: result.currency,
                length: result.length,
                year: result.year,
                location: result.location,
                country: result.country,
                description: result.description,
                imageUrl: result.image_url,
                imageUrls: result.images,
                styleVector: result.style_vector,
            }
            cleanedResults.push(cleanedResult);
        })

        console.log(cleanedResults)

        if (pageNum === 1) {
            setSearchResults(cleanedResults);
        } else {
            setSearchResults([...searchResults, ...cleanedResults]);
        }
        setSearchLoading(false);
        setSearchResultsLoaded(true);
    }


    function handleSearchStart(searchType, searchFd) {
        setSearchResults([]);
        setSearchLoading(true);
        setSearchResultsLoaded(false);
        setCurrentSearchFd(searchFd);
        setCurrentSearchType(searchType);
        handleGetMyBoats();
        setSearchCurrency(searchFd.currency);
        handleScroll();
    }


    function handleLoadMore() {
        setSearchLoading(true);
        var fd = currentSearchFd;
        if (currentSearchType === 'image') {
            fd['page'] = fd['page'] + 1;
            setCurrentSearchFd(fd);

            imageSearch(fd, 
                (data) => {
                    cleanSearchResults(data.results, fd['page'])
                },
                (data) => {
                    console.log(data)
                }
            )

        } else if (currentSearchType === 'keyword') {
            fd['page'] = fd['page'] + 1;
            setCurrentSearchFd(fd);

            keywordSearch(fd, 
                (data) => {
                    cleanSearchResults(data.results, fd['page'])
                },
                (data) => {
                    console.log(data)
                }
            )
        } else if (currentSearchType === 'similar') {
            fd['page'] = fd['page'] + 1;
            setCurrentSearchFd(fd);
            similarSearch(fd, 
                (data) => {
                    cleanSearchResults(data.results, fd['page'])
                },
                (data) => {
                    console.log(data)
                }
            )
        }
    }



    function changeClientCallback(clientProfile) {
        setSearchClient(clientProfile);
    }


    return (
        <>
            <Header signedIn={true} />
            <Container fluid style={{minHeight: '100vh', backgroundColor: 'rgb(220, 220, 220)'}}>
        
                <Row>
                    <Col lg={4} className="g-0">
                        <SearchCard hasClientId={state.client_profile_id ? true : null} clientProfile={searchClient} changeClientCallback={changeClientCallback} handleSearchResults={cleanSearchResults} handleSearchStart={handleSearchStart} />
                    </Col>
                    <Col lg={8} className='g-0'>
                        <div className='content-section'>
                            {
                                searchResults.map((result, index) => {
                                    return (
                                        <SearchResultCard 
                                            key={index} 
                                            clientProfile={searchClient}
                                            title={result.title} 
                                            imageUrl={result.imageUrl} 
                                            broker = {result.broker}
                                            price = {result.price}
                                            currency = {result.currency}
                                            length = {result.length}
                                            year = {result.year}
                                            location = {result.location}
                                            country = {result.country}
                                            description = {result.description}
                                            url = {result.url}
                                            imageUrls = {result.imageUrls}
                                            styleVector = {result.styleVector}
                                            myBoats = {myBoats}
                                            searchCurrency = {searchCurrency}
                                            handleSearchResults={cleanSearchResults} 
                                            handleSearchStart={handleSearchStart}
                                        />
                                    )
                                })
                            }
                        </div>
                        <div ref={ref}></div>
                        <div className='content-section' hidden={!searchLoading}>
                            <LoadingCard />
                            <LoadingCard />
                            <LoadingCard />
                            <LoadingCard />
                            <LoadingCard />
                            <LoadingCard />
                            <LoadingCard />
                            <LoadingCard />
                        </div>
                        <div hidden={!searchResultsLoaded} style={{padding: '40px'}}>
                            <Button className="primary-bg" onClick={() => {handleLoadMore()}}>Load More</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
