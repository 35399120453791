import { baseApiUrl } from "../settings";
import { 
    tokenRequiredRequest,
    tokenRequiredRequestWithBody
} from "./baseRequest";


export const getTheme = (successCallback, failedCallback) => {
    return tokenRequiredRequest(
        `${baseApiUrl}/theme`,
        'GET',
        successCallback,
        failedCallback)
}




export const updateTheme = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/theme`,
        'PUT',
        fd,
        successCallback,
        failedCallback)
}