import React, { useState, useEffect, useCallback } from "react";
import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  CircularProgress,
  Button,
  Snackbar,
  Alert,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { getAllBoats, getPricingData } from "../../../api/boat";
import useFetch from "../../../hooks/useFetch";
import { onLockEvent, offLockEvent } from "../../../EventEmitter";

const MergingForm = ({
  onValueDataChange,
  onCurrencyChange,
  pinnedGraphs,
  handleGraphSelect,
  selectedGraphs,
  mergeGraphs,
  showMergeTooltip,
  setShowMergeTooltip,
  currencyTooltipShown,
  setCurrencyTooltipShown,
}) => {
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [year, setYear] = useState("");
  const [country, setCountry] = useState("");
  const [currency, setCurrency] = useState("AUD");
  const [models, setModels] = useState([]);
  const [priceRange, setPriceRange] = useState(null);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [yearError, setYearError] = useState("");
  const [showStep1, setShowStep1] = useState(false);
  const [showStep2, setShowStep2] = useState(false);
  const [showStep3, setShowStep3] = useState(false);
  const [showRunRequestTooltip, setShowRunRequestTooltip] = useState(false);
  const [showAddBoatTooltip, setShowAddBoatTooltip] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const currentYear = new Date().getFullYear();
  const countries = ["USA", "Canada", "Australia", "UK"];

  const { data: makesData, isLoading } = useFetch(getAllBoats);
  const makes = makesData ? Object.keys(makesData) : [];

  const fetchModels = useCallback(async () => {
    if (make) {
      try {
        const data = await getAllBoats();
        if (data && data[make]) {
          setModels(data[make]);
        } else {
          setModels([]);
        }
      } catch {
        console.error("Error fetching models");
        setModels([]);
      }
    }
  }, [make]);

  useEffect(() => {
    fetchModels();
  }, [fetchModels]);

  useEffect(() => {
    setShowStep1(true);
    const step2Timeout = setTimeout(() => setShowStep2(true), 800);
    const step3Timeout = setTimeout(() => setShowStep3(true), 1300);

    return () => {
      clearTimeout(step2Timeout);
      clearTimeout(step3Timeout);
    };
  }, []);

  useEffect(() => {
    if (selectedGraphs.length >= 2) {
      setShowMergeTooltip(true);
      const tooltipTimeout = setTimeout(() => {
        setShowMergeTooltip(false);
      }, 4000);
      return () => clearTimeout(tooltipTimeout);
    }
  }, [selectedGraphs, setShowMergeTooltip]);

  useEffect(() => {
    const handleLockClick = () => setShowAddBoatTooltip(true);

    onLockEvent(handleLockClick);

    return () => {
      offLockEvent(handleLockClick);
    };
  }, []);

  useEffect(() => {
    if (make) {
      setShowAddBoatTooltip(false);
    } else {
      setShowAddBoatTooltip(true);
      const addBoatTooltipTimeout = setTimeout(() => {
        setShowAddBoatTooltip(false);
      }, 5000);
      return () => clearTimeout(addBoatTooltipTimeout);
    }
  }, [make]);

  const handleMakeChange = (event, newValue) => {
    setMake(newValue || "");
    setModel("");
    setYear("");
    setCountry("");
    setPriceRange(null);
    setShowRunRequestTooltip(false);
    onValueDataChange(null, null, "", "", false);
  };

  const handleModelChange = (event) => {
    setModel(event.target.value || "");
    setYear("");
    setCountry("");
    setPriceRange(null);
    setShowRunRequestTooltip(false);
    onValueDataChange(null, null, "", "", false);
  };

  const handleYearChange = (event) => {
    const newYear = event.target.value;
    setYear(newYear);
    if (newYear > currentYear || newYear < 1000) {
      setYearError(`Year must be between 1000 and ${currentYear}`);
    } else {
      setYearError("");
      setShowRunRequestTooltip(true);
    }
  };

  const handleCountryChange = (event) => {
    setCountry(event.target.value);
  };

  const handleCurrencyChange = (event) => {
    const newCurrency = event.target.value;
    setCurrency(newCurrency);
    setCurrencyTooltipShown(false);
    onCurrencyChange(newCurrency);
  };

  const handleRunRequest = async () => {
    setShowRunRequestTooltip(false);

    if (!make || !model || !year) {
      setSnackbarMessage("Make, Model, and Year are required");
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);

    try {
      const pricingData = await getPricingData(
        make,
        model,
        year,
        currency,
        country
      );
      if (pricingData.message) {
        onValueDataChange(null, null, "", "", true);
        setPriceRange(null);
      } else {
        onValueDataChange(pricingData, year, make, model, false);
        setPriceRange(pricingData.global_price_range);
      }
    } catch (error) {
      console.error("Error fetching pricing data:", error.message);
      onValueDataChange(null, null, "", "", true);
      setPriceRange(null);
    } finally {
      setLoading(false);
      setCurrencyTooltipShown(true);
      setTimeout(() => {
        setCurrencyTooltipShown(false);
      }, 4000);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleRunRequest();
    }
  };

  const handleMergeGraphs = () => {
    if (selectedGraphs.length < 2) {
      setSnackbarMessage("You must select at least two graphs for merging");
      setSnackbarOpen(true);
      return;
    }

    mergeGraphs();
    setShowMergeTooltip(false);
  };

  const currencySymbols = {
    AUD: "$",
    USD: "$",
    EUR: "€",
    GBP: "£",
    NZD: "$",
    CAD: "$",
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const getCurrencySymbol = (abbr) => {
    return currencySymbols[abbr] || abbr;
  };

  const formatPriceRange = (range) => {
    if (!range) return "";
    const symbol = getCurrencySymbol(currency);
    return `Min: ${symbol}${formatNumber(
      range.min
    )}, Max: ${symbol}${formatNumber(range.max)}`;
  };

  return (
    <Box onKeyDown={handleKeyDown}>
      <Box sx={{ p: 3, mb: 3, position: "relative" }}>
        <Tooltip
          title="Find/add a boat to compare"
          open={showAddBoatTooltip}
          placement={isSmallScreen ? "bottom" : "top"}
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: "#034d70",
                color: "#fff",
                fontSize: "1em",
                cursor: "pointer",
              },
            },
            arrow: {
              sx: {
                color: "#034d70",
              },
            },
          }}
        >
          <Typography
            variant="h4"
            gutterBottom
            sx={{ textAlign: "center", color: "#034d70" }}
          >
            Pricing Tool
          </Typography>
        </Tooltip>
        {isLoading || loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress sx={{ height: "10vh", mt: 2 }} />
          </Box>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Tooltip
                title="Step 1"
                open={showStep1}
                onClick={() => setShowStep1(false)}
                placement={isSmallScreen ? "bottom" : "right"}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#034d70",
                      color: "#fff",
                      fontSize: "1em",
                      cursor: "pointer",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "#034d70",
                    },
                  },
                }}
              >
                <Box>
                  <Autocomplete
                    options={makes}
                    value={make}
                    onChange={handleMakeChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="Make"
                        margin="normal"
                        InputProps={{
                          ...params.InputProps,
                        }}
                        sx={{
                          input: {
                            color: "#000",
                          },
                          "& .MuiInputLabel-root": {
                            color: "#034d70",
                          },
                          "& .MuiInputLabel-root.Mui-focused": {
                            color: "#0a93d9",
                          },
                          "& .MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#034d70",
                            },
                            "&:hover fieldset": {
                              borderColor: "#0a93d9",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#0a93d9",
                            },
                            "& input:-webkit-autofill": {
                              WebkitBoxShadow: `0 0 0 100px #deebf0 inset !important`,
                              backgroundColor: "transparent !important",
                              color: "#000",
                            },
                          },
                        }}
                      />
                    )}
                  />
                </Box>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Tooltip
                title="Step 2"
                open={showStep2}
                onClick={() => setShowStep2(false)}
                placement={isSmallScreen ? "bottom" : "right"}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#034d70",
                      color: "#fff",
                      fontSize: "1em",
                      cursor: "pointer",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "#034d70",
                    },
                  },
                }}
              >
                <Box>
                  <TextField
                    fullWidth
                    label="Model"
                    value={model}
                    onChange={handleModelChange}
                    margin="normal"
                    disabled={!make}
                    select
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            backgroundColor: "#034d70",
                            color: "#fff",
                          },
                        },
                      },
                    }}
                    sx={{
                      input: {
                        color: "#000",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#034d70",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#0a93d9",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#034d70",
                        },
                        "&:hover fieldset": {
                          borderColor: "#0a93d9",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#0a93d9",
                        },
                        "& input:-webkit-autofill": {
                          WebkitBoxShadow: `0 0 0 100px #deebf0 inset !important`,
                          backgroundColor: "transparent !important",
                          color: "#000",
                        },
                        "& .MuiSelect-select": {
                          "&::-webkit-scrollbar": {
                            width: "8px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#2c7599",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: "#6FA2BB",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "#a1c6d4",
                          },
                          overflowX: "hidden",
                        },
                      },
                    }}
                  >
                    {models.map((model, index) => (
                      <MenuItem key={`${model}-${index}`} value={model}>
                        {model}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <Tooltip
                title="Step 3"
                open={showStep3}
                onClick={() => setShowStep3(false)}
                placement={isSmallScreen ? "bottom" : "right"}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#034d70",
                      color: "#fff",
                      fontSize: "1em",
                      cursor: "pointer",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "#034d70",
                    },
                  },
                }}
              >
                <Box>
                  <TextField
                    fullWidth
                    label="Year"
                    value={year}
                    onChange={handleYearChange}
                    margin="normal"
                    type="number"
                    inputProps={{ min: 1000, max: currentYear }}
                    disabled={!model}
                    sx={{
                      input: {
                        color: "#000",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#034d70",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#0a93d9",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#034d70",
                        },
                        "&:hover fieldset": {
                          borderColor: "#0a93d9",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#0a93d9",
                        },
                        "& input:-webkit-autofill": {
                          WebkitBoxShadow: `0 0 0 100px #fff inset !important`,
                          backgroundColor: "transparent !important",
                          color: "#000",
                        },
                      },
                    }}
                  />
                  {yearError && (
                    <Typography
                      variant="caption"
                      color="error"
                      sx={{ display: "block" }}
                    >
                      {yearError}
                    </Typography>
                  )}
                </Box>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Country"
                value={country}
                onChange={handleCountryChange}
                margin="normal"
                select
                SelectProps={{
                  MenuProps: {
                    PaperProps: {
                      style: {
                        backgroundColor: "#034d70",
                        color: "#fff",
                      },
                    },
                  },
                }}
                sx={{
                  input: {
                    color: "#000",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#034d70",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#0a93d9",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#034d70",
                    },
                    "&:hover fieldset": {
                      borderColor: "#0a93d9",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0a93d9",
                    },
                    "& input:-webkit-autofill": {
                      WebkitBoxShadow: `0 0 0 100px #deebf0 inset !important`,
                      backgroundColor: "transparent !important",
                      color: "#000",
                    },
                    "& .MuiSelect-select": {
                      "&::-webkit-scrollbar": {
                        width: "8px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#2c7599",
                        borderRadius: "4px",
                      },
                      "&::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: "#6FA2BB",
                      },
                      "&::-webkit-scrollbar-track": {
                        backgroundColor: "#a1c6d4",
                      },
                      overflowX: "hidden",
                    },
                  },
                }}
              >
                {countries.map((country) => (
                  <MenuItem key={country} value={country}>
                    {country}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid item xs={12}>
              <Tooltip
                title="View in a different currency"
                open={currencyTooltipShown}
                placement={isSmallScreen ? "bottom" : "bottom"}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#034d70",
                      color: "#fff",
                      fontSize: "1em",
                      cursor: "pointer",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "#034d70",
                    },
                  },
                }}
              >
                <span>
                  <TextField
                    fullWidth
                    label="Currency"
                    value={currency}
                    onChange={handleCurrencyChange}
                    margin="normal"
                    select
                    SelectProps={{
                      MenuProps: {
                        PaperProps: {
                          style: {
                            backgroundColor: "#034d70",
                            color: "#fff",
                          },
                        },
                      },
                    }}
                    sx={{
                      input: {
                        color: "#000",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#034d70",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#0a93d9",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "#034d70",
                        },
                        "&:hover fieldset": {
                          borderColor: "#0a93d9",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#0a93d9",
                        },
                        "& input:-webkit-autofill": {
                          WebkitBoxShadow: `0 0 0 100px #deebf0 inset !important`,
                          backgroundColor: `transparent !important`,
                          color: "#000",
                        },
                        "& .MuiSelect-select": {
                          "&::-webkit-scrollbar": {
                            width: "8px",
                          },
                          "&::-webkit-scrollbar-thumb": {
                            backgroundColor: "#2c7599",
                            borderRadius: "4px",
                          },
                          "&::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: "#6FA2BB",
                          },
                          "&::-webkit-scrollbar-track": {
                            backgroundColor: "#a1c6d4",
                          },
                          overflowX: "hidden",
                        },
                      },
                    }}
                  >
                    <MenuItem value="USD">USD</MenuItem>
                    <MenuItem value="GBP">GBP</MenuItem>
                    <MenuItem value="EUR">EUR</MenuItem>
                    <MenuItem value="CAD">CAD</MenuItem>
                    <MenuItem value="AUD">AUD</MenuItem>
                    <MenuItem value="NZD">NZD</MenuItem>
                  </TextField>
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Global Price Range"
                value={formatPriceRange(priceRange)}
                margin="normal"
                disabled
                sx={{
                  input: {
                    color: "#000",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#034d70",
                  },
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "#0a93d9",
                  },
                  "& .MuiOutlinedInput-root": {
                    "& fieldset": {
                      borderColor: "#034d70",
                    },
                    "&:hover fieldset": {
                      borderColor: "#0a93d9",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "#0a93d9",
                    },
                    "& input:-webkit-autofill": {
                      WebkitBoxShadow: `0 0 0 100px #deebf0 inset !important`,
                      backgroundColor: "transparent !important",
                      color: "#000",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Tooltip
                title="Submit your Search"
                open={showRunRequestTooltip}
                placement={isSmallScreen ? "bottom" : "right"}
                arrow
                componentsProps={{
                  tooltip: {
                    sx: {
                      backgroundColor: "#034d70",
                      color: "#fff",
                      fontSize: "1em",
                      cursor: "pointer",
                    },
                  },
                  arrow: {
                    sx: {
                      color: "#034d70",
                    },
                  },
                }}
              >
                <Box sx={{ my: 1 }}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleRunRequest}
                    sx={{
                      backgroundColor: "#034d70",
                      "&:hover": {
                        backgroundColor: "#0a93d9",
                      },
                    }}
                  >
                    <Typography variant="button">Run request</Typography>
                  </Button>
                </Box>
              </Tooltip>
            </Grid>

            {pinnedGraphs.length >= 2 && (
              <Grid item xs={12}>
                <Tooltip
                  title="Merge selected graphs"
                  open={showMergeTooltip}
                  placement={isSmallScreen ? "bottom" : "right"}
                  arrow
                  componentsProps={{
                    tooltip: {
                      sx: {
                        backgroundColor: "#f57c00",
                        color: "#fff",
                        fontSize: "1em",
                        cursor: "pointer",
                      },
                    },
                    arrow: {
                      sx: {
                        color: "#f57c00",
                      },
                    },
                  }}
                >
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={handleMergeGraphs}
                    sx={{
                      backgroundColor: "#f57c00",
                      "&:hover": {
                        backgroundColor: "#ff9800",
                      },
                    }}
                  >
                    <Typography variant="button">Merge Graphs</Typography>
                  </Button>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        )}
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="warning"
          sx={{ backgroundColor: "#034d70", color: "#fff" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default MergingForm;
