import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Header from '../../universal_components/Header'
import SideCard from './components/SideCard'
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react'
import TitlePage from './report_components/TitlePage'
import MarketInsightsPage from './report_components/MarketInsightsPage'
import BoatPage from './report_components/BoatPage'
import SummaryPage from './report_components/SummaryPage'
import ContentsPage from './report_components/ContentsPage'
import { getReportById } from '../../requests/report/report'
import { getTheme } from '../../requests/theme'

import LandTitlePage from './lanscape_report_components/LandTitlePage'
import LandOverviewPage from './lanscape_report_components/LandOverviewPage'
import LandMarketInsightsPage from './lanscape_report_components/LandMarketInsightsPage'
import LandBoatPage from './lanscape_report_components/LandBoatPage'
import ComparisonPage from './lanscape_report_components/ComparisonPage'


export default function BuildReport() {

    const {state} = useLocation();

    const [theme, setTheme] = useState('rgb(2, 70, 128)');
    const [themePrimaryText, setThemePrimaryText] = useState('rgb(1, 151, 221)');
    const [themeSecondaryText, setThemeSecondaryText] = useState('rgb(255, 255, 255)');

    const [report, setReport] = useState(null);


    useEffect(() => {
        getReportById(
            state.report_id, 
            (data) => {
                console.log(data.report)
                setReport(data.report)
            },
            (data) => {
                console.log(data)
            }
        )
    }, [state.report_id])


    useEffect(() => {
        getTheme(
            (data) => {
                var themeMap = JSON.parse(data.theme_color)
                setTheme(themeMap.theme)
                setThemePrimaryText(themeMap.primaryText)
                setThemeSecondaryText(themeMap.secondaryText)
            },
            (data) => {
                console.log(data)
            }
        )
    }, [])


    function setThemeCallback(theme) {
        setTheme(theme.theme)
        setThemePrimaryText(theme.primaryText)
        setThemeSecondaryText(theme.secondaryText)
    }


    function addReportBoatCallback() {
        getReportById(
            state.report_id, 
            (data) => {
                setReport(data.report)
            },
            (data) => {
                console.log(data)
            }
        )
    }


    function handleMarketInsightsInclusionCallback() {
        getReportById(
            state.report_id, 
            (data) => {
                setReport(data.report)
            },
            (data) => {
                console.log(data)
            }
        )
    }

    function handleListingUrlsInclusionCallback() {
        getReportById(
            state.report_id, 
            (data) => {
                setReport(data.report)
            },
            (data) => {
                console.log(data)
            }
        )
    }


    function reloadReportCallback() {
        getReportById(
            state.report_id, 
            (data) => {
                console.log(data.report)
                setReport(data.report)
            },
            (data) => {
                console.log(data)
            }
        )
    }



    return (
        <>
            <Header signedIn={true} />
            <Container fluid style={{minHeight: '100vh', paddingBottom: '100px', backgroundColor: 'rgb(220, 220, 220)'}}>
                
                {
                report &&
                    <Row>
                        <Col lg={4} className='g-0' >
                            <SideCard 
                                report={report} 
                                addReportBoatCallback={addReportBoatCallback} 
                                handleMarketInsightsInclusionCallback={handleMarketInsightsInclusionCallback} 
                                setThemeCallback={setThemeCallback}
                                handleListingUrlsInclusionCallback={handleListingUrlsInclusionCallback}
                                />
                        </Col>
                        <Col lg={8} className="g-0" >
                            {
                                report.report_type === "portrait" ?

                                    <div style={{marginTop: '100px'}}>  
                                        <TitlePage 
                                            theme={theme} 
                                            themePrimaryText={themePrimaryText}
                                            themeSecondaryText={themeSecondaryText}
                                            report={report}
                                        />

                                        <ContentsPage 
                                            theme={theme} 
                                            themePrimaryText={themePrimaryText}
                                            themeSecondaryText={themeSecondaryText}
                                            report={report}
                                        />

                                        {
                                            report.market_insights && report.include_market_insights &&
                                            <MarketInsightsPage 
                                                theme={theme} 
                                                themePrimaryText={themePrimaryText}
                                                themeSecondaryText={themeSecondaryText}
                                                report={report} 
                                            />
                                        }

                                        {
                                            report.report_boats.map((boat, index) => {
                                                return <BoatPage
                                                    theme={theme} 
                                                    themePrimaryText={themePrimaryText}
                                                    themeSecondaryText={themeSecondaryText}
                                                    report={report} 
                                                    boat={boat} 
                                                    key={index}
                                                    reloadReportCallback={reloadReportCallback}
                                                />
                                                
                                            })
                                        }

                                        <SummaryPage 
                                            theme={theme} 
                                            themePrimaryText={themePrimaryText}
                                            themeSecondaryText={themeSecondaryText}
                                            report={report} 
                                        />
                                    </div>
                                :
                                    <div style={{marginTop: '100px'}}>
                                        <LandTitlePage
                                            theme={theme} 
                                            themePrimaryText={themePrimaryText}
                                            themeSecondaryText={themeSecondaryText}
                                            report={report}
                                            reloadReportCallback={reloadReportCallback}
                                        />
                                        <LandOverviewPage
                                            theme={theme}
                                            themePrimaryText={themePrimaryText}
                                            themeSecondaryText={themeSecondaryText}
                                            report={report}
                                            reloadReportCallback={reloadReportCallback}
                                        />
                                        {
                                            report.market_insights && report.include_market_insights &&
                                            <LandMarketInsightsPage
                                                theme={theme}
                                                themePrimaryText={themePrimaryText}
                                                themeSecondaryText={themeSecondaryText}
                                                report={report}
                                                reloadReportCallback={reloadReportCallback}
                                            />
                                        }
                                        {
                                            report.report_boats.map((boat, index) => {
                                                return <LandBoatPage
                                                    theme={theme} 
                                                    themePrimaryText={themePrimaryText}
                                                    themeSecondaryText={themeSecondaryText}
                                                    report={report} 
                                                    boat={boat} 
                                                    key={index}
                                                    reloadReportCallback={reloadReportCallback}
                                                />
                                                
                                            })
                                        }
                                        <ComparisonPage
                                            theme={theme}
                                            themePrimaryText={themePrimaryText}
                                            themeSecondaryText={themeSecondaryText}
                                            report={report}
                                        />
                                    </div>
                            }
                        </Col>
                    </Row>
                }
            </Container>
        </>
    )
}
