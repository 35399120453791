import React from 'react'
import LandscapePage from '../../../generic_report_components/LandscapePage'
import Image from 'react-bootstrap/Image'
import { useState, useEffect } from 'react';
import { getBrokerDetails} from '../../../requests/brokerDetails';
import { deleteReportBoat } from '../../../requests/report/reportBoat';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import BoatTitle from './boat_page_components/BoatTitle';
import BoatPrice from './boat_page_components/BoatPrice';
import BoatSpecs from './boat_page_components/BoatSpecs';
import MainImage from './boat_page_components/MainImage';
import ExtraImages from './boat_page_components/ExtraImages';


export default function LandBoatPage(props) {

    const [brokerName, setBrokerName] = useState(null);
    const [brokerEmail, setBrokerEmail] = useState(null);
    const [brokerPhone, setBrokerPhone] = useState(null);
    const [brokerLogoUrl, setBrokerLogoUrl] = useState(null);




    useEffect(() => {
        getBrokerDetails(
            (data) => {
                if (data.broker_details) {
                    setBrokerName(data.broker_details.name)
                    setBrokerEmail(data.broker_details.email)
                    setBrokerPhone(data.broker_details.phone)
                    setBrokerLogoUrl(data.broker_details.logo)
                }
            },
            (data) => {
                console.log(data)
            }
        )
    }, [])


    function handleDeleteBoat() {
        var fd = {
            id: props.boat.id
        }
    
        deleteReportBoat(fd, 
          (data) => {
            props.reloadReportCallback();
          },
          (data) => {
            console.log(data);
          }
        )
      }




    return (
        <div style={{marginBottom: '40px'}}>
            <LandscapePage
                includeFooter={true}
                brokerDetails={{name: brokerName, email: brokerEmail, phone: brokerPhone}}
                theme={{
                    theme:props.theme,
                    themePrimaryText:props.themePrimaryText,
                }}
            >

                <Container fluid>
                    <Row>
                        <Col xs={8} sm={8} md={8} lg={8} xl={8} className='g-0'>
                            <div>
                                <MainImage
                                    boat={props.boat}
                                    reloadReportCallback={props.reloadReportCallback}
                                />
                                
                            </div>
                            <ExtraImages
                                boat={props.boat}
                                reloadReportCallback={props.reloadReportCallback}
                            />

                        </Col>
                        <Col xs={4} sm={4} md={4} lg={4} xl={4} className='g-0'>
                            <div style={{width: '100%', textAlign: 'center'}} className='report-pt-2'>
                                <Image src={brokerLogoUrl} style={{width: '80%'}} />
                            </div>
                            <div className='report-pt-1 report-pl-1 report-pr-1'>
                                <BoatTitle 
                                    themePrimaryText={props.themePrimaryText}
                                    boat={props.boat}
                                    reloadReportCallback={props.reloadReportCallback}
                                />
                                <BoatPrice
                                    boat={props.boat}
                                    report={props.report}
                                    reloadReportCallback={props.reloadReportCallback}
                                />

                                <hr></hr>
                            </div>
                            <BoatSpecs
                                boat={props.boat}
                                reloadReportCallback={props.reloadReportCallback}
                            />
                            {
                                props.report.include_listing_url &&
                                <div className='report-p-1'>
                                    <p style={{overflowWrap: 'break-word'}} className='report-text-sm-landscape'>{props.boat.url}</p>
                                </div>
                            }
                        </Col>
                    </Row>
                </Container>
            </LandscapePage>
            <Container className='mild-neumorphic-card report-button-bar'>
                <Row>
                    <Col className='g-0'>
                        <Button className='button-bar-button danger-bg' style={{width: '100%', borderRadius: '0px'}} onClick={() => {handleDeleteBoat()}} >Remove Boat</Button>
                    </Col>
                    <Col className='g-0'>
                        <Button className='button-bar-button secondary-bg' style={{width: '100%', borderRadius: '0px'}} href={props.boat.url} rel="noreferrer" target='_blank'>View Listing</Button>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
