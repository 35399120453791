import React, { useEffect, useRef, useCallback } from "react";
import * as echarts from "echarts";
import { Typography, Box } from "@mui/material";

const PricingGraph = ({ data, title, currency, conversionRate, abbr }) => {
  const chartRef = useRef(null);

  const getCurrencySymbol = useCallback((abbr) => {
    const currencySymbols = {
      AUD: "$",
      USD: "$",
      EUR: "€",
      GBP: "£",
      NZD: "$",
      CAD: "$",
    };
    return currencySymbols[abbr] || abbr;
  }, []);

  useEffect(() => {
    if (data) {
      const chartDom = chartRef.current;
      const myChart = echarts.init(chartDom);

      const boatAges = Object.keys(data.price_curve || {});

      const upperBoundValues = Object.values(
        data.error_curve_upper_bound || {}
      ).map((value) => Math.round(value * conversionRate));
      const lowerBoundValues = Object.values(
        data.error_curve_lower_bound || {}
      ).map((value) => Math.round(value * conversionRate));
      const priceValues = Object.values(data.price_curve || {}).map((value) =>
        Math.round(value * conversionRate)
      );

      const formatNumber = (num) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      };

      const getGridLeftValue = () => {
        if (window.innerWidth >= 1200) {
          return "30%";
        } else if (window.innerWidth >= 960) {
          return "25%";
        } else {
          return "30%";
        }
      };

      const getYAxisNameGap = () => {
        if (window.innerWidth >= 960) {
          return 105;
        } else {
          return 71;
        }
      };

      const setChartOption = () => {
        const option = {
          grid: {
            left: getGridLeftValue(),
            right: "15%",
            top: "5%",
            bottom: "20%",
          },
          tooltip: {
            trigger: "axis",
            formatter: function (params) {
              const boatAge = params[0].name;
              const price = formatNumber(params[0].value);
              const upper = formatNumber(params[1].value);
              const lower = formatNumber(params[2].value);
              const symbol = getCurrencySymbol(abbr);
              return `<span style="color:#000; font-weight: bold;">Boat Age: ${boatAge}</span><br/><span style="color:#034d70; font-weight: bold;">Price: ${symbol}${price}</span><br/><span style="color:#0a93d9; font-weight: bold;">Upper Bound: ${symbol}${upper}</span><br/><span style="color:#57c6fb; font-weight: bold;">Lower Bound: ${symbol}${lower}</span>`;
            },
          },
          xAxis: {
            type: "category",
            data: boatAges,
            name: "Boat Age (Years)",
            nameLocation: "middle",
            nameGap: 40,
            nameTextStyle: {
              align: "left",
              padding: [0, 0, 0, -30],
              color: "#00557c",
              fontSize: "1.5em",
            },
            axisLabel: {
              color: "#034d70",
            },
          },
          yAxis: {
            type: "value",
            name: `Value (${abbr})`,
            nameLocation: "middle",
            nameGap: getYAxisNameGap(),
            nameTextStyle: {
              align: "left",
              padding: [0, 0, 0, -30],
              color: "#00557c",
              fontSize: "1.5em",
            },
            axisLabel: {
              formatter: function (value) {
                const symbol = getCurrencySymbol(abbr);
                return `${symbol}${formatNumber(value)}`;
              },
              color: "#034d70",
            },
          },
          series: [
            {
              name: "Price",
              data: priceValues,
              type: "line",
              smooth: true,
              lineStyle: {
                color: "#034d70",
              },
              itemStyle: {
                color: "#034d70",
              },
            },
            {
              name: "Upper Bound",
              data: upperBoundValues,
              type: "line",
              smooth: true,
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                color: "rgba(0, 147, 210, 0.2)",
                origin: "start",
              },
              emphasis: {
                focus: "series",
              },
              itemStyle: {
                color: "#0a93d9",
              },
            },
            {
              name: "Lower Bound",
              data: lowerBoundValues,
              type: "line",
              smooth: true,
              lineStyle: {
                opacity: 0,
              },
              areaStyle: {
                color: "rgba(0, 147, 210, 0.2)",
                origin: "end",
              },
              emphasis: {
                focus: "series",
              },
              itemStyle: {
                color: "#57c6fb",
              },
            },
          ],
        };

        myChart.setOption(option);
      };

      setChartOption();

      const resizeChart = () => {
        setChartOption();
        myChart.resize();
      };

      window.addEventListener("resize", resizeChart);

      return () => {
        myChart.dispose();
        window.removeEventListener("resize", resizeChart);
      };
    }
  }, [data, title, currency, conversionRate, abbr, getCurrencySymbol]);

  return (
    <Box sx={{ backgroundColor: "white", borderRadius: 2, py: 2 }}>
      <Typography
        variant="h4"
        gutterBottom
        sx={{
          textAlign: "center",
          color: "#034d70",
          textTransform: "capitalize",
        }}
      >
        {title}
      </Typography>
      <Box
        ref={chartRef}
        sx={{
          width: "100%",
          height: "40vh",
          borderRadius: 2,
        }}
      />
    </Box>
  );
};

export default PricingGraph;
