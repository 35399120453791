import React from 'react'
import { useSearchParams } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Image from 'react-bootstrap/Image'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { authCheckResetTokenValid, authResetPassword } from '../../requests/auth'

export default function ResetPassword() {

    const navigate = useNavigate()
    
    const [searchParams] = useSearchParams()

    const [tokenValid, setTokenValid] = useState(false)
    const [tokenChecked, setTokenChecked] = useState(false)


    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [passwordErrors, setPasswordErrors] = useState([])


    function tokenCheckedSuccess(data) {
        console.log(data)
        setTokenValid(true)
        setTokenChecked(true)
    }

    function tokenCheckedFailed(data) {
        console.log(data)
        setTokenValid(false)
        setTokenChecked(true)
    }


    useEffect(() => {
        var fd = {
            reset_token: searchParams.get('token')
        }
        authCheckResetTokenValid(fd, tokenCheckedSuccess, tokenCheckedFailed)
    }, [searchParams])
        



    function validatePassword() {
        setPasswordErrors([])
        let errors = []
        if (password.length === 0) {
            errors.push('Password is required')
        }
        if (password !== confirmPassword) {
            errors.push('Passwords must match')
        }
        setPasswordErrors(errors)
        if (errors.length === 0) {
            return true
        } else {
            return false
        }
    }


    function handleResetPasswordSuccess(data) {
        navigate('/')
    }


    function handleResetPasswordFailed(data) {
        console.log(data)
    }




    function handleResetPassword() {
        if (validatePassword()) {
            var fd = {
                reset_token: searchParams.get('token'),
                password: password
            }

            authResetPassword(fd, handleResetPasswordSuccess, handleResetPasswordFailed)
        }
    }


    return (
        <Container fluid>
            <Row>
                <Col className='g-0'>
                <div style={{
                    backgroundImage: 'url(./classic_yacht.jpg)', 
                    minHeight: '100vh', 
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundAttachment: 'fixed'
                }}>
                    <div style={{
                        backgroundColor: 'rgba(11, 25, 34, 0.7)',
                        minHeight: '100vh',
                        paddingTop: '200px',
                    }}>
                        {
                            tokenChecked ? (
                                <>
                                {
                                    tokenValid ? (
                                        <div className='mild-neumorphic-card' style={{padding: '40px', maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto', backgroundColor: 'white'}}>
                                            <div style={{width: '100%', textAlign: 'center'}}>
                                                <Image src="/new_logo_trans.png" style={{width: '200px', marginBottom: '40px'}} />
                                            </div>
                                            <h1>Create Password</h1>
                                            <div>
                                                <h5 style={{marginTop: '40px'}}>{searchParams.get('email')}</h5>
                                                <Form.Control value={password} onChange={(e) => {setPassword(e.target.value)}} style={{marginTop: '40px'}} type='password' placeholder='New Password' />
                                                <Form.Control value={confirmPassword} onChange={(e) => {setConfirmPassword(e.target.value)}} style={{marginTop: '20px'}} type='password' placeholder='Confirm New Password' />
                                                <div>
                                                    {
                                                        passwordErrors.map((error, index) => {
                                                            return <p key={index} style={{color: 'red'}}>{error}</p>
                                                        })
                                                    }
                                                </div>
                                                <div>
                                                    <Button onClick={() => {handleResetPassword()}} className="primary-bg" style={{marginTop: '40px', width: '100%'}}>Create Password</Button>
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className='mild-neumorphic-card' style={{padding: '40px', maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto', backgroundColor: 'white'}}>
                                            <div style={{width: '100%', textAlign: 'center'}}>
                                                <Image src="/new_logo_trans.png" style={{width: '200px', marginBottom: '40px'}} />
                                            </div>
                                            <h1>Invalid Token</h1>
                                            <div>
                                                <p>The create password token you provided is invalid or has expired. Please request a new password reset.</p>
                                            </div>
                                        </div>
                                    )
                                }
                                </>
                            ) : (
                                null
                            )
                        }
                    </div>
                </div>
                </Col>
            </Row>
        </Container>
    )
}