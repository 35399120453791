import React, {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback,
} from "react";
import * as echarts from "echarts";
import { Typography, Box, IconButton, Tooltip, Slider } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const MergedGraph2 = React.memo(
  ({
    dataSets,
    title,
    currency,
    abbr,
    onDelete,
    color,
    makeModelList,
    minX,
    maxX,
  }) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);
    const [zoom, setZoom] = useState(0);
    const [maxZoomReached, setMaxZoomReached] = useState(false);
    const [initialCurrency] = useState(abbr); 

    const isSmallScreen = useCallback(() => {
      return window.matchMedia("(max-width: 600px)").matches;
    }, []);

    const currencySymbols = useMemo(
      () => ({
        AUD: "$",
        USD: "$",
        EUR: "€",
        GBP: "£",
        NZD: "$",
        CAD: "$",
      }),
      []
    );

    const getCurrencySymbol = useCallback(
      (abbr) => {
        return currencySymbols[abbr] || abbr;
      },
      [currencySymbols]
    );

    const roundToNearestDecimal = useCallback((num) => {
      return Math.round(num * 10) / 10;
    }, []);

    const formatNumber = useCallback((num) => {
      if (num === null || num === undefined) return "";
      if (isSmallScreen()) {
        if (num >= 1000000000) return `${(num / 1000000000).toFixed(1)}B`;
        if (num >= 1000000) return `${(num / 1000000).toFixed(1)}M`;
        if (num >= 1000) return `${(num / 1000).toFixed(1)}K`;
        return num.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }, [isSmallScreen]);

    const alignDataToXAxis = useCallback(
      (xValues, data) => {
        const alignedData = Array.from({ length: maxX - minX + 1 }, () => null);
        xValues.forEach((xValue, i) => {
          alignedData[xValue - minX] = data[i];
        });
        return alignedData;
      },
      [minX, maxX]
    );

    const setChartOptions = useCallback(() => {
      if (!chartInstance.current) return;

      const colorSets = {
        blue: {
          price: "#219ebc",
          upperBound: "#023047",
          lowerBound: "#77d1fc",
          area: "rgba(0, 147, 210, 0.2)",
        },
        orange: {
          price: "#ff5821",
          upperBound: "#e82d17",
          lowerBound: "#ffb703",
          area: "rgba(255, 87, 51, 0.2)",
        },
        green: {
          price: "#2a9d8f",
          upperBound: "#1f6f61",
          lowerBound: "#84ccab",
          area: "rgba(42, 157, 143, 0.2)",
        },
      };

      const getColorSet = (index) => {
        return colorSets[
          index % 3 === 0 ? "blue" : index % 3 === 1 ? "orange" : "green"
        ];
      };

      const series = dataSets.flatMap((dataSet, index) => {
        const boatAges = Object.keys(dataSet.price_curve || {}).map(Number);
        const priceValues = alignDataToXAxis(
          boatAges,
          Object.values(dataSet.price_curve || {})
        );
        const upperBoundValues = alignDataToXAxis(
          boatAges,
          Object.values(dataSet.error_curve_upper_bound || {})
        );
        const lowerBoundValues = alignDataToXAxis(
          boatAges,
          Object.values(dataSet.error_curve_lower_bound || {})
        );

        const colors = getColorSet(index);

        return [
          {
            name: `Price ${makeModelList[index]}`,
            data: priceValues,
            type: "line",
            smooth: true,
            lineStyle: {
              color: colors.price,
            },
            itemStyle: {
              color: colors.price,
            },
          },
          {
            name: `Upper Bound ${makeModelList[index]}`,
            data: upperBoundValues,
            type: "line",
            smooth: true,
            lineStyle: {
              opacity: 0,
            },
            areaStyle: {
              color: colors.area,
              origin: "start",
            },
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              color: colors.upperBound,
            },
          },
          {
            name: `Lower Bound ${makeModelList[index]}`,
            data: lowerBoundValues,
            type: "line",
            smooth: true,
            lineStyle: {
              opacity: 0,
            },
            areaStyle: {
              color: colors.area,
              origin: "end",
            },
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              color: colors.lowerBound,
            },
          },
        ];
      });

      chartInstance.current.setOption({
        grid: {
          left: "30%",
          right: "15%",
          top: "5%",
          bottom: "20%",
        },
        tooltip: {
          trigger: "axis",
          confine: true,
          formatter: function (params) {
            const tooltipItems = params
              .map((param, index) => {
                const symbol = getCurrencySymbol(initialCurrency);
                const colors = getColorSet(Math.floor(index / 3));
                if (param.value === null || param.value === undefined) {
                  return null;
                }
                return `<span style="color:${
                  param.seriesName.includes("Price")
                    ? colors.price
                    : param.seriesName.includes("Upper Bound")
                    ? colors.upperBound
                    : colors.lowerBound
                }; font-weight: bold; text-transform: uppercase;">${
                  param.seriesName
                }: ${symbol}${param.value
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>`;
              })
              .filter(Boolean);
            return `<span style="color:#000; font-weight: bold;">BOAT AGE: ${
              params[0].name
            }</span><div>${tooltipItems.join("<br/>")}</div>`;
          },
        },
        xAxis: {
          type: "category",
          data: Array.from({ length: maxX - minX + 1 }, (_, i) => i + minX),
          name: "Boat Age (Years)",
          nameLocation: "middle",
          nameGap: 40,
          nameTextStyle: {
            align: "center",
            padding: [0, 0, 0, -30],
            color: "#00557c",
            fontSize: "1.2em",
          },
          axisLabel: {
            color: "#034d70",
          },
        },
        yAxis: {
          type: "value",
          name: `Value (${initialCurrency})`,
          nameLocation: "middle",
          nameGap: 83,
          nameTextStyle: {
            align: "left",
            padding: [0, 0, 0, -30],
            color: "#00557c",
            fontSize: "1.2em",
          },
          axisLabel: {
            formatter: function (value) {
              const symbol = getCurrencySymbol(initialCurrency);
              return `${symbol}${formatNumber(value)}`;
            },
            color: "#034d70",
          },
        },
        series,
        dataZoom: [
          {
            type: "inside",
            zoomOnMouseWheel: false,
            moveOnMouseMove: true,
            moveOnTouch: true,
            start: 0,
            end: 100 / (1 + zoom * 1.5),
          },
          {
            type: "slider",
            show: false,
          },
        ],
      });
    }, [
      dataSets,
      initialCurrency,
      minX,
      maxX,
      getCurrencySymbol,
      formatNumber,
      alignDataToXAxis,
      makeModelList,
      zoom,
    ]);

    const initializeChart = useCallback(() => {
      if (!chartInstance.current) {
        chartInstance.current = echarts.init(chartRef.current);
      }
      setChartOptions();
    }, [setChartOptions]);

    useEffect(() => {
      initializeChart();

      const resizeChart = () => {
        if (chartInstance.current) {
          chartInstance.current.resize();
        }
      };

      window.addEventListener("resize", resizeChart);

      return () => {
        window.removeEventListener("resize", resizeChart);
      };
    }, [initializeChart]);

    useEffect(() => {
      setChartOptions();
    }, [dataSets, setChartOptions]);

    const handleZoomChange = useCallback(
      (event, newValue) => {
        const roundedZoom = roundToNearestDecimal(newValue);
        if (roundedZoom >= 2.5) {
          setMaxZoomReached(true);
          setTimeout(() => setMaxZoomReached(false), 2000);
        }
        setZoom(roundedZoom);
        if (chartInstance.current) {
          chartInstance.current.dispatchAction({
            type: "dataZoom",
            start: 0,
            end: 100 / (1 + roundedZoom * 1.5),
          });
        }
      },
      [roundToNearestDecimal]
    );

    const handleWheel = useCallback(
      (event) => {
        event.preventDefault();
        let newZoom = zoom;
        if (event.deltaY < 0) {
          // scrolling up
          newZoom = roundToNearestDecimal(Math.min(zoom + 0.1, 2.5));
        } else if (event.deltaY > 0) {
          // scrolling down
          newZoom = roundToNearestDecimal(Math.max(zoom - 0.1, 0));
        }
        setZoom(newZoom);
        if (chartInstance.current) {
          chartInstance.current.dispatchAction({
            type: "dataZoom",
            start: 0,
            end: 100 / (1 + newZoom * 1.5),
          });
        }
      },
      [zoom, roundToNearestDecimal]
    );

    useEffect(() => {
      const chartElement = chartRef.current;
      chartElement.addEventListener("wheel", handleWheel);

      return () => {
        chartElement.removeEventListener("wheel", handleWheel);
      };
    }, [handleWheel]);

    return (
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: 2,
          mb: 0,
          position: "relative",
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            textTransform: "uppercase",
            textAlign: "center",
            p: 1,
            color: "#034d70",
            fontSize: "1.5em",
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>
        <Tooltip title="Remove" placement="left" arrow>
          <IconButton
            onClick={onDelete}
            sx={{ position: "absolute", top: 1, right: 0.5 }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>
        <Box
          ref={chartRef}
          sx={{
            width: "100%",
            height: { xs: "50vh", sm: "50vh", lg: "35vh" },
            borderRadius: 2,
          }}
        />
        <Box sx={{ width: "33%", mt: 2, mx: "auto", position: "relative" }}>
          <Typography variant="body2" color="textSecondary" align="center">
            Zoom Level: {zoom}x
          </Typography>
          <Slider
            value={zoom}
            min={0}
            max={2.5}
            step={0.1}
            onChange={handleZoomChange}
            valueLabelDisplay="auto"
            marks
            sx={{ color: "#034d70" }}
          />
          {maxZoomReached && (
            <Tooltip
              title="Maximum zoom reached"
              open={maxZoomReached}
              placement="top"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    backgroundColor: "#ff5722",
                    color: "#fff",
                    fontSize: "1em",
                    cursor: "pointer",
                  },
                },
                arrow: {
                  sx: {
                    color: "#ff5722",
                  },
                },
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "-30px",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
              />
            </Tooltip>
          )}
        </Box>
      </Box>
    );
  }
);

export default MergedGraph2;
