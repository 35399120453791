import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useNavigate } from 'react-router-dom';


export default function SavedBoats(props) {

    const navigate = useNavigate();

    function handleGoToSavedBoats() {
        navigate('/saved-boats', {state: {client_profile_id: props.clientProfile.id}})
    }

    return (
        <div className='clickable-card' style={{backgroundColor:'white', marginBottom: '30px'}}>
            <div style={{width: '100%'}}>
                <Container fluid style={{backgroundColor: 'rgb(240, 240, 240)', padding: '15px'}}>
                    <Row>
                        <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                            <div onClick={() => {handleGoToSavedBoats()}}>
                                <h2 className='styled-header'>Saved Boats</h2>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid>
                    <Row>
                        <Col md={12} lg={12}>
                            <div style={{padding: '15px'}}>
                                {
                                    props.myBoatsCount ?
                                        <h4>{props.myBoatsCount} { props.myBoatsCount === 1 ?<span>Boat</span> : <span>Boats</span> } Saved</h4>
                                    :
                                        <h4>No boats saved</h4>
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
    )
}
