import React from 'react'
import Header from '../../universal_components/Header'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { createClientProfile } from '../../requests/clientProfile'
import { getClientProfiles, getClientProfilesArchived, archiveClientProfile, unarchiveClientProfile } from '../../requests/clientProfile'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'


export default function MyClients() {

    const navigate = useNavigate()

    const [clientProfiles, setClientProfiles] = useState([])
    const [archivedClientProfiles, setArchivedClientProfiles] = useState([])

    const [showAddClientModal, setShowAddClientModal] = useState(false)

    const [showArchiveClientModal, setShowArchiveClientModal] = useState(false)

    const [showArchivedClientsModal, setShowArchivedClientsModal] = useState(false)


    const [clientName, setClientName] = useState('')
    const [clientEmail, setClientEmail] = useState('')
    const [clientPhone, setClientPhone] = useState('')
    const [clientRequiredLength, setClientRequiredLength] = useState(null)
    const [clientBudget, setClientBudget] = useState('')
    const [clientCurrency, setClientCurrency] = useState('USD')


    const [clientNameError, setClientNameError] = useState(null)
    const [clientEmailError, setClientEmailError] = useState(null)
    const [clientPhoneError, setClientPhoneError] = useState(null)
    const [clientRequiredLengthError, setClientRequiredLengthError] = useState(null)
    const [clientBudgetError, setClientBudgetError] = useState(null)
    const [clientCurrencyError, setClientCurrencyError] = useState(null)

    const [clientToArchive, setClientToArchive] = useState(null)


    useEffect(() => {
        getClientProfiles(
            (data) => {
                console.log(data)
                setClientProfiles(data.client_profiles)
            },
            (data) => {
                console.log(data)
            }
        )
    }, [])


    function reloadClientProfiles() {
        getClientProfiles(
            (data) => {
                console.log(data)
                setClientProfiles(data.client_profiles)
            },
            (data) => {
                console.log(data)
            }
        )
    }


    function validateCreateClientProfile() {
        var valid = true
        
        if (clientName === '') {
            setClientNameError('Client name cannot be empty')
            valid = false
        } else {
            setClientNameError(null)
        }

        if (clientEmail === '') {
            setClientEmailError('Client email cannot be empty')
            valid = false
        } else {
            setClientEmailError(null)
        }

        if (clientPhone === '') {
            setClientPhoneError('Client phone cannot be empty')
            valid = false
        } else {
            setClientPhoneError(null)
        }

        if (clientRequiredLength === null) {
            setClientRequiredLengthError('Client required length cannot be empty')
            valid = false
        } else {
            setClientRequiredLengthError(null)
        }

        if (clientBudget === '') {
            setClientBudgetError('Client budget cannot be empty')
            valid = false
        } else {
            setClientBudgetError(null)
        }

        if (clientCurrency === '') {
            setClientCurrencyError('Client currency cannot be empty')
            valid = false
        } else {
            setClientCurrencyError(null)
        }

        return valid
    }




    function handleCreateClientProfile() {

        if (!validateCreateClientProfile()) {
            return
        }

        var fd = {
            name: clientName,
            email: clientEmail,
            phone: clientPhone,
            required_length: clientRequiredLength,
            budget: clientBudget,
            currency: clientCurrency
        }

        createClientProfile(
            fd,
            (data) => {
                console.log(data)
                setShowAddClientModal(false)
                reloadClientProfiles()
                setClientName('')
                setClientEmail('')
                setClientPhone('')
                setClientRequiredLength(null)
                setClientBudget('')
                setClientCurrency('USD')
            },
            (data) => {
                console.log(data)
            }
        )
    }


    function handleGoToClient(clientProfile) {
        navigate('/client-page', {state: {client_profile_id: clientProfile.id}});
    }


    function handleArchiveClient() {
        archiveClientProfile(
            clientToArchive.id,
            (data) => {
                console.log(data)
                setShowArchiveClientModal(false)
                reloadClientProfiles()
            },
            (data) => {
                console.log(data)
            }
        )
    }


    function handleGetArchivedClients() {
        getClientProfilesArchived(
            (data) => {
                console.log(data)
                setArchivedClientProfiles(data.client_profiles)
            },
            (data) => {
                console.log(data)
            }
        )
    }



    function handleOpenArchivedClientsModal() {
        setShowArchivedClientsModal(true)
        handleGetArchivedClients()
    }


    function handleUnarchiveClient(clientProfile) {
        unarchiveClientProfile(
            clientProfile.id,
            (data) => {
                console.log(data)
                handleGetArchivedClients()
                reloadClientProfiles()
            },
            (data) => {
                console.log(data)
            }
        )
    }




    return (
    <>
        <Header signedIn={true} />
        <Container fluid style={{minHeight: '100vh', backgroundColor: 'rgb(220, 220, 220)'}}>
            
            <Row>
                <Col md={12} lg={3} className='g-0'>
                    <div className='sidecard'>
                        <div style={{
                            backgroundImage: 'url(./landing-img.jpeg)', 
                            width: '100%', 
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            textAlign: 'center',
                            paddingTop: '10vh',
                            paddingBottom: '10vh'
                        }}>
                            <h1 className='styled-header'>My Clients</h1>
                        </div>
                        <div style={{height: '1px', width: '100%', backgroundColor: 'gray'}}></div>
                        <div style={{textAlign: 'center', padding: '30px'}}>
                            <h4>Add, view or archive your clients</h4>
                        </div>
                        <div style={{width: '100%', textAlign: 'center'}}>
                            <Button style={{width: '50%'}} className='primary-bg' onClick={() => {setShowAddClientModal(true)}}>Add Client</Button>
                        </div>
                        <div style={{width: '100%', textAlign: 'center', marginTop: '30px'}}>
                            <Button style={{width: '50%'}} className='secondary-bg' onClick={() => {handleOpenArchivedClientsModal()}}>Archived Clients</Button>
                        </div>
                    </div>
                    
                </Col>
                <Col lg={7}>
                    <Row className='content-section'>

                        {
                            clientProfiles.map((clientProfile) => {

                                return <Col md={12} lg={4} className='g-0 responsive-col-padding'>
                                    <div className='clickable-card' 
                                        style={{
                                                backgroundColor:'white', 
                                                width: '100%', 
                                                marginBottom: '40px'
                                            }}
                                        >
                                        <div style={{textAlign: 'center', padding: '20px', backgroundColor: 'rgb(240, 240, 240)'}} onClick={() => {handleGoToClient(clientProfile)}}>
                                            <Image className='mild-neumorphic-card' src='/profile-placeholder.png' style={{width: '40%', borderRadius: '50%'}} />
                                            <h4 className='styled-header' style={{paddingTop: '20px'}}>{clientProfile.name}</h4>
                                        </div>
                                        <div style={{width: '100%', height: '1px', backgroundColor: 'gray'}}></div>
                                        <div style={{textAlign: 'center', padding: '20px'}}>
                                            <div onClick={() => {handleGoToClient(clientProfile)}}>
                                                <h6 style={{paddingTop: '10px'}}>{clientProfile.email}</h6>
                                                <h6 style={{paddingTop: '10px'}}>{clientProfile.phone}</h6>
                                            </div>
                                            <div style={{textAlign: 'center', marginTop: '40px'}}>
                                                <Button onClick={() => {setClientToArchive(clientProfile); setShowArchiveClientModal(true)}} className='danger-bg'>Archive</Button>
                                            </div>
                                        </div>
                                    </div>
                                </Col>  
                            })
                        }
                          
                    </Row>                    
                </Col>
            </Row>
            
        </Container>
        <Modal show={showAddClientModal} onHide={() => setShowAddClientModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title className="styled-header">Add Client</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <label>Client Name *</label>
                <Form.Control value={clientName} onChange={(e) => {setClientName(e.target.value)}} type="text" placeholder="Client Name" />
                {
                    clientNameError &&
                    <p style={{color: 'red', fontSize: '12px'}}>{clientNameError}</p>
                }


                <label style={{marginTop: '20px'}}>Client Email *</label>
                <Form.Control value={clientEmail} onChange={(e) => {setClientEmail(e.target.value)}} type="text" placeholder="Client Email" />
                {
                    clientEmailError &&
                    <p style={{color: 'red', fontSize: '12px'}}>{clientEmailError}</p>
                }        

                <label style={{marginTop: '20px'}}>Client Phone *</label>
                <Form.Control value={clientPhone} onChange={(e) => {setClientPhone(e.target.value)}} type="text" placeholder="Client Phone" />
                {
                    clientPhoneError &&
                    <p style={{color: 'red', fontSize: '12px'}}>{clientPhoneError}</p>
                }

                <label style={{marginTop: '30px'}}>Length of Boat (metres) *</label>
                <Form.Control value={clientRequiredLength} onChange={(e) => {setClientRequiredLength(e.target.value)}} type="number" placeholder='Required Length' />
                {
                    clientRequiredLengthError &&
                    <p style={{color: 'red', fontSize: '12px'}}>{clientRequiredLengthError}</p>
                }

                <label style={{marginTop: '20px'}}>Budget *</label>
                <Row>
                    <Col>
                        <Form.Control value={clientBudget} onChange={(e) => {setClientBudget(e.target.value)}} type="number" placeholder="Budget" />
                    </Col>
                    <Col>
                        <Form.Control value={clientCurrency} onChange={(e) => {setClientCurrency(e.target.value)}} as="select">
                            <option>USD</option>
                            <option>EUR</option>
                            <option>GBP</option>
                            <option>NZD</option>
                            <option>AUD</option>
                        </Form.Control>
                    </Col>
                </Row>
                {
                    clientBudgetError &&
                    <p style={{color: 'red', fontSize: '12px'}}>{clientBudgetError}</p>
                }
                {
                    clientCurrencyError &&
                    <p style={{color: 'red', fontSize: '12px'}}>{clientCurrencyError}</p>
                }


            </Modal.Body>
            <Modal.Footer>
                <Button className='secondary-bg' onClick={() => setShowAddClientModal(false)}>
                    Cancel
                </Button>
                <Button className='primary-bg' onClick={() => handleCreateClientProfile()}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showArchiveClientModal} onHide={() => setShowArchiveClientModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title className="styled-header">Archive Client</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    clientToArchive &&
                    <p>Are you sure you want to archive {clientToArchive.name}?</p>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button className='secondary-bg' onClick={() => setShowArchiveClientModal(false)}>
                    Cancel
                </Button>
                <Button className='danger-bg' onClick={() => {handleArchiveClient()}}>
                    Archive
                </Button>
            </Modal.Footer>
        </Modal>

        <Modal show={showArchivedClientsModal} onHide={() => setShowArchivedClientsModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title className="styled-header">Archived Clients</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                {
                    archivedClientProfiles.map((clientProfile) => {
                        return (
                            <>
                                <Row style={{marginBottom: '20px'}}>
                                    <Col>
                                        <h4>{clientProfile.name}</h4>
                                    </Col>
                                    <Col>
                                        <Button onClick={() => {handleUnarchiveClient(clientProfile)}} className='primary-bg'>Unarchive</Button>
                                    </Col>
                                </Row>
                                <hr />
                            </>
                        )    
                    })
                }
                
            </Modal.Body>
        </Modal>

    </>
  )
}
