import React from 'react'
import { adminGetAllAccounts } from '../../../requests/admin'
import { useState, useEffect } from 'react'
import AccountCard from './AccountCard'



export default function ListAccounts() {

    const [accounts, setAccounts] = useState([])

    useEffect(() => {
        adminGetAllAccounts(
            (data) => {
                setAccounts(data.accounts)
            },
            (data) => {
                console.log(data)
            }
        )
    }, [])


    function reloadAccountsCallback() {
        adminGetAllAccounts(
            (data) => {
                setAccounts(data.accounts)
            },
            (data) => {
                console.log(data)
            }
        )
    }


    return (
        <div>
            {
                accounts.map((account, index) => {
                    return (
                        <AccountCard key={account.id} account={account} reloadAccountsCallback={reloadAccountsCallback} />
                    )
                })
            }
        </div>
    )
}
