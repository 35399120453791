import React from 'react'
import Page from '../../../../generic_report_components/Page';
import ColorCut from '../../../../generic_report_components/ColorCut';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone'
import { createAppraisedBoatExtraImage, deleteAppraisedBoatExtraImage } from '../../../../requests/appraisal_report/appraisedBoat';


export default function ExtraImages (props) {

    const [showManageImagesModal, setShowManageImagesModal] = useState(false);
    const [imageToUpload, setImageToUpload] = useState('./placeholder.jpg');

    const [extraImages, setExtraImages] = useState(props.appraisedBoat.extra_images);

    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            var file = acceptedFiles[0];
            if(file){
                var reader = new FileReader();
    
                reader.onload = function(e){
                    setImageToUpload(e.target.result)
                }
    
                reader.readAsDataURL(file);
            }
        }
    });


    function handleAddImage() {
    
        var fd = {
            appraised_boat_id: props.appraisedBoat.id,
            image: imageToUpload
        }

        setImageToUpload('./placeholder.jpg')

        createAppraisedBoatExtraImage(fd, 
            (data) => {
                setExtraImages([...extraImages, data.appraised_boat_extra_image])
            },
            (data) => {
                console.log(data);
            }
        )

    }


    function handleDeleteImage(image_id) {
        var fd = {
            appraised_boat_extra_image_id: image_id
        }

        deleteAppraisedBoatExtraImage(fd, 
            (data) => {
                setExtraImages(extraImages.filter((image) => {
                    return image.id !== image_id
                }))
            },
            (data) => {
                console.log(data);
            }
        )
    }


    return (
        <div style={{paddingTop: '40px'}}>
            <Page>
                <ColorCut theme={props.theme} height='10%' rightCut='80%'>
                    <div className='report-p-2'>
                        <h1 className='report-title' style={{color: props.themePrimaryText}}>Images</h1>
                    </div>
                </ColorCut>
                <Row>

                    {
                        extraImages.map((image, index) => {
                        return (
                            <Col key={index} xs={6} sm={6} md={6} lg={6} xl={6}>
                                <div className='report-p-1' onClick={() => {setShowManageImagesModal(true)}}>
                                    <div
                                    className='extra-image'
                                    style={{
                                        backgroundImage: `url(${image.image_url})`,
                                        backgroundRepeat: 'no-repeat',
                                        backgroundPosition: 'center',
                                        backgroundSize: 'cover',
                                        width: '100%',
                                    }}>

                                    </div>
                                </div>
                            </Col>
                        )
                        })
                    }
                    
                </Row>
            </Page>
            <Container className='mild-neumorphic-card report-button-bar'>
                <Row>
                <Col className='g-0'>
                    <Button className='button-bar-button primary-bg' style={{width: '100%', borderRadius: '0px'}} onClick={() => {setShowManageImagesModal(true)}} >Manage Images</Button>
                </Col>
                </Row>
            </Container>

            <Modal size="lg" show={showManageImagesModal} onHide={() => {setShowManageImagesModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Manage Images</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            <h4>Images (Add up to 10)</h4>
                            {
                                extraImages.length > 0 ? 
                                    <Row style={{marginBottom: '40px'}}>
                                        {
                                            extraImages.map((image, index) => {
                                                return (
                                                    <Col key={index} md={4} lg={4}>
                                                        <Image onClick={() => {handleDeleteImage(image.id)}} className='mild-neumorphic-card hero-image' src={image.image_url} style={{width: '100%', height: 'auto', marginTop: '20px'}}/>
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>
                                :
                                <h6 style={{marginBottom: '40px'}}>No Images Added</h6>
                            }
                            <h4>Upload Image</h4>
                            <p style={{marginTop: '20px'}}>Image</p>
                            <div className="image-upload" style={{textAlign: 'center', marginTop:'40px'}} {...getRootProps()}  >
                                <Image className='upload-image mild-neumorphic-card' id="previewImg" src={imageToUpload} style={{width: '50%'}} />
                                <input {...getInputProps()} />
                            </div>
                            <div style={{width: '100%', textAlign: 'center'}}>
                                {
                                    imageToUpload !== './placeholder.jpg' ?
                                        <Button style={{marginTop: '20px'}} className="primary-bg" onClick={() => {handleAddImage()}}>Add Image</Button>
                                    :
                                        null
                                }
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="secondary-bg" onClick={() => {setShowManageImagesModal(false)}}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
