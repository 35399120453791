import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import SharedFilters from './SharedFilters'
import { useState } from 'react'

import { keywordSearch } from '../../../requests/search'


export default function KeywordSearchCard(props) {
    
    const [keywords, setKeywords] = useState('')

    const [boatType, setBoatType] = useState('Any');

    const [orderBy, setOrderBy] = useState('---');

    const [sharedFilters, setSharedFilters] = useState({})

    function runSearch() {
        if (keywords !== '') {
            var fd = sharedFilters

            if (boatType !== 'Any') {
                fd['boat_type'] = boatType
            }

            if (orderBy !== '---') {
                fd['order_by'] = orderBy
            }

            fd['keywords'] = keywords;
            fd['page'] = 1;

            props.handleSearchStart('keyword', fd)

            keywordSearch(fd, 
                (data) => {
                    props.handleSearchResults(data.results, 1)
                },
                (data) => {
                    console.log(data)
                }
            )
        }
    }

    function handleChangeSearchFilters(newFilters) {
        setSharedFilters(newFilters)
    }

    return (
        <Container fluid style={{paddingBottom: '50px'}}>
            <Row>
                <Col className='g-0'>
                    <div style={{paddingTop: '30px', paddingBottom: '30px', paddingLeft: '60px', paddingRight: '60px', backgroundColor: 'rgb(240, 240, 240)'}}>
                        
                        <InputGroup>
                            <Form.Control value={keywords} onChange={(e) => {setKeywords(e.target.value)}} type="text" placeholder="keywords" />
                            <Button onClick={() => {runSearch()}} className="primary-bg">Search</Button>
                        </InputGroup>
                        <div style={{marginTop: '20px'}}>
                            <p style={{color: 'black'}}>Order By</p>
                            <Form.Control as='select' onChange={(e) => {setOrderBy(e.target.value)}}>
                                <option value='---'>---</option>
                                <option value='price-low'>Price (Low - High)</option>
                                <option value='price-high'>Price (High - Low)</option>
                            </Form.Control>
                        </div>
                    </div>
                    <div style={{height: '1px', width: '100%', backgroundColor: 'gray'}}></div>

                    <div style={{textAlign: 'center', paddingTop: '30px'}}>
                        <h3 className='styled-header'>Filters</h3>
                    </div>

                    <div style={{paddingLeft: '60px', paddingRight: '60px'}}>

                        <div style={{marginTop: '20px'}}>
                            <p style={{color: 'black'}}>Boat Type</p>
                            <Form.Control as='select' onChange={(event) => {setBoatType(event.target.value)}}>
                                <option value='Any' selected>Any</option>
                                <option value='Powerboat'>Powerboat</option>
                                <option value='Yacht'>Yacht</option>
                            </Form.Control>
                        </div>
                        
                    </div>
                    <SharedFilters handleChangeSearchFilters={handleChangeSearchFilters} />

                </Col>
            </Row>
        </Container>
    )
}
