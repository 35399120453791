import { baseApiUrl } from "../settings";
import { 
    tokenRequiredRequest,
    tokenRequiredRequestWithBody
} from "./baseRequest";



export const createClientRequirement = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/client-requirements`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}




export const getClientRequirements = (clientProfileId, successCallback, failedCallback) => {
    return tokenRequiredRequest(
        `${baseApiUrl}/client-requirements?client_profile_id=${clientProfileId}`, 
        'GET', 
        successCallback, 
        failedCallback)
}



export const updateClientRequirement = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/client-requirements`,
        'PUT',
        fd,
        successCallback,
        failedCallback)
}





export const deleteClientRequirement = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/client-requirements`, 
        'DELETE', 
        fd,
        successCallback, 
        failedCallback)
}