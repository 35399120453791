import { baseApiUrl } from "../../settings";
import { 
    tokenRequiredRequestWithBody
} from "../baseRequest";




export const createReportBoatField = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/report-boats/fields`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}



export const updateReportBoatField = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/report-boats/fields`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}



export const deleteReportBoatField = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/report-boats/fields`, 
        'DELETE', 
        fd, 
        successCallback, 
        failedCallback)
}

