import apiClient from "./apiClient";

export const getAllBoats = async () => {
  try {
    const response = await apiClient.get(
      "knowledge-engine/all-makes-and-models-with-pricing-available"
    );
    return response;
  } catch (error) {
    console.error("Error fetching boats:", error.message);
    throw error;
  }
};

export const getPricingData = async (make, model, year, currency) => {
  try {
    const response = await apiClient.get(
      `knowledge-engine/pricing?make=${make}&model=${model}&year=${year}&currency=${currency}`
    );
    return response;
  } catch (error) {
    console.error("Error fetching pricing data:", error.message);
    throw error;
  }
};
