import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { useState, useEffect } from 'react'
import { createMyBoat, deleteMyBoat} from '../../../requests/myBoats' 
import { similarSearch } from '../../../requests/search'



export default function SearchResultCard(props) {
    const getCurrencyConversion = (fromCurrency, toCurrency, amount, successCallback) => {
        fetch(`https://api.exchangerate.host/convert?from=${fromCurrency}&to=${toCurrency}&amount=${amount}`, {
            method: 'GET',
            headers: {},
        })
        .then(res => res.json())
        .then(data => {
            successCallback(data)
        })
    }

    const imageUrl = props.imageUrl
    const [convertedCurrency, setConvertedCurrency] = useState(null)

    function getCurrencySymbol(currency) {
        switch(currency) {
            case 'USD':
                return '$';
            case 'NZD':
                return '$';
            case 'AUD':
                return '$';
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            default:
                return '$';
        }
    }

    function formatNumber(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    const [myBoat, setMyBoat] = useState(() => {
        for (let i = 0; i < props.myBoats.length; i++) {
            if (props.myBoats[i].url === props.url) {
                return props.myBoats[i]
            }
        }
        return null
    })



    useEffect(() => {
        if (props.searchCurrency !== null && props.currency !== null && props.price !== null && props.currency !== props.searchCurrency) {
            getCurrencyConversion(props.currency, props.searchCurrency, props.price, (data) => {
                setConvertedCurrency(parseInt(data.result))
            })
        }
    }, [props.currency, props.searchCurrency, props.price])


    function handleAddMyBoat() {
        var fd = {
            client_profile_id: props.clientProfile.id,
            title: props.title,
            broker: props.broker,
            url: props.url,
            price: props.price,
            currency: props.currency,
            length: props.length,
            year: props.year,
            location: props.location,
            country: props.country,
            description: props.description,
            image_url: props.imageUrl,
            image_urls: props.imageUrls,
            style_vector: props.styleVector
        }
        createMyBoat(fd, 
            (data) => {
                setMyBoat(data.my_boat)
            },
            (data) => {
                console.log(data)
            }
        )
    }

    function handleDeleteMyBoat() {
        var fd = {
            id: myBoat.id
        }
        deleteMyBoat(fd, 
            (data) => {
                setMyBoat(null)
            },
            (data) => {
                console.log(data)
            }
        )
    }


    function handleSimilarSearch() {
        var fd = {};
        fd['comp_vec'] = props.styleVector;
        fd['page'] = 1;

        props.handleSearchStart('similar', fd);

        similarSearch(fd, 
            (data) => {
                props.handleSearchResults(data.results, 1)
            },
            (data) => {
                console.log(data)
            }
        )
    }

  return (
    <div style={{marginTop: '40px'}}>
        <Container fluid className='neumorphic-card' style={{backgroundColor: 'white'}}>
                <Row> 
                    <Col className='g-0' md={12} lg={6} style={{backgroundColor:'rgba(9, 11, 41, 0.3)'}}>
                        <div className='boat-card-image' style={{
                            backgroundImage: `url(${imageUrl})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                            width: '100%'
                        }} ></div> 
                    </Col>
                    <Col className='g-0' md={12} lg={6}>
                        <div className='search-result-card' style={{padding: '30px', height: '350px'}}>
                            <div onClick={() => {window.open(props.url, '_blank')}}>
                                <h2 style={{fontSize: '3vh'}} className='styled-header'>{props.title}</h2>
                                <div style={{backgroundColor: 'grey', width: '100%', height: '1px'}}></div>
                                
                                <div style={{marginTop: '20px'}}>
                                    { props.price !== null && props.currency != null ?
                                        <>
                                            <h4 style={{fontSize:'2.5vh', marginTop: '10px'}}>{getCurrencySymbol(props.currency)}{formatNumber(parseInt(props.price))} {props.currency}
                                            {
                                                convertedCurrency !== null ?
                                                    <span className='styled-header' style={{fontSize:'2vh', marginTop: '10px', marginLeft: '20px'}}>({getCurrencySymbol(props.searchCurrency)}{formatNumber(parseInt(convertedCurrency))} {props.searchCurrency})</span>
                                                :
                                                    null
                                            }
                                            </h4>
                                            
                                        </>
                                    : 
                                        null
                                    }   
                                
                                    <h4 style={{fontSize:'2.5vh', marginTop: '10px'}}>{props.broker}</h4>                                    
                                    <h4 style={{fontSize:'2.5vh', marginTop: '10px'}}>{props.length}m ({parseInt(props.length*3.281)}ft)</h4>
                                    <h4 style={{fontSize: '2.5vh', marginTop: '10px'}}>{props.year}</h4>
                                    <h4 style={{fontSize:'2.5vh', marginTop: '10px'}}>{props.country}</h4>
                                </div>
                            </div>
                        </div>
                        <div style={{height: '50px'}}>
                            <Container fluid>
                                <Row>
                                    {
                                        props.clientProfile ?
                                        <Col className='g-0'>
                                            {
                                                myBoat === null ?
                                                    <Button className='primary-bg' onClick={() => {handleAddMyBoat()}} style={{width: '100%', height: '50px', borderRadius: '0px'}}>Save Boat</Button>
                                                :
                                                    <Button className='danger-bg' onClick={() => {handleDeleteMyBoat()}} style={{width: '100%', height: '50px', borderRadius: '0px'}}>Remove Boat</Button>
                                            }
                                        </Col>
                                        :
                                        null
                                    }
                                    <Col className='g-0'>
                                        <Button className='secondary-bg' onClick={() => {handleSimilarSearch()}} style={{width: '100%', height: '50px', borderRadius: '0px'}}>Find Similar</Button>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                    </Col>
                </Row>
            
        </Container>
    </div>
  )
}
