import React from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { useState } from 'react'
import { updateReportBoatTitle } from '../../../../requests/report/reportBoat'


export default function BoatTitle(props) {

    const [showEditBoatModal, setShowEditBoatModal] = useState(false);
    const [editBoatTitle, setEditBoatTitle] = useState(props.boat.title);
    const [editBoatTitleError, setEditBoatTitleError] = useState('');

    function validateFormData() {
        var isValid = true;
        setEditBoatTitleError('');
        if (editBoatTitle.length < 1) {
            isValid = false;
            setEditBoatTitleError('Title is required');
        }

        return isValid;
    }


    function handleUpdateTitle() {
        if (!validateFormData()) {
            return;
        }
        var fd = {
            report_boat_id: props.boat.id,
            title: editBoatTitle
        }

        updateReportBoatTitle(fd, 
            (data) => {
                setShowEditBoatModal(false);
                props.reloadReportCallback();
            },
            (data) => {
                console.log(data);
            }
        )
    }



    return (
        <div>
            <h4 className='report-subtitle-landscape' onClick={() => {setShowEditBoatModal(true)}} style={{color: props.themePrimaryText, cursor: 'pointer'}}><b>{props.boat.title}</b></h4>
            
            <Modal show={showEditBoatModal} onHide={() => setShowEditBoatModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Edit Title</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Form.Control style={{marginTop: '20px'}} type='text' placeholder='Title' value={editBoatTitle} onChange={(e) => setEditBoatTitle(e.target.value)} />
                        <p style={{color: 'red'}}>{editBoatTitleError}</p>
                    </div>
                    <div>
                        <Button style={{marginTop: '20px'}} className="primary-bg" onClick={() => {handleUpdateTitle()}}>Save</Button>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}
