import { baseApiUrl } from "../../settings";
import { 
    tokenRequiredRequestWithBody
} from "../baseRequest";




export const createAppraisedBoatFeature = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/appraised-boats/features`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}



export const deleteAppraisedBoatFeature = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/appraised-boats/features`, 
        'DELETE', 
        fd, 
        successCallback, 
        failedCallback)
}