import React, { useState, useEffect, useCallback } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Modal,
  Box,
  FormControlLabel,
  IconButton,
  Checkbox,
  Grid,
  CircularProgress,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import ParentGraph from "./ParentGraph";
import { getPricingData } from "../../../api/boat";
import backupImage from "../../../assets/backup.png";

const StyledCard = styled(({ isSelected, isError, ...other }) => <Card {...other} />)(
  ({ isSelected, isError }) => ({
    "& h2": {
      position: "absolute",
      bottom: "10px",
      left: "10px",
      margin: 0,
      fontWeight: "normal",
      textTransform: "uppercase",
      fontSize: "1.2em",
    },
    width: "92%",
    maxWidth: "500px",
    height: "16rem",
    borderRadius: "10px",
    overflow: "hidden",
    cursor: "pointer",
    position: "relative",
    color: "rgb(240, 240, 240)",
    backgroundColor: isError
      ? "rgba(169, 169, 169, 0.6)" 
      : isSelected
      ? "inherit"
      : "rgba(128,128,128,0.7)",
  })
);

const Image = styled(({ isSelected, isError, alt, ...other }) => <img alt={alt} {...other} />)(
  ({ isSelected, isError }) => ({
    objectFit: "cover",
    width: "100%",
    height: "calc(100% - 50px)",
    opacity: isError ? 0.2 : isSelected ? 0.9 : 0.2,
    transition: "opacity 0.2s ease-out",
  })
);

const BoatSmallScreen = ({
  boat,
  index,
  selectedBoats = [],
  handleCheckboxChange = () => {},
  currency,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [savedPricingData, setSavedPricingData] = useState(null);
  const [fetchError, setFetchError] = useState(null);
  const isSelected = selectedBoats[index] || false;
  const isError = typeof savedPricingData === "string";

  const fetchPricingData = useCallback(async () => {
    try {
      const data = await getPricingData(boat.make, boat.model, 2010, currency);
      if (data.message === "No pricing guidance available for the given make and model") {
        setSavedPricingData(data.message);
      } else {
        setSavedPricingData(data);
      }
      setFetchError(null);
    } catch (err) {
      setFetchError(err.message || "Error fetching pricing data");
    }
  }, [boat.make, boat.model, currency]);

  useEffect(() => {
    if (!savedPricingData) {
      fetchPricingData();
    }
  }, [fetchPricingData, savedPricingData]);

  const handleOpenModal = () => {
    if (savedPricingData || isError) {
      setModalOpen(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const renderModalContent = () => {
    if (fetchError) {
      return (
        <Typography color="error">
          {fetchError || "Error loading graph data."}
        </Typography>
      );
    }

    if (isError) {
      return (
        <Typography
          color="error"
          sx={{ textAlign: "center", p: 2 }}
        >
          {savedPricingData}
        </Typography>
      );
    }

    return savedPricingData ? (
      <Box sx={{ width: "100%", maxWidth: "800px" }}>
        <ParentGraph
          data={savedPricingData}
          make={boat.make}
          model={boat.model}
          currency={currency}
        />
      </Box>
    ) : (
      <CircularProgress />
    );
  };

  return (
    <>
      <StyledCard isSelected={isSelected} isError={isError}>
        <Grid
          container
          spacing={0}
          sx={{
            height: "50px",
            backgroundColor: isError ? "gray" : isSelected ? "#034d70" : "gray",
            opacity: 1,
            top: 0,
            right: 0,
            width: "100%",
            position: "relative",
          }}
        >
          <Grid
            item
            xs={5}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRight: "2px solid",
              maxHeight: "50px",
              borderColor: isError? "lightgray" : isSelected ? "#0574a9" : "lightgray",
            }}
          >
            <Button
              variant="text"
              endIcon={
                <ArrowForwardIcon
                  sx={{ transition: "transform 0.3s ease-in-out" }}
                />
              }
              onClick={handleOpenModal}
              sx={{
                fontWeight: "bold",
                color: isSelected ? "white" : "lightgray",
                width: "100%",
                height: "100%",
                textTransform: "none",
                "&:hover .MuiSvgIcon-root": {
                  transform: "translateX(5px)",
                  transition: "transform 0.3s ease-in-out",
                  color: "white",
                },
              }}
            >
              See Pricing
            </Button>
          </Grid>
          <Grid
            item
            xs={7}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box>
              <FormControlLabel
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  color: isSelected ? "white" : "lightgray",
                  borderRadius: 2,
                  width: "100%",
                  cursor: isError ? "not-allowed" : "pointer",
                  "&:hover .MuiSvgIcon-root": {
                    transform: "scale(1.1)",
                    transition: "transform 0.3s ease-in-out",
                    color: "white",
                  },
                }}
                control={
                  <Checkbox
                    checked={isSelected}
                    onChange={() => handleCheckboxChange(index)}
                    color="primary"
                    sx={{
                      color: isSelected ? "white" : "lightgray",
                      "&.Mui-checked": {
                        color: isSelected ? "white" : "lightgray",
                      },
                    }}
                    disabled={isError} 
                  />
                }
                label={
                  isSelected ? (
                    <Typography
                      variant="h7"
                      sx={{ fontSize: "0.7em", fontWeight: "bold" }}
                    >
                      Added to merged graph
                    </Typography>
                  ) : (
                    <Typography
                      variant="h7"
                      sx={{ fontSize: "0.7em", fontWeight: "bold" }}
                    >
                      Removed from merged
                    </Typography>
                  )
                }
              />
            </Box>
          </Grid>
        </Grid>
        <Image
          src={boat.image_url || backupImage}
          alt={`${boat.make} ${boat.model}`}
          isSelected={isSelected}
          isError={isError}
        />
        <CardContent>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Grid item xs={10}>
              <Typography variant="h2">
                {boat.make} {boat.model}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>

        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="parent-graph-modal"
          aria-describedby="parent-graph-modal-description"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "800px",
              backgroundColor: "white",
              borderRadius: 1,
              position: "relative",
              pt: 5,
            }}
          >
            <IconButton
              onClick={handleCloseModal}
              sx={{
                position: "absolute",
                top: 5,
                right: 10,
                color: "#034d70",
                zIndex: 40,
              }}
            >
              <CloseIcon />
            </IconButton>

            {renderModalContent()}
          </Box>
        </Modal>
      </StyledCard>
    </>
  );
};

export default BoatSmallScreen;
