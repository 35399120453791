import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Image from 'react-bootstrap/Image'
import { useState } from 'react'
import { createReportBoatExtraImage, deleteReportBoatExtraImage } from '../../../../requests/report/reportBoat'




export default function ExtraImages(props) {

    const [extraImages, setExtraImages] = useState(props.boat.extra_images);
    const allImages = [props.boat.image_url, ...JSON.parse(props.boat.image_urls)];
    const [showSelectImagesModal, setShowSelectImagesModal] = useState(false);



    function SelectableImages() {
        var selectable = [];
        allImages.forEach((image, index) => {
            var isSelectable = true;
            for (var i = 0; i < extraImages.length; i++) {
                if (extraImages[i].image_url === image) {
                    isSelectable = false;
                }
            }
            if (isSelectable) {
                selectable.push(image);
            }
        })
        return selectable;
    }


    function handleSelectImage(image) {
        if (extraImages.length >= 6) {
            return;
        }
        var fd = {
            report_boat_id: props.boat.id,
            image_url: image
        }

        createReportBoatExtraImage(fd, 
            (data) => {
                console.log(data);
                if (data.success === true) {
                    setExtraImages([...extraImages, data.report_boat_extra_image]);
                    props.reloadReportCallback();
                }
            },
            (data) => {
                console.log(data);
            }
        )
    }


    function handleDeleteImage(image_id) {
        var fd = {
            image_id: image_id
        }

        deleteReportBoatExtraImage(fd, 
            (data) => {
                if (data.success === true) {
                    var newImages = [];
                    extraImages.forEach((image, index) => {
                        if (image.id !== image_id) {
                            newImages.push(image);
                        }
                    })
                    setExtraImages(newImages);
                    props.reloadReportCallback();
                }
            },
            (data) => {
                console.log(data);
            }
        )

    }



    function getExtraImageMarginRight(index) {
        if ([0,1,3,4].includes(index)) {
            return '0.4vw';
        }
        return '0';
    }



    function getExtraImageMarginLeft(index) {
        if ([1,2,4,5].includes(index)) {
            return '0.4vw';
        }
        return '0';
    }

    return (
        <>
            <Container fluid onClick={() => {setShowSelectImagesModal(true)}}>
                <Row>
                    {
                        props.boat.extra_images.map((image, index) => {
                            if (index < 6) {
                                return (
                                    <Col 
                                        key={index} 
                                        xs={4} 
                                        sm={4} 
                                        md={4} 
                                        lg={4} 
                                        xl={4} 
                                        className='report-pt-1 g-0'
                                        style={{
                                            paddingRight: getExtraImageMarginRight(index),
                                            paddingLeft: getExtraImageMarginLeft(index)
                                        }}
                                    >
                                        <div
                                            style={{
                                                backgroundImage: `url(${image.image_url})`,
                                                backgroundRepeat: 'no-repeat',
                                                backgroundPosition: 'center',
                                                backgroundSize: 'cover',
                                                width: '100%',
                                                marginTop: '1vw',
                                                cursor: 'pointer'
                                            }}
                                            className='report-image-shadow extra-image-landscape'
                                        >
                                        </div>
                                    </Col>
                                )
                            } else {
                                return null;
                            }
                        })
                    }
                </Row>
            </Container>
            <Modal size='lg' show={showSelectImagesModal} onHide={() => setShowSelectImagesModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Manage Images</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Selected Images (Select up to 6)</h4>
                    {
                        extraImages.length > 0 ? 
                            <Row style={{marginBottom: '40px'}}>
                                {
                                    extraImages.map((image, index) => {
                                        return (
                                            <Col key={index} md={4} lg={4}>
                                                <Image onClick={() => {handleDeleteImage(image.id)}} className='mild-neumorphic-card hero-image' src={image.image_url} style={{width: '100%', height: 'auto', marginTop: '20px'}}/>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        :
                        <h6 style={{marginBottom: '40px'}}>No Images Selected</h6>
                    }
                    <h4>Select From</h4>
                    <Row>
                        {
                            SelectableImages().map((image, index) => {
                                return (
                                    <Col key={index} md={4} lg={4}>
                                        <Image onClick={() => {handleSelectImage(image)}} className='mild-neumorphic-card hero-image' src={image} style={{width: '100%', height: 'auto', marginTop: '20px'}}/>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="primary-bg" onClick={() => setShowSelectImagesModal(false)}>
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
