import { baseApiUrl } from "../../settings";
import { 
    tokenRequiredRequest,
    tokenRequiredRequestWithBody
} from "../baseRequest";



export const createReport = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}



export const getReportById = (reportId, successCallback, failedCallback) => {
    return tokenRequiredRequest(
        `${baseApiUrl}/reports/${reportId}`, 
        'GET', 
        successCallback, 
        failedCallback)
}



export const getReports = (clientProfileId, successCallback, failedCallback) => {
    return tokenRequiredRequest(
        `${baseApiUrl}/reports?client_profile_id=${clientProfileId}`, 
        'GET', 
        successCallback, 
        failedCallback)
}



export const getReportsArchived = (successCallback, failedCallback) => {
    return tokenRequiredRequest(
        `${baseApiUrl}/reports?archived=True`,
        'GET',
        successCallback,
        failedCallback)
}



export const updateReport = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}



export const archiveReport = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/archive`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}



export const deleteReport = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports`, 
        'DELETE', 
        fd, 
        successCallback, 
        failedCallback)
}



export const reportIncludeListingUrl = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/include-listing-url`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}




export const reportIncludeMarketInsights = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/include-market-insights`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}



export const createReportPdf = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/create-pdf`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}


export const checkReportPdf = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/check-pdf`,
        'POST',
        fd,
        successCallback,
        failedCallback)
}



export const reportCreateMarketInsights = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/market-insights`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}





