import React from 'react'
import IndSectionCard from './IndSectionCard'


export default function MyBoatsSection() {
  return (
    <div>
        <IndSectionCard image="/help-images/my-boats-page1.png" title="My Boats Page" textSections={[
            "The My Boats page is where you can view and manage all the boats you have saved",
            "You can archive boats that you no longer want to use in your reports",
            "Click the 'View Archived Boats' button to view and manage all the boats you have archived"
        ]} />
        <IndSectionCard image="/help-images/my-boats-page2.png" title="Archived Boats" textSections={[
            "click the 'Unarchive Boat' button to unarchive the boat and add it back to your My Boats page"
        ]} />
    </div>
  )
}
