import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { archiveMyBoat } from '../../../requests/myBoats'


export default function SavedBoatCard(props) {

    
    function handleArchiveBoat(boatId) {
        
        var fd = {
            id: boatId,
            archive: true
        }
        
        archiveMyBoat(
            fd,
            (data) => {
                console.log(data)
                props.reloadMyBoats()
            },
            (data) => {
                console.log(data)
            }
        )
    }


    function getCurrencySymbol(currency) {
        switch(currency) {
            case 'USD':
                return '$';
            case 'NZD':
                return '$';
            case 'AUD':
                return '$';
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            default:
                return '$';
        }
    }

    function formatNumber(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    function openListingPage(url) {
        window.open(url, '_blank');
    }


    return (
        <div style={{backgroundColor: 'white', marginBottom: '40px'}}>
            <Row className='g-0'> 
                <Col md={12} lg={6} style={{backgroundColor:'rgba(9, 11, 41, 0.3)'}}>
                    <div className='boat-card-image' style={{
                        backgroundImage: `url(${props.boat.image_url})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        width: '100%'
                    }} ></div> 
                </Col>
                <Col md={12} lg={6}>
                    <div className='search-result-card' style={{height: '350px'}}>
                        <div onClick={() => {openListingPage(props.boat.url)}}>
                            <div style={{backgroundColor: 'rgb(240, 240, 240)', padding: '20px'}}>
                                <h2 style={{fontSize: '2.5vh'}} className='styled-header'>{props.boat.title}</h2>
                            </div>
                            <div style={{backgroundColor: 'grey', width: '100%', height: '1px'}}></div>
                            
                            <div style={{padding: '20px'}}>
                                { props.boat.price !== null && props.boat.currency != null ?
                                    <>
                                        <h4 style={{fontSize:'2.5vh', marginTop: '10px'}}>{getCurrencySymbol(props.boat.currency)}{formatNumber(parseInt(props.boat.price))} {props.boat.currency}</h4>
                                    </>
                                : 
                                    null
                                }   
                            
                                <h4 style={{fontSize:'2vh', marginTop: '10px'}}>{props.boat.broker}</h4>                                    
                                <h4 style={{fontSize:'2vh', marginTop: '10px'}}>{props.boat.length}m ({parseInt(props.boat.length*3.281)}ft)</h4>
                                <h4 style={{fontSize: '2vh', marginTop: '10px'}}>{props.boat.year}</h4>
                                <h4 style={{fontSize:'2vh', marginTop: '10px'}}>{props.boat.country}</h4>
                            </div>
                        </div>
                    </div>
                    <div style={{height: '50px'}}>
                        <Container fluid>
                            <Row>
                                <Col className='g-0'>
                                    <Button className='danger-bg' onClick={() => {handleArchiveBoat(props.boat.id)}} style={{width: '100%', height: '50px', borderRadius: '0px'}}>Remove</Button>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
