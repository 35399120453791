import React, {
  useEffect,
  useRef,
  useMemo,
  useState,
  useCallback,
} from "react";
import * as echarts from "echarts";
import { Typography, Box, IconButton, Tooltip, Checkbox } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ParentGraph2 = React.memo(
  ({
    dataSets,
    title,
    currency,
    conversionRate,
    abbr,
    onPinToggle,
    onDelete,
    isPinned,
    color,
    selected,
    onSelect,
    makeModelList,
    showCheckbox = true,
    highlightCheckboxes,
  }) => {
    const chartRef = useRef(null);
    const chartInstance = useRef(null);

    const [deleteTooltipOpen, setDeleteTooltipOpen] = useState(false);
    const [showCheckboxTooltip, setShowCheckboxTooltip] = useState(false);

    const isSmallScreen = useCallback(() => {
      return window.matchMedia("(max-width: 600px)").matches;
    }, []);

    const currencySymbols = useMemo(
      () => ({
        AUD: "$",
        USD: "$",
        EUR: "€",
        GBP: "£",
        NZD: "$",
        CAD: "$",
      }),
      []
    );

    const getCurrencySymbol = useCallback(
      (abbr) => {
        return currencySymbols[abbr] || abbr;
      },
      [currencySymbols]
    );

    const formatNumber = useCallback((num) => {
      if (num === null || num === undefined) return "";
      if (isSmallScreen()) {
        if (num >= 1000000000) return `${(num / 1000000000).toFixed(1)}B`;
        if (num >= 1000000) return `${(num / 1000000).toFixed(1)}M`;
        if (num >= 1000) return `${(num / 1000).toFixed(1)}K`;
        return num.toFixed(1).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }, [isSmallScreen]);

    const getColorSet = useCallback(
      (index) => {
        const colorSets = {
          blue: {
            price: "#219ebc",
            upperBound: "#023047",
            lowerBound: "#77d1fc",
            area: "rgba(0, 147, 210, 0.2)",
          },
          orange: {
            price: "#ff5821",
            upperBound: "#e82d17",
            lowerBound: "#ffb703",
            area: "rgba(255, 87, 51, 0.2)",
          },
          green: {
            price: "#2a9d8f",
            upperBound: "#1f6f61",
            lowerBound: "#84ccab",
            area: "rgba(42, 157, 143, 0.2)",
          },
        };
        if (color === "multi") {
          return colorSets[
            index % 3 === 0 ? "blue" : index % 3 === 1 ? "orange" : "green"
          ];
        }
        return colorSets[color] || colorSets.blue;
      },
      [color]
    );

    const setChartOptions = useCallback(() => {
      if (!chartInstance.current) return;

      const series = dataSets.flatMap((dataSet, index) => {
        const priceValues = Object.values(dataSet.price_curve || {}).map(
          (value) => Math.round(value * conversionRate)
        );
        const upperBoundValues = Object.values(
          dataSet.error_curve_upper_bound || {}
        ).map((value) => Math.round(value * conversionRate));
        const lowerBoundValues = Object.values(
          dataSet.error_curve_lower_bound || {}
        ).map((value) => Math.round(value * conversionRate));

        const colors = getColorSet(index);

        return [
          {
            name: `Price ${makeModelList[index]}`,
            data: priceValues,
            type: "line",
            smooth: true,
            lineStyle: {
              color: colors.price,
            },
            itemStyle: {
              color: colors.price,
            },
          },
          {
            name: "Upper Bound",
            data: upperBoundValues,
            type: "line",
            smooth: true,
            lineStyle: {
              opacity: 0,
            },
            areaStyle: {
              color: colors.area,
              origin: "start",
            },
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              color: colors.upperBound,
            },
          },
          {
            name: "Lower Bound",
            data: lowerBoundValues,
            type: "line",
            smooth: true,
            lineStyle: {
              opacity: 0,
            },
            areaStyle: {
              color: colors.area,
              origin: "end",
            },
            emphasis: {
              focus: "series",
            },
            itemStyle: {
              color: colors.lowerBound,
            },
          },
        ];
      });

      chartInstance.current.setOption({
        grid: {
          left: "30%",
          right: "15%",
          top: "5%",
          bottom: "20%",
        },
        tooltip: {
          trigger: "axis",
          confine: true,
          formatter: function (params) {
            const tooltipItems = params.map((param, index) => {
              const symbol = getCurrencySymbol(abbr);
              const colorIndex = Math.floor(index / 3);
              const colors = getColorSet(colorIndex);
              const isLowerBound = param.seriesName.includes("Lower Bound");
              return `<span style="color:${
                param.seriesName.includes("Price")
                  ? colors.price
                  : param.seriesName.includes("Upper Bound")
                  ? colors.upperBound
                  : colors.lowerBound
              }; font-weight: bold; text-transform: uppercase;">${
                param.seriesName
              }: ${symbol}${formatNumber(param.value)}</span>${
                isLowerBound ? '<div style="margin-bottom: 2px;"></div>' : ""
              }`;
            });
            return `<span style="color:#000; font-weight: bold;">BOAT AGE: ${
              params[0].name
            }</span><div style="margin-bottom: 5px;"></div>${tooltipItems.join(
              "<br/>"
            )}`;
          },
        },
        xAxis: {
          type: "category",
          data: Object.keys(dataSets[0].price_curve || {}),
          name: "Boat Age (Years)",
          nameLocation: "middle",
          nameGap: 40,
          nameTextStyle: {
            align: "center",
            padding: [0, 0, 0, -30],
            color: "#00557c",
            fontSize: "1.2em",
          },
          axisLabel: {
            color: "#034d70",
          },
        },
        yAxis: {
          type: "value",
          name: `Value (${abbr})`,
          nameLocation: "middle",
          nameGap: 83,
          nameTextStyle: {
            align: "left",
            padding: [0, 0, 0, -30],
            color: "#00557c",
            fontSize: "1.2em",
          },
          axisLabel: {
            formatter: function (value) {
              const symbol = getCurrencySymbol(abbr);
              return `${symbol}${formatNumber(value)}`;
            },
            color: "#034d70",
          },
        },
        series,
      });
    }, [
      dataSets,
      conversionRate,
      abbr,
      getCurrencySymbol,
      formatNumber,
      getColorSet,
      makeModelList,
    ]);

    const initializeChart = useCallback(() => {
      if (!chartInstance.current) {
        chartInstance.current = echarts.init(chartRef.current);
      }
      setChartOptions();
    }, [setChartOptions]);

    useEffect(() => {
      initializeChart();

      const resizeChart = () => {
        if (chartInstance.current) {
          chartInstance.current.resize();
        }
      };

      window.addEventListener("resize", resizeChart);

      return () => {
        window.removeEventListener("resize", resizeChart);
      };
    }, [initializeChart]);

    useEffect(() => {
      setChartOptions();
    }, [dataSets, conversionRate, abbr, setChartOptions]);

    useEffect(() => {
      if (isPinned) {
        setDeleteTooltipOpen(true);
        setShowCheckboxTooltip(true);
        const deleteTooltipTimeout = setTimeout(
          () => setDeleteTooltipOpen(false),
          4000
        );
        const checkboxTooltipTimeout = setTimeout(
          () => setShowCheckboxTooltip(false),
          3000
        );
        return () => {
          clearTimeout(deleteTooltipTimeout);
          clearTimeout(checkboxTooltipTimeout);
        };
      }
    }, [isPinned]);

    return (
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: 2,
          mb: 0,
          position: "relative",
          width: "100%",
        }}
      >
        <Typography
          variant="h6"
          gutterBottom
          sx={{
            textTransform: "uppercase",
            textAlign: "center",
            p: 1,
            color: "#034d70",
            fontSize: "1.5em",
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>

        <Tooltip
          title=""
          open={isPinned ? deleteTooltipOpen : false}
          placement="left"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                backgroundColor: "#034d70",
                color: "#fff",
                fontSize: "1em",
                cursor: "pointer",
              },
            },
            arrow: {
              sx: {
                color: "#034d70",
              },
            },
          }}
        >
          <IconButton
            onClick={() => {
              onDelete();
            }}
            sx={{ position: "absolute", top: 1, right: 0.5 }}
          >
            <CloseIcon />
          </IconButton>
        </Tooltip>

        <Box
          ref={chartRef}
          sx={{
            width: "100%",
            height: { xs: "50vh", sm: "50vh", lg: "35vh" },
            borderRadius: 2,
          }}
        />

        {showCheckbox && (
          <Tooltip
            title="Select graph for comparing (minimum 2 required)"
            open={showCheckboxTooltip}
            placement="right"
            arrow
            componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: "#034d70",
                  color: "#fff",
                  fontSize: "1em",
                  cursor: "pointer",
                },
              },
              arrow: {
                sx: {
                  color: "#034d70",
                },
              },
            }}
          >
            <Checkbox
              checked={selected}
              onChange={onSelect}
              sx={{
                position: "absolute",
                bottom: 8,
                left: 8,
                transform: highlightCheckboxes
                  ? "scale(1.2) translateY(-2px)"
                  : "scale(1)",
                transition: "transform 0.2s, color 0.2s",
                color: highlightCheckboxes ? "orange" : "default",
              }}
            />
          </Tooltip>
        )}
      </Box>
    );
  }
);

export default ParentGraph2;
