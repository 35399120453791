import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { adminDeleteAccount, adminUpdatePassword } from '../../../requests/admin'
import { useState } from 'react'


export default function AccountCard(props) {


    const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false)
    const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)

    const [emailCheckInput, setEmailCheckInput] = useState('')
    const [emailCheckInputError, setEmailCheckInputError] = useState('')

    const [passwordInput, setPasswordInput] = useState('')
    const [confirmPasswordInput, setConfirmPasswordInput] = useState('')
    const [passwordInputError, setPasswordInputError] = useState('')




    function validateEmailCheck() {
        var valid = true;
        setEmailCheckInputError('')
        if(emailCheckInput !== props.account.email){
            setEmailCheckInputError('Email address does not match')
            valid = false;
        }
        return valid;
    }


    function handleDeleteAccount() {
        if(!validateEmailCheck()){
            return;
        }

        var fd = {
            email: props.account.email,
            account_id: props.account.id
        }
        adminDeleteAccount(fd, (data) => {
            props.reloadAccountsCallback()
        },
        (data) => {
            console.log(data)
        }
        )
    }


    function validateChangePassword() {
        var valid = true;
        setPasswordInputError('')

        if(passwordInput === ''){
            setPasswordInputError('Password is required')
            valid = false;
        }

        if(passwordInput !== confirmPasswordInput){
            setPasswordInputError('Passwords do not match')
            valid = false;
        }
        return valid;
    }


    function handleChangePassword() {
        if(!validateChangePassword()){
            return;
        }

        var fd = {
            email: props.account.email,
            password: passwordInput
        }

        adminUpdatePassword(fd, (data) => {
            setShowChangePasswordModal(false)
        },
        (data) => {
            console.log(data)
        }
        )
    }

        
    return (
        <>
            <Container fluid>
                <Row>
                    <Col className='g-0'>
                        <div style={{padding: '20px', marginTop: '20px'}} className='neumorphic-card'>
                            <h4>{props.account.first_name} {props.account.last_name}</h4>
                            <p>{props.account.email}</p>
                            <div>
                                <Button className="danger-bg" onClick={() => {setShowDeleteAccountModal(true)}}>Delete</Button>
                                <Button style={{marginLeft: '20px'}} className='primary-bg' onClick={() => {setShowChangePasswordModal(true)}}>Change Password</Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Modal show={showDeleteAccountModal} onHide={() => {setShowDeleteAccountModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Delete Account</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to delete this account?</p>
                    <p>This action cannot be undone.</p>
                    <p>To confirm, please enter <b>{props.account.email}</b> below</p>
                    <Form.Control type='text' value={emailCheckInput} onChange={(e) => {setEmailCheckInput(e.target.value)}}></Form.Control>
                    <p style={{color: 'red'}}>{emailCheckInputError}</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="danger-bg" onClick={() => {handleDeleteAccount()}}>Delete</Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showChangePasswordModal} onHide={() => {setShowChangePasswordModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>{props.account.email}</h4>
                    <p style={{marginTop: '40px'}}>Enter a new password below</p>
                    <Form.Control type='password' value={passwordInput} onChange={(e) => {setPasswordInput(e.target.value)}}></Form.Control>
                    
                    <p style={{marginTop: '40px'}}>Confirm new password</p>
                    <Form.Control type='password' value={confirmPasswordInput} onChange={(e) => {setConfirmPasswordInput(e.target.value)}}></Form.Control>
                    <p style={{color: 'red'}}>{passwordInputError}</p>
                
                </Modal.Body>
                <Modal.Footer>
                    <Button className='primary-bg' onClick={() => {handleChangePassword()}}>Change Password</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
