import React from 'react'
import IndSectionCard from './IndSectionCard'

export default function BuildAppraisalReportSection() {
    return (
        <div>
            <IndSectionCard image="/help-images/appraisal-report-page1.png" title="Build Appraisal Report Page" textSections={[
                "The Build Appraisal Report page is where you can build an appraisal report for a specific boat",
                "You can edit and add to the basic details of the boat that is being appraised",
                "You can add comparison boats to the report by clicking the 'Add Boats' button",
                "you can change the theme of the report by clicking the 'Change Theme' button",
                "Once you have finished building the report, click the 'Create PDF' button to create the PDF"
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page2.png" title="Title Page" textSections={[
                "The title page of the report will display the clients details as well as your details as a broker",
                "You can edit your details by clicking on them"
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page3.png" title="Appraised Boat" textSections={[
                "The appraised boat page will display the basic details of the boat that is being appraised",
                "You can edit the details by clicking on them"
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page4.png" title="Edit Appraised Boat Image" textSections={[
                "You can edit the main image of the appraised boat by clicking the boats image",
                "you can then upload a new image by clicking on it and uploading"
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page5.png" title="Edit Appraised Title" textSections={[
                "You can edit the title of the appraised boat by clicking the title"
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page6.png" title="Edit Specifications" textSections={[
                "You can edit the specifications of the appraised boat by clicking on the specifications section",
                "You can then edit and save the specifications"
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page7.png" title="Features" textSections={[
                "You can edit the features of the appraised boat by clicking on the features section",
                "you can then add or remove key features of the boat, these will be added to any comparison boats that are added to the report to be checked or unchecked"
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page8.png" title="Add Item" textSections={[
                "You can add a new item to the features list by clicking the 'Add Item' button",
                "You then have the choice of a field with or without an image",
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page9.png" title="Add Item Without Image" textSections={[
                "To add an item without an image, add a Title and body before saving"
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page10.png" title="Add Item With Image" textSections={[
                "To add an item with an image, add a Title and body",
                "Then click the 'upload Image' button and upload an image"
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page11.png" title="Edit Item" textSections={[
                "Once you have added an item, you can edit it by clicking on it"
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page12.png" title="Market Insights" textSections={[
                "The market insights section will display the market insights based on the comparison boats that have been added to the report",
                "If you do not wish to include the market insights in the report, you can toggle the 'Include Market Insights' switch on the sidecard to off"
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page13.png" title="Comparison Boats" textSections={[
                "The comparison boats pages will display the comparison boats that have been added to the report",
                "You can edit the details of the comparison boats by clicking on them"
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page14.png" title="Edit Comparison Boat Image" textSections={[
                "You can edit the main image of the comparison boat by clicking the boats image",
                "You can then select from the images from the listing"
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page15.png" title="Edit Comparison Boat Title" textSections={[
                "You can edit the title of the comparison boat by clicking the title"
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page16.png" title="Edit Comparison Boat Specifications" textSections={[
                "You can edit the specifications of the comparison boat by clicking on the specifications section",
                "You can then edit and save the specifications"
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page17.png" title="Edit Comparison Boat Features" textSections={[
                "You can edit the features of the comparison boat by clicking on the features section",
                "You can then check or uncheck the features that are included in the boat"
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page18.png" title="Add Comparison Boat item" textSections={[
                "You can add a new item to the boat page by clicking the 'Add Item' button",
                "Add a title and body and then save"
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page19.png" title="Summary Page" textSections={[
                "The summary page will display the summary of the report, as well as the recommended price for the boat",
                "You can add a recommended price by clicking 'Add Recommended Listing Price'",
                "you can add a summary field by clicking the 'Add Item' button"
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page20.png" title="Add Recommended Listing Price" textSections={[
                "You can add a recommended listing price by clicking 'Add Recommended Listing Price'",
                "Add a Price (denominated in the report currency) and then click save"
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page21.png" title="Edit Recommended Listing Price" textSections={[
                "You can edit the recommended listing price by clicking on it"
            ]} />
            <IndSectionCard image="/help-images/appraisal-report-page22.png" title="Add Summary Item" textSections={[
                "You can add a summary item by clicking the 'Add Item' button",
                "Add a title and body and then save"
            ]} />

        </div>

    )
}
