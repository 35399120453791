import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useState } from 'react'

export default function TandCModal() {

    const [showTermsModal, setShowTermsModal] = useState(false)    


  return (
    <>
        <p className='footer-text' onClick={() => {setShowTermsModal(true)}}>Terms and Conditions</p>
        <Modal show={showTermsModal} onHide={() => {setShowTermsModal(false)}} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title className='styled-header'>Terms and Conditions</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4 className='styled-header'>1. Introduction</h4>
                <p>This website ("Site") is owned and operated by We Find Your Boat Limited ("we", "us" or "our"). Your access and use of the Site and its services (collectively, the "Services"), including any search and report generation services to boat brokers, is conditioned on your acceptance of and compliance with these Terms and Conditions ("Terms").</p>
            
                <h4 style={{marginTop: '30px'}} className='styled-header'>2. Acceptance of Terms</h4>
                <p>By accessing or using the Services, you agree to be bound by these Terms. If you do not agree with these Terms, please do not use our Services.</p>

                <h4 style={{marginTop: '30px'}} className='styled-header'>3. Description of Services</h4>
                <p>Our Services provide a search and report generation platform to boat brokers. This allows brokers to efficiently search for, analyze, and compile information about boats for the purposes of their brokerage activities.</p>

                <h4 style={{marginTop: '30px'}} className='styled-header'>4. User Registration</h4>
                <p>To access certain features of our Services, you may be required to register as a user. You agree to provide accurate and current information about yourself in all registration forms on the Services.</p>

                <h4 style={{marginTop: '30px'}} className='styled-header'>5. User Conduct</h4>
                <p>You are solely responsible for your conduct and any data, text, files, information, usernames, images, graphics, photos, profiles, applications, links and other content or materials that you submit, post or display on or via the Services.</p>

                <h4 style={{marginTop: '30px'}} className='styled-header'>6. Intellectual Property Rights</h4>
                <p>All contents of the Site and Services, including but not limited to the text, graphics, logo, icons, images, audio clips, digital downloads and software, are the property of We Find Your Boat Limited or its content suppliers and protected by international copyright laws.</p>

                <h4 style={{marginTop: '30px'}} className='styled-header'>7. Data Privacy</h4>
                <p>Your use of our Services is subject to our Privacy Policy, which governs our collection, use, and disclosure of your information. Please review the Privacy Policy carefully.</p>

                <h4 style={{marginTop: '30px'}} className='styled-header'>8. Limitation of Liability</h4>
                <p>In no event will We Find Your Boat Limited be liable for any direct, indirect, consequential, exemplary, incidental, special or punitive damages, including without limitation loss of profits, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the services.</p>

                <h4 style={{marginTop: '30px'}} className='styled-header'>9. Termination</h4>
                <p>We may terminate or suspend access to our Services immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the Terms.</p>

                <h4 style={{marginTop: '30px'}} className='styled-header'>10. Changes to Terms</h4>
                <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. Continued use of the Site or Services after any such changes constitutes your acceptance of the new Terms.</p>

                <h4 style={{marginTop: '30px'}} className='styled-header'>11. Governing Law</h4>
                <p>These Terms and any action related thereto will be governed by the laws of New Zealand without regard to its conflict of laws provisions.</p>
                
                <h4 style={{marginTop: '30px'}} className='styled-header'>12. Contact Us</h4>
                <p>If you have any questions about these Terms, please contact us at Tim@wefindyourboat.com</p>

                <p>By using our Services, you are agreeing to these Terms. Please read them carefully.</p>
            </Modal.Body>
        </Modal>
    </>
  )
}
