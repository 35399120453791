import React from 'react'
import Header from '../../universal_components/Header'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import TitlePage from './report_components/TitlePage'
import AppraisedBoat from './report_components/AppraisedBoat'
import { useLocation } from 'react-router-dom'
import { useState, useEffect } from 'react'
import SideCard from './components/SideCard'
import BoatPage from './report_components/BoatPage'
import MarketInsightsPage from './report_components/MarketInsightsPage'
import SummaryPage from './report_components/SummaryPage'
import ContentsPage from './report_components/ContentsPage'
import { getAppraisalReportById } from '../../requests/appraisal_report/appraisalReport'
import { getTheme } from '../../requests/theme'

export default function BuildAppraisalReport() {

    const {state} = useLocation();

    const [theme, setTheme] = useState('rgb(2, 70, 128)');
    const [themePrimaryText, setThemePrimaryText] = useState('rgb(1, 151, 221)');
    const [themeSecondaryText, setThemeSecondaryText] = useState('rgb(255, 255, 255)');

    const [report, setReport] = useState(null);


    useEffect(() => {
        getAppraisalReportById(
            state.report_id, 
            (data) => {
                console.log(data.report)
                setReport(data.report)
            },
            (data) => {
                console.log(data)
            }
        )
    }, [state.report_id])



    useEffect(() => {
        getTheme(
            (data) => {
                var themeMap = JSON.parse(data.theme_color)
                setTheme(themeMap.theme)
                setThemePrimaryText(themeMap.primaryText)
                setThemeSecondaryText(themeMap.secondaryText)
            },
            (data) => {
                console.log(data)
            }
        )
    }, [])

    function setThemeCallback(theme) {
        setTheme(theme.theme)
        setThemePrimaryText(theme.primaryText)
        setThemeSecondaryText(theme.secondaryText)
    }


    function reloadReportCallback() {
        getAppraisalReportById(
            state.report_id, 
            (data) => {
                console.log(data.report)
                setReport(data.report)
            },
            (data) => {
                console.log(data)
            }
        )
    }


    return (
        <>
            <Header signedIn={true} />
            <Container fluid style={{minHeight: '100vh', paddingBottom: '100px', backgroundColor: 'rgb(220, 220, 220)'}}>
                {
                    report &&
                        <Row>
                            <Col lg={4} className='g-0' >
                                <SideCard
                                    theme={theme}
                                    themePrimaryText={themePrimaryText}
                                    themeSecondaryText={themeSecondaryText}
                                    setThemeCallback={setThemeCallback}
                                    report={report}
                                    addAppraisalReportBoatCallback={reloadReportCallback}
                                    reloadReportCallback={reloadReportCallback}
                                />
                            </Col>
                            <Col lg={8} className='g-0' >
                                <div style={{marginTop: '100px'}}>
                                    <TitlePage
                                        theme={theme} 
                                        themePrimaryText={themePrimaryText}
                                        themeSecondaryText={themeSecondaryText}
                                        report={report}
                                    />
                                    <ContentsPage 
                                        theme={theme} 
                                        themePrimaryText={themePrimaryText}
                                        themeSecondaryText={themeSecondaryText}
                                        report={report}
                                    />
                                    <AppraisedBoat
                                        theme={theme}
                                        themePrimaryText={themePrimaryText}
                                        themeSecondaryText={themeSecondaryText}
                                        appraisedBoat={report.appraised_boat}
                                        reloadReportCallback={reloadReportCallback}
                                    />
                                    {
                                    report.market_insights && report.include_market_insights &&
                                        <MarketInsightsPage
                                            theme={theme}
                                            themePrimaryText={themePrimaryText}
                                            themeSecondaryText={themeSecondaryText}
                                            report={report}
                                        />
                                    }
                                </div>
                                
                                <div>
                                    {
                                        report.appraisal_report_boats.map((boat, index) => {
                                            return <BoatPage
                                                theme={theme} 
                                                themePrimaryText={themePrimaryText}
                                                themeSecondaryText={themeSecondaryText}
                                                report={report} 
                                                boat={boat}  
                                                key={boat.id}
                                                comparisonNum={index + 1}
                                                reloadReportCallback={reloadReportCallback}
                                            />
                                            
                                        })
                                    }
                                </div>
                                <div>
                                    <SummaryPage
                                        theme={theme} 
                                        themePrimaryText={themePrimaryText}
                                        themeSecondaryText={themeSecondaryText}
                                        report={report}
                                        reloadReportCallback={reloadReportCallback}
                                    />
                                </div>
                            </Col>
                        </Row>
                }
            </Container>
        </>
    )
}
