import React from 'react'
import LandscapePage from '../../../generic_report_components/LandscapePage'
import Image from 'react-bootstrap/Image'
import { useState, useEffect } from 'react';
import { getBrokerDetails, updateBrokerDetails } from '../../../requests/brokerDetails';
import EditBrokerDetailsModal from './title_page_components/EditBrokerDetailsModal';

export default function LandTitlePage(props) {

    const [logoUrl, setLogoUrl] = useState(null);

    const [brokerName, setBrokerName] = useState(null);
    const [brokerEmail, setBrokerEmail] = useState(null);
    const [brokerPhone, setBrokerPhone] = useState(null);

    const [editBrokerName, setEditBrokerName] = useState('');
    const [editBrokerEmail, setEditBrokerEmail] = useState('');
    const [editBrokerPhone, setEditBrokerPhone] = useState('');

    const [editBrokerNameError, setEditBrokerNameError] = useState('');
    const [editBrokerEmailError, setEditBrokerEmailError] = useState('');
    const [editBrokerPhoneError, setEditBrokerPhoneError] = useState('');


    const [showEditBrokerModal, setShowEditBrokerModal] = useState(false);

    useEffect(() => {
        getBrokerDetails(
            (data) => {
                if (data.broker_details) {
                    setLogoUrl(data.broker_details.logo)
                    setBrokerName(data.broker_details.name)
                    setBrokerEmail(data.broker_details.email)
                    setBrokerPhone(data.broker_details.phone)
                    setEditBrokerName(data.broker_details.name)
                    setEditBrokerEmail(data.broker_details.email)
                    setEditBrokerPhone(data.broker_details.phone)
                }
            },
            (data) => {
                console.log(data)
            }
        )
    }, [])

    
    function validateFormData() {
        var valid = true;
        setEditBrokerNameError('');
        setEditBrokerEmailError('');
        setEditBrokerPhoneError('');
        if (editBrokerName.length < 1) {
            setEditBrokerNameError('Please enter a name');
            valid = false;
        } else {
            setEditBrokerNameError('');
        }
        if (editBrokerEmail.length < 1) {
            setEditBrokerEmailError('Please enter an email');
            valid = false;
        } else {
            setEditBrokerEmailError('');
        }
        if (editBrokerPhone.length < 1) {
            setEditBrokerPhoneError('Please enter a phone number');
            valid = false;
        } else {
            setEditBrokerPhoneError('');
        }
        return valid;
    }



    function handleEditBrokerDetails() {
        if (!validateFormData()) {
            return;
        }
        var fd = {
            name: editBrokerName,
            email: editBrokerEmail,
            phone: editBrokerPhone
        };
        updateBrokerDetails(fd, 
            (data) => {
                setBrokerName(editBrokerName);
                setBrokerEmail(editBrokerEmail);
                setBrokerPhone(editBrokerPhone);
                setShowEditBrokerModal(false);
            },
            (data) => {
                console.log(data);
            }
        )
    }


    return (
        <div style={{marginBottom: '40px'}}>
            <LandscapePage>
            <div style={{width: '100%', textAlign: 'center'}}>
                        <Image className='report-mt-5' src={logoUrl} style={{width: '40%'}} />
                    </div>
                    <div className='report-mt-2' style={{width: '100%', textAlign: 'center'}}>
                        <h1 className='report-title-landscape' style={{color:props.themePrimaryText}}>Report created for {props.report.client_profile.name}</h1>
                        <div style={{width: '100%'}}>
                            <hr style={{width: '70%', marginLeft: 'auto', marginRight: 'auto', marginTop:'0px', marginBottom: '0px'}}></hr>
                        </div>
                    </div>
                    <div onClick={() => {setShowEditBrokerModal(true)}} className='editable-section report-mt-2' style={{width: '100%', textAlign: 'center'}}>
                        <h4 className='report-subtitle-landscape'>{brokerName}</h4>
                        <h4 className='report-subtitle-landscape'>{brokerEmail}</h4>
                        <h4 className='report-subtitle-landscape'>{brokerPhone}</h4>
                    </div>
            </LandscapePage>
            <EditBrokerDetailsModal
                showEditBrokerModal={showEditBrokerModal}
                setShowEditBrokerModal={setShowEditBrokerModal}
                editBrokerName={editBrokerName}
                setEditBrokerName={setEditBrokerName}
                editBrokerEmail={editBrokerEmail}
                setEditBrokerEmail={setEditBrokerEmail}
                editBrokerPhone={editBrokerPhone}
                setEditBrokerPhone={setEditBrokerPhone}
                editBrokerNameError={editBrokerNameError}
                editBrokerEmailError={editBrokerEmailError}
                editBrokerPhoneError={editBrokerPhoneError}
                handleEditBrokerDetails={handleEditBrokerDetails}
            />

        </div>
    )
}
