import { useState, useEffect, useRef, useCallback } from "react";

const useFetch = (fetchFunction, dependencies = []) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const abortControllerRef = useRef(null);

  const memoizedFetchFunction = useCallback(fetchFunction, [
    fetchFunction,
    ...dependencies,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();

      setIsLoading(true);
      setError(null);

      try {
        const response = await memoizedFetchFunction({
          signal: abortControllerRef.current.signal,
        });
        setData(response);
      } catch (e) {
        if (e.name !== "AbortError") {
          setError(e);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [memoizedFetchFunction]);

  return { data, isLoading, error };
};

export default useFetch;
