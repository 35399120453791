import React from 'react'
import LandscapePage from '../../../generic_report_components/LandscapePage'
import { useState, useEffect } from 'react';
import { getBrokerDetails} from '../../../requests/brokerDetails';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default function LandOverviewPage(props) {

    const [brokerName, setBrokerName] = useState(null);
    const [brokerEmail, setBrokerEmail] = useState(null);
    const [brokerPhone, setBrokerPhone] = useState(null);


    useEffect(() => {
        getBrokerDetails(
            (data) => {
                if (data.broker_details) {
                    setBrokerName(data.broker_details.name)
                    setBrokerEmail(data.broker_details.email)
                    setBrokerPhone(data.broker_details.phone)
                }
            },
            (data) => {
                console.log(data)
            }
        )
    }, [])

    function getCurrencySymbol(currency) {
        switch(currency) {
            case 'USD':
                return '$';
            case 'NZD':
                return '$';
            case 'AUD':
                return '$';
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            default:
                return '$';
        }
    }

    function formatNumber(number) {
        if (number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return '';
        }
    }

    function buildPages() {
        let pages = [];
        if (props.report.report_boats.length <= 6) {
            pages.push(props.report.report_boats)
        } else {
            let page = [];
            for (let i = 0; i < props.report.report_boats.length; i++) {
                if (i % 6 === 0 && i !== 0) {
                    pages.push(page);
                    page = [];
                }
                page.push(props.report.report_boats[i])
            }
            pages.push(page);
        }
        console.log(pages);
        return pages;
    }

    return (
        <div>
            {
                buildPages().map((page, index) => {
                    return <div key={index} style={{marginBottom: '40px'}}>
                        <LandscapePage
                            includeFooter={true}
                            brokerDetails={{name: brokerName, email: brokerEmail, phone: brokerPhone}}
                            theme={{
                                theme:props.theme,
                                themePrimaryText:props.themePrimaryText,
                            }}

                        >
                            <div className="report-pt-2 report-pl-2 report-pr-2">
                                <h2 className='report-title-landscape' style={{color: props.themePrimaryText}}>Overview</h2>
                                <div className='report-pb-1'>
                                    <hr style={{marginTop:'0px', marginBottom: '0px'}}></hr>
                                </div>
                            </div>

                            <Container fluid>
                                <Row>
                                    {
                                        page.map((boat, index) => {
                                            return (
                                                <Col key={index} xs={4} md={4} lg={4} xl={4}>
                                                    <div style={{
                                                        backgroundImage: `url(${boat.selected_image_url})`,
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'center',
                                                        backgroundSize: 'cover',
                                                    }}       
                                                    className='report-image-shadow overview-image'  
                                                    ></div>
                                                    <div className='report-pt-1 report-pb-1'>
                                                        <h4 style={{color: props.themePrimaryText}} className='report-subtitle-sm-landscape '>{boat.title}</h4>
                                                        <h3 className='report-text-landscape'>{getCurrencySymbol(boat.currency)}{formatNumber(boat.price)} {boat.currency}</h3>
                                                        <hr style={{marginTop:'0px', marginBottom: '0px'}}></hr>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>
                            </Container>

                        </LandscapePage>
                    </div>
                })
            }
        </div>
    )
}
