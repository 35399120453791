import React from 'react'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { updateAppraisalReportSummaryField, deleteAppraisalReportSummaryField } from '../../../../requests/appraisal_report/appraisalReportSummaryField'


export default function SummaryField(props) {

    const [showEditFieldModal, setShowEditFieldModal] = useState(false)

    const [editFieldTitle, setEditFieldTitle] = useState(props.field.title)
    const [editFieldBody, setEditFieldBody] = useState(props.field.body)

    const [titleError, setTitleError] = useState('')
    const [bodyError, setBodyError] = useState('')


    function validateFormData() {
        var valid = true;

        setTitleError('')
        setBodyError('')

        if(editFieldTitle === ''){
            setTitleError('Title is required')
            valid = false;
        }

        if(editFieldBody === ''){
            setBodyError('Body is required')
            valid = false;
        }

        return valid;
    }



    function handleSaveSummaryField() {
        if(!validateFormData()){
            return;
        }

        var fd = {
            appraisal_report_summary_field_id: props.field.id,
            title: editFieldTitle,
            body: editFieldBody
        }

        updateAppraisalReportSummaryField(fd, 
            (data) => {
                setShowEditFieldModal(false)
                props.reloadReportCallback()
            },
            (data) => {
                console.log(data)
            }
        )
    }


    function handleDeleteSummaryField() {
        var fd = {
            appraisal_report_summary_field_id: props.field.id
        }

        deleteAppraisalReportSummaryField(fd, 
            (data) => {
                setShowEditFieldModal(false)
                props.reloadReportCallback()
            },
            (data) => {
                console.log(data)
            }
        )
    }


    return (
        <>
            <div onClick={() => {setShowEditFieldModal(true)}} className='report-pl-2 report-mt-2 editable-section'>
                <h3 style={{color: props.themePrimaryText}} className='report-subtitle'>{props.field.title}</h3>
                <p className='report-text'>{props.field.body}</p>
            </div>
            

            <Modal show={showEditFieldModal} onHide={() => {setShowEditFieldModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Edit Summary Field</Modal.Title>
                </Modal.Header>
                
                <Modal.Body>

                    <p style={{marginTop: '20px'}}>Title</p>
                    <Form.Control value={editFieldTitle} type='text' onChange={(e) => {setEditFieldTitle(e.target.value)}} />
                    <p style={{color: 'red'}}>{titleError}</p>

                    <p style={{marginTop: '20px'}}>Body</p>
                    <Form.Control value={editFieldBody} type='text' as='textarea' rows={5} onChange={(e) => {setEditFieldBody(e.target.value)}} />
                    <p style={{color: 'red'}}>{bodyError}</p>

                </Modal.Body>
                
                <Modal.Footer>
                    <Button className="danger-bg" onClick={() => {handleDeleteSummaryField()}} >Delete</Button>
                    <Button className="primary-bg" onClick={() => {handleSaveSummaryField()}}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
