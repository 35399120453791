import { baseApiUrl } from "../../settings";
import { 
    tokenRequiredRequestWithBody
} from "../baseRequest";



export const updateAppraisedBoatImage = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/appraised-boats/images`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}



export const updateAppraisedBoatTitle = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/appraised-boats/title`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}



export const updateAppraisedBoatSpecifications = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/appraised-boats/specifications`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}



export const createAppraisedBoatExtraImage = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/appraised-boats/extra-images`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}



export const deleteAppraisedBoatExtraImage = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/appraised-boats/extra-images`, 
        'DELETE', 
        fd, 
        successCallback, 
        failedCallback)
}

