export const handleError = (error) => {
  if (error.response) {
    // Server responded with a status other than 2xx
    switch (error.response.status) {
      case 400:
        return Promise.reject(new Error("Bad Request"));
      case 401:
        return Promise.reject(new Error("Unauthorized"));
      case 403:
        return Promise.reject(new Error("Forbidden"));
      case 404:
        return Promise.reject(new Error("Not Found"));
      case 500:
        return Promise.reject(new Error("Internal Server Error"));
      default:
        return Promise.reject(new Error("An error occurred"));
    }
  } else if (error.request) {
    // Request was made but no response received
    return Promise.reject(new Error("No response from server"));
  } else {
    // Something else happened while setting up the request
    return Promise.reject(new Error("Error setting up request"));
  }
};
