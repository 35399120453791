import React from 'react'
import { fetchToken, removeToken } from './Auth'
import { authCheckToken } from './requests/auth'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function RequireToken(props) {
    
    const navigate = useNavigate()

    const [tokenChecked, setTokenChecked] = useState(false)

    useEffect(() => {
        var token = fetchToken()
        if (token === null) {
            navigate('/')
        }
        else {
            authCheckToken(
                (data) => {
                    setTokenChecked(true)
                },
                (data) => {
                    removeToken()
                    navigate('/')
                }
            )
        }
    }, [navigate])
    
    
    return (
        <div>
            {
                tokenChecked ? props.children : null
            }
        </div>
    )
}
