import { baseApiUrl } from "../settings";
import { 
    tokenRequiredRequestWithBody
} from "./baseRequest";




export const imageSearch = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/search/image`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}




export const keywordSearch = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/search/keyword`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}




export const similarSearch = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/search/similar`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}






