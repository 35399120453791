import React, { useState } from "react";
import {
  Container,
  Grid,
  Box,
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  Checkbox,
  IconButton,
  Button,
} from "@mui/material";
import Header from "../../universal_components/Header";
import DirectoryCard from "../home/components/DirectoryCard";
import { useNavigate } from "react-router-dom";
import MergingForm2 from "./components2/MergingForm2";
import ParentGraph2 from "./components2/ParentGraph2";
import MergedGraph2 from "./components2/MergedGraph2";
import DeleteIcon from "@mui/icons-material/Delete";
import { getPricingData } from "../../api/boat";

const graphColors = ["blue", "orange", "green"];

export default function MergingGraphPage2() {
  const navigate = useNavigate();
  const [valueData, setValueData] = useState(null);
  const [loadingValueData, setLoadingValueData] = useState(false);
  const [noPricingGuidance, setNoPricingGuidance] = useState(false);
  const [currencyData, setCurrencyData] = useState({
    currency: "AUD",
    abbr: "AUD",
  });
  const [pinnedGraphs, setPinnedGraphs] = useState([]);
  const [selectedGraphs, setSelectedGraphs] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [highlightCheckboxes, setHighlightCheckboxes] = useState(false);
  const [showMergeTooltip, setShowMergeTooltip] = useState(false);
  const [currencyTooltipShown, setCurrencyTooltipShown] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [deleteButtonColor, setDeleteButtonColor] = useState("primary");
  const [toolbarVisible, setToolbarVisible] = useState(false);
  const [mergedGraphData, setMergedGraphData] = useState(null);

  const handleSearchClick = () => {
    navigate("/search", { state: { client_profile_id: null } });
  };

  const handleMyClientsClick = () => {
    navigate("/my-clients");
  };

  const handleValueDataChange = (data, year, make, model, noData) => {
    setLoadingValueData(true);
    setNoPricingGuidance(noData);
    try {
      if (data) {
        const graph = {
          data,
          baseYear: year,
          title: `${make} ${model} Price Curve`,
          make,
          model,
        };
        setValueData(graph);
        setPinnedGraphs((prevPinned) => [...prevPinned, graph]);
        setToolbarVisible(true);
      } else {
        setValueData(null);
      }
    } catch (error) {
      console.error("Error updating value data:", error);
    } finally {
      setLoadingValueData(false);
    }
  };

  const handleCurrencyChange = async (currency) => {
    console.log("Currency received in MergingGraphPage2:", currency);
    setCurrencyData({ currency, abbr: currency });

    if (valueData) {
      setLoadingValueData(true);

      try {
        console.log("Fetching data with:", valueData.make, valueData.model, valueData.baseYear, currency);
        const pricingData = await getPricingData(
          valueData.make,
          valueData.model,
          valueData.baseYear,
          currency
        );
        if (pricingData.message) {
          console.log("No pricing guidance available.");
          setNoPricingGuidance(true);
          setValueData(null);
        } else {
          console.log("Pricing data fetched successfully:", pricingData);
          setNoPricingGuidance(false);
          setValueData({
            ...valueData,
            data: pricingData,
          });
        }
      } catch (error) {
        console.error("Error fetching pricing data:", error.message);
        setNoPricingGuidance(true);
        setValueData(null);
      } finally {
        setLoadingValueData(false);
      }
    }
  };

  const handlePinToggle = (graph) => {
    if (pinnedGraphs.includes(graph)) {
      setPinnedGraphs(pinnedGraphs.filter((g) => g !== graph));
      setSelectedGraphs(selectedGraphs.filter((g) => g !== graph));
    } else {
      setPinnedGraphs([...pinnedGraphs, graph]);
    }
  };

  const handleDeleteGraph = (graph) => {
    const index = pinnedGraphs.indexOf(graph);
    if (index !== -1) {
      pinnedGraphs.splice(index, 1);
      setPinnedGraphs([...pinnedGraphs]);
    }

    if (selectedGraphs.includes(graph)) {
      setSelectedGraphs(selectedGraphs.filter((g) => g !== graph));
    }

    if (graph === valueData) {
      setValueData(null);
      setNoPricingGuidance(false);
    }

    setToolbarVisible(pinnedGraphs.length > 0 || mergedGraphData !== null);
  };

  const isGraphPinned = (graph) => {
    return pinnedGraphs.some(
      (pinnedGraph) =>
        pinnedGraph.make === graph.make &&
        pinnedGraph.model === graph.model &&
        pinnedGraph.baseYear === graph.baseYear
    );
  };

  const handleGraphSelect = (graph) => {
    if (selectedGraphs.includes(graph)) {
      setSelectedGraphs(selectedGraphs.filter((g) => g !== graph));
    } else {
      setSelectedGraphs([...selectedGraphs, graph]);
    }
  };

  const getGraphColor = (index) => {
    return graphColors[index % graphColors.length];
  };

  const handleUnselectGraph = (graph) => {
    handleGraphSelect(graph);
  };

  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setSelectAll(checked);
    if (checked) {
      setSelectedGraphs([...pinnedGraphs]);
    } else {
      setSelectedGraphs([]);
    }
  };

  const handleDeleteAll = () => {
    if (mergedGraphData && pinnedGraphs.length === 0) {
      setSnackbarMessage(
        'To remove merged graphs, please use the "X" icon on the top right of the graph'
      );
      setSnackbarOpen(true);
    } else {
      setPinnedGraphs([]);
      setValueData(null);
      setSelectedGraphs([]);
      setNoPricingGuidance(false);
    }

    setToolbarVisible(mergedGraphData !== null);
  };

  const handleDeleteSelected = () => {
    if (selectedGraphs.length === 0) {
      setSnackbarMessage("Please select graphs you wish to delete");
      setSnackbarOpen(true);
      setDeleteButtonColor("error");
      setTimeout(() => setDeleteButtonColor("primary"), 2000);
      return;
    }

    selectedGraphs.forEach((graph) => {
      const index = pinnedGraphs.indexOf(graph);
      if (index !== -1) {
        pinnedGraphs.splice(index, 1);
      }
    });

    if (selectedGraphs.includes(valueData)) {
      setValueData(null);
      setNoPricingGuidance(false);
    }

    setPinnedGraphs([...pinnedGraphs]);
    setSelectedGraphs([]);

    if (mergedGraphData && pinnedGraphs.length === 0) {
      setSnackbarMessage(
        'To remove merged graphs, please use the "x" icon on the top right of the graph'
      );
      setSnackbarOpen(true);
    }

    setToolbarVisible(pinnedGraphs.length > 0 || mergedGraphData !== null);
  };

  const handleMergeGraphs = () => {
    if (selectedGraphs.length < 2) {
      setSnackbarMessage("You must select at least two graphs for merging");
      setHighlightCheckboxes(true);
      setSnackbarOpen(true);
      return;
    }

    let minX = Infinity;
    let maxX = -Infinity;

    selectedGraphs.forEach((graph) => {
      const xValues = Object.keys(graph.data.price_curve || {}).map(Number);
      const graphMinX = Math.min(...xValues);
      const graphMaxX = Math.max(...xValues);

      if (graphMinX < minX) minX = graphMinX;
      if (graphMaxX > maxX) maxX = graphMaxX;
    });

    const mergedData = {
      dataSets: selectedGraphs.map((graph) => graph.data),
      title: "Merged Graph",
      makeModelList: selectedGraphs.map(
        (graph) => `${graph.make} ${graph.model}`
      ),
      minX,
      maxX,
    };

    setMergedGraphData(mergedData);
    setSelectedGraphs([]);
    setToolbarVisible(true);
  };

  return (
    <>
      <Header signedIn={true} />
      <Container maxWidth={false} disableGutters>
        <Grid container style={{ minHeight: "100vh" }}>
          <Grid
            item
            xs={12}
            sm={12}
            lg={3}
            sx={{
              backgroundColor: "rgb(210, 210, 210)",
              pb: { xs: 5, md: 3, lg: 0 },
            }}
          >
            <Box
              sx={{
                backgroundImage: "url(./landing-img.jpeg)",
                width: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
                py: "10vh",
              }}
            >
              <Typography variant="h3" className="styled-header">
                Broker Portal
              </Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                md={6}
                lg={12}
                sx={{ mx: { xs: 2, sm: 2, md: 0 } }}
              >
                <DirectoryCard
                  title="My Clients"
                  description="View and manage your clients and their reports."
                  callToAction="Go to My Clients"
                  navigate={handleMyClientsClick}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={12}
                sx={{ mx: { xs: 2, sm: 2, md: 0 } }}
              >
                <DirectoryCard
                  title="Search"
                  description="Use our Proprietary Photolumination technology to find your clients their dream boat."
                  callToAction="Go to Search"
                  navigate={handleSearchClick}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            lg={9}
            sx={{
              backgroundImage: "url(./classic_yacht.jpg)",
              minHeight: "100vh",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
                p: { xs: 1, md: 2 },
                position: "relative",
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={5}>
                  <Box
                    sx={{
                      p: 2,
                      backgroundColor: "white",
                      borderRadius: 2,
                    }}
                  >
                    <MergingForm2
                      onValueDataChange={handleValueDataChange}
                      onCurrencyChange={handleCurrencyChange}
                      pinnedGraphs={pinnedGraphs}
                      handleGraphSelect={handleGraphSelect}
                      selectedGraphs={selectedGraphs}
                      mergeGraphs={handleMergeGraphs}
                      showMergeTooltip={showMergeTooltip}
                      setShowMergeTooltip={setShowMergeTooltip}
                      currencyTooltipShown={currencyTooltipShown}
                      setCurrencyTooltipShown={setCurrencyTooltipShown}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} lg={7}>
                  {toolbarVisible && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                        p: 2,
                        borderRadius: 2,
                        mb: 2,
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          color: "#034d70",
                        }}
                      >
                        <Typography
                          variant="body1"
                          sx={{ mr: 1, fontWeight: "bold" }}
                        >
                          Select All
                        </Typography>
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAllChange}
                          sx={{
                            color: "#034d70",
                            "&.Mui-checked": {
                              color: "#034d70",
                            },
                          }}
                        />
                        <IconButton
                          onClick={handleDeleteSelected}
                          sx={{
                            color: "#034d70",
                            "&:hover": {
                              color: "#0a93d9",
                            },
                          }}
                        >
                          <DeleteIcon />
                          <Typography
                            variant="body1"
                            sx={{
                              ml: 1,
                              color: "#034d70",
                              fontWeight: "bold",
                            }}
                          >
                            Delete Selected
                          </Typography>
                        </IconButton>
                      </Box>

                      <Button
                        variant="contained"
                        color={deleteButtonColor}
                        onClick={handleDeleteAll}
                        startIcon={<DeleteIcon />}
                        sx={{
                          ml: 2,
                          fontWeight: "bold",
                          textTransform: "none",
                          backgroundColor: "#034d70",
                          "&:hover": {
                            backgroundColor: "#0a93d9",
                          },
                        }}
                      >
                        Remove All Except Merged
                      </Button>
                    </Box>
                  )}
                  <Box
                    sx={{
                      borderRadius: 2,
                      position: "relative",
                      mb: { xs: 2, lg: 0 },
                      display: "flex",
                      flexDirection: "column",
                      gap: 2,
                    }}
                  >
                    {!valueData && loadingValueData ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <CircularProgress sx={{ height: "10vh", mt: 2 }} />
                      </Box>
                    ) : noPricingGuidance ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "40vh",
                          backgroundColor: "white",
                          borderRadius: 2,
                        }}
                      >
                        <Typography variant="h6" color="textSecondary">
                          No pricing guidance available for the given make and
                          model
                        </Typography>
                      </Box>
                    ) : (
                      valueData &&
                      !isGraphPinned(valueData) && (
                        <Box
                          sx={{
                            width: "100%",
                            mb: 1,
                            zIndex: 1,
                          }}
                        >
                          <ParentGraph2
                            dataSets={[valueData.data]}
                            title={valueData.title}
                            currency={currencyData.currency}
                            conversionRate={1}
                            abbr={currencyData.abbr}
                            onPinToggle={() => handlePinToggle(valueData)}
                            onDelete={() => {
                              handleDeleteGraph(valueData);
                              setValueData(null);
                              setNoPricingGuidance(false);
                            }}
                            isPinned={true}
                            color={getGraphColor(0)}
                            makeModelList={[
                              `${valueData.make} ${valueData.model}`,
                            ]}
                            selected={selectedGraphs.includes(valueData)}
                            onSelect={() => handleUnselectGraph(valueData)}
                            showCheckbox={true}
                            highlightCheckboxes={highlightCheckboxes}
                          />
                        </Box>
                      )
                    )}
                    {pinnedGraphs.map((graph, index) => (
                      <Box
                        key={index}
                        sx={{
                          width: "100%",
                          height: "auto",
                          zIndex: 1,
                          order: index - 2,
                          position: "relative",
                        }}
                      >
                        <ParentGraph2
                          dataSets={[graph.data]}
                          title={graph.title}
                          currency={currencyData.currency}
                          conversionRate={1}
                          abbr={currencyData.abbr}
                          onPinToggle={() => handlePinToggle(graph)}
                          onDelete={() => handleDeleteGraph(graph)}
                          isPinned={true}
                          color={getGraphColor(index)}
                          selected={selectedGraphs.includes(graph)}
                          onSelect={() => handleUnselectGraph(graph)}
                          makeModelList={[`${graph.make} ${graph.model}`]}
                          showCheckbox={true}
                          highlightCheckboxes={highlightCheckboxes}
                        />
                      </Box>
                    ))}
                    {mergedGraphData && (
                      <Box
                        sx={{
                          width: "100%",
                          height: "auto",
                          zIndex: 1,
                          position: "relative",
                          order: pinnedGraphs.length + 1,
                        }}
                      >
                        <MergedGraph2
                          dataSets={mergedGraphData.dataSets}
                          title={mergedGraphData.title}
                          currency={currencyData.currency}
                          conversionRate={1}
                          abbr={currencyData.abbr}
                          onDelete={() => setMergedGraphData(null)}
                          color="multi"
                          makeModelList={mergedGraphData.makeModelList}
                          minX={mergedGraphData.minX}
                          maxX={mergedGraphData.maxX}
                        />
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity="warning"
          sx={{ backgroundColor: "#034d70", color: "#fff" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
