import React from 'react'
import AnalysisField from './AnalysisField'



export default function AnalysisFields(props) {
    return (
        <div>
            {
                props.fields.map((field, index) => {
                    return (
                        <AnalysisField themePrimaryText={props.themePrimaryText} key={field.id} field={field} deleteFieldCallback={props.reloadReportCallback} />
                    )
                })
            }
        </div>
    )
}
