import { baseApiUrl } from "../settings";
import { tokenRequiredRequest, tokenRequiredRequestWithBody } from "./baseRequest";



export const adminCreateAccount = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/admin/create-account`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}



export const adminGetAllAccounts = (successCallback, failedCallback) => {
    return tokenRequiredRequest(
        `${baseApiUrl}/admin/get-all-accounts`, 
        'GET', 
        successCallback, 
        failedCallback)
}




export const adminDeleteAccount = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/admin/delete-account`, 
        'DELETE', 
        fd, 
        successCallback, 
        failedCallback)
}




export const adminUpdatePassword = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/admin/update-password`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}




