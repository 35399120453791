import { baseApiUrl } from "../../settings";
import { 
    tokenRequiredRequestWithBody
} from "../baseRequest";




export const createReportSummaryField = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/summary-fields`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}



export const updateReportSummaryField = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/summary-fields`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}



export const deleteReportSummaryField = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/reports/summary-fields`, 
        'DELETE', 
        fd, 
        successCallback, 
        failedCallback)
}