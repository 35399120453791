import React from 'react'
import Page from '../../../generic_report_components/Page'
import ColorCut from '../../../generic_report_components/ColorCut'
import SummaryField from './summary_page_components/SummaryField'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Form from 'react-bootstrap/Form'
import { useState, useEffect } from 'react'
import { createReportSummaryField } from '../../../requests/report/reportSummaryField'
import { getBrokerDetails } from '../../../requests/brokerDetails'

export default function SummaryPage(props) {
    
    const [summaryFields, setSummaryFields] = useState(props.report.summary_fields)
    const [showAddFieldModal, setShowAddFieldModal] = useState(false);
    const [addFieldTitle, setAddFieldTitle] = useState('');
    const [addFieldBody, setAddFieldBody] = useState('');
    const [titleError, setTitleError] = useState('');
    const [bodyError, setBodyError] = useState('');


    function validateFormData() {
        var valid = true;

        setTitleError('');
        setBodyError('');

        if (addFieldTitle.length === 0) {
            setTitleError('Title is required')
            valid = false;
        }

        if (addFieldBody.length === 0) {
            setBodyError('Body is required')
            valid = false;
        }

        return valid;
    }


    function handleCreateAnaylsisField() {
        if (!validateFormData()) {
            return;
        }

        var fd = {
            report_id: props.report.id,
            title: addFieldTitle,
            body: addFieldBody
        }

        createReportSummaryField(fd, 
            (data) => {
                setSummaryFields([...summaryFields, data.report_summary_field]);
                setShowAddFieldModal(false);
                setAddFieldTitle('');
                setAddFieldBody('');
            },
            (data) => {
                console.log(data)
            }
        )
    }


    function deleteFieldCallback(fieldId) {
        var newFields = summaryFields.filter((field) => {
            return field.id !== fieldId;
        })

        setSummaryFields(newFields);
    }



    const [brokerName, setBrokerName] = useState(null);
    const [brokerEmail, setBrokerEmail] = useState(null);
    const [brokerPhone, setBrokerPhone] = useState(null);


    useEffect(() => {
        getBrokerDetails(
            (data) => {
                if (data.broker_details) {
                    setBrokerName(data.broker_details.name)
                    setBrokerEmail(data.broker_details.email)
                    setBrokerPhone(data.broker_details.phone)
                }
            },
            (data) => {
                console.log(data)
            }
        )
    }, [])


    
    return (
        <>
            <Page
                includeFooter={true} 
                brokerDetails={{name: brokerName, email: brokerEmail, phone: brokerPhone}}
                theme={{
                    theme:props.theme,
                    themePrimaryText:props.themePrimaryText,
                }}
            >
                <ColorCut theme={props.theme} height="15%" rightCut="70%">
                    <div className="report-p-2">
                        <h1 style={{color: props.themePrimaryText}} className='report-title'>Summary</h1>
                    </div>
                </ColorCut>
                <div className="report-p-3">
                    {
                        summaryFields.map((field, index) => {
                            return (
                                <SummaryField themePrimaryText={props.themePrimaryText} field={field} key={index} deleteFieldCallback={deleteFieldCallback} />
                            )
                        })
                    }
                </div>

            </Page>
            <div className='mild-neumorphic-card report-button-bar' style={{marginLeft: 'auto', marginRight: 'auto'}}>
                <Button className='button-bar-button primary-bg' onClick={() => {setShowAddFieldModal(true)}} style={{width: '100%', borderRadius: '0px', padding: '10px'}}>Add Field</Button>
            </div>

            <Modal show={showAddFieldModal} onHide={() => setShowAddFieldModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Add Field</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <Form.Control style={{marginTop: '20px'}} type="text" placeholder="Title" value={addFieldTitle} onChange={(e) => {setAddFieldTitle(e.target.value)}} />
                        <p style={{color: 'red'}}>{titleError}</p>
                        
                        <Form.Control style={{marginTop: '20px'}} as='textarea' type="text" placeholder="Body" value={addFieldBody} onChange={(e) => {setAddFieldBody(e.target.value)}} rows={10} />
                        <p style={{color: 'red'}}>{bodyError}</p>

                    </div>
                    <div style={{marginTop: '20px'}}>
                        <Button className="primary-bg" onClick={() => {handleCreateAnaylsisField()}}>Save</Button>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}
