



export const tokenRequiredRequest = (url, method, successCallback, failedCallback) => {
    fetch(url, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('wfybtoken')}`
        },
    })
    .then(res => res.json())
    .then(data => {
        if (data.success === true) {
            successCallback(data)
        } else {
            failedCallback(data)
        }
    })
}




export const tokenRequiredRequestWithBody = (url, method, body, successCallback, failedCallback) => {
    fetch(url, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('wfybtoken')}`
        },
        body: JSON.stringify(body)
    })
    .then(res => res.json())
    .then(data => {
        if (data.success === true) {
            successCallback(data)
        } else {
            failedCallback(data)
        }
    })
}




export const tokenNotRequiredRequest = (url, method, successCallback, failedCallback) => {
    fetch(url, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        },
    })
    .then(res => res.json())
    .then(data => {
        if (data.success === true) {
            successCallback(data)
        } else {
            failedCallback(data)
        }
    })
}




export const tokenNotRequiredRequestWithBody = (url, method, body, successCallback, failedCallback) => {
    fetch(url, {
        method: method,
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
    })
    .then(res => res.json())
    .then(data => {
        if (data.success === true) {
            successCallback(data)
        } else {
            failedCallback(data)
        }
    })
}