import React from 'react'
import Page from '../../../../generic_report_components/Page';
import ColorCut from '../../../../generic_report_components/ColorCut';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import { useState } from 'react';
import { createReportBoatExtraImage, deleteReportBoatExtraImage } from '../../../../requests/report/reportBoat';


export default function ExtraImages (props) {

    const [extraImages, setExtraImages] = useState(props.boat.extra_images);
    const allImages = [props.boat.image_url, ...JSON.parse(props.boat.image_urls)];
    const [showSelectImagesModal, setShowSelectImagesModal] = useState(false);


    function handleSelectImage(image) {
        if (extraImages.length >= 10) {
            return;
        }
        var fd = {
            report_boat_id: props.boat.id,
            image_url: image
        }

        createReportBoatExtraImage(fd, 
            (data) => {
                console.log(data);
                if (data.success === true) {
                    setExtraImages([...extraImages, data.report_boat_extra_image]);
                }
            },
            (data) => {
                console.log(data);
            }
        )
    }


    function handleDeleteImage(image_id) {
        var fd = {
            image_id: image_id
        }

        deleteReportBoatExtraImage(fd, 
            (data) => {
                if (data.success === true) {
                    var newImages = [];
                    extraImages.forEach((image, index) => {
                        if (image.id !== image_id) {
                            newImages.push(image);
                        }
                    })
                    setExtraImages(newImages);
                }
            },
            (data) => {
                console.log(data);
            }
        )

    }


    function SelectableImages() {
        var selectable = [];
        allImages.forEach((image, index) => {
            var isSelectable = true;
            for (var i = 0; i < extraImages.length; i++) {
                if (extraImages[i].image_url === image) {
                    isSelectable = false;
                }
            }
            if (isSelectable) {
                selectable.push(image);
            }
        })
        return selectable;
    }



    return (
        <div style={{paddingTop: '40px'}}>
            <Page>
            <ColorCut theme={props.theme} height='10%' rightCut='80%'>
                <div className='report-p-2'>
                    <h1 className='report-title' style={{color: props.themePrimaryText}}>{props.boat.title} Images</h1>
                </div>
            </ColorCut>
                <Row>
                {
                    extraImages.map((image, index) => {
                    return (
                        <Col key={index} xs={6} sm={6} md={6} lg={6} xl={6}>
                            <div className='report-p-1' onClick={() => {setShowSelectImagesModal(true)}}>
                                <div
                                className='extra-image'
                                style={{
                                    backgroundImage: `url(${image.image_url})`,
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    width: '100%',
                                }}>

                                </div>
                            </div>
                        </Col>
                    )
                    })
                }
                </Row>
            </Page>
            <Container className='mild-neumorphic-card report-button-bar'>
                <Row>
                <Col className='g-0'>
                    <Button className='button-bar-button primary-bg' style={{width: '100%', borderRadius: '0px'}} onClick={() => {setShowSelectImagesModal(true)}} >Manage Images</Button>
                </Col>
                </Row>
            </Container>
            <Modal size='lg' show={showSelectImagesModal} onHide={() => setShowSelectImagesModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Manage Images</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Selected Images (Select up to 10)</h4>
                    {
                        extraImages.length > 0 ? 
                            <Row style={{marginBottom: '40px'}}>
                                {
                                    extraImages.map((image, index) => {
                                        return (
                                            <Col key={index} md={4} lg={4}>
                                                <Image onClick={() => {handleDeleteImage(image.id)}} className='mild-neumorphic-card hero-image' src={image.image_url} style={{width: '100%', height: 'auto', marginTop: '20px'}}/>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>
                        :
                        <h6 style={{marginBottom: '40px'}}>No Images Selected</h6>
                    }
                    <h4>Select From</h4>
                    <Row>
                        {
                            SelectableImages().map((image, index) => {
                                return (
                                    <Col key={index} md={4} lg={4}>
                                        <Image onClick={() => {handleSelectImage(image)}} className='mild-neumorphic-card hero-image' src={image} style={{width: '100%', height: 'auto', marginTop: '20px'}}/>
                                    </Col>
                                )
                            })
                        }
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="primary-bg" onClick={() => setShowSelectImagesModal(false)}>
                        Done
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
