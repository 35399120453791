import React from 'react'
import SummaryField from './SummaryField'


export default function SummaryFields(props) {
    return (
        <div>
            {
                props.report.summary_fields.map((field, index) => {
                    return (
                        <SummaryField 
                            key={field.id}
                            field={field}
                            theme={props.theme} 
                            themePrimaryText={props.themePrimaryText}
                            themeSecondaryText={props.themeSecondaryText}
                            reloadReportCallback={props.reloadReportCallback}
                        />
                    )
                })
            }
        </div>
    )
}
