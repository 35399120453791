import React from 'react'
import Image from 'react-bootstrap/Image'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useState } from 'react';
import {useDropzone} from 'react-dropzone';
import { updateAppraisedBoatImage } from '../../../../requests/appraisal_report/appraisedBoat';

export default function AppraisedBoatHeroImage(props) {

  const [imageChanged, setImageChanged] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editBoatImage, setEditBoatImage] = useState(props.appraisedBoat.image_url);
  const [boatImage, setBoatImage] = useState(props.appraisedBoat.image_url);


  const {getRootProps, getInputProps} = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
        setImageChanged(true);
        var file = acceptedFiles[0];
        if(file){
            var reader = new FileReader();

            reader.onload = function(e){
                setEditBoatImage(e.target.result)
            }

            reader.readAsDataURL(file);
        }
    }
  });


  function handleSaveEditImage() {
    if (!imageChanged) return;
    var fd = {
      appraised_boat_id: props.appraisedBoat.id,
      image: editBoatImage
    }
    updateAppraisedBoatImage(fd, 
      (data) => {
        setBoatImage(data.appraised_boat.image_url);
        setShowModal(false);
      },
      (data) => {
        console.log(data);
      }
    )
  }


  return (
    <>
      <div className='hero-image' 
        style={{
          backgroundImage: `url(${boatImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
        onClick={() => setShowModal(true)}
      ></div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="styled-header">Edit Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="image-upload" style={{textAlign: 'center', marginTop:'40px'}} {...getRootProps()}  >
              <Image className='upload-image mild-neumorphic-card' id="previewImg" src={editBoatImage} style={{width: '100%'}} />
              <input {...getInputProps()} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="primary-bg" onClick={() => {handleSaveEditImage()}}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>

  )
}
