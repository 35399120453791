import React from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { useState } from 'react'
import { createReportPdf } from '../../../requests/report/report'
import AddBoatsButton from './AddBoatsButton'
import ChangeThemeButton from './ChangeThemeButton'
import { reportIncludeMarketInsights, reportIncludeListingUrl } from '../../../requests/report/report'
import AnimatedLogo from '../../../universal_components/AnimatedLogo'

export default function SideCard(props) {

    const [includeListingUrlsCheck, setIncludeListingUrlsCheck] = useState(props.report.include_listing_url);
    const [includeMarketInsightsCheck, setIncludeMarketInsightsCheck] = useState(props.report.include_market_insights);
    const [showPdfBuildingModal, setShowPdfBuildingModal] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');


    function changeMarketInsightsInclusion(include) {
        setIncludeMarketInsightsCheck(!includeMarketInsightsCheck)
        let fd = {
            id: props.report.id,
            include_market_insights: include
        }
        reportIncludeMarketInsights(fd, 
            (data) => {
                props.handleMarketInsightsInclusionCallback();
            },
            (data) => {
                console.log(data)
            }
        )
    }

    function changeListingUrlsInclusion(include) {
        setIncludeListingUrlsCheck(!includeListingUrlsCheck)
        let fd = {
            id: props.report.id,
            include_listing_url: include
        }
        reportIncludeListingUrl(fd, 
            (data) => {
                props.handleListingUrlsInclusionCallback();
            },
            (data) => {
                console.log(data)
            }
        )
    }

    function handleCreateReportPdf() {
        setShowPdfBuildingModal(true);
        setPdfUrl('');
        var fd = {
            report_id: props.report.id
        }
        createReportPdf(fd, 
            (data) => {
                setPdfUrl(data.pdf_url);
            },
            (data) => {
                console.log(data)
            }
        )
    }


    return (
        <>
            <div className='sidecard'>
                <div style={{
                    backgroundImage: 'url(./landing-img.jpeg)', 
                    width: '100%', 
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    textAlign: 'center',
                    paddingTop: '10vh',
                    paddingBottom: '10vh',
                }}>
                    <h1 className='styled-header'>Build Report</h1>
                </div>
                <div style={{paddingLeft: '50px', paddingRight: '50px'}}>
                    <Form.Check checked={includeListingUrlsCheck} onChange={() => {changeListingUrlsInclusion(!includeListingUrlsCheck)}} size='lg' style={{marginTop: '30px'}} type='switch' label='Include Listing URLs' />
                </div>
                <div style={{paddingLeft: '50px', paddingRight: '50px'}}>
                    <Form.Check checked={includeMarketInsightsCheck} onChange={() => {changeMarketInsightsInclusion(!includeMarketInsightsCheck)}} size='lg' style={{marginTop: '30px'}} type='switch' label='Include Market Insights' />
                </div>

                <div style={{marginTop: '50px', textAlign: 'center'}}>
                    <ChangeThemeButton setThemeCallback={props.setThemeCallback} />
                    <AddBoatsButton report={props.report} addReportBoatCallback={props.addReportBoatCallback} />
                    <Button style={{margin: '15px'}} onClick={() => {handleCreateReportPdf()}} className='secondary-bg'>Create PDF</Button>
                </div>
            </div>


            <Modal show={showPdfBuildingModal}>
                <Modal.Header>
                    <Modal.Title className="styled-header">Building PDF</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col>
                            {
                                pdfUrl.length > 0 ?
                                    <div>
                                        <p>PDF is ready!</p>
                                        <div>
                                            <Button className='secondary-bg' target='_blank' href={pdfUrl}>View PDF</Button>
                                            <Button style={{marginLeft: '30px'}} className='secondary-bg' onClick={() => {setShowPdfBuildingModal(false)}}>Close</Button>
                                        </div>
                                    </div>
                                :
                                    <div style={{textAlign: 'center'}}>
                                        <AnimatedLogo />
                                        <h4 className='styled-header'>This could take up to 30 seconds</h4>
                                    </div>
                            }
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>    
                
        </>
    )
}
