import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Page from '../../../generic_report_components/Page'
import ColorCut from '../../../generic_report_components/ColorCut'
import HeroImage from './boat_page_components/HeroImage'
import Specifications from './boat_page_components/Specifications'
import Requirements from './boat_page_components/Requirements'
import AnalysisFields from './boat_page_components/AnalysisFields'
import ExtraImages from './boat_page_components/ExtraImages'
import Title from './boat_page_components/Title'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useState, useEffect } from 'react'
import { createReportBoatField } from '../../../requests/report/reportBoatField'
import { deleteReportBoat } from '../../../requests/report/reportBoat'
import { getBrokerDetails } from '../../../requests/brokerDetails'

export default function BoatPage(props) {

  const [showAddFieldModal, setShowAddFieldModal] = useState(false);
  const [addFieldTitle, setAddFieldTitle] = useState('');
  const [addFieldBody, setAddFieldBody] = useState('');
  const [selectedImage, setSelectedImage] = useState(props.boat.selected_image_url);
  const [fieldTitleError, setFieldTitleError] = useState('');
  const [fieldBodyError, setFieldBodyError] = useState('');


  function validateFormData() {
    var valid = true;
    setFieldTitleError('');
    setFieldBodyError('');

    if (addFieldTitle.length < 1) {
        setFieldTitleError('Title is required');
        valid = false;
    } else {
        setFieldTitleError('');
    }

    if (addFieldBody.length < 1) {
        setFieldBodyError('Body is required');
        valid = false;
    } else {
        setFieldBodyError('');
    }

    return valid;
  }


  function handleCreateAnaylsisField() {

    if (!validateFormData()) {
        return;
    }

    var fd = {
        report_boat_id: props.boat.id,
        title: addFieldTitle,
        body: addFieldBody
    }
    createReportBoatField(fd, 
      (data) => {
        setAddFieldTitle('');
        setAddFieldBody('');
        setShowAddFieldModal(false);
        props.reloadReportCallback();
      },
      (data) => {
        console.log(data);
      }
    )
  }





  function handleDeleteBoat() {
    var fd = {
        id: props.boat.id
    }

    deleteReportBoat(fd, 
      (data) => {
        props.reloadReportCallback();
      },
      (data) => {
        console.log(data);
      }
    )
  }




  function updateSelectedImageCallback(imageUrl) {
    setSelectedImage(imageUrl);
  }



    const [brokerName, setBrokerName] = useState(null);
    const [brokerEmail, setBrokerEmail] = useState(null);
    const [brokerPhone, setBrokerPhone] = useState(null);


    useEffect(() => {
        getBrokerDetails(
            (data) => {
                if (data.broker_details) {
                    setBrokerName(data.broker_details.name)
                    setBrokerEmail(data.broker_details.email)
                    setBrokerPhone(data.broker_details.phone)
                }
            },
            (data) => {
                console.log(data)
            }
        )
    }, [])



  return (
    <div style={{marginBottom: '40px'}}>
      <Page
        includeFooter={true} 
        brokerDetails={{name: brokerName, email: brokerEmail, phone: brokerPhone}}
        theme={{
            theme:props.theme,
            themePrimaryText:props.themePrimaryText,
        }}
      >
          <HeroImage imageUrl={selectedImage} boat={props.boat} updateSelectedImageCallback={updateSelectedImageCallback} />
          <ColorCut theme={props.theme} height='12%' rightCut='70%'>
              <Title 
                boat={props.boat} 
                themePrimaryText={props.themePrimaryText}
                themeSecondaryText={props.themeSecondaryText}
                report={props.report} 
              />
          </ColorCut>
          <Container fluid>
              <Row>
                  <Col>
                    <Specifications 
                      report={props.report}
                      boat={props.boat}
                      themePrimaryText={props.themePrimaryText} 
                    />
                  </Col>
              </Row>
              <Row>
                  <Col xs={8} sm={8} md={8} lg={8} xl={8} className="g-0">
                    <AnalysisFields 
                      fields={props.boat.fields}
                      themePrimaryText={props.themePrimaryText} 
                      reloadReportCallback={props.reloadReportCallback}
                    />
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4} className="g-0">
                    <div className='report-ml-1 report-mr-1'>
                      <Requirements 
                        theme={props.theme} 
                        themePrimaryText={props.themePrimaryText}
                        themeSecondaryText={props.themeSecondaryText}
                        boat={props.boat} 
                      />
                    </div>
                  </Col>
              </Row>
          </Container>
      </Page>
      <Container className='mild-neumorphic-card report-button-bar'>
        <Row>
          <Col className='g-0'>
            <Button className='button-bar-button danger-bg' style={{width: '100%', borderRadius: '0px'}} onClick={() => {handleDeleteBoat()}} >Remove Boat</Button>
          </Col>
          <Col className='g-0'>
            <Button className='button-bar-button secondary-bg' style={{width: '100%', borderRadius: '0px'}} href={props.boat.url} rel="noreferrer" target='_blank'>View Listing</Button>
          </Col>
          <Col className='g-0'>
            <Button className='button-bar-button primary-bg' style={{width: '100%', borderRadius: '0px'}} onClick={() => {setShowAddFieldModal(true)}}>Add Field</Button>
          </Col>
        </Row>
      </Container>

      <ExtraImages 
        theme={props.theme}
        themePrimaryText={props.themePrimaryText}
        themeSecondaryText={props.themeSecondaryText}
        boat={props.boat} 
      />



      <Modal show={showAddFieldModal} onHide={() => setShowAddFieldModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title className="styled-header">Add Field</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Form.Control style={{marginTop: '20px'}} value={addFieldTitle} type="text" placeholder="Title" onChange={(e) => setAddFieldTitle(e.target.value)} />
            <p style={{color: 'red'}}>{fieldTitleError}</p>
            
            <Form.Control style={{marginTop: '20px'}} value={addFieldBody} as='textarea' type="text" placeholder="Body" onChange={(e) => setAddFieldBody(e.target.value)} rows={10} />
            <p style={{color: 'red'}}>{fieldBodyError}</p>
          
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="primary-bg" onClick={() => {handleCreateAnaylsisField()}}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}
