import { baseApiUrl } from "../../settings";
import { 
    tokenRequiredRequest,
    tokenRequiredRequestWithBody
} from "../baseRequest";



export const createAppraisalReport = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}



export const getAppraisalReportById = (appraisalReportId, successCallback, failedCallback) => {
    return tokenRequiredRequest(
        `${baseApiUrl}/appraisal-reports/${appraisalReportId}`, 
        'GET',
        successCallback,
        failedCallback)
}



export const getAppraisalReports = (clientProfileId, successCallback, failedCallback) => {
    return tokenRequiredRequest(
        `${baseApiUrl}/appraisal-reports?client_profile_id=${clientProfileId}`, 
        'GET', 
        successCallback, 
        failedCallback)
}



export const getAppraisalReportsArchived = (successCallback, failedCallback) => {
    return tokenRequiredRequest(
        `${baseApiUrl}/appraisal-reports?archived=True`,
        'GET',
        successCallback,
        failedCallback)
}



export const createAppraisalReportPDF = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/create-pdf`, 
        'POST', 
        fd, 
        successCallback, 
        failedCallback)
}



export const archiveAppraisalReport = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/archive`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}



export const appraisalReportIncludeListingUrl = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/include-listing-url`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}



export const appraisalReportIncludeMarketInsights = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/include-market-insights`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}



export const updateAppraisalReportListingPrice = (fd, successCallback, failedCallback) => {
    return tokenRequiredRequestWithBody(
        `${baseApiUrl}/appraisal-reports/listing-price`, 
        'PUT', 
        fd, 
        successCallback, 
        failedCallback)
}


