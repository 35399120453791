import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from 'react-router-dom'
import { authLogin, authInitiateResetPassword } from '../../requests/auth'
import { setToken } from '../../Auth'


import { useState } from 'react'


export default function Landing() {

    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false)
    const [resetEmail, setResetEmail] = useState('')
    const [resetLinkSent, setResetLinkSent] = useState(false)
    const [resetError, setResetError] = useState(null)


    function handleSignInSuccess(data) {
        setToken(data.token)
        navigate('/home')
    }

    function handleSignInFailed(data) {
        console.log(data)
    }


    function handleSignIn() {
        var fd ={
            email: email,
            password: password
        }
        
        authLogin(fd, handleSignInSuccess, handleSignInFailed)
    }


    function handleInitiateResetPasswordSuccess() {
        setResetLinkSent(true)
    }


    function handleInitiateResetPasswordFailed() {
        setResetError('Account matching that email not found')
        setResetEmail('')
    }



    function handleInitiateResetPassword() {
        var fd = {
            email: resetEmail
        }
        console.log(fd)
        authInitiateResetPassword(fd, handleInitiateResetPasswordSuccess, handleInitiateResetPasswordFailed)
    }
    



  return (
    <>
        <Container fluid>
            <Row>
                <Col className='g-0'>
                    <div style={{
                        backgroundImage: 'url(./classic_yacht.jpg)', 
                        minHeight: '100vh', 
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundAttachment: 'fixed'
                    }}>
                        <div style={{
                            backgroundColor: 'rgba(11, 25, 34, 0.7)',
                            minHeight: '100vh'
                        }}>
                            <Container fluid>
                                <Row style={{minHeight: '100vh'}}>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={7} className='d-none d-xs-none d-sm-none d-md-none d-lg-block d-xl-block'>
                                        <div style={{width: '100%', textAlign: 'left', paddingTop: '25vh', paddingLeft: '10vw', paddingRight: '5vw'}}>
                                            <h1 className='landing-page-text'><span style={{color: 'white'}}></span> <span className='styled-header'><b>WE</b><span style={{fontWeight: '100'}}>FIND</span><b>YOUR</b><span style={{fontWeight: '100'}}>BOAT</span></span> <span style={{color: 'white'}}>Broker Portal</span></h1>
                                            <h4 style={{color: 'white', fontSize: '25px', marginTop: '30px'}}>Artificial Intelligence Driven <span className='primary-color'>Boat Search Solutions</span></h4>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={6} xl={5} className='g-0'>
                                        <div style={{backgroundColor: 'white', height: '100%'}}>
                                            <div style={{
                                                backgroundImage: 'url(./landing-img.jpeg)', 
                                                width: '100%', 
                                                backgroundSize: 'cover',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                            }}>
                                                <div style={{textAlign: 'center', paddingTop: '5vh', paddingBottom: '5vh'}}>
                                                    <Image src='./new_logo_trans.png' style={{width: '70%'}} />
                                                </div>
                                            </div>
                                            
                                            <div style={{padding: '5vw'}}>
                                                <div style={{width: '100%', paddingLeft: '40px', paddingRight: '40px', paddingTop: '5vh'}}>
                                                    <h1 className='styled-header' style={{marginBottom: '20px'}}>Sign In</h1>
                                                    <Form.Control value={email} onChange={(e) => {setEmail(e.target.value)}} type="email" placeholder="Email" />
                                                    <Form.Control value={password} onChange={(e) => {setPassword(e.target.value)}} style={{marginTop: '20px'}} type="password" placeholder="Password" />
                                                </div>
                                                <div style={{paddingLeft: '40px', paddingRight: '40px', paddingTop: '20px'}}>
                                                    <Button onClick={() => {handleSignIn()}} className='primary-bg' type="submit" style={{width: '100%'}}>Sign In</Button>
                                                </div>
                                                <div className='editable-section' style={{padding: '40px'}}>
                                                    <p onClick={() => {setShowForgotPasswordModal(true)}}>Forgot Password?</p>
                                                </div>
                                            </div>
                                        
                                        </div>
                                    </Col>
                                </Row>
                            </Container>

                        </div>

                    </div>
                </Col>
            </Row>
        </Container>
        <Modal show={showForgotPasswordModal} onHide={() => {setShowForgotPasswordModal(false)}}>
            <Modal.Header closeButton>
                <Modal.Title className="styled-header">Forgot Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    resetLinkSent ?
                        <div>
                            <p>Reset link sent</p>
                            <p>Please check your email</p>
                        </div>
                    :
                        <div>
                            <Form.Control value={resetEmail} onChange={(e) => {setResetEmail(e.target.value)}} type="email" placeholder="Email" />
                            {
                                resetError ?
                                    <p style={{color: 'red'}}>{resetError}</p>
                                :
                                    null
                            }
                        </div>
                }
            </Modal.Body>
            <Modal.Footer>
                {
                    resetLinkSent ?
                        <Button className='secondary-bg' onClick={() => {setShowForgotPasswordModal(false)}}>Close</Button>
                    :
                        <Button className="primary-bg" onClick={() => {handleInitiateResetPassword()}}>Send Reset Link</Button>
                }
            </Modal.Footer>
        </Modal>
    </>

  )
}
