import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Image from 'react-bootstrap/Image'
import { createClientImage, getClientImages, archiveClientImage } from '../../../requests/clientImage'
import { useState, useEffect } from 'react'
import {useDropzone} from 'react-dropzone';


export default function ClientImages(props) {
    
    const [showCreateImageModal, setShowCreateImageModal] = useState(false);

    const [showArchiveImageModal, setShowArchiveImageModal] = useState(false);

    const [imageToAdd, setImageToAdd] = useState('./placeholder.jpg');

    const [imageToRemove, setImageToRemove] = useState(null);

    const[clientImages, setClientImages] = useState([])


    useEffect(() => {
        getClientImages(
            props.clientProfileId,
            (data) => {
                console.log(data)
                setClientImages(data.client_images)
            },
            (data) => {
                console.log(data)
            }
        )
    }, [props.clientProfileId])


    function reloadClientImages() {
        getClientImages(
            props.clientProfileId,
            (data) => {
                console.log(data)
                setClientImages(data.client_images)
            },
            (data) => {
                console.log(data)
            }
        )
    }




    const {getRootProps, getInputProps} = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            var file = acceptedFiles[0];
            if(file){
                var reader = new FileReader();
    
                reader.onload = function(e){
                    setImageToAdd(e.target.result)
                }
    
                reader.readAsDataURL(file);
            }
        }
    });


    function handleAddImage() {
        if (imageToAdd === './placeholder.jpg') {
            return
        }

        var fd = {
            'client_profile_id': props.clientProfileId,
            'image_bytes': imageToAdd
        }

        createClientImage(fd,
            (data) => {
                console.log(data)
                setImageToAdd('./placeholder.jpg')
                reloadClientImages()
            },
            (data) => {
                console.log(data)
            }
        )
    }



    function handleImageClick(image) {
        setImageToRemove(image)
        setShowArchiveImageModal(true)
    }


    function handleArchiveImage() {
        var fd = {
            'client_image_id': imageToRemove.id,
            'archive': true
        }

        console.log(fd)

        archiveClientImage(fd,
            (data) => {
                console.log(data)
                setShowArchiveImageModal(false)
                reloadClientImages()
            },
            (data) => {
                console.log(data)
            }
        )
    }
    
    return (
        <>
            <div style={{backgroundColor:'white', marginBottom: '30px'}}>
                <div style={{width: '100%', padding: '15px', backgroundColor: 'rgb(240, 240, 240)'}}>
                    <Row>
                        <Col>
                            <h2 className='styled-header'>Saved Images <i style={{marginLeft: '20px', float: 'right'}} onClick={() => {setShowCreateImageModal(true)}} class="fa fa-plus clickable-icon" aria-hidden="true"></i></h2>
                        </Col>
                    </Row>
                </div>
                <div style={{padding: '30px'}}>
                    <Row>
                        {clientImages.map((image, index) => {
                            return (
                                <Col key={index} style={{textAlign: 'center'}} xs={6} sm={6} md={4} lg={3}>
                                    <Image onClick={() => {handleImageClick(image)}} className='mild-neumorphic-card client-image' src={image.url} style={{width: '100%', height: 'auto'}} />
                                </Col>
                            )
                        })}
                    </Row>
                </div>
            </div>
            <Modal show={showCreateImageModal} onHide={() => {setShowCreateImageModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Add Client Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="image-upload" style={{textAlign: 'center', marginTop:'40px'}} {...getRootProps()}  >
                        <Image className='upload-image mild-neumorphic-card' id="previewImg" src={imageToAdd} style={{width: '60%'}} />
                        <input {...getInputProps()} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="secondary-bg" onClick={() => {setShowCreateImageModal(false)}}>
                        Cancel
                    </Button>
                    <Button className="primary-bg" onClick={() => {handleAddImage()}}>
                        Add Image
                    </Button>
                </Modal.Footer>
            </Modal>


            <Modal show={showArchiveImageModal} onHide={() => {setShowArchiveImageModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Remove Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Are you sure you want to remove this image?</p>
                    {
                        imageToRemove &&
                        <Image src={imageToRemove.url} style={{width: '100%'}} />
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button className="secondary-bg" onClick={() => {setShowArchiveImageModal(false)}}>
                        Cancel
                    </Button>
                    <Button className="danger-bg" onClick={() => {handleArchiveImage()}}>
                        Remove Image
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
