import apiClient from "./apiClient";

export const uploadImageAndGetBoatInfo = async (imageBase64) => {
  try {
    const payload = JSON.stringify({
      images_b64: [imageBase64],
    });

    const response = await apiClient.request(
      "knowledge-engine/models-for-images-with-pricing",
      {
        method: "POST",
        body: payload,
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response;
  } catch (error) {
    throw error;
  }
};
