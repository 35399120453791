import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import { getClientProfile } from '../../requests/clientProfile'
import { getMyBoats} from '../../requests/myBoats'
import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Header from '../../universal_components/Header'
import SavedBoatCard from './components/SavedBoatCard'


export default function SavedBoats() {

    const navigate = useNavigate();

    const {state} = useLocation();

    const [clientProfile, setClientProfile] = useState(null);

    const [myBoats, setMyBoats] = useState(null);



    useEffect(() => {
        getClientProfile(
            state.client_profile_id, 
            (data) => {
                console.log(data)
                setClientProfile(data.client_profile)
            },
            (data) => {
                console.log(data)
            }
        )
    }, [state.client_profile_id])


    useEffect(() => {
        getMyBoats(
            state.client_profile_id,
            (data) => {
                console.log(data)
                setMyBoats(data.my_boats)
            },
            (data) => {
                console.log(data)
            }
        )
    }, [state.client_profile_id])



    function reloadMyBoats() {
        getMyBoats(
            state.client_profile_id,
            (data) => {
                console.log(data)
                setMyBoats(data.my_boats)
            },
            (data) => {
                console.log(data)
            }
        )
    }


    function getCurrencySymbol(currency) {
        switch(currency) {
            case 'USD':
                return '$';
            case 'NZD':
                return '$';
            case 'AUD':
                return '$';
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            default:
                return '$';
        }
    }

    function formatNumber(number) {
        return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }





    return (
        <>
            <Header signedIn={true} />
            <Container fluid style={{minHeight: '100vh', backgroundColor: 'rgb(220, 220, 220)'}}>
                {
                    clientProfile && 
                    
                    <Row>
                        <Col md={12} lg={3} className='g-0'>
                            <div className='sidecard'>

                                <div onClick={() => {navigate('/client-page', {state: {client_profile_id: clientProfile.id}});}} className='clickable-card' style={{
                                    backgroundImage: 'url(./landing-img.jpeg)', 
                                    width: '100%', 
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    backgroundRepeat: 'no-repeat',
                                    textAlign: 'center',
                                    paddingTop: '5vh',
                                    paddingBottom: '5vh',
                                }}>
                                    <Image className='mild-neumorphic-card' src='/profile-placeholder.png' style={{width: '20%', borderRadius: '50%'}} />
                                    <h1 style={{paddingTop:'10px'}} className='styled-header'>{clientProfile.name}</h1>
                                    <h2 className='styled-header' style={{marginTop: '30px'}}>Saved Boats</h2>
                                </div>
                                <div style={{height: '1px', width: '100%', backgroundColor: 'gray'}}></div>
                                <div style={{padding: '30px'}}>
                                    <h5 style={{paddingTop:'20px'}}><i class="fa fa-envelope" style={{marginRight: '20px'}} aria-hidden="true"></i> {clientProfile.email}</h5>
                                    <h5 style={{paddingTop:'20px'}}><i class="fa fa-phone" style={{marginRight: '20px'}} aria-hidden="true"></i> {clientProfile.phone}</h5>
                                    <h6 style={{paddingTop:'20px'}}>Required Length: {clientProfile.required_length}m</h6>
                                    <h6 style={{paddingTop:'20px'}}>Budget: {getCurrencySymbol(clientProfile.currency)}{formatNumber(clientProfile.budget)} {clientProfile.currency}</h6>
                                </div>
                        
                            </div>
                        </Col>
                        <Col lg={8} className='g-0'>
                            <Row className='content-section'>
                                <Col md={12} lg={12} className='g-0 responsive-col-padding'>
                                    {
                                        myBoats && myBoats.map((boat) => {
                                            return (
                                                <SavedBoatCard boat={boat} reloadMyBoats={reloadMyBoats} />
                                            )
                                        })
                                    }

                                </Col>
                            </Row>                    
                        </Col>
                    </Row>
                }
                
            </Container>
        </>
    )
}
