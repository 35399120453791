import React, { useState, useRef } from "react";
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Container,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grid,
} from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ParentGraph from "./components/ParentGraph";
import MergedGraph from "./components/MergedGraph";
import DemoPlaceholder from "../../assets/placeholder.jpg";
import { uploadImageAndGetBoatInfo } from "../../api/upload";
import { getPricingData } from "../../api/boat";
import "./UploadImage.css";
import BoatSmallScreen from "./components/BoatSmallScreen";

const UploadImage = () => {
  const [currency, setCurrency] = useState("USD");
  const [imagePreviewSrc, setImagePreviewSrc] = useState(DemoPlaceholder);
  const [uploadedBoats, setUploadedBoats] = useState([]);
  const [pricingData, setPricingData] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [selectedBoats, setSelectedBoats] = useState([]);
  const [expanded, setExpanded] = useState(true);
  const graphCarouselRef = useRef(null);


  const fetchPricingData = async (boats, currency) => {
    try {
      const pricingPromises = boats.map((boat) =>
        getPricingData(boat.make, boat.model, 2010, currency)
      );
      const pricingResponses = await Promise.all(pricingPromises);

      const validPricingData = pricingResponses.map((pricing, index) => {
        if (
          pricing.message ===
          "No pricing guidance available for the given make and model"
        ) {
          return null;
        }
        return pricing;
      });

      setPricingData(validPricingData);
      setSelectedBoats(
        validPricingData.map((pricing) => (pricing ? true : false))
      );
    } catch (error) {
      setErrorMessage("Failed to fetch pricing data.");
    }
  };

  const handleCurrencyChange = (event) => {
    const newCurrency = event.target.value;
    setCurrency(newCurrency);

    if (uploadedBoats.length > 0) {
      setLoading(true);
      fetchPricingData(uploadedBoats, newCurrency).finally(() => {
        setLoading(false);
      });
    }
  };

  const handleImageUpload = async (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const imageBase64 = reader.result.split(",")[1];
        setImagePreviewSrc(reader.result);
        setLoading(true);

        try {
          setUploadedBoats([]);
          setPricingData([]);
          setSelectedBoats([]);
          setErrorMessage("");

          const response = await uploadImageAndGetBoatInfo(imageBase64);
          if (response && response.length > 0) {
            setUploadedBoats(response);

            await fetchPricingData(response, currency);
          } else {
            setErrorMessage("No boats found for the uploaded image.");
          }
        } catch (error) {
          setErrorMessage("Failed to upload image and fetch boat data.");
        } finally {
          setLoading(false);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setIsDragging(false);

    const file = event.dataTransfer.files[0];
    handleImageUpload(file);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };


  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const scrollToCenter = (carouselRef, direction) => {
    if (carouselRef.current) {
      const containerWidth = carouselRef.current.offsetWidth;
      const currentScrollPosition = carouselRef.current.scrollLeft;
      const children = Array.from(carouselRef.current.children);

      let targetIndex;

      const centeredIndex = children.findIndex((child) => {
        const childCenter =
          child.offsetLeft + child.offsetWidth / 2 - currentScrollPosition;
        return (
          childCenter >= containerWidth / 2 - child.offsetWidth / 2 &&
          childCenter <= containerWidth / 2 + child.offsetWidth / 2
        );
      });

      if (direction === "left") {
        targetIndex = Math.max(centeredIndex - 1, 0);
      } else {
        targetIndex = Math.min(centeredIndex + 1, children.length - 1);
      }

      const targetChild = children[targetIndex];
      const targetScrollPosition =
        targetChild.offsetLeft +
        targetChild.offsetWidth / 2 -
        containerWidth / 2;

      carouselRef.current.scrollTo({
        left: targetScrollPosition,
        behavior: "smooth",
      });
    }
  };

  const handleCheckboxChange = (index) => {
    const newSelectedBoats = [...selectedBoats];
    newSelectedBoats[index] = !newSelectedBoats[index];
    setSelectedBoats(newSelectedBoats);
  };

  const filteredPricingData = pricingData.filter(
    (data) => data !== null && selectedBoats[pricingData.indexOf(data)]
  );

  const handleAccordionToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        margin: "0",
      }}
    >
      <Grid
        container
        sx={{
          px: 2,
          display: "flex",
          justifyContent: { xs: "center", md: "end" },
        }}
      >
        <Grid item xs={12} md={2}>
          <FormControl fullWidth margin="normal">
            <Select value={currency} onChange={handleCurrencyChange}>
              <MenuItem value="USD">USD ($)</MenuItem>
              <MenuItem value="GBP">GBP (£)</MenuItem>
              <MenuItem value="EUR">EUR (€)</MenuItem>
              <MenuItem value="CAD">CAD ($)</MenuItem>
              <MenuItem value="AUD">AUD ($)</MenuItem>
              <MenuItem value="NZD">NZD ($)</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      {imagePreviewSrc === DemoPlaceholder && (
        <Box
          sx={{
            textAlign: "center",
            mt: 1,
            py: 3,
            fontWeight: "bold",
            color: "gray",
          }}
        ></Box>
      )}
      <Container
        sx={{
          border: imagePreviewSrc === DemoPlaceholder ? 2 : 0,
          p: 4,
          borderStyle: "dashed",
          borderRadius: 5,
          borderColor:
            imagePreviewSrc === DemoPlaceholder ? "lightgray" : "transparent",
          maxWidth: { xs: 310, sm: 400, md: 450 },
          backgroundColor: isDragging ? "lightblue" : "transparent",
          mb: 3,
        }}
        onDrop={handleDrop}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
      >
        <Box
          sx={{
            margin: "auto",
            borderRadius: 2,
            backgroundImage: `url(${imagePreviewSrc})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            transition:
              "transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
            "&:hover": {
              transform: "scale(1.05)",
            },
            cursor: "pointer",
          }}
          onClick={() => document.getElementById("imageUploadInput").click()}
        >
          <input
            id="imageUploadInput"
            type="file"
            accept="image/*"
            onChange={(event) => handleImageUpload(event.target.files[0])}
            style={{ display: "none" }}
          />

          <Box
            sx={{
              backgroundSize: "contain",
              backgroundPosition: "center",
              height: { xs: 170, sm: 280, md: 300 },
            }}
          />
        </Box>
        {imagePreviewSrc === DemoPlaceholder && (
          <Box
            sx={{
              textAlign: "center",
              mt: 1,
              fontWeight: "bold",
              color: "gray",
            }}
          >
            <Typography variant="h7">
              Drag and drop photo here. Or select a file from your computer
            </Typography>
          </Box>
        )}
      </Container>

      {loading && (
        <Box sx={{ textAlign: "center", my: 4 }}>
          <CircularProgress />
        </Box>
      )}

      {uploadedBoats.length > 0 && (
        <>
          <Typography
            variant="h5"
            sx={{
              textAlign: "center",
              mt: 2,
              fontWeight: "bold",
              backgroundColor: "#034d70",
              color: "white",
              p: 2,
            }}
          >
            Models:
          </Typography>

          <Grid
            container
            sx={{ display: "flex", justifyContent: "center", py: 2 }}
          >
            {uploadedBoats.map((boat, index) => (
              <Grid
                item
                key={index}
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={3}
                sx={{ display: "flex", justifyContent: "center", py: 2 }}
              >
                <BoatSmallScreen
                  boat={boat}
                  index={index}
                  selectedBoats={selectedBoats}
                  handleCheckboxChange={handleCheckboxChange}
                  currency={currency}
                />
              </Grid>
            ))}
          </Grid>

          <Accordion expanded={expanded}>
            <AccordionSummary
              expandIcon={
                <ExpandMoreIcon
                  sx={{
                    color: "white",
                    fontSize: "3rem",
                  }}
                />
              }
              onClick={handleAccordionToggle}
              sx={{
                backgroundColor: "#034d70",
                color: "white",
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", textAlign: "center", width: "100%" }}
              >
                Model Pricing:
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  position: "relative",
                }}
              >
                {uploadedBoats.length > 0 && (
                  <IconButton
                    onClick={() => scrollToCenter(graphCarouselRef, "left")}
                    sx={{
                      position: "absolute",
                      left: 0,
                      zIndex: 2,
                      backgroundColor: "rgba(255,255,255, 0.6)",
                      "&:hover": {
                        backgroundColor: "rgba(3, 77, 112, 0.4)",
                        color: "#fff",
                      },
                    }}
                  >
                    <ArrowBackIosIcon
                      sx={{ color: "#034d70", "&:hover": { color: "#034d70" } }}
                    />
                  </IconButton>
                )}

                <Box
                  ref={graphCarouselRef}
                  sx={{
                    display: "flex",
                    overflowX: "auto",
                    scrollBehavior: "smooth",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    msOverflowStyle: "none",
                    scrollbarWidth: "none",
                  }}
                >
                  {uploadedBoats.map((boat, index) => {
                    const pricing = pricingData[index];
                    if (!pricing) {
                      return null;
                    }
                    return (
                      <Box
                        key={index}
                        sx={{
                          flex: "0 0 auto",
                          minWidth: {
                            xs: "300px",
                            sm: "350px",
                            md: "400px",
                          },
                          p: 2,
                          opacity: selectedBoats[index] ? 1 : 0.4,
                        }}
                      >
                        <ParentGraph
                          data={pricing}
                          make={boat.make}
                          model={boat.model}
                          currency={currency}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 2,
                            ml: 9,
                          }}
                        >
                          <FormControlLabel
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              mt: { xs: 5, sm: 4, md: 4, lg: 0.5 },
                              p: 1,
                              width: 260,
                              backgroundColor: "#034d70",
                              color: "white",
                              borderRadius: 5,
                              "&:hover .MuiSvgIcon-root": {
                                transform: "scale(1.1)",
                                transition: "transform 0.3s ease-in-out",
                              },
                            }}
                            control={
                              <Checkbox
                                checked={selectedBoats[index] || false}
                                onChange={() => handleCheckboxChange(index)}
                                color="primary"
                                sx={{
                                  ml: -2,
                                  color: "white",
                                  "&.Mui-checked": {
                                    color: "white",
                                  },
                                }}
                              />
                            }
                            label={
                              selectedBoats[index] ? (
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    color: "white",
                                  }}
                                >
                                  Added to merged graph
                                </Typography>
                              ) : (
                                <Typography>Removed from merged</Typography>
                              )
                            }
                          />
                        </Box>
                      </Box>
                    );
                  })}
                </Box>

                {uploadedBoats.length > 0 && (
                  <IconButton
                    onClick={() => scrollToCenter(graphCarouselRef, "right")}
                    sx={{
                      position: "absolute",
                      right: 0,
                      zIndex: 2,
                      backgroundColor: "rgba(255,255,255, 0.6)",
                      "&:hover": {
                        backgroundColor: "rgba(3, 77, 112, 0.4)",
                        color: "#fff",
                      },
                    }}
                  >
                    <ArrowForwardIosIcon
                      sx={{ color: "#034d70", "&:hover": { color: "#034d70" } }}
                    />
                  </IconButton>
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </>
      )}

      {filteredPricingData.length > 0 && (
        <Box sx={{ mt: 6 }}>
          <MergedGraph
            dataSets={filteredPricingData}
            title="Merged Model Pricing"
            currency={currency}
            conversionRate={1}
            abbr={currency}
            color="blue"
            makeModelList={uploadedBoats
              .filter((_, index) => selectedBoats[index])
              .map((boat) => `${boat.make} ${boat.model}`)}
            minX={Math.min(
              ...filteredPricingData.map((data) =>
                Math.min(...Object.keys(data.price_curve || {}).map(Number))
              )
            )}
            maxX={Math.max(
              ...filteredPricingData.map((data) =>
                Math.max(...Object.keys(data.price_curve || {}).map(Number))
              )
            )}
          />
        </Box>
      )}

      {errorMessage && (
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            mt: 4,
            color: "gray",
          }}
        >
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

export default UploadImage;
