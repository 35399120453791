import React from 'react'
import LandscapePage from '../../../generic_report_components/LandscapePage'
import { useState, useEffect } from 'react';
import { getBrokerDetails} from '../../../requests/brokerDetails';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Cell from './comparison_page_components/Cell';
import TitleCell from './comparison_page_components/TitleCell';


export default function ComparisonPage(props) {

    const [brokerName, setBrokerName] = useState(null);
    const [brokerEmail, setBrokerEmail] = useState(null);
    const [brokerPhone, setBrokerPhone] = useState(null);


    useEffect(() => {
        getBrokerDetails(
            (data) => {
                if (data.broker_details) {
                    setBrokerName(data.broker_details.name)
                    setBrokerEmail(data.broker_details.email)
                    setBrokerPhone(data.broker_details.phone)
                }
            },
            (data) => {
                console.log(data)
            }
        )
    }, [])


    function getCurrencySymbol(currency) {
        switch(currency) {
            case 'USD':
                return '$';
            case 'NZD':
                return '$';
            case 'AUD':
                return '$';
            case 'EUR':
                return '€';
            case 'GBP':
                return '£';
            default:
                return '$';
        }
    }

    function formatNumber(number) {
        if (number) {
            return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return '';
        }
    }

    function buildPages() {
        let pages = [];
        if (props.report.report_boats.length <= 6) {
            pages.push(props.report.report_boats)
        } else {
            let page = [];
            for (let i = 0; i < props.report.report_boats.length; i++) {
                if (i % 6 === 0 && i !== 0) {
                    pages.push(page);
                    page = [];
                }
                page.push(props.report.report_boats[i])
            }
            pages.push(page);
        }
        return pages;
    }


    return (
        <div>
            {
                buildPages().map((page, index) => {
                    return <div key={index} style={{marginBottom: '40px'}}>
                        <LandscapePage
                            includeFooter={true}
                            brokerDetails={{name: brokerName, email: brokerEmail, phone: brokerPhone}}
                            theme={{
                                theme:props.theme,
                                themePrimaryText:props.themePrimaryText,
                            }}
                        >
                            <div className="report-pt-2 report-pl-2 report-pr-2">
                                <h2 style={{color: props.themePrimaryText}} className='report-title-landscape'>Comparison</h2>
                                <hr></hr>
                            </div>

                            <Container fluid className='report-p-2'>
                                <Row>
                                    <Col xs={2} md={2} lg={2} className='g-0'>

                                    </Col>
                                    <Col>
                                        <Row>
                                            {
                                                page.map((boat, index) => {
                                                    return (
                                                        <Col key={index} xs={2} md={2} lg={2} className='g-0'>
                                                            <div
                                                                style={{
                                                                    backgroundImage: `url(${boat.selected_image_url})`,
                                                                    backgroundRepeat: 'no-repeat',
                                                                    backgroundPosition: 'center',
                                                                    backgroundSize: 'cover',
                                                                    width: '100%',
                                                                }}
                                                                className='comparison-image-landscape'
                                                                
                                                            >
                                                            </div>
                                                        </Col>
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '0px', marginBottom: '2px', padding: '0px'}}>
                                    <TitleCell
                                        text='Name'
                                        theme={props.theme}
                                        themeSecondaryText={props.themeSecondaryText}
                                    />
                                    <Col>
                                        <Row>
                                            {
                                                page.map((boat, index) => {
                                                    return (
                                                        <Cell
                                                            key={index}
                                                            index={index}
                                                            text={boat.title}
                                                        />
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '0px', marginBottom: '2px'}} className='gy-0'>
                                    <TitleCell
                                        text='Price'
                                        theme={props.theme}
                                        themeSecondaryText={props.themeSecondaryText}
                                    />
                                    <Col>
                                        <Row>
                                            {
                                                page.map((boat, index) => {
                                                    return (
                                                        <Cell
                                                            key={index}
                                                            index={index}
                                                            text={`${getCurrencySymbol(boat.currency)}${formatNumber(parseInt(boat.price))} ${boat.currency}`}
                                                        />
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Col>
                                </Row>


                                {
                                    props.report.report_currency && false &&
                                    <Row style={{marginTop: '0px', marginBottom: '2px'}} className='gy-0'>
                                        <TitleCell
                                            text={`Price (${props.report.report_currency})`}
                                            theme={props.theme}
                                            themeSecondaryText={props.themeSecondaryText}
                                        />
                                        <Col>
                                            <Row>
                                                {
                                                    page.map((boat, index) => {
                                                        return (
                                                            <Cell
                                                                key={index}
                                                                index={index}
                                                                text={
                                                                    boat.converted_currency ?
                                                                        `${getCurrencySymbol(props.report.report_currency)}${formatNumber(parseInt(boat.converted_currency))} ${props.report.report_currency}`
                                                                    :
                                                                        `${getCurrencySymbol(boat.currency)}${formatNumber(parseInt(boat.price))} ${boat.currency}`
                                                                }
                                                            />
                                                        )
                                                    })

                                                }
                                            </Row>
                                        </Col>
                                    </Row>
                                }
                                <Row style={{marginTop: '0px', marginBottom: '2px'}} className='gy-0'>
                                    <TitleCell
                                        text='Length'
                                        theme={props.theme}
                                        themeSecondaryText={props.themeSecondaryText}
                                    />
                                    <Col>
                                        <Row>
                                            {
                                                page.map((boat, index) => {
                                                    return (
                                                        <Cell
                                                            key={index}
                                                            index={index}
                                                            text={`${boat.length}m (${parseInt(boat.length*3.281)}ft)`}
                                                        />
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '0px', marginBottom: '2px'}} className='gy-0'>
                                    <TitleCell
                                        text='Year'
                                        theme={props.theme}
                                        themeSecondaryText={props.themeSecondaryText}
                                    />
                                    <Col>
                                        <Row>
                                            {
                                                page.map((boat, index) => {
                                                    return (
                                                        <Cell
                                                            key={index}
                                                            index={index}
                                                            text={boat.year}
                                                        />
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                                <Row style={{marginTop: '0px', marginBottom: '2px'}} className='gy-0'>
                                    <TitleCell
                                        text='Location'
                                        theme={props.theme}
                                        themeSecondaryText={props.themeSecondaryText}
                                    />
                                    <Col>
                                        <Row>
                                            {
                                                page.map((boat, index) => {
                                                    return (
                                                        <Cell
                                                            key={index}
                                                            index={index}
                                                            text={boat.country}
                                                        />
                                                    )
                                                })
                                            }
                                        </Row>
                                    </Col>
                                </Row>
                            </Container>


                        </LandscapePage>
                    </div>
                })
            }
        </div>
    )
}
