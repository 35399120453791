import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Form from 'react-bootstrap/Form'
import Page from '../../../generic_report_components/Page'
import ColorCut from '../../../generic_report_components/ColorCut'
import HeroImage from './boat_page_components/HeroImage'
import Specifications from './boat_page_components/Specifications'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useState } from 'react'
import Title from './boat_page_components/Title'
import Features from './boat_page_components/Features'
import Fields from './boat_page_components/Fields'
import ExtraImages from './boat_page_components/ExtraImages'
import { deleteAppraisalReportBoat } from '../../../requests/appraisal_report/appraisalReportBoat'
import { createAppraisalReportBoatField } from '../../../requests/appraisal_report/appraisalReportBoatField'
import { getBrokerDetails } from '../../../requests/brokerDetails'
import { useEffect } from 'react'

export default function BoatPage(props) {

  const [showModal, setShowModal] = useState(false)

  const [fieldTitle, setFieldTitle] = useState('')
  const [fieldBody, setFieldBody] = useState('')

  const [fieldTitleError, setFieldTitleError] = useState('')
  const [fieldBodyError, setFieldBodyError] = useState('')


  const [selectedImage, setSelectedImage] = useState(props.boat.selected_image_url);


  function validateFormData() {
    var valid = true;
    setFieldTitleError('');
    setFieldBodyError('');

    if (fieldTitle.length === 0) {
        setFieldTitleError('Title is required');
        valid = false;
    }

    if (fieldBody.length === 0) {
        setFieldBodyError('Body is required');
        valid = false;
    }

    return valid;
  }

  function handleAddField() {
    if (!validateFormData()) {
        return;
    }

    var fd = {
      appraisal_report_boat_id: props.boat.id,
      title: fieldTitle,
      body: fieldBody
    }

    createAppraisalReportBoatField(fd, 
      (data) => {
        props.reloadReportCallback()
        setFieldTitle('')
        setFieldBody('')
        setShowModal(false)
      },
      (data) => {
        console.log(data)
      }
    )
  }


  function updateSelectedImageCallback(imageUrl) {
    setSelectedImage(imageUrl);
  }


  function handleRemoveAppraisalReportBoat () {
    var fd = {
      appraisal_report_boat_id: props.boat.id  
    }

    deleteAppraisalReportBoat(fd, 
      (data) => {
        props.reloadReportCallback()
      },
      (data) => {
        console.log(data)
      }
    )

  }


  const [brokerName, setBrokerName] = useState(null);
  const [brokerEmail, setBrokerEmail] = useState(null);
  const [brokerPhone, setBrokerPhone] = useState(null);


    useEffect(() => {
        getBrokerDetails(
            (data) => {
                if (data.broker_details) {
                    setBrokerName(data.broker_details.name)
                    setBrokerEmail(data.broker_details.email)
                    setBrokerPhone(data.broker_details.phone)
                }
            },
            (data) => {
                console.log(data)
            }
        )
    }, [])


  return (
    <div style={{marginBottom: '40px'}}>
      <Page
        includeFooter={true} 
        brokerDetails={{name: brokerName, email: brokerEmail, phone: brokerPhone}}
        theme={{
            theme:props.theme,
            themePrimaryText:props.themePrimaryText,
        }}
      >
          <HeroImage 
            imageUrl={selectedImage} 
            boat={props.boat} 
            updateSelectedImageCallback={updateSelectedImageCallback} 
            comparisonNum={props.comparisonNum}
            theme={props.theme}
            themePrimaryText={props.themePrimaryText}
            themeSecondaryText={props.themeSecondaryText}
          />
          <ColorCut theme={props.theme} height='12%' rightCut='70%'>
              <Title
                theme={props.theme}
                themePrimaryText={props.themePrimaryText}
                themeSecondaryText={props.themeSecondaryText}
                report={props.report}
                boat={props.boat}
              />
          </ColorCut>
          <Container fluid>
              <Specifications
                theme={props.theme}
                themePrimaryText={props.themePrimaryText}
                themeSecondaryText={props.themeSecondaryText}
                boat={props.boat}
              />
          </Container>
          <Container>
            <Row>
              <Col xs={8} sm={8} md={8} lg={8} xl={8} className="g-0">
                <Fields
                  theme={props.theme}
                  themePrimaryText={props.themePrimaryText}
                  themeSecondaryText={props.themeSecondaryText}
                  boat={props.boat}
                  reloadReportCallback={props.reloadReportCallback}
                />
              </Col>
              <Col xs={4} sm={4} md={4} lg={4} xl={4} className="g-0">
                <Features
                  theme={props.theme}
                  themePrimaryText={props.themePrimaryText}
                  themeSecondaryText={props.themeSecondaryText}
                  boat={props.boat}
                  reloadReportCallback={props.reloadReportCallback}
                />
              </Col>
            </Row>
          </Container>
      </Page>
      <Container className='mild-neumorphic-card report-button-bar'>
        <Row>
          <Col className='g-0'>
            <Button className='button-bar-button danger-bg' style={{width: '100%', borderRadius: '0px'}} onClick={() => {handleRemoveAppraisalReportBoat()}} >Remove Boat</Button>
          </Col>
          <Col className='g-0'>
            <Button className='button-bar-button secondary-bg' style={{width: '100%', borderRadius: '0px'}} href={props.boat.url} rel="noreferrer" target='_blank'>View Listing</Button>
          </Col>
          <Col className='g-0'>
            <Button className='button-bar-button primary-bg' style={{width: '100%', borderRadius: '0px'}} onClick={() => {setShowModal(true)}}>Add Field</Button>
          </Col>
        </Row>
      </Container>

      <ExtraImages
        theme={props.theme}
        themePrimaryText={props.themePrimaryText}
        themeSecondaryText={props.themeSecondaryText}
        boat={props.boat}
      />

      <Modal show={showModal} onHide={() => {setShowModal(false)}}>
          <Modal.Header closeButton>
              <Modal.Title className="styled-header">Add Field</Modal.Title>
          </Modal.Header>
          <Modal.Body>

              <p>Title</p>
              <Form.Control value={fieldTitle} onChange={(e) => {setFieldTitle(e.target.value)}} type='text' placeholder='title' />
              <p style={{color: 'red'}}>{fieldTitleError}</p>

              <p style={{marginTop: '20px'}}>Body</p>
              <Form.Control value={fieldBody} onChange={(e) => {setFieldBody(e.target.value)}} as='textarea' rows={5} placeholder='body' />
              <p style={{color: 'red'}}>{fieldBodyError}</p>
          
          </Modal.Body>
          <Modal.Footer>
              <Button className="primary-bg" onClick={() => {handleAddField()}}>Save</Button>
          </Modal.Footer>
      </Modal>

    </div>
  )
}
