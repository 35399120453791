import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";

export default function DirectoryCard(props) {
  return (
    <Box
      className="directory-card-container"
      onClick={props.navigate}
      sx={{ cursor: "pointer" }}
    >
      <Paper
        elevation={3}
        sx={{
          backgroundColor: "rgb(240, 240, 240)",
          p: 3,
          minHeight: 220,
          maxHeight: 300,
        }}
      >
        <Typography variant="h4" className="styled-header">
          {props.title}
        </Typography>
        <Typography variant="h6" sx={{ mt: 2 }}>
          {props.description}
        </Typography>
      </Paper>
    </Box>
  );
}
