import React from 'react'
import { useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import { updateAppraisedBoatTitle } from '../../../../requests/appraisal_report/appraisedBoat'


export default function AppraisedBoatTitle(props) {
  
  const [title, setTitle] = useState(props.appraisedBoat.title)

  const [titleError, setTitleError] = useState('')

  const [showEditTitleModal, setShowEditTitleModal] = useState(false)


  function validateFormData() {
    var valid = true;

    setTitleError('');

    if (title.length === 0) {
      setTitleError('Title is required')
      valid = false;
    }

    return valid;
  }



  function handleSaveTitle() {
    if (!validateFormData()) {
      return;
    }

    var fd = {
      appraised_boat_id: props.appraisedBoat.id,
      title: title
    }

    updateAppraisedBoatTitle(fd, 
      (data) => {
        setShowEditTitleModal(false)
      },
      (data) => {
        console.log(data);
      }
    )
  }
  
  return (
    <>
      <div className='editable-section report-p-2' onClick={() => {setShowEditTitleModal(true)}}>
          <h1 style={{color: props.themePrimaryText}} className='report-title'>{title}</h1>
      </div>
      <Modal show={showEditTitleModal} onHide={() => {handleSaveTitle()}}>
          <Modal.Header closeButton>
              <Modal.Title className="styled-header">Edit Title</Modal.Title>
          </Modal.Header>
          <Modal.Body>
              <div>
                  <p>Title</p>
                  <Form.Control type='text' value={title} onChange={(e) => {setTitle(e.target.value)}} />
                  <p style={{color: 'red'}}>{titleError}</p>
              </div>
          </Modal.Body>
          <Modal.Footer>
              <Button className="primary-bg" onClick={() => {handleSaveTitle()}}>Save</Button>
          </Modal.Footer>
      </Modal>
    </>
  )
}
