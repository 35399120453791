import React from 'react'
import Col from 'react-bootstrap/Col'

export default function TitleCell(props) {
    return (
        <Col xs={2} md={2} lg={2} className='g-0'>
            <p 
                className='report-text-sm-landscape report-p-tiny' 
                style={{
                    margin: '0px', 
                    color: props.themeSecondaryText, 
                    backgroundColor: props.theme, 
                    height: '100%',
                    textAlign: 'center'
                }}>
                    {props.text}
                </p>
        </Col>
    )
}
