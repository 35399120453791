import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useState, useEffect } from 'react'
import { getClientProfiles } from '../../../requests/clientProfile'
import ImageSearchCard from './ImageSearchCard'
import KeywordSearchCard from './KeywordSearchCard'

export default function SearchCard(props) {

    const [searchType, setSearchType] = useState('image')
    
    const [showChangeClientModal, setShowChangeClientModal] = useState(props.hasClientId ? false : true);

    const [clientProfiles, setClientProfiles] = useState([]);

    useEffect(() => {
        getClientProfiles(
            (data) => {
                console.log(data)
                setClientProfiles(data.client_profiles)
            },
            (data) => {
                console.log(data)
            }
        )
    }, [])


    function handleChangeClient(clientProfile) {
        console.log('change client')
        props.changeClientCallback(clientProfile)
        setShowChangeClientModal(false)
    }


    return (
        <Container fluid className='sidecard'>
            <Row>
                <Col className='g-0' style={{
                backgroundImage: 'url(./landing-img.jpeg)', 
                width: '100%', 
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                textAlign: 'center',
                paddingTop: '5vh',
                paddingBottom: '5vh',
            }}>
                    <h1 className='styled-header'>Search</h1>
                    {
                        props.clientProfile ?
                            <div style={{width: '100%', textAlign: 'center'}} className='clickable-card'>        
                                <h2 style={{color: 'white', paddingTop: '20px'}} onClick={() => {setShowChangeClientModal(true)}}>Client: {props.clientProfile.name}</h2>
                            </div>
                        :
                        <div style={{padding: '30px', width: '100%', textAlign: 'center'}} className='clickable-card'>
                            <h2 style={{color: 'white'}} onClick={() => {setShowChangeClientModal(true)}}>
                                Select Client
                            </h2>
                        </div>
                            
                    }
                </Col>
            </Row>
            
            <Row>
                <Col className='g-0'>
                    <div>
                        <Button onClick={() => {setSearchType("image")}} className={searchType === "image" ? "primary-bg" : "secondary-bg"} style={{width: '100%', borderRadius: '0px', paddingTop: '10px', paddingBottom: '10px'}}>Image Search</Button>
                    </div>
                </Col>
                <Col className='g-0'>
                    <div>
                        <Button onClick={() => {setSearchType("keyword")}} className={searchType === "image" ? "secondary-bg" : "primary-bg"} style={{width: '100%', borderRadius: '0px', paddingTop: '10px', paddingBottom: '10px'}}>Keyword Search</Button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className='g-0'>

                    <div style={{height: '1px', width: '100%', backgroundColor: 'gray'}}></div>

                    {
                        searchType === "image" ?
                            <ImageSearchCard clientProfile={props.clientProfile} handleSearchResults={props.handleSearchResults} handleSearchStart={props.handleSearchStart} />
                        :
                            <KeywordSearchCard handleSearchResults={props.handleSearchResults} handleSearchStart={props.handleSearchStart} />
                    }
                </Col>
            </Row>
            <Modal show={showChangeClientModal} onHide={() => {setShowChangeClientModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Select Client to search for:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
    
                        {
                            clientProfiles.map((clientProfile) => {
                                return (
                                    <div onClick={() => {handleChangeClient(clientProfile)}} style={{padding: '5px', marginBottom: '10px'}} key={clientProfile.id} className='clickable-card'>
                                        <h4>{clientProfile.name}</h4>
                                        <hr />
                                    </div>
                                )
                            })
                        }


                </Modal.Body>
                <Modal.Footer>
                    <Button className="secondary-bg" onClick={() => {setShowChangeClientModal(false)}}>
                        Continue without client
                    </Button>
                </Modal.Footer>
            </Modal>

        </Container>
    )
}
