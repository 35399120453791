import React from 'react'



export default function SideCard(props) {

    return (
        <>
            <div className='mild-neumorphic-card info-card' style={{backgroundColor:'white'}}>
                <div style={{width: '100%', textAlign: 'center', padding: '50px'}}>
                    <h1 className='styled-header'>Help</h1>
                </div>
                

                <div style={{marginTop: '50px', textAlign: 'center'}}>
                    
                    <div className='neumorphic-button' onClick={() => {props.handlePageChange('search')}}>
                        <h5>Search</h5>
                    </div>
                    <div className='neumorphic-button' onClick={() => {props.handlePageChange('my boats')}}>
                        <h5>My Boats</h5>
                    </div>
                    <div className='neumorphic-button' onClick={() => {props.handlePageChange('my reports')}}>
                        <h5>My Reports</h5>
                    </div>
                    <div className='neumorphic-button' onClick={() => {props.handlePageChange('build report')}}>
                        <h5>Build Report</h5>
                    </div>
                    <div className='neumorphic-button' onClick={() => {props.handlePageChange('build appraisal report')}}>
                        <h5>Build Appraisal Report</h5>
                    </div>
                </div>
            </div>      
        </>
    )
}
