import React, { useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import Header from "../../universal_components/Header";
import DirectoryCard from "./components/DirectoryCard";
import { useNavigate } from "react-router-dom";
import PricingTool from "./components/PricingTool";
import PricingGraph from "./components/PricingGraph";
import { getPricingData } from "../../api/boat";

export default function Home() {
  const navigate = useNavigate();
  const [valueData, setValueData] = useState(null);
  const [loadingValueData, setLoadingValueData] = useState(false);
  const [noPricingGuidance, setNoPricingGuidance] = useState(false);
  const [currencyData, setCurrencyData] = useState({
    currency: "AUD",
    abbr: "AUD",
  });

  const handleSearchClick = () => {
    navigate("/search", { state: { client_profile_id: null } });
  };

  const handleMyClientsClick = () => {
    navigate("/my-clients");
  };

  const handleValueDataChange = (data, year, make, model, noData) => {
    setLoadingValueData(true);
    setNoPricingGuidance(noData);
    try {
      if (data) {
        setValueData({
          data,
          baseYear: year,
          title: `${make} ${model} Price Curve`,
          make,
          model,
        });
      } else {
        setValueData(null);
      }
    } catch (error) {
      console.error("Error updating value data:", error);
    } finally {
      setLoadingValueData(false);
    }
  };

  const handleCurrencyChange = async (currency) => {
    setCurrencyData({ currency, abbr: currency });

    if (valueData) {
      setLoadingValueData(true);

      try {
        const pricingData = await getPricingData(
          valueData.make,
          valueData.model,
          valueData.baseYear,
          currency
        );
        if (pricingData.message) {
          setNoPricingGuidance(true);
          setValueData(null);
        } else {
          setNoPricingGuidance(false);
          setValueData({
            ...valueData,
            data: pricingData,
          });
        }
      } catch (error) {
        console.error("Error fetching pricing data:", error.message);
        setNoPricingGuidance(true);
        setValueData(null);
      } finally {
        setLoadingValueData(false);
      }
    }
  };

  return (
    <>
      <Header signedIn={true} />
      <Container maxWidth={false} disableGutters>
        <Grid container style={{ minHeight: "100vh" }}>
          <Grid
            item
            xs={12}
            sm={12}
            lg={3}
            sx={{
              backgroundColor: "rgb(210, 210, 210)",
              pb: { xs: 5, md: 3, lg: 0 },
            }}
          >
            <Box
              sx={{
                backgroundImage: "url(./landing-img.jpeg)",
                width: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                textAlign: "center",
                py: "10vh",
              }}
            >
              <Typography variant="h3" className="styled-header">
                Broker Portal
              </Typography>
            </Box>
            <Grid container spacing={1}>
              <Grid
                item
                xs={12}
                md={6}
                lg={12}
                sx={{ mx: { xs: 2, sm: 2, md: 0 } }}
              >
                <DirectoryCard
                  title="My Clients"
                  description="View and manage your clients and their reports."
                  callToAction="Go to My Clients"
                  navigate={handleMyClientsClick}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={12}
                sx={{ mx: { xs: 2, sm: 2, md: 0 } }}
              >
                <DirectoryCard
                  title="Search"
                  description="Use our Proprietary Photolumination technology to find your clients their dream boat."
                  callToAction="Go to Search"
                  navigate={handleSearchClick}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            lg={9}
            sx={{
              backgroundImage: "url(./classic_yacht.jpg)",
              minHeight: "100vh",
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "fixed",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                height: "100%",
                width: "100%",
                p: 2,
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} lg={5}>
                  <Box sx={{ p: 2, backgroundColor: "white", borderRadius: 2 }}>
                    <PricingTool
                      onValueDataChange={handleValueDataChange}
                      onCurrencyChange={handleCurrencyChange}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={12} lg={7} sx={{ borderRadius: 2 }}>
                  {loadingValueData ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <CircularProgress sx={{ height: "10vh", mt: 2 }} />
                    </Box>
                  ) : noPricingGuidance ? (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "40vh",
                        backgroundColor: "white",
                        borderRadius: 2,
                      }}
                    >
                      <Typography variant="h6" color="textSecondary">
                        No pricing guidance available for the given make and
                        model
                      </Typography>
                    </Box>
                  ) : (
                    valueData && (
                      <PricingGraph
                        data={valueData.data}
                        title={valueData.title}
                        currency={currencyData.currency}
                        conversionRate={1}
                        abbr={currencyData.abbr}
                      />
                    )
                  )}
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
