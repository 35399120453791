import React from 'react'
import Modal from 'react-bootstrap/Modal'
import { useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import { updateAppraisalReportBoatField, deleteAppraisalReportBoatField } from '../../../../requests/appraisal_report/appraisalReportBoatField'


export default function Field(props) {

    const [showEditModal, setShowEditModal] = useState(false)

    const [fieldTitle, setFieldTitle] = useState(props.field.title)

    const [fieldBody, setFieldBody] = useState(props.field.body)

    const [fieldTitleError, setFieldTitleError] = useState('')
    const [fieldBodyError, setFieldBodyError] = useState('')


    function validateFormData() {
        var valid = true;
        setFieldTitleError('');
        setFieldBodyError('');

        if (fieldTitle.length === 0) {
            setFieldTitleError('Title is required');
            valid = false;
        }

        if (fieldBody.length === 0) {
            setFieldBodyError('Body is required');
            valid = false;
        }

        return valid;
    }



    function handleEditField() {
        if (!validateFormData()) {
            return;
        }

        var fd = {
            appraisal_report_boat_field_id: props.field.id,
            title: fieldTitle,
            body: fieldBody
        }

        updateAppraisalReportBoatField(fd, 
            (data) => {
                setShowEditModal(false)
            },
            (data) => {
                console.log(data)
            }
        )
    }


    function handleDeleteField() {
        var fd = {
            appraisal_report_boat_field_id: props.field.id
        }

        deleteAppraisalReportBoatField(fd, 
            (data) => {
                setShowEditModal(false)
                props.reloadReportCallback()
            },
            (data) => {
                console.log(data)
            }
        )
    }


    return (
        <>
            <div onClick={() => {setShowEditModal(true)}} className='report-pl-2 report-mt-1 editable-section'>
                <h3 style={{color: props.themePrimaryText}} className='report-subtitle-sm'>{fieldTitle}</h3>
                <p className='report-text'>{fieldBody}</p>
            </div>


            <Modal show={showEditModal} onHide={() => {setShowEditModal(false)}}>
                <Modal.Header closeButton>
                    <Modal.Title className="styled-header">Edit Field</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Title</p>
                    <Form.Control value={fieldTitle} onChange={(e) => {setFieldTitle(e.target.value)}} type='text' placeholder='title' />
                    <p style={{color: 'red'}}>{fieldTitleError}</p>

                    <p style={{marginTop: '20px'}}>Body</p>
                    <Form.Control value={fieldBody} onChange={(e) => {setFieldBody(e.target.value)}} as='textarea' rows={5} placeholder='body' />
                    <p style={{color: 'red'}}>{fieldBodyError}</p>
                
                </Modal.Body>
                <Modal.Footer>
                    <Button className="danger-bg" onClick={() => {handleDeleteField()}}>Delete</Button>
                    <Button className="primary-bg" onClick={() => {handleEditField()}}>Save</Button>
                </Modal.Footer>
            </Modal>
        </>
    )


}
